import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material/material.module';

import { PieChartComponent } from './pie-chart/pie-chart.component';
import { MultilineChartComponent } from './multiline-chart/multiline-chart.component';
import { GroupedBarChartComponent } from './grouped-bar-chart/grouped-bar-chart.component';
import { MiniCardComponent } from './mini-card/mini-card.component';
import { RadialChartComponent } from './radial-chart/radial-chart.component';
import { CalendarChartComponent } from './calendar-chart/calendar-chart.component';
import { AlarmsComponent } from './/alarms/alarms.component';
import { AlarmTableComponent } from './alarms/alarm-table/alarm-table.component';
import { RasterLegendComponent } from './raster-legend/raster-legend.component';

@NgModule({
    declarations: [
        PieChartComponent,
        MultilineChartComponent,
        GroupedBarChartComponent,
        MiniCardComponent,
        RadialChartComponent,
        CalendarChartComponent,
        AlarmsComponent,
        AlarmTableComponent,
        RasterLegendComponent
    ],
    exports: [
        PieChartComponent,
        MultilineChartComponent,
        GroupedBarChartComponent,
        MiniCardComponent,
        RadialChartComponent,
        CalendarChartComponent,
        AlarmsComponent,
        AlarmTableComponent,
        RasterLegendComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ]
})
export class SharedDashboardModule { }
