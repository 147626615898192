<mat-form-field tabindex="0" (blur)="!fileInputDirty && blur.emit()" (focus)="focus.emit()">
  <mat-label>{{alias || name}}</mat-label>
  <input matInput disabled [value]="displayedValue" [ngClass]="{'file-text' : isFileUrl}">

  <div *ngIf="isFileUrl" class="click-overlay" (click)="openFile($event)" [ngClass]="{'file-link' : isFileUrl}"></div>

  <!-- Icon buttons file open/upload -->
  <button matSuffix mat-icon-button title="Επιλογή αρχείου" (click)="fileInput.click()">
    <mat-icon>folder
      <input #fileInput hidden (change)="onSelectFile($event)" type="file">
    </mat-icon>
  </button>

  <button matSuffix mat-icon-button title="Ανέβασμα αρχείου" (click)="onUploadFile()"
          *ngIf="(fileInput && fileInput.value && fileInput.value.length > 0) && !!file">
    <mat-icon class="save-icon">save
    </mat-icon>
  </button>

</mat-form-field>