import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterEventType } from 'app/models';

@Component({
  selector: 'app-filter-control',
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.scss']
})
export class FilterControlComponent {
  @Input() layerFilter = '';
  @Input() enabled = false;
  @Output() onFilterChange: EventEmitter<FilterEventType> = new EventEmitter();
  
  filterEnabledLabel = $localize`Φίλτρο (ενεργό)`;
  filterDisabledLabel = $localize`Φίλτρο (ανενεργό)`;
  enableFilterLabel = $localize`Εφαρμογή φίλτρου`;
  disableFilterLabel = $localize`Απενεργοποίηση φίλτρου`;
  createFilterLabel = $localize`Δημιουργία φίλτρου`;

  showMoreFilterControls = false;
  
  onFilter(toggleFilter: boolean) {
    if (this.showMoreFilterControls && !toggleFilter) {
      this.showMoreFilterControls = false;
      return;
    }

    if (!this.layerFilter) {
      this.onEdit();
      return;
    }

    if (toggleFilter) {
      this.onFilterChange.next(this.enabled ? 'DISABLE' : 'ENABLE');
      this.showMoreFilterControls = false;
      return;
    }

    this.showMoreFilterControls = true;
  }

  onDelete() {
    this.onFilterChange.next('DELETE');
    this.showMoreFilterControls = false;
  }

  onEdit() {
    this.onFilterChange.next('EDIT');
    this.showMoreFilterControls = false;
  }
}

