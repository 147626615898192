import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';

// App imports
import { StateService } from '../../core/state.service';
import { GeoserverService } from '../../core/geoserver.service';
import { MapService } from '../map.service';
import { ActiveScreenManagementService } from '../../core/active-screen-management.service';
import { WorkspaceLayer, OPERATIONS, ScreenState, LayerControlEvent, LayerControl, NavButtons, FilterEventType } from '../../models';
import { TimeseriesToggleService } from 'app/shared/services/timeseries-toggle.service';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { EditingStateService } from "../../shared/services/editing-state.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash-es';
import { LayoutService } from 'app/core/layout.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
	selector: 'app-layer-list',
	templateUrl: './layer-list.component.html',
	styleUrls: ['./layer-list.component.scss'],
	animations: [
		trigger('collapseExpand', [
			state('true', style({
				height: '*',
				visibility: 'visible',
				opacity: '1'
			})),
			state('false', style({
				height: '0px',
				visibility: 'hidden',
				opacity: '0'
			})),
			transition('* => *', animate('200ms ease-in-out'))
		])

	]
})
export class LayerListComponent implements OnInit {

	@HostListener("document:themeChange") onThemeChange() {
		setTimeout(() => {
			const appRootEl = document.querySelector("app-root");
			const computedStyle = getComputedStyle(appRootEl);

			if (appRootEl.classList.contains('dark-theme')) {
				this.bgColor = this.toHex(
					computedStyle.getPropertyValue("--primary-dark-color")
				);
			} else {
				this.bgColor = this.toHex(
					computedStyle.getPropertyValue("--primary-darker-color")
				);
			}

			this.fontColor = this.toHex(
				computedStyle.getPropertyValue("--text-color")
			);
		}, 1);
	}

	private toHex(color: string) {
		if (color.startsWith("rgb")) {
			const parts = color.substring(color.indexOf("(")).split(",");
			const r = parseInt(parts[0].substring(1).trim(), 10);
			const g = parseInt(parts[1].trim(), 10);
			const b = parseInt(parts[2].trim(), 10);

			const hex = ((1 << 24) + (r << 16) + (g << 8) + b)
				.toString(16)
				.slice(1)
				.toUpperCase();

			return `0x${hex}`;
		} else if (color.startsWith("#")) {
			return "0x" + color.slice(1).toUpperCase();
		}

		return color;
	}

	public bgColor: string;
	public fontColor: string;

	private stateUnsubscription;
	private initialized: boolean;
	public workspaceLayers: Array<WorkspaceLayer>;
	public otherLayersGroup: Array<WorkspaceLayer>;

	protected groupEnabledMap: Map<string, boolean>;
	protected groupVisibilityMap: Map<string, boolean>;
	protected groupHasEditingMap: Map<string, boolean>;
	protected groupHasTopologyEditingMap: Map<string, boolean>;
	public editorVisible: boolean = false;
	public editorEnabledGroupTitle: string = '';
	public topologyEditorEnabledGroupTitle: string = '';
	public topologyEditorVisible: boolean = false;
	private initLayerVisibilityDone = false;
	public workspaceLayerIcons: { [key: string]: string }[] = [];
	public dataTableLayerTitle: string;

	protected slidersEnabled: Map<string, boolean>;
	protected showDetails: Map<string, boolean>;
	protected opacityValues: Map<string, number>;
	protected otherGroupLabel: string = $localize`Άλλα`;
	private destroy$: Subject<void> = new Subject();

	constructor(public stateService: StateService,
		private mapService: MapService,
		private geoserverService: GeoserverService,
		private asmService: ActiveScreenManagementService,
		private cdRef: ChangeDetectorRef,
		private toggleTseries: TimeseriesToggleService,
		private editingStateService: EditingStateService,
		private layoutService: LayoutService) {
		this.initialized = false;
		this.workspaceLayers = [];

		this.groupEnabledMap = new Map<string, boolean>();
		this.groupVisibilityMap = new Map<string, boolean>();
		this.groupHasEditingMap = new Map<string, boolean>();
		this.groupHasTopologyEditingMap = new Map<string, boolean>();

		this.otherLayersGroup = [];
		this.slidersEnabled = new Map<string, boolean>();
		this.showDetails = new Map<string, boolean>();
		this.opacityValues = new Map<string, number>();
		this.onThemeChange();
		this.layoutService.dataTableLayerTitle$.pipe(
			takeUntil(this.destroy$)
		).subscribe(dataTableLayerTitle => this.dataTableLayerTitle = dataTableLayerTitle);
	}

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.updateFromState();
	}



	updateFromState() {

		if (!this.initialized && this.stateService.getState().workspaceModelsLoaded) {

			this.workspaceLayerIcons = [];
			let screenState = this.stateService.getScreenState();
			let initialWorkspaceLayers = cloneDeep(this.stateService.getWorkspaceLayers());
			let workspaceLayers = [];

			if (screenState && screenState.layerOrder && screenState.layerOrder.length > 0) {
				workspaceLayers = cloneDeep(screenState.layerOrder);
				initialWorkspaceLayers = this.asmService.filterLayersByScreen(initialWorkspaceLayers);
				this.mapService.adjustWorkspaceLayers(workspaceLayers, initialWorkspaceLayers);
				for (let i = 0; i < workspaceLayers.length; i++) {
					this.mapService.adjustWorkspaceLayers(workspaceLayers[i].Layer, initialWorkspaceLayers[i].Layer);
				}

			} else {
				workspaceLayers = initialWorkspaceLayers;
			}

			if (workspaceLayers && workspaceLayers.length > 0) {
				this.workspaceLayers = this.asmService.filterLayersByScreen(workspaceLayers);

				this.workspaceLayers.forEach(group => {
					group.Layer.forEach(layer => {
						layer.KeywordList = [...new Set(layer.KeywordList)];
					});
				})

				this.workspaceLayers.forEach((workspace) => {
					if (!!workspace.Layer) { // is layer group
						if (workspace.KeywordList.includes('expand')) {
							this.groupEnabledMap.set(workspace.Title, false);
							this.groupVisibilityMap.set(workspace.Title, true);
						}
						else {
							this.groupEnabledMap.set(workspace.Title, false);
							this.groupVisibilityMap.set(workspace.Title, false);
						}
						workspace.Layer.forEach((layer) => {
							this.slidersEnabled.set(layer.Title, false);
							this.opacityValues.set(layer.Title, (!!layer.Model && layer.Model.opacity) ? layer.Model.opacity : 100);
							//init showDetails map
							this.showDetails.set(layer.Title, false);
						})

						// Check for editing keyword
						if (workspace.KeywordList.includes('is_edit')) {
							this.groupHasEditingMap.set(workspace.Title, true);
						} else {
							this.groupHasEditingMap.set(workspace.Title, false);
						}

						// Check for topology  editing keyword
						if (workspace.KeywordList.includes('is_topology_edit')) {
							this.groupHasTopologyEditingMap.set(workspace.Title, true);
						} else {
							this.groupHasTopologyEditingMap.set(workspace.Title, false);
						}

					} else {
						this.otherLayersGroup.push(workspace);
						this.slidersEnabled.set(workspace.Title, false);
						this.opacityValues.set(workspace.Title, (!!workspace.Model && workspace.Model.opacity) ? workspace.Model.opacity : 100);
						this.showDetails.set(workspace.Title, false);
					}
				});

				if (this.otherLayersGroup.length > 0) {
					this.groupEnabledMap.set(this.otherGroupLabel, false);
					this.groupVisibilityMap.set(this.otherGroupLabel, false);
				}
				this.initLayerVisibility();

				this.initialized = true;
				this.cdRef.detectChanges();
			}
		} else {
			let workspaceLayers = this.workspaceLayers;/* this.stateService.getWorkspaceLayers() as WorkspaceLayer[]; */
			if (workspaceLayers && workspaceLayers.length > 0) {
				workspaceLayers = this.asmService.filterLayersByScreen(workspaceLayers);
				workspaceLayers.forEach(workspace => {
					if (!workspace.Layer.some(layer => !layer.KeywordList.includes('show'))) {
						this.groupEnabledMap.set(workspace.Title, true);
					}
				});
				this.cdRef.detectChanges();

			}
		}

	}

	public drop(event: CdkDragDrop<WorkspaceLayer[]>, index: number) {
		moveItemInArray(this.workspaceLayers[index].Layer, event.previousIndex, event.currentIndex);
		StateService.stateStore.dispatch(this.stateService.setLayerOrderOperation(this.workspaceLayers));
		let newZindex = this.mapService.calculateMapIndex(event.currentIndex, this.workspaceLayers[index], this.workspaceLayers);
		this.mapService.setLayerIndex(this.workspaceLayers[index].Layer[event.currentIndex], newZindex);

		for (let i = 0; i < this.workspaceLayers[index].Layer.length; i++) {
			this.mapService.setLayerIndex(this.workspaceLayers[index].Layer[i],
				this.mapService.calculateMapIndex(i, this.workspaceLayers[index], this.workspaceLayers));
		}

	}

	checkLayerVisibility(layer, layerKeywords: Array<string>) {
		if (!this.initLayerVisibilityDone) {
			return false;
		}
		return layerKeywords.includes('show');
	}

	initLayerVisibility() {
		// Get screen state from local storage
		const state = this.stateService.getScreenState() as ScreenState;
		const firstRun = this.stateService.getUserStateFirstRun();

		// If openLayers exist
		if (state.openLayers && !firstRun) {
			const openLayerNames = state.openLayers.map(so => so.Name);
			this.workspaceLayers.forEach(wl => {
				// Remove existing shown layers inside workspaceLayer.keywordList
				this.removeExistingShownLayers(wl);
				// Add show for layers found in local storage (openLayers)
				if (openLayerNames.indexOf(wl.Name) !== -1) {
					wl.KeywordList.push('show');
				}

				// Repeat above for sublayers of each group
				if (wl.Layer) {
					wl.Layer.forEach(sl => {
						this.removeExistingShownLayers(sl);
						if (openLayerNames.indexOf(sl.Name) !== -1) {
							sl.KeywordList.push('show');
						}
					});
				}
			});
		}

		this.restoreLayerFilters();
		this.initLayerVisibilityDone = true;
	}

	restoreLayerFilters() {
		const state = this.stateService.getScreenState() as ScreenState;
		const firstRun = this.stateService.getUserStateFirstRun();

		if (!state.layerFilters?.length || !state.layerFilters.some(lf => !!lf.filter) || firstRun) {
			return;
		}

		// Restore layer filters from LocalStorage
		this.workspaceLayers.forEach(
			wl => {
				wl.Layer.forEach(
					(layer, i) => {
						const matchFilter = state.layerFilters.find(lf => !!lf.filter && lf.layerName === layer.Name);
						if (!!matchFilter?.filter) {
							// TODO: Why do we need to mutate stateLayer.Model here to update the state of the filter-control UI?
							// We do it already in the next statement in `setLayerEcqlFilter` where we call setLayerFilterOperation
							const stateLayer = this.stateService.getWorkspaceLayerByName(layer.Name);
							stateLayer.Model.ecql_filter = matchFilter.filter;
							stateLayer.Model.ecql_applied = matchFilter.applied;
							if (matchFilter.filter && matchFilter.applied && layer.KeywordList.includes('show')) {
								setTimeout(() => {
									this.mapService.setLayerEcqlFilter(stateLayer, matchFilter.filter, 'ENABLE');
								}, 1
								);
							}
						}
					}
				)
			});
	}

	removeExistingShownLayers(wl: WorkspaceLayer) {
		// Find and remove 'show' from keywordList of the provided layer
		const showIndex = wl.KeywordList.findIndex(k => k === 'show');
		if (showIndex > -1) {
			wl.KeywordList.splice(showIndex, 1);
		}
	}

	toggleLayer(layer: WorkspaceLayer, index: number, visible: boolean, other: boolean = false, groupLabel?: string) {

		// This index is just useful for zIndex in map layers so it won't break the app if it is not 100% correct
		const shiftIndex = (other ? index + this.otherLayersGroup.length : index);
		// Reset opacity slider value
		// this.opacityValues.set(layer.Title, 100);

		let layerJustEnabled = false;
		if (visible) {
			if (!layer.KeywordList.includes('show')) {
				layer.KeywordList.push('show');
				layerJustEnabled = true;
			} else {
				return; // Do not open if already opened
			}
		} else {
			if (layer.KeywordList.includes('show')) {
				layer.KeywordList.splice(layer.KeywordList.indexOf('show'), 1);
			} else {
				return; // Do not close if already closed
			}
		}

		if (layer.KeywordList.includes('render_as_wfs')) {
			this.mapService.toggleWFSService(layer, shiftIndex, visible, undefined, undefined, '_edit');
		} else if (layer.KeywordList.includes('ImageMosaic') || layer.KeywordList.includes('TiledGWC')) {
			this.mapService.toggleGWCTileService(layer, shiftIndex, visible);
		} else if (layer.KeywordList.includes('Tiled')) {
			this.mapService.toggleWMSService(layer, shiftIndex, visible);
		} else {
			this.mapService.toggleImageWMS(layer, shiftIndex, visible);
		}

		// If groupLabel was set then we can derive if sublayers are closed and set groupEnabledMap status
		if (!!groupLabel) {
			this.groupEnabledMap.set(groupLabel, this.getGroupLabelHighlight(groupLabel));
		}

		// If layer has an applied filter then apply it after setting visibility
		if (layerJustEnabled && this.layerHasFilterApplied(layer.Name)) {
			const layerContainingGroup = this.workspaceLayers.find(wl => wl.Name === groupLabel);
			if (layerContainingGroup) {
				setTimeout(() => this.onLayerControl({event: LayerControl.FILTER, value: 'ENABLE'}, layer, index, layerContainingGroup));
			} else {
				console.warn(`Could not apply filter after layer visibility toggle. Layer's parent group could not be found`);
			}
		}
	}

	private layerHasFilterApplied(layerName: string): boolean {
		const layerModel = this.stateService.getWorkspaceLayerByName(layerName)?.Model;
		return !!layerModel?.ecql_filter && layerModel?.ecql_applied;
	}

	toggleGroup(groupLabel) {
		let mapIndex: number;
		let totalLayers = this.workspaceLayers.reduce((Layers, group) => Layers + group.Layer.length, 0);
		let eventValue = !this.groupEnabledMap.get(groupLabel);

		if (groupLabel === this.otherGroupLabel) {
			this.otherLayersGroup.forEach((layer, i) => {
				if (!layer.Layer) {
					this.toggleLayer(layer, i - totalLayers, eventValue);
				}
			});
		}
		else {
			const groups = this.workspaceLayers.find(workspace => workspace.Title === groupLabel);
			if (!!groups.Layer) {
				groups.Layer.forEach((layer, i) => {
					mapIndex = this.mapService.calculateMapIndex(i, groups, this.workspaceLayers);
					this.toggleLayer(layer, mapIndex, eventValue);
				});
			}
		}

		this.groupEnabledMap.set(groupLabel, eventValue);

		// Align visibility with enabled group state
		// this.groupVisibilityMap.set(groupLabel, this.groupEnabledMap.get(groupLabel));

	}

	toggleGroupVisibility(groupLabel) {
		this.groupVisibilityMap.set(groupLabel, !this.groupVisibilityMap.get(groupLabel));
	}

	getGroupLabelHighlight(groupLabel) {
		if (groupLabel === this.otherGroupLabel) {
			return !this.otherLayersGroup.find(sublayer => !sublayer.KeywordList.includes('show'));
		}
		const groups = this.workspaceLayers.find(workspace => workspace.Title === groupLabel);
		if (!!groups.Layer) {
			return !groups.Layer.find(sublayer => !sublayer.KeywordList.includes('show'));
		}
		else return false;
	}

	onZoomTo(layer) {
		this.mapService.zoomToLayer(layer);
	}

	onOpenAttributeTable(layer) {

		// Check if is only view layer
		if (this.geoserverService.getKeyValue(layer, 'is_view')) {
			return;
		}

		// Fetch
		StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));
		this.geoserverService.getFeature(layer, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
	}

	public toggleLayerDetails(layer: string) {
		this.showDetails.set(layer, !this.showDetails.get(layer))
	}


	onEnableDisableStyle(layer) {

		this.mapService.getMap().getLayers().forEach((currentLayer) => {

			if (currentLayer && currentLayer.get('name') === layer.Name) {//Find the map layer

				if (currentLayer.getSource().getParams().env && currentLayer.getSource().getParams().env === 'font-size:0') {
					currentLayer.getSource().updateParams({
						env: 'font-size:13'
					})
				} else {
					currentLayer.getSource().updateParams({
						env: 'font-size:0'
					})
				}

				currentLayer.getSource().refresh;

			}
		});

	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
		this.stateUnsubscription();
	}

	toggleSlider(name) {
		// this.slidersEnabled.forEach((v, k, m) => {
		// 	if (k != name) {
		// 		m.set(k, false);
		// 	}
		// });
		this.slidersEnabled.set(name, !this.slidersEnabled.get(name));
	}

	opacityChange(layer, value, layerTitle) {
		// Use the slider.value here to set map layer opacity
		// console.log(slider.value);
		this.opacityValues.set(layerTitle, value);
		this.mapService.setLayerOpacity(layer, value);
	}

	onToggleEdit(layer: WorkspaceLayer) {
		this.editTogglingHandler(layer);
	}

	onToggleTopologyEdit(layer: WorkspaceLayer) {
		this.editTogglingHandler(layer);
	}

	getGroupNameFromAbstract(abstract: string) {
		// Layer-Group type layer: makrohori:epanet
		let regex = /layer:(.*)/g;
		let name = regex.exec(abstract);

		return name[1].trim();
	}

	onTimeseriesClick(layer: WorkspaceLayer) {
		this.toggleTseries.showTimeseriesWidget(layer);
	}

	exportKMZ(layer: WorkspaceLayer) {
		this.mapService.getExportURL('kmz', [layer]);
	}

	onLayerControl(controlEvent: LayerControlEvent, layer: WorkspaceLayer, index: number, group: WorkspaceLayer) {
		let mapIndex = this.mapService.calculateMapIndex(index, group, this.workspaceLayers);
		switch (controlEvent.event) {
			case LayerControl.CREATE: {
				this.onToggleCreateV3(layer, group, controlEvent.value);
				break;
			}
			case LayerControl.EDIT: {
				this.onToggleEditV3(layer, controlEvent.value);
				break;
			}
			case LayerControl.TOPOLOGY_EDIT: {
				this.onToggleEditV3(layer, controlEvent.value);
				break;
			}
			case LayerControl.ENABLE: {
				this.toggleLayer(layer, mapIndex, controlEvent.value, false, group.Name);
				break;
			}
			case LayerControl.OPACITY: {
				this.toggleSlider(layer.Title);
				break;
			}
			case LayerControl.TIMESERIES: {
				this.onTimeseriesClick(layer);
				break;
			}
			case LayerControl.EXPORT: {
				this.exportKMZ(layer);
				break;
			}
			case LayerControl.FOCUS: {
				this.onZoomTo(layer);
				break;
			}
			case LayerControl.TABLE: {
				this.onOpenAttributeTable(layer);
				break;
			}
			case LayerControl.FILTER: {
				const layerEventType = (controlEvent.value as FilterEventType);
				if (layerEventType === 'EDIT') {
					StateService.stateStore.dispatch(this.stateService.setLayerFilterNameOperation(layer.Name));
					this.layoutService.setSideNavContent(NavButtons.filters);
					return;
				}
				// TODO: Explain why layer !== this.stateService.getWorkspaceLayerByName(layer.Name)
				const stateLayer = this.stateService.getWorkspaceLayerByName(layer.Name);
				this.mapService.setLayerEcqlFilter(stateLayer, stateLayer.Model.ecql_filter, layerEventType);

				// When we apply the filter and if the respective layer data table is displayed,
				// we should refresh table data afterwards too
				if (this.dataTableLayerTitle === layer?.Name) {
					setTimeout(() => this.onOpenAttributeTable(layer));
				}

				break;
			}
			default: {
				this.toggleLayerDetails(layer.Title);
				return false;
			}
		}

	}

	onToggleCreateV3(layer: any, group: WorkspaceLayer, enable: boolean) {
		if (enable) {
			const operation = layer.Model.is_topology_edit ? OPERATIONS.topology_editor_draw : OPERATIONS.editor_draw;
			StateService.stateStore.dispatch(this.stateService.setOperation(operation));
			StateService.stateStore.dispatch(this.stateService.setEditOperation({
				active: true,
				groupName: this.getGroupNameFromAbstract(group.Abstract)
			}));
			this.editingStateService.setLayerToDraw(layer);
		} else {
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
			this.editingStateService.setLayerToDraw(null);
			this.layoutService.formToggle(false);
			StateService.stateStore.dispatch(this.stateService.setFormDataOperation(null));
		}

		setTimeout(() => {
			if (enable) {
				this.topologyEditorVisible = layer.Model.is_topology_edit;
				this.editorVisible = layer.Model.is_edit;
			} else {
				this.topologyEditorVisible = false;
				this.editorVisible = false;
			}
		}, 1);
	}

	onToggleEditV3(layer: any, enable: boolean) {
		if (enable) {
			const operation = layer.Model.is_topology_edit ? OPERATIONS.topology_editor_edit : OPERATIONS.editor_edit;
			StateService.stateStore.dispatch(this.stateService.setOperation(operation));
			this.editingStateService.setLayerToEdit(layer);
		} else {
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
			this.editingStateService.setLayerToEdit(null);
			this.layoutService.formToggle(false);
			StateService.stateStore.dispatch(this.stateService.setFormDataOperation(null));
		}

		setTimeout(() => {
			if (enable) {
				this.topologyEditorVisible = layer.Model.is_topology_edit;
				this.editorVisible = layer.Model.is_edit;
			} else {
				this.topologyEditorVisible = false;
				this.editorVisible = false;
			}
		}, 1);
	}

	private editTogglingHandler(layer: WorkspaceLayer) {
		this.editorVisible = !this.editorVisible;
		if (this.editorVisible) {
			this.topologyEditorVisible = false;
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.editor_active));

			StateService.stateStore.dispatch(this.stateService.setEditOperation({
				active: true,
				groupName: this.getGroupNameFromAbstract(layer.Abstract)
			}));
		} else {
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
		}
	}

}
