<app-loader *ngIf="!features?.length"></app-loader>

<div #matTableContainer class="mr-table-container">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort($event)" [trackBy]="myTrackById"
         class="table-transparent">
    <ng-container *ngIf="showRowSelectionCheckbox && selectedLayer?.Model.checkboxesVisible" matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? onSelectAllRowsChange() : null" [checked]="listSelection.hasValue() && isAllSelected()"
          [indeterminate]="listSelection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>

      <td mat-cell *matCellDef="let row">
        <mat-checkbox class="checkbox" (click)="$event.stopPropagation()" (change)="$event ? onRowSelect(row) : null"
          [checked]="listSelection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container
      *ngFor="let column of (showRowSelectionCheckbox && selectedLayer?.Model.checkboxesVisible) ? (displayedColumns | slice:1) : displayedColumns; let i = index;"
      [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'dashboardFont': dashboardMode}">
        {{tableHeaders[i]}}
      </th>

      <td mat-cell *matCellDef="let row" [ngClass]="{'dashboardFont': dashboardMode}">
        <div [innerHtml]="row[column] | tolink"></div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        class="row-cursor"
        [ngClass]="{'selected-row' : selectedRows | rowSelected : row}"
        (click)="onRowSelect(row)">
    </tr>
  </table>
</div>

<div class="mr-paginator-container">
  <mat-paginator [ngClass]="{'dashboardFont': dashboardMode}" #paginator [pageSize]="dataPageSize" [length]="dataLength"
    [showFirstLastButtons]="false" [hidePageSize]="dashboardMode" [pageSizeOptions]="pageSizeOptions"
    (page)="onPageEvent($event)">
  </mat-paginator>

  <div class="controls-wrapper">
    <button *ngIf="showFilterButton"
            class="filter-tag"
            (click)="handleTableAction('filter')"
            matTooltip="Επεξεργασία φίλτρου"
            i18n-matTooltip="Edit filter">
      <mat-icon>filter_list</mat-icon>&nbsp;<span i18n>Φίλτρο ενεργό</span>
    </button>

    <button *ngIf="this.selectedLayer?.Model.geometryType=='MultiLineString'" mat-icon-button
      i18n-matTooltip="Total length | Total length" matTooltip="Συνολικό μήκος" (click)="handleTableAction('lineLayerLength')">
      <mat-icon>straighten</mat-icon>
    </button>

    <button *ngIf="this.selectedLayer?.KeywordList.includes('can_upload_csv')" mat-icon-button matTooltip="Εισαγωγή CSV" i18n-matTooltip="CSV Import"
      (click)="handleTableAction('import')">
      <mat-icon>cloud_upload</mat-icon>
    </button>

    <button *ngIf="this.selectedLayer?.Model.canMoveTo" [matMenuTriggerFor]="clickMenu" mat-icon-button
      i18n-matTooltip="Move to | Move to" matTooltip="Μετακίνηση σε" (click)="availableDestinationLayers()">
      <mat-icon>swap_horiz</mat-icon>
      <mat-menu #clickMenu="matMenu">
        <button mat-menu-item (click)="handleTableAction('move',availableLayer)"
          *ngFor="let availableLayer of availableLayers">
          <span> {{ availableLayer.Title }}</span>
        </button>
      </mat-menu>
    </button>

    <button mat-icon-button i18n-matTooltip="Calculate elevation for layer| Calculate elevation for layer"
      matTooltip="Υπολογισμος Υψομέτρου" *ngIf="dataModel?.geometryType === 'MultiPoint' && hasElevationField()"
      (click)="handleTableAction('calculate')">
      <mat-icon>landscape</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Export to file| Export to file" matTooltip="Εξαγωγή σε αρχείο"
      (click)="handleTableAction('export')">
      <mat-icon>import_export</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Delete items| Delete items" matTooltip="Διαγραφή στοιχείων"
      (click)="handleTableAction('delete')">
      <mat-icon>delete_forever</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Show on map | Show on map" matTooltip="Επισήμανση"
      (click)="handleTableAction('show')">
      <mat-icon>visibility</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Clear map | Clear map" matTooltip="Καθαρισμός χάρτη"
      (click)="handleTableAction('clear')">
      <mat-icon>visibility_off</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Focus | Focus" matTooltip="Εστίαση" (click)="handleTableAction('zoom')">
      <mat-icon>border_outer</mat-icon>
    </button>

    <div *ngIf="!dashboardMode" class="view-controls">
      <button mat-icon-button i18n-matTooltip="Expand/Shrink | Expand/Shrink" matTooltip="Επέκταση/Συρρίκνωση"
        (click)="handleTableAction('resize')">
        <mat-icon *ngIf="isExpanded" aria-label="Table resize button">unfold_less</mat-icon>
        <mat-icon *ngIf="!isExpanded" aria-label="Table resize button">unfold_more</mat-icon>
      </button>

      <button mat-icon-button i18n-matTooltip="Close | Close" matTooltip="Κλείσιμο" (click)="handleTableAction('close')">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</div>
