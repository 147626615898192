import { Component, OnInit } from '@angular/core';
import { MapService } from '../../map/map.service';
import { GeoserverService } from '../../core/geoserver.service';
import { StateService } from '../../core/state.service';

@Component({
	selector: 'app-print',
	templateUrl: './print.component.html',
	styleUrls: ['./print.component.css']
})
export class PrintComponent implements OnInit {
	private initialized: boolean;
	private stateUnsubscription;
	public loading;
	public dpis;
	private selectedDpi;
	public layouts;
	private selectedLayout: string;
	public scales;
	public selectedScale: any;
	public autoScale;


	constructor(private stateService: StateService, private mapService: MapService, private geoserverService: GeoserverService) { }

	ngOnInit(): void {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.updateFromState();
		this.loading = false;
		this.selectedDpi = 150;
		this.selectedLayout = 'A4 Landscape';
		this.selectedScale = {
			value: 2400,
			name: '1:2,400',
			auto: true
		};
	}

	updateFromState() {

		if (!this.initialized && this.stateService.getState().workspaceModelsLoaded && this.geoserverService.printCapabilities) {
			this.dpis = this.geoserverService.printCapabilities.dpis;
			this.layouts = this.geoserverService.printCapabilities.layouts;
			this.scales = this.geoserverService.printCapabilities.scales;
			this.initialized = true;
		}
	}

	printMap() {
		this.loading = true;
		this.mapService.printMap(this.selectedLayout, this.selectedDpi, this.selectedScale).subscribe((res: any) => {

			// let link = document.createElement("a");
			// link.href = res.getURL;
			// link.setAttribute("download", res.getURL);
			// link.click();

			window.open(res.getURL.replace(/^http:\/\//i, 'https://'), '_blank');
			this.loading = false;
		}, error => {
			this.loading = false;
		});
	}

	selectDpi(value) {
		this.selectedDpi = value;
	}

	selectLayout(value) {
		this.selectedLayout = value;
	}

	selectScale(scale) {
		this.selectedScale = scale;
		this.selectedScale.auto = false;
	}

	selectAutoScale(value) {
		this.selectedScale.auto = value;
	}

}
