// Angular imports
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

// App imports 
import { StateService } from '../../core/state.service';
import { MapService } from '../map.service';
import { GeoserverService } from '../../core/geoserver.service';
import { TopologyService } from './topology.service';
import { ModelOperationsService } from '../../core/model-operations.service';
import { ActiveScreenManagementService } from '../../core/active-screen-management.service';
import { WorkspaceLayer, EDIT_ACTIONS, EDITOR_MODES, OPERATIONS, FormOperation, FormTransaction, coordinate, FormDataModel, GeometryType } from '../../models';

// Openlayers imports
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { Draw, Modify, Select, DragBox } from 'ol/interaction';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { click } from 'ol/events/condition';
import { shiftKeyOnly } from 'ol/events/condition';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { LineString, Point, MultiLineString, Polygon, MultiPolygon, MultiPoint } from 'ol/geom';
import LinearRing from 'ol/geom/LinearRing';
import Collection from 'ol/Collection';
import { EditingStateService } from 'app/shared/services/editing-state.service';

// Vendor imports
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';
import RelateOp from 'jsts/org/locationtech/jts/operation/relate/RelateOp';
import { EditorControlsComponent } from 'app/shared/editor-controls/editor-controls.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';



@Component({
	selector: 'app-topology-editor',
	templateUrl: './topology-editor.component.html',
	styleUrls: ['./topology-editor.component.css'],
	host: {
		'(document:keydown)': 'handleKeyboardEvent($event)'
	}
})
export class TopologyEditorComponent implements OnInit, OnDestroy {

	@ViewChild('editorControls') editorControls: EditorControlsComponent;

	private stateUnsubscription: any;
	private workspaceLayersInitialized: boolean;
	private map: Map;
	public workspaceLayers: Array<WorkspaceLayer>;
	private wfsVectorEdgeLayer: any;
	private drawInteraction: Draw;
	private selectInteraction: Select;
	private multiSelectInteraction: DragBox;
	public selectedLayer: WorkspaceLayer;
	public selectedFeature: Feature;
	public currentEditedVectorLayer: VectorLayer<any>;
	public currentEditorMode: EDITOR_MODES;
	public canSplit: boolean;
	private modifyInteraction: Modify;
	private undoFlag: boolean;
	private modifyState: Array<Geometry>;
	private destroy$: Subject<void> = new Subject();
	public activeOperation: OPERATIONS;
	private formData: FormDataModel;
	public geometryType: string;

	constructor(private geoserverService: GeoserverService, private mapService: MapService,
		private stateService: StateService, private asmService: ActiveScreenManagementService,
		private topologyService: TopologyService,
		private modelOperationsService: ModelOperationsService,
		private editingStateService: EditingStateService) {

		this.workspaceLayers = [];
		this.selectedLayer = undefined;
		this.selectedFeature = null;
		this.currentEditedVectorLayer = undefined;
		this.currentEditorMode = EDITOR_MODES.stop;
		this.undoFlag = false;
		this.modifyState = [];
	}

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.updateFromState();
		this.editingStateService.layerToDraw$.pipe(takeUntil(this.destroy$)).subscribe(layer => {
			if (!!layer) {
				this.onLayerSelect(layer);
				this.onUserAction(EDIT_ACTIONS.create);
			} else {
				if (this.currentEditorMode === EDITOR_MODES.draw) {
					// Abort operation
					this.currentEditedVectorLayer.getSource().getFeatures().forEach( (feature)=>{
						if(!feature.id_){
							this.currentEditedVectorLayer.getSource().removeFeature(feature);
						}						
					});
					// this.stopDrawing();
				}
			}
		});

		this.editingStateService.layerToEdit$.pipe(takeUntil(this.destroy$)).subscribe(layer => {
			if (!!layer) {
				this.onLayerSelect(layer);
				this.onUserAction(EDIT_ACTIONS.update);
			} else {
				if (this.currentEditorMode === EDITOR_MODES.edit) {
					// Abort operation
					// this.stopEditing();
				}
			}
		});
	}

	ngOnDestroy() {
		this.stateUnsubscription();
		this.stopDrawing();
		this.destroy$.next();
		this.destroy$.complete();
	}

	stop() {
		switch (this.currentEditorMode) {
			case EDITOR_MODES.draw:
				this.stopDrawing();
				break;
			case EDITOR_MODES.edit:
				this.stopEditing();
				break;
			case EDITOR_MODES.delete:
				this.stopDeleting();
				break;
			default:
				break;
		}

		// StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
	}

	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key == "Escape") {
			this.stop();
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
		}

		if ((event.ctrlKey || event.metaKey) && event.key == "c") {
			// console.log('CTRL + C');
		} else if ((event.ctrlKey || event.metaKey) && event.key == "v") {
			// console.log('CTRL +  V');
		} else if ((event.ctrlKey || event.metaKey) && event.key == "z") { // Ctrl + z
			this.undoFlag = true;
			if (this.modifyInteraction && this.modifyState.length > 1) {
				this.modifyState.pop();// Pop the previous state 
				this.selectInteraction.getFeatures().getArray()[0].setGeometry(this.modifyState[this.modifyState.length - 1]);
				this.modelOperationsService.persist(this.selectedLayer, this.selectInteraction.getFeatures().getArray()).toPromise().then(() => {
					// TODO : Something is messing with the modify state right after editing feature for the second time after an undo
					this.modifyState = [this.selectInteraction.getFeatures().getArray()[0].getGeometry().clone()];
				});
			}
		}

	}

	updateFromState() {

		if (!this.workspaceLayersInitialized && this.mapService.getMap() && this.stateService.getState().workspaceModelsLoaded) {
			this.map = this.mapService.getMap();
			// let topologyEditState = this.stateService.getTopologyEdit();
			let workspaceLayers = this.stateService.getWorkspaceLayers();
			// Filter by screen then by current edited group
			workspaceLayers = this.topologyService.flattenWorkspaceLayers(this.asmService.filterLayersByScreen(workspaceLayers));

			if (workspaceLayers && workspaceLayers.length > 0) {

				for (let index = 0; index < workspaceLayers.length; index++) {
					const layer = workspaceLayers[index];
					if (this.topologyService.layerContainsKeyword(layer, 'is_view')) {
						continue;
					} else if (this.topologyService.layerContainsKeyword(layer, 'is_topology_edit')) {
						this.workspaceLayers.push(layer);
					}
				}

				this.workspaceLayersInitialized = true;
			}
		}

		this.activeOperation = this.stateService.getActiveOperation().operation;
		this.formData = this.stateService.getFormData();
		this.geometryType = !!this.formData?.features[0] ? this.formData.features[0].getGeometry().getType() : undefined;

	}

	// ##########################################
	// ############## SELECT LAYER ##############
	// ##########################################
	onLayerSelect(layer: WorkspaceLayer) {
		this.selectedLayer = layer;
		this.selectedFeature = null;
		if (!!layer) {
			this.canSplit = this.selectedLayer.Model.on_edit_split.length > 0;
		}
	}

	addCurrentEditedVectorLayer() {
		if (this.selectedLayer) {
			this.mapService.toggleWFSService(this.selectedLayer, undefined, false);
		}

		this.currentEditedVectorLayer = this.mapService.toggleWFSService(this.selectedLayer, this.mapService.getMap().getLayers().getLength(), true);
		this.currentEditedVectorLayer.setStyle(this.generateStyle());
	}

	removeCurrentEditedVectorLayer() {
		if (this.selectedLayer) {
			this.mapService.toggleWFSService(this.selectedLayer, undefined, false);
		}
	}

	// ##########################################
	// ################## DRAW ##################
	// ##########################################
	startDrawing() {
		this.mapService.clearVectorLayer();
		this.addCurrentEditedVectorLayer();
		this.addDrawInteraction();
		this.addRelatedLayers();
		this.addSnapInteractions();
		StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.topology_editor_draw));
	}

	stopDrawing() {
		this.removeDrawInteraction();
		this.topologyService.removeSnapInteractionsOfMap();
		this.topologyService.removeSnapWfsLayersOfMap();
		this.topologyService.removeRelatedWfsLayersOfMap();
		this.removeCurrentEditedVectorLayer();
	}

	addDrawInteraction() {

		this.drawInteraction = new Draw({
			source: this.currentEditedVectorLayer.getSource(),
			type: this.selectedLayer.Model.geometryType,
			style: this.generateStyle(),
			stopClick: true,
			condition: (event: any) => {
				// when the point's button is 2(rightclick), finish drawing
				if (event.originalEvent.buttons === 2) {
					this.drawInteraction.finishDrawing();
				} else {
					return true;
				}
			},
			// geometryFunction: (coords, geom: any) => {

			// 	if (!geom) {
			// 		if( this.selectedLayer.Model.geometryType==="MultiLineString" ){
			// 			geom = new olGeometries["LineString"](coords);
			// 		}else if( this.selectedLayer.Model.geometryType==="MultiPolygon" ){
			// 			geom = new olGeometries["Polygon"]([coords]);
			// 		}else{
			// 			geom = new olGeometries[this.selectedLayer.Model.geometryType](coords);
			// 		}					
			// 		// geom = new LineString(coords);
			// 	} else {
			// 		geom.setCoordinates(coords);
			// 	}

			// 	if (this.undoFlag) {
			// 		if (coords.length > 2) {
			// 			coords.pop();
			// 		}
			// 		this.undoFlag = false;
			// 		geom.setCoordinates(coords);
			// 	}
			// 	// geom.setCoordinates(coords);
			// 	// console.info(coords);
			// 	return geom;
			// }
			// maxPoints: 2
			// snapTolerance:1
			// ,geometryName : "the_geom"
		});

		this.drawInteraction.on('drawend', (event: any) => {

			//Do the topology checks
			let topologyChecksPassed = this.topologyService.topologyChecks(this.selectedLayer, this.currentEditedVectorLayer, event.feature, this.selectedLayer, this.editorControls.splitActive);
			if (!topologyChecksPassed) {
				this.currentEditedVectorLayer.getSource().once('addfeature', (newFeatureEvent) => {
					this.currentEditedVectorLayer.getSource().removeFeature(newFeatureEvent.feature);
				})
			}
		});

		this.map.addInteraction(this.drawInteraction);
	}

	removeDrawInteraction() {
		if (this.drawInteraction) {
			this.map.removeInteraction(this.drawInteraction);
			this.drawInteraction = null;
		}
	}

	addSnapInteractions() {

		let workspaceLayers = this.stateService.getWorkspaceLayers();
		// Filter by screen
		workspaceLayers = this.topologyService.flattenWorkspaceLayers(this.asmService.filterLayersByScreen(workspaceLayers));

		// Get snap always layers
		let snapAlwaysLayers = this.geoserverService.getAllLayersWithKeyword(workspaceLayers, 'snap_always');
		snapAlwaysLayers ? true : snapAlwaysLayers = [];

		// 2. Get snapping layers from editing layer
		let snapToLayers = this.geoserverService.getKeyValue(this.selectedLayer, 'on_edit_snap_to');
		snapToLayers ? true : snapToLayers = [];

		this.topologyService.addSnapInteractions([...snapAlwaysLayers, ...snapToLayers]);
	}

	addRelatedLayers() {
		let relatedLayers = this.geoserverService.getKeyValue(this.selectedLayer, 'on_edit_related');
		this.topologyService.addRelatedLayers(this.workspaceLayers, relatedLayers);
	}

	isEdgeTouchesAndNotIntersects(edgeGeometry) {
		let parser = new OL3Parser();
		parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);
		let edgeToCheckJstsGeom = parser.read(edgeGeometry);
		let edgeLayerSource = this.wfsVectorEdgeLayer.getSource();
		let allEdges = edgeLayerSource.getFeatures();

		for (let index = 0; index < allEdges.length; index++) {
			let topologyEdgeJstsGeom = parser.read(allEdges[index].getGeometry());
			let intersects = RelateOp.intersects(topologyEdgeJstsGeom,edgeToCheckJstsGeom);
			let touches = RelateOp.touches(topologyEdgeJstsGeom,edgeToCheckJstsGeom);

			if (!touches && intersects) {
				return allEdges[index];
			}
		}

		return false;
	}

	onAddCoordinate(coordinate: coordinate) {
		if (this.drawInteraction) {
			if ((this.drawInteraction as any).type_ === "MultiPoint") {
				(this.drawInteraction as any).startDrawing_([coordinate.lat, coordinate.lon]);
				this.drawInteraction.finishDrawing();
			} else {
				this.drawInteraction.appendCoordinates([[coordinate.lat, coordinate.lon]]);
			}

		}
	}

	// ##########################################
	// ############# EDITING ####################
	// ##########################################
	startEditing(updateState: boolean = true) {
		this.mapService.clearVectorLayer();
		this.addCurrentEditedVectorLayer();
		this.addSelectInteraction();
		this.addModifyInteraction();
		this.addRelatedLayers();
		this.addSnapInteractions();
		StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.topology_editor_edit));
	}

	stopEditing(updateState: boolean = true) {
		this.currentEditorMode = EDITOR_MODES.stop;
		this.removeSelectInteraction();
		this.removeModifyInteraction();
		this.topologyService.removeSnapInteractionsOfMap();
		this.topologyService.removeSnapWfsLayersOfMap();
		this.topologyService.removeRelatedWfsLayersOfMap();
		this.removeCurrentEditedVectorLayer();
	}

	addSelectInteraction() {

		this.selectInteraction = new Select({
			condition: click,
			hitTolerance: 5,
			// wrapX           : false,
			// toggleCondition: shiftKeyOnly,

			layers: layer => {
				return layer.get('name') == this.selectedLayer.Name + '_edit';
			},
			toggleCondition: (event) => { // This disables multiple selection with shift+click
				return false;
			},
			style: this.getTopologyStyle()
		});

		this.mapService.selectInteraction = this.selectInteraction;

		this.selectInteraction.on('select', (event) => {

			// TODO: handle deselection			
			this.selectedFeature = event.target.getFeatures().getArray()[0];
			if (!this.selectedFeature) return;
			this.modifyState = [this.selectedFeature.getGeometry().clone()];

			// 1. Create a form transaction
			let editFormOperation: FormOperation = {
				layer: this.selectedLayer,
				layerModel: this.selectedLayer.Model,
				feature: this.selectedFeature,
				formHeader: 'Επεξεργασία στοιχείου',
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.update
			}

			// 2. Create transaction 
			let editTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [editFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(editTransaction));
		});

		this.map.addInteraction(this.selectInteraction);
	}

	removeSelectInteraction() {
		this.map.removeInteraction(this.selectInteraction);
		this.mapService.selectInteraction = null;
	}

	addModifyInteraction(featureCollection: Collection<Feature<Geometry>> = undefined) {

		this.modifyInteraction = new Modify({
			features: this.selectInteraction.getFeatures(),
			// deleteCondition: (event) => { // Default is alt + click
			// 	return shiftKeyOnly(event) && singleClick(event);
			// }
		});

		this.modifyInteraction.on('modifyend', (event) => {
			this.modifyState.push((event.features.getArray()[0].getGeometry() as Geometry).clone());
			this.modelOperationsService.persist(this.selectedLayer, event.features.getArray());
		});

		this.map.addInteraction(this.modifyInteraction);
	}

	removeModifyInteraction() {
		this.map.removeInteraction(this.modifyInteraction);
	}

	onEditCoordinate(coordinate: coordinate) {
		if (this.selectedFeature) {
			(this.selectInteraction.getFeatures().getArray()[0].getGeometry() as MultiPoint).setCoordinates([[coordinate.lat, coordinate.lon]]);
			this.modelOperationsService.persist(this.selectedLayer, this.selectInteraction.getFeatures().getArray());
		}
	}

	// ##########################################
	// ############# DELETING ###################
	// ##########################################
	startDeleting(updateState: boolean = true) {
		this.addCurrentEditedVectorLayer();
		this.addSelectForDeleteInteraction();
		this.addMultiSelectForDeleteInteraction();
		StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.topology_editor_delete));
	}

	stopDeleting() {
		this.removeSelectForDeleteInteraction();
		this.removeMultiSelectForDeleteInteraction();
		this.removeCurrentEditedVectorLayer();
	}

	addSelectForDeleteInteraction() {
		let me = this;

		this.selectInteraction = new Select({
			condition: click,
			toggleCondition: shiftKeyOnly,

			layers: layer => {
				return layer.get('name') == me.selectedLayer.Name + '_edit';
			},
			style: this.getTopologyStyle()
		});

		this.mapService.selectInteraction = this.selectInteraction;

		this.selectInteraction.on('select', (e) => {
			me.selectedFeature = e.target.getFeatures().getArray()[0];

			// 1. Create a form transaction
			let deleteFormOperation: FormOperation = {
				layer: this.selectedLayer,
				layerModel: this.selectedLayer.Model,
				feature: me.selectedFeature,
				formHeader: 'Διαγραφή στοιχείου',
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.delete
			}

			// 2. Create transaction 
			let deleteTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [deleteFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(deleteTransaction));

		});

		this.map.addInteraction(this.selectInteraction);
	}

	removeSelectForDeleteInteraction() {
		this.map.removeInteraction(this.selectInteraction);
		this.mapService.selectInteraction = null;
	}

	addMultiSelectForDeleteInteraction() {

		this.multiSelectInteraction = new DragBox({
			condition: platformModifierKeyOnly
		});

		this.map.addInteraction(this.multiSelectInteraction);

		this.multiSelectInteraction.on('boxend', () => {

			// Intersected features
			let extent = this.multiSelectInteraction.getGeometry().getExtent();
			let selectedFeatures = [];
			this.currentEditedVectorLayer.getSource().forEachFeatureIntersectingExtent(extent, (feature) => {
				selectedFeatures.push(feature);
			});
			console.log(selectedFeatures);

			// 1. Create a form transaction
			let deleteFormOperation: FormOperation = {
				layer: this.selectedLayer,
				layerModel: this.selectedLayer.Model,
				feature: selectedFeatures,
				formHeader: 'Διαγραφή στοιχείου',
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.delete
			}

			// 2. Create transaction 
			let deleteTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [deleteFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(deleteTransaction));

		});

	}

	removeMultiSelectForDeleteInteraction() {
		this.map.removeInteraction(this.multiSelectInteraction);
	}
	// ##########################################
	// ################# STYLES #################
	// ##########################################
	getTopologyStyle() {

		let style = new Style({
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.2)'
			}),
			stroke: new Stroke({
				color: '#1976D2',
				width: 3
			}),
			image: new Circle({
				radius: 5,
				stroke: new Stroke({
					color: '#1976D2'
				}),
				fill: new Fill({
					color: 'rgb(187,222,251,0.4)'
				})
			})
		})

		return style;
	}

	generateStyle() {
		switch (this.selectedLayer.Name.split(':')[1]) {
			case "pipes":
				return this.geoserverService.generateVectorStyle('line', '', '#3b5da7', '#06c5fb', 4);
			case "supplies":
				return this.geoserverService.generateVectorStyle('line', '', '#3b5da7', '#06c5fb', 2);
			case "hydrometers":
				return this.geoserverService.generateVectorStyle('point', 'hexagon', '#03c3ff', '#666666', 1);
			case "network_parts":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#cecb79', '#232323', 1, 3);
			case "facilities":
				return this.geoserverService.generateVectorStyle('polygon', '', '#ffa000', '#7b1fa2', 2);
			case "switches":
				return this.geoserverService.generateVectorStyle('point', 'rectangle', '#f5f23c', '#325780');
			case "control_valves":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#e65100', '#616161');
			case "hydrants":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#b71c1c', '#616161');
			case "network_facilities":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#004d40', '#616161');
			default:
				return this.getTopologyStyle();
		}
	}

	onUserAction(action?: EDIT_ACTIONS) {
		switch (action) {
			case EDIT_ACTIONS.create: {
				this.startDrawing();
				this.currentEditorMode = EDITOR_MODES.draw;
				break;
			}
			case EDIT_ACTIONS.update: {
				this.startEditing()
				this.currentEditorMode = EDITOR_MODES.edit;
				break;
			}
			case EDIT_ACTIONS.delete: {
				this.startDeleting();
				this.currentEditorMode = EDITOR_MODES.delete;
				break;
			}
			default: {
				this.stop();
				this.currentEditorMode = EDITOR_MODES.stop;
				break;
			}
		}
	}

	onSplitChecked(checked: boolean) {
		console.log("Stavros add action here");
	}
}
