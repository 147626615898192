import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Modules
import { CoreModule } from '../core/core.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { MrFormModule } from '../mr-form/mr-form.module';
//Services
import { MapService } from './map.service';
// Components
import { MapComponent } from './map/map.component';
import { LayerListComponent } from './layer-list/layer-list.component';
import { BasemapListComponent } from './basemap-list/basemap-list.component';
import { FeatureInfoComponent } from './feature-info/feature-info.component';
import { KeysPipe } from './keys.pipe';
import { MeasurementComponent } from './measurement/measurement.component';
import { QueryComponent } from './query/query.component';
import { FeatureTableComponent } from './feature-table/feature-table.component';
import { EditorComponent } from './editor/editor.component';
import { TransformComponent } from './transform/transform.component';
import { GeocodingComponent } from './geocoding/geocoding.component';
import { MapStatusComponent } from './map-status/map-status.component';
import { DrawComponent } from './draw/draw.component';
import { DateFormcontrolComponent } from './date-formcontrol/date-formcontrol.component';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { MapGeolocationComponent } from './map-geolocation/map-geolocation.component';
import { NetworkDetectionControlsComponent } from '../topology/network-detection-controls/network-detection-controls.component';
import { CreateSuppliesControlComponent } from '../topology/create-supplies-control/create-supplies-control.component';
import { TopologyEditorComponent } from './topology-editor/topology-editor.component';
import { NetworkDetectionComponent } from './network-detection/network-detection.component';
import { TopologyResultsComponent } from './topology-results/topology-results.component';
import { SensorGraphComponent } from './sensor-graph/sensor-graph.component';
import { TimeseriesGraphComponent } from './timeseries-graph/timeseries-graph.component';
import { FormComponent } from '../shared/form/form.component';
import { TopologyAuditComponent } from './topology-audit/topology-audit.component';
import { FeatureTableMultiComponent } from './feature-table-multi/feature-table-multi.component';
import { LayerControlsComponent } from './layer-list/layer-controls/layer-controls.component';
import { ExportToFileComponent } from './export-to-file/export-to-file.component';
import { SensorsQueryComponent } from './sensors-query/sensors-query.component';
import { PrintComponent } from './print/print.component';
import { CreateSuppliesComponent } from './create-supplies/create-supplies.component';
import { LayerIconDirective } from './layer-icon.directive';
import { ScadaInfoComponent } from './scada-info/scada-info.component';
import { AnnouncementDialogComponent } from './network-detection/announcement-dialog/announcement-dialog.component';
import { SharedDashboardModule } from "../shared-dashboard/shared-dashboard.module";
import { FilterControlComponent } from './layer-list/layer-controls/filter-control/filter-control.component';

@NgModule({
    declarations: [
        MapComponent,
        LayerListComponent,
        BasemapListComponent,
        FeatureInfoComponent,
        KeysPipe,
        MeasurementComponent,
        QueryComponent,
        FeatureTableComponent,
        EditorComponent,
        FormComponent,
        TransformComponent,
        GeocodingComponent,
        MapStatusComponent,
        DrawComponent,
        DateFormcontrolComponent,
        MapToolbarComponent,
        MapGeolocationComponent,
        TopologyEditorComponent,
        NetworkDetectionComponent,
        TopologyResultsComponent,
        NetworkDetectionControlsComponent,
        CreateSuppliesControlComponent,
        SensorGraphComponent,
        TimeseriesGraphComponent,
        TopologyAuditComponent,
        FeatureTableMultiComponent,
        LayerControlsComponent,
        ExportToFileComponent,
        SensorsQueryComponent,
        PrintComponent,
        CreateSuppliesComponent,
        LayerIconDirective,
        ScadaInfoComponent,
        AnnouncementDialogComponent,
        FilterControlComponent
    ],
    exports: [
        MapComponent,
        LayerListComponent,
        BasemapListComponent,
        FeatureInfoComponent,
        MeasurementComponent,
        QueryComponent,
        FeatureTableComponent,
        EditorComponent,
        FormComponent,
        TransformComponent,
        GeocodingComponent,
        MapStatusComponent,
        DrawComponent,
        DateFormcontrolComponent,
        MapToolbarComponent,
        MapGeolocationComponent,
        TopologyEditorComponent,
        NetworkDetectionComponent,
        KeysPipe,
        TopologyResultsComponent,
        NetworkDetectionControlsComponent,
        CreateSuppliesControlComponent,
        SensorGraphComponent,
        TimeseriesGraphComponent,
        TopologyAuditComponent,
        FeatureTableMultiComponent,
        LayerControlsComponent,
        ExportToFileComponent,
        SensorsQueryComponent,
        PrintComponent,
        CreateSuppliesComponent,
        ScadaInfoComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        SharedModule,
        MaterialModule,
        MrFormModule,
        SharedDashboardModule
    ]
})
export class MapModule {
	static forRoot(): ModuleWithProviders<MapModule> {
		return {
			ngModule: MapModule,
			providers: [
				MapService
			]
		}
	}

}
