import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { StateService } from '../core/state.service';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor(private stateService: StateService,
		protected readonly keycloakService: KeycloakService) {

		// this.initGlobalInterceptor();
	}

	initGlobalInterceptor() {

		if ('serviceWorker' in navigator) {
			window.addEventListener('load', function () {
				navigator.serviceWorker.register('sw.js').then(function (registration) {
					console.log('Service worker registered with scope: ', registration.scope);
				}, function (err) {
					console.log('ServiceWorker registration failed: ', err);
				});
			});
		}

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// Intercept the token only to api calls
		if (request.url.indexOf('water-pillar.com/geoserver/wfs') >= 0) {			
			const { headers } = request;

			if(!headers.get("Authorization")){
				let userData = this.stateService.getUserData();
				request = request.clone({
					setHeaders: {
						Authorization: 'Bearer ' + userData.apiToken,
					},
				});
			}
			


		} else if (request.url.indexOf('autocomplete.geocoder.ls.hereapi.com') >= 0 || request.url.indexOf('geocoder.ls.hereapi.com') >= 0) {
			// Do nothing
		} else {

			// I think you must add Content-Type to the allowed headers if it's value isn't application/x-www-form-urlencoded, multipart/form-data or text/plain.
			// https://www.w3.org/TR/cors/
			if (request.responseType === 'blob') {
				request = request.clone({
					setHeaders: {
						Accept: 'image/png,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8'
					},
					// withCredentials: true
				});
			}

		}

		return next.handle(request);
	}

}