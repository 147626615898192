import { Injectable } from '@angular/core';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';

@Injectable({
	providedIn: 'root'
})
export class FormDataService {

	constructor() { }

	/**
	 * Removes geometry properties from a feature
	 * @param feature 
	 * @returns 
	 */
	removeGeometryFromFeature(feature: Feature<Geometry>){
		const props = feature.getProperties();
		delete props['geometry'];
		return props;
	}

}
