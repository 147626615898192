<div class="flex flex-auto h-full items-end">
  <div class="sidebar-nav">
    <app-nav-buttons [navBtnList]="navBtnList" [navSelected]="navSelected" (navChange)="onNavChange($event, true)"></app-nav-buttons>
  </div>

  <ng-content select="[sidebar-content]"></ng-content>

  <div *ngIf="!isInDocuments" class="main-content-wrapper" [ngClass]="{'dashboard': dashboardMode}">
    <div *ngIf="!dashboardMode" class="map-container">
      <ng-content select="[map-content]"></ng-content>
    </div>

    <div class="main-container">
      <ng-content select="[main-content]"></ng-content>
    </div>

    <div class="floating-elements">
      <ng-content select="[floating-content]"></ng-content>
    </div>

    <div *ngIf="!isInDocuments && !dashboardMode" [ngStyle]="{ 'height': tableHeight + 'px' }"
      class="feature-table-container" [ngClass]="{
                hidden:
                  !featureTableVisible && !multiTableVisible && !topologyResultsVisible
              }">
      <ng-content select="[table-content]"></ng-content>
    </div>

    <div [ngStyle]="{ 'flex-basis': graphHeight + 'px' }" class="timeseries-container">
      <ng-content select="[timeseries-content]"></ng-content>
    </div>

    <div class="scada-info-container">
      <ng-content select="[scada-info-content]"></ng-content>
    </div>
  </div>

  <div [ngStyle]="{
                  'flex-basis': layoutService.formWidth + 'px',
                  'min-width': layoutService.formWidth + 'px'
                }" class="form-content" [ngClass]="{ hidden: !formVisible }">
    <ng-content select="[form-content]"></ng-content>
  </div>

  <div *ngIf="!dashboardMode && !isInDocuments" class="geocoding-container">
    <ng-content select="[geocoding-content]"></ng-content>
  </div>

  <div *ngIf="isInDocuments" class="document-viewer">
    <ng-content select="[document-content]"></ng-content>
  </div>
</div>
