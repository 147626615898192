<ng-container *ngIf="form">
  <form class="dynamic-form" [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="field-container">
      <ng-container *ngFor="let field of config;" 
                    dynamicField [config]="field"
                    [formGroup]="form"
                    [indicateMultiValueSelection]="multiRowSelectionSet?.has(field.name)"
                    (calculateElevation)="handleCalculateElevation()"
                    (fileUpload)="handleFileUpload($event)">
      </ng-container>
    </div>

    <div class="form-controls" *ngIf="!isInModalTableEdit">
      <button [disabled]="freeze" type="button" mat-raised-button color="warn"
        (click)="handleDelete()">Διαγραφή</button>
      <button [disabled]="freeze" mat-raised-button color="primary">Αποθήκευση αλλαγών</button>
    </div>
  </form>
</ng-container>