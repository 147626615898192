import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformGuard  {
  constructor(private stateService: StateService, private router: Router){
  }

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      const isAndroid = this.stateService.getState().isAndroid;
      // if trying to access android screen
      if (state.url.indexOf('android') !== -1) {
        if (isAndroid) {
          return true;
        } else {
          return this.router.navigate(['/home']);
        }
      }
      // if trying to access web app screens
      else { 
        if (isAndroid) {
          return this.router.navigate(['/android']);
        } else {
          return true;
        }
      }
  }
  
}
