<div class="flex justify-end flex-auto" (mouseenter)="showExtraControls = true"
  (mouseleave)="showExtraControls = false">
  <div class="flex" [@fadeInOut]="
      showExtraControls ||
      controlState?.opacityChanging ||
      controlState?.topologyEditing ||
      controlState?.editing ||
      controlState?.creating ||
      controlState?.dataTableShowing
    ">
    <button mat-icon-button matRippleDisabled i18n-aria-label="opacity | layer's opacity level on map"
      aria-label="Αδιαφάνεια" [ngClass]="{ 'text-accent': controlState?.opacityChanging }"
      (click)="onLayerControlEvent(controlEvent.OPACITY)" i18n-matTooltip="opacity | layer's opacity level on map"
      matTooltip="Αδιαφάνεια">
      <mat-icon>opacity</mat-icon>
    </button>

    <button mat-icon-button matRippleDisabled i18n-aria-label="focus | adjust map so that the layer is vissible"
      aria-label="Εστίαση" (click)="onLayerControlEvent(controlEvent.FOCUS)"
      i18n-matTooltip="focus | adjust map so that the layer is vissible" matTooltip="Εστίαση">
      <mat-icon>filter_center_focus</mat-icon>
    </button>

    <!-- <button mat-icon-button matRippleDisabled aria-label="Εξαγωγή σε KMZ" (click)="onLayerControlEvent(controlEvent.EXPORT)" matTooltip="Εξαγωγή σε KMZ" >
      <mat-icon>import_export</mat-icon>
    </button> -->
    <button mat-icon-button matRippleDisabled i18n-aria-label="timeseries | timeseries" aria-label="Χρονοσειρά"
      *ngIf="layer?.Model?.timeseries_graph" (click)="onLayerControlEvent(controlEvent.TIMESERIES)"
      i18n-matTooltip="timeseries | timeseries" matTooltip="Χρονοσειρά">
      <mat-icon>show_chart</mat-icon>
    </button>

    <button *ngIf="(isEdit || isTopologyEdit) && controlState?.creating" mat-icon-button matRippleDisabled
      i18n-aria-label="Add new | Add new features on the map" aria-label="Σχεδίαση"
      [ngClass]="{ 'text-accent': controlState?.creating }" (click)="onLayerControlEvent(controlEvent.CREATE)"
      [disabled]="
        (otherIsEditing$ | async) ||
        controlState?.editing ||
        controlState?.topologyEditing
      " i18n-matTooltip="Stop | Stop adding new features on the map" matTooltip="Τερματισμός σχεδίασης">
      <mat-icon>add</mat-icon>
    </button>

    <button *ngIf="(isEdit || isTopologyEdit) && !controlState?.creating" mat-icon-button matRippleDisabled
      i18n-aria-label="Add new | Add new features on the map" aria-label="Σχεδίαση"
      [ngClass]="{ 'text-accent': controlState?.creating }" (click)="onLayerControlEvent(controlEvent.CREATE)"
      [disabled]="
        (otherIsEditing$ | async) ||
        controlState?.editing ||
        controlState?.topologyEditing
      " i18n-matTooltip="Stop : Add new | Add new features on the map" matTooltip="Σχεδίαση νέου">
      <mat-icon>add</mat-icon>
    </button>

    <button *ngIf="isEdit && controlState?.editing" mat-icon-button matRippleDisabled
      i18n-aria-label="Edit| Edit an existing feature" aria-label="Επεξεργασία"
      [ngClass]="{ 'text-accent': controlState?.editing }" (click)="onLayerControlEvent(controlEvent.EDIT)"
      [disabled]="(otherIsEditing$ | async) || controlState?.creating"
      i18n-matTooltip="Stop editing | Stop editing an existing feature" matTooltip="Τερματισμός επεξεργασίας">
      <mat-icon>edit</mat-icon>
    </button>

    <button *ngIf="isEdit && !controlState?.editing" mat-icon-button matRippleDisabled
      i18n-aria-label="Edit| Edit an existing feature" aria-label="Επεξεργασία"
      [ngClass]="{ 'text-accent': controlState?.editing }" (click)="onLayerControlEvent(controlEvent.EDIT)"
      [disabled]="(otherIsEditing$ | async) || controlState?.creating" i18n-matTooltip="Edit | Edit an existing feature"
      matTooltip="Επεξεργασία">
      <mat-icon>edit</mat-icon>
    </button>

    <button *ngIf="isTopologyEdit && controlState?.topologyEditing" mat-icon-button matRippleDisabled i18n-aria-label="
        Stop topology edit | Stop topology editing of an existing feature
      " aria-label="Τοπολογική επεξεργασία" [ngClass]="{ 'text-accent': controlState?.topologyEditing }"
      (click)="onLayerControlEvent(controlEvent.TOPOLOGY_EDIT)"
      [disabled]="(otherIsEditing$ | async) || controlState?.creating" i18n-matTooltip="
        Stop topology edit | Stop the topology editing of an existing feature
      " matTooltip="Τερματισμός επεξεργασίας">
      <mat-icon>format_shapes</mat-icon>
    </button>

    <button *ngIf="isTopologyEdit && !controlState?.topologyEditing" mat-icon-button matRippleDisabled
      i18n-aria-label="Topology edit | Topology of an existing feature" aria-label="Τοπολογική επεξεργασία"
      [ngClass]="{ 'text-accent': controlState?.topologyEditing }"
      (click)="onLayerControlEvent(controlEvent.TOPOLOGY_EDIT)"
      [disabled]="(otherIsEditing$ | async) || controlState?.creating" i18n-matTooltip="
        Topology edit | Start the topology editing of an existing feature
      " matTooltip="Τοπολογική Επεξεργασία">
      <mat-icon>format_shapes</mat-icon>
    </button>

    <button mat-icon-button matRippleDisabled i18n-aria-label="properties' array| Array with features' properties"
      aria-label="Πίνακας ιδιοτήτων" *ngIf="layer?.Model?.hasAttributeTable"
      [ngClass]="{ 'text-accent': controlState?.dataTableShowing }"
      (click)="onLayerControlEvent(controlEvent.TABLE)"
      i18n-matTooltip="properties' array | Array with features' properties" matTooltip="Πίνακας ιδιοτήτων">
      <mat-icon>grid_on</mat-icon>
    </button>
  </div>

  <div
    *ngIf="currentScreenSupportsQueries$ | async"
    class="flex"
    [@fadeInOut]="
      showExtraControls ||
      controlState?.opacityChanging ||
      controlState?.topologyEditing ||
      controlState?.editing ||
      controlState?.creating ||
      controlState?.dataTableShowing ||
      !!controlState?.layerFilter
  ">
    <app-filter-control
      [layerFilter]="controlState?.layerFilter"
      [enabled]="controlState?.enableFilter"
      (onFilterChange)="onLayerControlEvent(controlEvent.FILTER, $event)"
    >
    </app-filter-control>
  </div>

  <button *ngIf="controlState?.visible" mat-icon-button matRippleDisabled
    i18n-aria-label="Show/Hide | Toggle layer's vissibility" aria-label="Εμφάνιση/Απόκρυψη"
    [color]="controlState?.visible ? 'primary' : ''" (click)="onLayerControlEvent(controlEvent.ENABLE)"
    i18n-matTooltip="Hide | Toggle layer's vissibility off" matTooltip="Απόκρυψη">
    <mat-icon>visibility</mat-icon>
  </button>

  <button *ngIf="!controlState?.visible" mat-icon-button matRippleDisabled
    i18n-aria-label="Show/Hide | Toggle layer's vissibility" aria-label="Εμφάνιση/Απόκρυψη"
    [color]="controlState?.visible ? 'primary' : ''" (click)="onLayerControlEvent(controlEvent.ENABLE)"
    i18n-matTooltip="Show | Toggle layer's vissibility on" matTooltip="Εμφάνιση">
    <mat-icon>visibility_off</mat-icon>
  </button>
</div>
