import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'file-input',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: FileInputComponent,
		multi: true
	}],
	templateUrl: './file-input.component.html',
	styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit, ControlValueAccessor {

	@Input() name: string;
	@Input() alias: string;
	public file: File;
	public isFileUrl: boolean;
	private value: any;
	private onChange: Function;
	public disabled: boolean = false;
	public displayedValue: string = '';
	private urlRegex: RegExp = new RegExp('^(http|https)://');
    public fileInputDirty = false;

	@Output() fileOutput: EventEmitter<any> = new EventEmitter();
	@Output() blur: EventEmitter<void> = new EventEmitter();
	@Output() focus: EventEmitter<void> = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	writeValue(value) {
		if (this.isValidURL(value)) {
			this.displayedValue = 'File';
			this.value = value;
		}
		else {
			this.displayedValue = (!!this.file) ? this.file.name : '';
		}
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched() { }

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	onSelectFile(event) {
		if (event.target.files) {
			this.file = event.target.files[0];
            this.fileInputDirty = true;
			const temp = {};
			if (this.file) {
				temp[this.name] = this.file.name;
			}
			else {
				temp[this.name] = '';
			}
		}
		this.displayedValue = (!!this.file) ? this.file.name : '';
		this.isFileUrl = false;
		if (!!this.file) {
			this.focus.emit();
		}
	}

	isValidURL(fileValue: string) {
		if (!!fileValue && this.urlRegex.test(fileValue)) {
			this.isFileUrl = true;
			return true;
		} else {
			this.isFileUrl = false;
			return false;
		}
	}

	openFile(event) {
		if (this.isFileUrl) {
			window.open(this.value, '_blank');
		}
	}

	onUploadFile() {
		this.fileOutput.emit(this.file);
		this.file = null;
	}
}
