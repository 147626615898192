import { Injectable } from '@angular/core';
import { StateService } from '../../core/state.service';
import { WorkspaceLayer, ActiveScreenIDs } from '../../models';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	constructor(private stateService: StateService) { }

	private persistUserState() {

		try {

			// const serializedHistory = JSON.stringify( state.services.history,this.replacer );

			const userState = JSON.stringify(this.stateService.getState().userState);

			localStorage.setItem('userState', userState);

		} catch (error) {

			console.log(error);

		}

	}

	public addOpenLayer(activeScreen: number, layer: WorkspaceLayer) {

	}

	public removeOpenLayer(activeScreen: number, layer: WorkspaceLayer) {

	}

	public setScreenExtent(activeScreen: number, extent: [number, number, number, number]) {

	}

}
