<div class="mat-card" *ngIf="!hideControls">
  <h4 class="group-heading">Επεξεργασία</h4>

  <div class="group-control-container">
    <div class="button-group-wrapper">
      <mat-button-toggle-group name="editorAction" aria-label="Editor Action" #group>
        <div class="btn-wrapper" i18n-matTooltip="Draw" matTooltip="Σχεδίαση" [matTooltipHideDelay]="200">
          <button mat-raised-button [ngClass]="{'toggled flashing': currentAction === editActions.create}"
            (click)="onCreate()" [disabled]="actionDisabled.get('create')">
            <mat-icon class="editor-icon">show_chart</mat-icon>
          </button>
        </div>
        <div class="btn-wrapper" i18n-matTooltip="Edit | Edit" matTooltip="Επεξεργασία" [matTooltipHideDelay]="200">
          <button mat-raised-button [ngClass]="{'toggled flashing': currentAction === editActions.update}"
            (click)="onUpdate()" [disabled]="actionDisabled.get('update')">
            <mat-icon class="editor-icon">edit</mat-icon>
          </button>
        </div>
        <div class="btn-wrapper" i18n-matTooltip="Delete | Delete" matTooltip="Διαγραφή" [matTooltipHideDelay]="200">
          <button mat-raised-button [ngClass]="{'toggled flashing': currentAction === editActions.delete}"
            (click)="onDelete()" [disabled]="actionDisabled.get('delete')">
            <mat-icon class="editor-icon">clear</mat-icon>
          </button>
        </div>
        <div class="btn-wrapper" i18n-matTooltip="Stop | Stop" matTooltip="Τερματισμός λειτουργίας"
          [matTooltipHideDelay]="200">
          <button mat-raised-button (click)="onCancel()">
            <mat-icon class="editor-icon">block</mat-icon>
          </button>
        </div>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-checkbox *ngIf="canSplit && (currentAction === editActions.create)" (change)="onSplitToggle($event)"
    i18n-matTooltip="Activate | Activate" matTooltip="Ενεργοποίηση" [matTooltipHideDelay]="200">
    <ng-container i18n="Pipeline segmentation | Pipeline segmentation">Κατάτμηση αγωγού</ng-container>
  </mat-checkbox>
</div>

<mat-card *ngIf="hideControls && canSplit" style="margin: 0">
  <mat-card-content>
    <mat-checkbox (change)="onSplitToggle($event)" i18n-matTooltip="Activate | Activate" matTooltip="Ενεργοποίηση"
      [matTooltipHideDelay]="200">
      <ng-container i18n="Pipeline segmentation | Pipeline segmentation">Κατάτμηση αγωγού</ng-container>
    </mat-checkbox>
  </mat-card-content>
</mat-card>