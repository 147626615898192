/*
TODO: Refactor this component so that it doesn't have to access the
DynamicForm component directly, but use its inputs to pass changes
That way we avoid writing all this dynForm manipulation logic on all
components that use the dynamic form in their templates
*/
import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { LayoutService } from 'app/core/layout.service';
import { FormDataService } from '../services/form-data.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormDataModel, EDIT_ACTIONS } from 'app/models';
import { FieldConfigDeya } from 'app/mr-form/models/field-config.interface';
import { DynamicFormComponent } from 'app/mr-form/containers/dynamic-form/dynamic-form.component';
import { StateService } from '../../core/state.service';
import { ApiService } from 'app/core/api.service';
import { ModelOperationsService } from 'app/core/model-operations.service';
import { MatDialog } from '@angular/material/dialog';
import { EditingStateService } from '../services/editing-state.service';
import { TopologyUiService } from 'app/core/topology-ui.service';
import { DecideDialogComponent } from '../decide-dialog/decide-dialog.component';
import { SnackBarService } from 'app/core/snack-bar.service';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { cloneDeep, isEqual } from 'lodash-es';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

	@Input()
	isInSensorScreen: boolean;

	@ViewChild('dynForm') dynForm: DynamicFormComponent;
	private file: File;
	private destroy$: Subject<void> = new Subject();
	private stateUnsubscription;

	public formData?: FormDataModel;
	public formDataFeaturesLength;
	public dynamicFormRowData: any;
	lastFeature: Feature<Geometry>;
	fields: FieldConfigDeya[];
	freeze = false;
	actions = EDIT_ACTIONS;
	deleteTableData: any[] = [];
	// selectedTableRows: any[] = [];
    multiRowSelectionSet = new Set();

	private _lastSelectedFeatures: Array<any> = [];
    public formHeaderBadgeIcon = '';
    public get lastSelectedFeatures(): Array<any> {
        return this._lastSelectedFeatures;
    }
    public set lastSelectedFeatures(value: Array<any>) {
        this._lastSelectedFeatures = value;
        const selectedFeatureCount = this._lastSelectedFeatures?.length ?? 0;
        this.formHeaderBadgeIcon = selectedFeatureCount > 9 ? 'filter_9_plus' : `filter_${selectedFeatureCount}`;
    }

	private selectionTimeStamp: number;
	private selectionInfoTimeStamp: number;

	constructor(
		private layoutService: LayoutService,
		private formDataService: FormDataService,
		private stateService: StateService,
		private apiService: ApiService,
		private cdRef: ChangeDetectorRef,
		private modelOperationsService: ModelOperationsService,
		private snackBarService: SnackBarService,
		private editingStateService: EditingStateService,
		private topologyUiService: TopologyUiService,
		private dialog: MatDialog
	) { }

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => {
            this.updateFromState();
        });

        // Clean up DynamicForm each time the Form widget is dismissed
		this.layoutService.formVisible$.pipe(
			filter(formVisible => !formVisible),
			takeUntil(this.destroy$)
		).subscribe(() => this.formData = null);
	}

	ngAfterViewInit() {

		if (this.formData && this.formData.multiCell && this.formData.action !== EDIT_ACTIONS.delete) {
			setTimeout(() => {
				Object.keys(this.dynForm.form.controls).forEach(control => {
					if (control !== this.formData.multiCell) {
						this.dynForm.form.controls[control].disable();
					}
				});
			}, 100);
		}
	}

	updateFromState() {
		let featureTableData = this.stateService.getFeatureTableData();
		let formStateData: FormDataModel = cloneDeep(this.stateService.getFormData());
		let tempFeatureInfo = this.stateService.getFeatureInfoNew();

		//if form has no data clear last selected features and reset timestamps
		if (formStateData?.features.length === 0) {
			this.lastSelectedFeatures = [];
			this.selectionInfoTimeStamp = 0;
			this.selectionTimeStamp = 0;
		}

		// Get cell from featureInfo array that contains the selectedFeatures
		let featureInfoSelected = tempFeatureInfo.tables.find(({ selectedLayer }) => selectedLayer?.Name === formStateData.selectedLayer?.Name)?.selectedFeatures;

		if (featureTableData.featureTableVisible && !!featureTableData.selectedFeatures?.features && this.selectionTimeStamp !== featureTableData.selectedFeatures.timestamp) {
			this.selectionTimeStamp = featureTableData.selectedFeatures.timestamp;
			this.lastSelectedFeatures = featureTableData.selectedFeatures.features;
		}
		else if (!!featureInfoSelected?.features && featureInfoSelected?.selectedLayer?.Name === formStateData.selectedLayer?.Name && this.selectionInfoTimeStamp !== featureInfoSelected.timestamp) {
			this.selectionInfoTimeStamp = featureInfoSelected.timestamp;
			this.lastSelectedFeatures = featureInfoSelected.features;
		}


		if (!!formStateData && !!formStateData.selectedLayer && formStateData.selectedLayer.Model.open_form_on_identify
			&& this.selectionTimeStamp != formStateData.timestamp) {
			this.selectionTimeStamp = formStateData.timestamp;
			this.lastFeature = formStateData.features[formStateData.features.length - 1];
			// remove geometry from feature
			if (!!formStateData && !!this.lastFeature && !!this.lastFeature.getProperties) {
				const props = this.formDataService.removeGeometryFromFeature(this.lastFeature);
				Object.assign(formStateData, { rowData: props, features: this.lastFeature });
			}

			let resetForm = true;
			if (!!this.formData && formStateData.selectedLayer.Name === this.formData.selectedLayer?.Name) {
				resetForm = false;
			}

			// Update dynamicFormRowData only if the state contains new feature data
			if (!!formStateData?.rowData && !isEqual(this.formData?.rowData ?? {}, formStateData?.rowData ?? {})) {
				this.dynamicFormRowData = structuredClone(formStateData.rowData);
			}

			this.formData = formStateData;

			if (resetForm) {
				this.getFields();
			}

			if (formStateData.features.length === 0) {
				this.onClose();
			} else {
				this.layoutService.formToggle(true);
			}

			this.cdRef.detectChanges();
		}
		this.populateMultiRowSelectionSet();
	}

	ngOnDestroy() {
		this.stateUnsubscription();
		this.destroy$.next();
		this.destroy$.complete();
	}

	getFields() {
		this.fields = this.formData.selectedLayer.Model.fields.map(f => Object.assign({}, f));
		if (this.formData.action === EDIT_ACTIONS.create) {
			this.fields = this.fields.filter(f => f.editable);
		}
		this.fields = this.fields
			.filter(f => f.visible)
			.map(f => {
				if (f.name === 'file') {
					f.localType = 'file';
				}
				if (!!this.formData.multiCell && f.name !== this.formData.multiCell) {
					f.editable = false;
				}
				return f;
			});

		if (this.formData.multiCell && this.formData.action === this.actions.delete) {
			this.formData.rowData.forEach(row => {
				const props = this.formDataService.removeGeometryFromFeature(row);
				const id = row.id_;
				this.deleteTableData.push(Object.assign({}, { properties: props, id: id }));
			});
		}
	}

	onClose() {
		if (this.formData?.totalOperations) {
			if (this.formData.action === EDIT_ACTIONS.create) {
				this.editingStateService.setLayerToDraw(null);
				// setTimeout(() => this.editingStateService.setLayerToDraw(this.topologyUiService.getFirstOperationLayer()), 100);
			} else {
				// this.editingStateService.setLayerToEdit(null);
			}
			this.topologyUiService.endFormOperation(null);
		}
		this.layoutService.formToggle(false);
		StateService.stateStore.dispatch(this.stateService.setFormDataOperation(null));
		this.formData = null;
		// this.formDataService.updateTable(null);
	}

	onSaveFromInfo() {
		if (this.lastSelectedFeatures.length > 1) {
			const dirtyProps = [];
			Object.keys(this.dynForm.form.controls).forEach(control => {
				if (this.dynForm.form.controls[control].dirty) {
					dirtyProps.push(control);
				}
			});
			if (dirtyProps.length === 0) {
				return;
			} else {
				const updates = {};
				dirtyProps.forEach(prop => updates[prop] = this.dynForm.form.controls[prop].value);
				this.lastSelectedFeatures.forEach(f => f.setProperties(updates));

				this.modelOperationsService.persist(
					this.formData.selectedLayer,
					this.lastSelectedFeatures
				).toPromise().then(() => { });
				this.dynForm.form.markAsPristine();
			}
		} else {
			this.dynForm.form.markAsPristine();
			const formData = Object.assign({}, this.dynForm.form.getRawValue());
			Object.keys(formData).forEach(key => {
				if (formData[key] === undefined || formData[key] === null) {
					delete formData[key];
				}
			});
			this.lastFeature.setProperties(formData);
			// If form edit started from a formOperation (has totalOperations set)
			if (this.formData.totalOperations) {
				this.topologyUiService.endFormOperation(this.lastFeature);
			} else {
				// Else if started from identify or table or anything call persist here
				this.modelOperationsService.persist(
					this.formData.selectedLayer,
					[
						this.lastFeature
					]
				)
			}
		}
	}

	onFormCalculateElevation() {
		this.apiService.getElevationByPoint(this.lastFeature).subscribe((response) => {
			
			let formStateData: FormDataModel = cloneDeep(this.stateService.getFormData());
			let lastFeature = formStateData.features[formStateData.features.length - 1]; // If the user does a multiple selection only the last feature will be updated
			let newLastFeatureProperties = { ...lastFeature.getProperties(), ...response };
			lastFeature.setProperties(newLastFeatureProperties);

			formStateData.features[formStateData.features.length - 1] = lastFeature;
			StateService.stateStore.dispatch(this.stateService.setFormDataOperation({ ...formStateData }));
			this.dynForm.form.patchValue(response);
			this.dynForm.form.markAsDirty();
		}, (error) => {
			console.error('Error fetching elevation:', error);
		});
	}

	onDeleteFromInfo() {
        if ((this.formData?.features as any).isEpanetFeature) {
            this.snackBarService.setMessage('Η διαγραφή στοιχείων δεν επιτρέπεται κατά την λειτουργία του epanet', 4000);
            return;
        }
		if (this.lastSelectedFeatures.length > 1) {
			// TODO : MULTI ROW
			this.snackBarService.setMessage('Η πολλαπλή εγγραφή δεν υποστηρίζεται προσωρινά', 4000);
		} else {

			this.dialog.open(DecideDialogComponent, {
            }).afterClosed().subscribe(data => {
				if (data) {
					this.modelOperationsService.persist(
						this.formData.selectedLayer,
						null,
						null,
						[
							this.lastFeature
						]
					).toPromise().then(() => {
						this.onClose();
					});
				}
			});
		}
	}
	
	onFormFileUpload(file: any) {
		this.file = file;
		this.uploadFile();
	}

	uploadFile() {
		this.freeze = true;
		//StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));
		this.apiService.uploadFile(this.file, this.file.name).subscribe((res: any) => {
			// Inspect if this creates an immutable object when upload file is working
			// This should trigger formData change event on the child and update file url on file input component
			//StateService.stateStore.dispatch(this.stateService.setFormDataOperation(null));
			this.formData.rowData['file'] = `${this.apiService.storageUrl}/${res.generatedName}`;
			this.dynForm.patchValues({ file: `${this.apiService.storageUrl}/${res.generatedName}` });
			this.dynForm.form.controls['file'].markAsTouched();
			this.dynForm.form.controls['file'].markAsDirty();
			this.file = undefined;
			this.freeze = false;
			//StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
			setTimeout(() => this.cdRef.detectChanges(), 1);
		});

	}

	onEdit() {
		this.editingStateService.setLayerToEdit(this.formData.selectedLayer);
	}

	private populateMultiRowSelectionSet() {
		this.multiRowSelectionSet = new Set();
		if (this.lastSelectedFeatures?.length <= 1) {
			return;
		}
		for(let dataProperty in this.dynamicFormRowData) {
			const dataPropertyHasMultiValues = this.lastSelectedFeatures.map(lsf => this.formDataService.removeGeometryFromFeature(lsf)).some(
				selectedFeature => {
					return selectedFeature[dataProperty] !== this.dynamicFormRowData[dataProperty];
				}
			);
			if (dataPropertyHasMultiValues) {
				this.multiRowSelectionSet.add(dataProperty);
			}
		}
	}
}
