<div class="sidebar-widget flex flex-col gap-5">
  <h2 class="mb-2 font-light" i18n="Distance Measurement | Distance Measurement">
    Μέτρηση απόστασης
  </h2>

  <div class="flex flex-row">
    <button [disabled]="areaMeasurementActive" (click)="activateDistanceMeasurement()" mat-raised-button
      [color]="distanceMeasurementActive ? 'accent' : 'default'" class="rounded-r-none leading-none">
      <ng-container i18n="Distance Measurement | Distance Measurement">
        Μέτρηση αποστασης
      </ng-container>
    </button>

    <button [disabled]="distanceMeasurementActive" (click)="activateAreaMeasurement()" mat-raised-button
      [color]="areaMeasurementActive ? 'accent' : 'default'" class="rounded-l-none leading-none">
      <ng-container i18n="Area Measurement | Area Measurement">
        Μέτρηση περιοχής
      </ng-container>
    </button>
  </div>

  <button (click)="clearMeasurements()" mat-raised-button color="warn">
    <ng-container i18n="Clear | Clear">
      Καθαρισμός
    </ng-container>
  </button>
</div>
