import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';

import { ZoneEditorComponent } from './zone-editor/zone-editor.component';
import { EditorControlsComponent } from './editor-controls/editor-controls.component';
import { TimeseriesToggleService } from './services/timeseries-toggle.service';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { LocalStorageService } from './services/local-storage.service';
import { LayoutComponent } from './layout/layout.component';
import { NavButtonsComponent } from './layout/nav-buttons/nav-buttons.component';
import { DecideDialogComponent } from './decide-dialog/decide-dialog.component';
import { UploadCsvFormComponent } from './upload-csv-form/upload-csv-form.component';
import { CoordEditorComponent } from './coord-editor/coord-editor.component';
import { DashboardCardComponent } from './layout/dashboard-card/dashboard-card.component';
import { TopologyUiDialogComponent } from './topology-ui-dialog/topology-ui-dialog.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { LineChartComponent } from './line-chart/line-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  declarations: [
    ZoneEditorComponent,
    EditorControlsComponent,
    ClickOutsideDirective,
    HelpDialogComponent,
    LayoutComponent,
    NavButtonsComponent,
    DecideDialogComponent,
    UploadCsvFormComponent,
    CoordEditorComponent,
    DashboardCardComponent,
    TopologyUiDialogComponent,
    LoaderComponent,
    LineChartComponent
  ],
  exports:[
    ZoneEditorComponent,
    EditorControlsComponent,
    ClickOutsideDirective,
    LayoutComponent,
    NavButtonsComponent,
    CoordEditorComponent,
    DashboardCardComponent,
    LoaderComponent,
    LineChartComponent
  ],
  providers: [
    LocalStorageService,
    TimeseriesToggleService
  ]
})
export class SharedModule { }
