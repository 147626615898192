import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule } from 'keycloak-angular';

import { StateService } from './state.service';
import { GeoserverService } from './geoserver.service';
import { ApiService } from './api.service';
import { ModelOperationsService } from './model-operations.service';
import { AuthenticationService } from './authentication.service';
import { ActiveScreenManagementService } from './active-screen-management.service';
import { WidgetFocusService } from './widget-focus.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { PlatformGuard } from './platform.guard';
import { NotauthorizedComponent } from './notauthorized/notauthorized.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    KeycloakAngularModule
  ],
  exports: [
    KeycloakAngularModule
  ],
  declarations: [
  
    NotauthorizedComponent
  ],
  providers: [
    StateService,
    AuthenticationService,
    ActiveScreenManagementService,
    // ApiService,
    ModelOperationsService,
    WidgetFocusService,
    PlatformGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        GeoserverService,
        ApiService
      ]
    }
  }
}
