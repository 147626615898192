import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rowSelected'
})
export class RowSelectedPipe implements PipeTransform {

  transform(selectedRows: any[], row: any): boolean {
    return selectedRows.indexOf(row.id) !== -1;
  }
}
