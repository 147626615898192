<div class="sidebar-widget">
  <h2 class="mb-2 font-light" i18n="Layers|Layer list's header">Επίπεδα</h2>

  <div *ngFor="let group of workspaceLayers; let j = index" class="mb-2">
    <div class="flex gap-1 items-center justify-between mb-2">
      <button class="flex gap-1 items-center" (click)="toggleGroupVisibility(group.Title)">
        <mat-icon [ngClass]="{'arrow-down': groupVisibilityMap.get(group.Title)}">
          keyboard_arrow_right
        </mat-icon>
        <span [ngClass]="getGroupLabelHighlight(group.Title) ? 'font-normal' : 'font-light'">
          {{ group.Title }}
        </span>
      </button>

      <mat-slide-toggle class="layer-toggle" [checked]="getGroupLabelHighlight(group.Title)"
        (click)="toggleGroup(group.Title)">
      </mat-slide-toggle>
    </div>

    <div cdkDropList (cdkDropListDropped)="drop($event, j)" class="group-body"
      [@collapseExpand]="groupVisibilityMap.get(group.Title) + ''">
      <!-- LAYER INFO/CONTROLS -->
      <div class="dragBox" *ngFor="let layer of group.Layer; let i = index" cdkDrag>
        <div class="flex flex-row gap-2">
          <div (contextmenu)="onLayerControl($event, layer, i, group)" class="flex gap-2 items-center">
            <div i18n-matTooltip matTooltip="Εναλλαγή ορατότητας ετικέτας" matTooltipClass="custom-tooltip"
              class="layer-label" [appLayerIcon] layer="{{ layer.Style[0].LegendURL[0].OnlineResource }}"
              property="backgroundImage" fontColor="{{ fontColor }}" bgColor="{{ bgColor }}"></div>
            <div class="font-light" cdkDragHandle>
              {{ layer.Title }}
            </div>
          </div>

          <div class="flex-auto layer-controls">
            <app-layer-controls *ngIf="!!layer" [groupEnabled]="groupEnabledMap.get(group.Title)" [layer]="layer"
                                [dataTableLayerTitle]="dataTableLayerTitle" (layerControlEvent)="onLayerControl($event, layer, i, group)">
            </app-layer-controls>
          </div>
        </div>

        <div class="opacity-label" *ngIf="slidersEnabled.get(layer.Title)">
          <mat-label class="text-sm pl-2 font-light">
            Αδιαφάνεια
          </mat-label>
          <mat-slider discrete color="primary">
            <input matSliderThumb [value]="opacityValues.get(layer.Title)"
              (valueChange)="opacityChange(layer, $event, layer.Title)" />
          </mat-slider>
        </div>

        <!-- layer details -->
        <div class="layer-details" *ngIf="showDetails.get(layer.Title)">
          <img [appLayerIcon] layer="{{ layer.Style[0].LegendURL[0].OnlineResource }}" property="src"
            fontColor="{{ fontColor }}" bgColor="{{ bgColor }}" />
        </div>
      </div>

      <!-- LAYER EDITOR -->
      <div class="layer-editor"></div>

      <!-- LAYER TOPOLOGY EDITOR -->
      <div class="layer-topology-editor"></div>
    </div>
  </div>
</div>
