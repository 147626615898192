<div class="dynamic-field form-select relative" [formGroup]="formGroup">
  <form-multi-value-indicator
    [show]="indicateMultiValueSelection"
    [config]="config">
  </form-multi-value-indicator>

  <app-dropdown-input 
    [ngClass]="{'opacity-0': indicateMultiValueSelection}"
    [formControlName]="config.name"
    [name]="config.name"
    [editable]="config.editable"
    [placeHolder]="config.alias || config.name"
    [domain]="config.domain"
    (blur)="onFieldBlur()"
    (focus)="onFieldFocus()"></app-dropdown-input>
    
</div>
