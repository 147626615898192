import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { LayerControlState, LayerControlEvent, LayerControl, FilterEventType, NavButtons } from 'app/models';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EditingStateService } from 'app/shared/services/editing-state.service';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators';
import { StateService } from 'app/core/state.service';
import { ActiveScreenManagementService } from 'app/core/active-screen-management.service';

@Component({
	selector: 'app-layer-controls',
	templateUrl: './layer-controls.component.html',
	styleUrls: ['./layer-controls.component.scss'],
	animations: [
		trigger('fadeInOut', [
			state('true', style({
				visibility: 'visible',
				opacity: '1'
			})),
			state('false', style({
				visibility: 'hidden',
				opacity: '0'
			})),
			transition('* => *', animate('300ms ease-in-out'))
		])

	]
})
export class LayerControlsComponent implements OnInit, OnDestroy {
	@Input() layer: any;
	@Input() groupEnabled = false;
	@Input() dataTableLayerTitle?: string;
	@Output() layerControlEvent: EventEmitter<LayerControlEvent> = new EventEmitter();

	isEdit = false;
	isTopologyEdit = false;
	controlEvent = LayerControl;
	showExtraControls = false;
	otherIsEditing$: Observable<boolean>;
	stateUnsubscription: Function;
	controlState: LayerControlState;
	destroy$: Subject<void> = new Subject();
	currentScreenSupportsQueries$: Observable<boolean>;

	constructor(private editingStateService: EditingStateService, private stateService: StateService, private asmService: ActiveScreenManagementService) {
		this.currentScreenSupportsQueries$ = this.asmService.currentScreenId$.pipe(
			map(currentScreenId => ActiveScreenManagementService.getAvailableNavButtons(currentScreenId)),
			map(screenNavButtons => !!screenNavButtons.find(nav => nav === NavButtons.filters)),
			takeUntil(this.destroy$)
		);
		this.stateUnsubscription = StateService.stateStore.subscribe(() => this.updateFromState());

		this.otherIsEditing$ = combineLatest([
			this.editingStateService.layerToDraw$,
			this.editingStateService.layerToEdit$,
		]).pipe(
			filter(() => !!this.layer),
			map(res => {
				if (!res[0] && !res[1]) {
					return false;
				} else if (!!res[0] && res[0].Name !== this.layer.Name || !!res[1] && res[1].Name !== this.layer.Name) {
					return true;
				} else {
					return false;
				}
			})
		);
	}

	updateFromState() {
		if (!this.layer?.Name) {
			return;
		}
		let layerModel = this.stateService.getLayerModel(this.layer.Name);
		this.controlState = { ...this.controlState, layerFilter: layerModel?.ecql_filter, enableFilter: !!layerModel?.ecql_applied };
	}

	ngOnInit() {
		this.isEdit = this.layer.KeywordList.includes('is_edit');
		this.isTopologyEdit = this.layer.KeywordList.includes('is_topology_edit');
		this.controlState = {
			creating: false,
			editing: false,
			opacityChanging: false,
			visible: this.layer.KeywordList.includes('show'),
			topologyEditing: false,
			dataTableShowing: false,
			layerFilter: this.stateService.getLayerModel(this.layer.Name)?.ecql_filter,
			enableFilter: !!this.stateService.getLayerModel(this.layer.Name)?.ecql_applied
		};
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['groupEnabled'] && !!this.controlState) {
			this.controlState.visible = this.groupEnabled;
		}

		if (changes['dataTableLayerTitle'] && !!this.controlState) {
			this.controlState.dataTableShowing = this.dataTableLayerTitle === this.layer?.Name;
		}
	}

	onLayerControlEvent(event: LayerControl, filterEvent?: FilterEventType) {
		let eventValue: any;
		switch (event) {
			case LayerControl.CREATE: {
				this.controlState.creating = !this.controlState.creating;
				eventValue = this.controlState.creating;
				break;
			}
			case LayerControl.EDIT: {
				this.controlState.editing = !this.controlState.editing;
				eventValue = this.controlState.editing;
				break;
			}
			case LayerControl.TOPOLOGY_EDIT: {
				this.controlState.topologyEditing = !this.controlState.topologyEditing;
				eventValue = this.controlState.topologyEditing;
				break;
			}
			case LayerControl.ENABLE: {
				this.controlState.visible = !this.controlState.visible;
				eventValue = this.controlState.visible;
				break;
			}
			case LayerControl.OPACITY: {
				this.controlState.opacityChanging = !this.controlState.opacityChanging;
				eventValue = this.controlState.opacityChanging;
				break;
			}
			case LayerControl.TIMESERIES: {
				break;
			}
			case LayerControl.EXPORT: {
				break;
			}
			case LayerControl.FOCUS: {
				break;
			}
			case LayerControl.FILTER: {
				eventValue = filterEvent;
				break;
			}
			default: {
				break;
			}
		}

		this.layerControlEvent.emit({ event: event, value: eventValue });
	}

}
