<div #matTableContainer class="mr-table-container">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort($event)" class="table-transparent" [trackBy]="myTrackById">
    <ng-container *ngFor="let column of displayedColumns; let i = index;" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{tableHeaders[i]}}</th>

      <td mat-cell *matCellDef="let row">
        <div [innerHtml]="row[column] | tolink"></div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        class="row-cursor"
        [ngClass]="{'selected-row' : selectedRows | rowSelected : row}"
        (click)="onRowSelect(row)">
    </tr>
  </table>
</div>

<div class="mr-paginator-container">
  <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="100" [showFirstLastButtons]="false"
                 (page)="onPageEvent()">
  </mat-paginator>

  <div class="controls-wrapper">
    <button *ngIf="this.selectedLayer?.Model.canMoveTo" [matMenuTriggerFor]="clickMenu" mat-icon-button
      i18n-matTooltip="Move to | Move to" matTooltip="Μετακίνηση σε" (click)="availableDestinationLayers()">
      <mat-icon>swap_horiz</mat-icon>
      <mat-menu #clickMenu="matMenu">
        <button mat-menu-item (click)="handleTableAction('move',availableLayer)"
          *ngFor="let availableLayer of availableLayers">
          <span> {{ availableLayer.Title }}</span>
        </button>
      </mat-menu>
    </button>

    <button mat-icon-button i18n-matTooltip="Calculate elevation for layer| Calculate elevation for layer"
      matTooltip="Υπολογισμος Υψομέτρου" *ngIf="dataModel?.geometryType === 'MultiPoint' && hasElevationField()"
      (click)="handleTableAction('calculate')">
      <mat-icon>landscape</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Export to file| Export to file" matTooltip="Εξαγωγή σε αρχείο"
      (click)="handleTableAction('export')">
      <mat-icon>import_export</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Delete selected items | Delete selected items" matTooltip="Διαγραφή επιλεγμένων"
      (click)="handleTableAction('delete')">
      <mat-icon>delete_forever</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Show on map | Show on map" matTooltip="Επισήμανση"
      (click)="handleTableAction('show')">
      <mat-icon>visibility</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Clear map | Clear map" matTooltip="Καθαρισμός χάρτη"
      (click)="handleTableAction('clear')">
      <mat-icon>visibility_off</mat-icon>
    </button>

    <button mat-icon-button i18n-matTooltip="Focus | Focus" matTooltip="Εστίαση" (click)="handleTableAction('zoom')">
      <mat-icon>border_outer</mat-icon>
    </button>

    <button *ngIf="!dashboardMode" mat-icon-button i18n-matTooltip="Close | Close" matTooltip="Κλείσιμο"
      (click)="handleTableAction('close')">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
