import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// App imports 
import { StateService } from '../core/state.service';
import { GeoserverService } from '../core/geoserver.service';
import { ActiveScreenManagementService } from '../core/active-screen-management.service';
import { OPERATIONS, WorkspaceLayer, ScadaDashboardType, scadaEntity, station, DateUtils, FilterEventType } from '../models';

import { environment } from '../../environments/environment';

// Openlayers imports
// import * as openlayers from 'openlayers/dist/ol-debug';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import { Select, DragBox } from 'ol/interaction.js';
import olMap from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer } from 'ol/layer.js';
import ImageLayer from 'ol/layer/Image';
import { XYZ, OSM, TileWMS, ImageWMS, Stamen, BingMaps, TileImage } from 'ol/source';
import VectorTile from 'ol/source/VectorTile';
import VectorTileLayer from 'ol/layer/VectorTile';
import MVT from 'ol/format/MVT';
import MousePosition from 'ol/control/MousePosition';
import ScaleLine from 'ol/control/ScaleLine';
import { defaults as defaultInteractions } from 'ol/interaction.js';
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import Geolocation from 'ol/Geolocation';
import { EventsKey } from 'ol/events.js';
import { format } from 'ol/coordinate.js';
import * as olObservable from 'ol/Observable.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import * as extent from 'ol/extent.js';
import { LineString, Point, MultiLineString, Polygon, MultiPolygon, MultiPoint } from 'ol/geom';
import LinearRing from 'ol/geom/LinearRing';
import GeoJSON from 'ol/format/GeoJSON';
import { getLength, getArea } from 'ol/sphere.js';
import { transform } from 'ol/proj.js';
import { platformModifierKeyOnly } from 'ol/events/condition';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import RasterSource from 'ol/source/Raster';
import { forkJoin, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import TileJSON from 'ol/source/TileJSON';
import Geometry from 'ol/geom/Geometry';
import { Draw } from 'ol/interaction.js';
import {
	contains as containsFilter,
	intersects as intersectsFilter,
	within as withinFilter
} from 'ol/format/filter';
import BufferOP from 'jsts/org/locationtech/jts/operation/buffer/BufferOp';
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser.js';
// import GeoJSONParser from 'jsts/org/locationtech/jts/io/GeoJSONParser.js'

import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { cloneDeep } from 'lodash-es';
import { SnackBarService } from 'app/core/snack-bar.service';
import { LayoutService } from 'app/core/layout.service';

@Injectable()
export class MapService {
	private map: any;
	private gisServerUrl: string;
	public serverUrl: string;
	private workspace: string;
	private basemaps: Array<Object>;
	private selectedBasemap: string;
	private workspaceLayers: Array<WorkspaceLayer>;
	private layersInit: boolean;
	private drawVectorLayerSource: VectorSource<Geometry>;
	private drawVectorLayer: VectorLayer<any>;
	private currentEditedVectorLayer: VectorLayer<any>;
	public selectInteraction: Select;
	private multiSelectInteraction: DragBox;
	private mapSingleClickEventKey: EventsKey;
	private mapMoveEventKey: EventsKey;
	private accuracyFeature: Feature<Geometry>;
	private scaleLineControl: ScaleLine;
	private userState;
	private drawInteraction: Draw;
	private jstsParser: any;
	// private projection2100: Projection;

	//Geolocation
	private geolocationVectorLayerSource: VectorSource<Geometry>;
	private geolocationVectorLayer: VectorLayer<any>;
	private positionFeature: Feature<Geometry>;
	private geolocation: Geolocation;
	private lastKnownLat;
	private lastKnownLon;
	private androidWatchLocationId;
	private scadaData: Map<string, station>;

	constructor(private stateService: StateService,
		private httpClient: HttpClient,
		private geoserverService: GeoserverService,
		private snackBarService: SnackBarService,
		private asmService: ActiveScreenManagementService,
		private layoutService: LayoutService
	) {

		this.serverUrl = StateService.getScheme() + '//' + environment.geoserverDomain + '/';
		this.gisServerUrl = StateService.getScheme() + '//' + environment.geoserverDomain + '/' + 'geoserver/';
		this.basemaps = [];
		this.selectedBasemap = undefined;
		this.layersInit = false;
		this.currentEditedVectorLayer = null;
		this.selectInteraction = null;
		this.multiSelectInteraction = null;

		this.initproj4();
		this.initBasemaps();

		StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.mapSingleClickEventKey = undefined;
		this.mapMoveEventKey = undefined;

		this.jstsParser = new OL3Parser();
		this.jstsParser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);

	}

	updateFromState() {
		if (!this.layersInit && this.stateService.getState().workspaceModelsLoaded && this.map) {

			this.userState = this.stateService.getScreenState();
			this.addMapLayersFromState();
			this.loadMapExtentFromState();
			this.registerMapMoveEvent();
		}

		//Operations actions
		let activeOperation = this.stateService.getActiveOperation();
		if (activeOperation.operation != OPERATIONS.info && this.map) {
			this.unregisterMapEvents();
		}

		if (activeOperation.operation == OPERATIONS.info && this.map) {
			this.registerMapEvents();
		}

	}

	public adjustWorkspaceLayers(dest: Array<WorkspaceLayer>, source: Array<WorkspaceLayer>) {

		let missing = source.filter(sourceLayer => !dest.some(destLayer => sourceLayer.Title === destLayer.Title));
		let unwanted = dest.filter(destLayer => !source.some(sourceLayer => sourceLayer.Title === destLayer.Title));

		unwanted.forEach(unwantedLayer => dest.splice(dest.indexOf(dest.find(layer => unwantedLayer.Title === layer.Title)), 1));
		missing.forEach(missingLayer => dest.push(missingLayer));
	}

	addMapLayersFromState() {

		this.workspace = this.stateService.getWorkspace();
		let screenState = this.stateService.getScreenState();
		let initialWorkspaceLayers = [];
		let workspaceLayers = [];

		if (screenState && screenState.layerOrder && screenState.layerOrder.length > 0) {
			workspaceLayers = cloneDeep(screenState.layerOrder);
			initialWorkspaceLayers = this.asmService.filterLayersByScreen(this.stateService.getWorkspaceLayers());
			this.adjustWorkspaceLayers(workspaceLayers, initialWorkspaceLayers);
			for (let i = 0; i < workspaceLayers.length; i++) {
				this.adjustWorkspaceLayers(workspaceLayers[i].Layer, initialWorkspaceLayers[i].Layer);
			}

		} else {
			workspaceLayers = this.stateService.getWorkspaceLayers();
		}

		if (workspaceLayers && workspaceLayers.length > 0) {
			this.workspaceLayers = this.asmService.filterLayersByScreen(workspaceLayers);
			this.layersInit = true;

			//Iterate through layers and add the to the map
			for (let i = 0; i < this.workspaceLayers.length; i++) {
				const layerEntry = this.workspaceLayers[i];
				if (layerEntry.Layer) {// Is a layer group
					for (let j = 0; j < layerEntry.Layer.length; j++) {
						const subLayer = layerEntry.Layer[j];
						this.layerVisibilityActions(subLayer, this.calculateMapIndex(j, layerEntry, this.workspaceLayers), this.userState);
					}
				} else {
					this.layerVisibilityActions(layerEntry, i, this.userState);
				}

			}

			StateService.stateStore.dispatch(this.stateService.setUserStateFirstRunOperation(false));
			this.addVectorLayer();
		}

	}

	layerVisibilityActions(layer: WorkspaceLayer, index: number, userState) {
		//let userState = this.stateService.getScreenState();
		let firstRun = this.stateService.getUserStateFirstRun();

		// User state has priority
		if (userState && !firstRun) {
			let openlayers = userState.openLayers;
			for (let i = 0; i < openlayers.length; i++) {
				const stateLayer = openlayers[i];
				if (stateLayer.Name === layer.Name) {
					if (layer.KeywordList.includes('render_as_wfs')) {
						this.toggleWFSService(layer, index + 1, true, undefined, undefined, '_edit');
					} else if (layer.KeywordList.includes('ImageMosaic') || layer.KeywordList.includes('TiledGWC')) {
						this.toggleGWCTileService(layer, index + 1, true);
					} else if (layer.KeywordList.includes('Tiled')) {
						this.toggleWMSService(layer, index + 1, true);
					} else {
						this.toggleImageWMS(layer, index + 1, true);
					}

				}
			}
		} else {
			if (layer.Model && layer.Model.show_on_init) {
				if (layer.KeywordList.includes('render_as_wfs')) {
					this.toggleWFSService(layer, index + 1, true, undefined, undefined, '_edit');
				} else if (layer.KeywordList.includes('ImageMosaic') || layer.KeywordList.includes('TiledGWC')) {
					this.toggleGWCTileService(layer, index + 1, true);
				} else if (layer.KeywordList.includes('Tiled')) {
					this.toggleWMSService(layer, index + 1, true);
				} else {
					this.toggleImageWMS(layer, index + 1, true);
				}
			}
			if (layer.KeywordList.includes('zoom_to')) {
				this.zoomToLayer(layer);
			}
		}

	}

	/**
	 * 
	 * @param layer The layer to change z-index on the map
	 * @param index The desirable new index 
	 */
	public setLayerIndex(layer: WorkspaceLayer, index: number) {

		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer.get('name') === layer.Name) {
				currentLayer.setZIndex(index);
			}
		});

	}

	/**
	 * 
	 * @param layer The layer to change the filter to
	 * @param filter The desirable new filter
   * @param [filterEvent] Filter event type. See models.ts -> FilterEventType
	 */
	public setLayerEcqlFilter(layer: WorkspaceLayer, filter: string | null, filterEvent?: FilterEventType) {
		if (filterEvent === 'DELETE') {
			filter = null;
		}
		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer.get('name') === layer.Name) {
				currentLayer.getSource().updateParams({ "cql_filter": filterEvent === 'ENABLE' ? filter : null });				
			}
		});
		// Store the filter to state
		StateService.stateStore.dispatch(this.stateService.setLayerFilterOperation(this.stateService.getWorkspaceLayerByName(layer.Name), filter, filterEvent === 'ENABLE'));
		StateService.stateStore.dispatch(this.stateService.removeOpenLayerOperation(layer));
		StateService.stateStore.dispatch(this.stateService.addOpenLayerOperation(layer));

	}

	public calculateMapIndex(index: number, currentGroup: WorkspaceLayer, workspaceLayers: Array<WorkspaceLayer>): number {
		let reversed_index: number;
		let offset: number;
		offset = workspaceLayers
			.filter(group => workspaceLayers.indexOf(group) > workspaceLayers.indexOf(currentGroup))
			.reduce((lowerLayers, group) => lowerLayers + group.Layer.length, 0);
		reversed_index = currentGroup.Layer.length - 1 - index;
		return reversed_index + offset;
	}

	/**
	 * Bootstraps the map
	 * Gets called by ngAfterViewInit in map.component
	 */
	bootstrapMap() {
		this.initMapProjection2100();
		this.loadBasemapFromState();
		this.addMapControls();
		// this.addMapInteractions();
		this.registerMapEvents(true);
		// this.registerMapMoveEvent();
		// this.addVectorLayer();
		// this.addRasterLayer();


		this.layersInit = false;
		setTimeout(() => {
			this.updateFromState();
		}, 0);
		return this.map;
	}

	loadBasemapFromState() {
		let screenState = this.stateService.getScreenState();
		if (screenState) {
			this.setBasemap(screenState.basemapName);
		} else {
			this.setBasemap('esritopo');
		}
	}

	loadMapExtentFromState() {
		let screenState = this.stateService.getScreenState();
		if (screenState && screenState.mapExtent) {
			this.map.getView().fit(screenState.mapExtent, this.map.getSize());
		}
	}

	getMap() {
		return this.map;
	}

	//================================================
	// 0. MAP INITIALIZATIONS ========================
	//================================================
	// initMap3857() {

	// 	this.map = new Map({

	// 		layers: [
	// 			new TileLayer({
	// 				source: new OSM()
	// 			})
	// 		],
	// 		target: 'map',
	// 		controls: defaultControls({
	// 			zoom: true,
	// 			attribution: false,
	// 			rotate: true
	// 		}).extend([]),
	// 		view: new View({
	// 			center: fromLonLat([22.220832, 40.564674]),
	// 			zoom: 16
	// 		})

	// 	});

	// }

	initMapProjection2100() {

		// this.projection2100 = new Projection({
		// 	code: 'EPSG:2100',
		// 	extent: [94874.71, 3868409.44, 857398.00, 4630676.91]
		// });

		let userData = this.stateService.getUserData();
		let maxZoomLevel = 27;
		if (userData && userData.maxZoomLevel) {
			maxZoomLevel = userData.maxZoomLevel;
		}

		let projectedView = new View({
			// projection: this.projection2100,
			projection: 'EPSG:2100',
			center: [349195.06, 4491669.43],
			// center: openlayers.proj.fromLonLat([-85.685, 39.891], 'EPSG:2100'),
			// extent: openlayers.proj.transformExtent([-172.54, 23.81, -47.74, 86.46], 'EPSG:4326', 'EPSG:2100'),
			enableRotation: false,
			zoom: 7,
			maxZoom: maxZoomLevel,
			minZoom: 9
		});

		this.map = new olMap({
			target: 'map',
			view: projectedView,
			// loadTilesWhileAnimating: true,
			// loadTilesWhileInteracting: true,
			// renderer: (['webgl', 'canvas']),
			interactions: defaultInteractions({ doubleClickZoom: false })
		});

		// projectedView.on('change:resolution', (e)=>{
		// 	console.log(e)
		// });

		this.updateFromState();
	}

	addMapControls() {
		// Scale line control
		this.scaleLineControl = new ScaleLine({
			units: 'metric',
			bar: true,
			steps: 4,
			minWidth: 140,
			text: true
		});
		this.map.addControl(this.scaleLineControl);

		// Mouse position control
		let mousePositionControl = new MousePosition({
			projection: 'EPSG:2100',
			coordinateFormat: (coordinate) => {
				return format(coordinate, '{x}, {y}', 4);
			}
		});
		this.map.addControl(mousePositionControl);

		// Full screen control 
		// let fullScreenControl = new openlayers.control.FullScreen();
		// this.map.addControl(fullScreenControl);
	}

	addMapInteractions() {
		// let dragZoom = new DragZoom ({
		//   condition: openlayers.events.condition.always,      
		// });
		// this.map.addInteraction( dragZoom );
	}

	registerMapEvents(reregister: boolean = false) {
		if (this.mapSingleClickEventKey && !reregister) {
			return;
		} else {
			this.unregisterMapEvents();
		}

		this.mapSingleClickEventKey = this.map.on('singleclick', (event) => {
			this.mapSingleClickEventHandler(event);
		});

		// Disable context menu
		this.map.getViewport().addEventListener('contextmenu', (mouseEvent) => {
			mouseEvent.preventDefault();
			// console.log(this.map.getEventCoordinate(mouseEvent));
		});

		this.addMultiSelectForInfoInteraction();

		StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
	}

	registerMapMoveEvent() {
		if (this.mapMoveEventKey) {
			olObservable.unByKey(this.mapMoveEventKey);
		}

		this.mapMoveEventKey = this.map.on('moveend', (mapEvent) => {
			StateService.stateStore.dispatch(this.stateService.setScreenMapExtentOperation(mapEvent.frameState.extent));
		});
	}

	mapSingleClickEventHandler(mouseEvent) {

		// Start loading
		StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));

		// Vector layers identify
		this.map.forEachFeatureAtPixel(mouseEvent.pixel, (feature, layer) => {
			if (!layer) return;
			let workspaceLayer = this.stateService.getWorkspaceLayerByName(layer.get('name').replace('_edit', ''));

			if (workspaceLayer?.Model.openDashboardItemOnSelect) {
				if (feature.get('type') in ScadaDashboardType) {
					StateService.stateStore.dispatch(this.stateService.setActiveDashboardOperation(feature.get('type'), feature.get('name')));
				}
			}

		}, {
			hitTolerance: 5
		});

		// Get identifiable layers
		let visibleIdentifiableLayers = this.getVisibleLayers(true, true);//Only identifiable, include rasters
		if (visibleIdentifiableLayers.length > 0) {

			this.getFeatureInfo(visibleIdentifiableLayers, mouseEvent).subscribe((parsedData: any) => {

				// If only one feature is selected add to vector layer else deselect any selected features 
				// as the user will select from the table
				if (parsedData.vectorData.length === 1 && parsedData.vectorData[0].features.length === 1) {// One layer, one feature
					this.clearVectorLayer();

					let selectedLayer: WorkspaceLayer = parsedData.vectorData[0].selectedLayer;
					let selectedFeature = parsedData.vectorData[0].features[0];
					this.addFeaturesToVectorLayer([selectedFeature]);

					//These lines opn feature table and select the selected freature from map on it
					/* StateService.stateStore.dispatch(this.stateService.setFeatureTableSelectedFeatures(selectedLayer, [selectedFeature]));
					StateService.stateStore.dispatch(this.stateService.setFeatureTableData([selectedFeature], true, selectedLayer, 1, 0)); */

					//these lines set the form data so that the form can open without the table
					StateService.stateStore.dispatch(this.stateService.setFormDataOperation({
						title: selectedLayer.Title,
						features: [selectedFeature],
						selectedLayer: selectedLayer,
						eventFrom: 1
					}));

				} else {
					this.clearVectorLayer();

					if (parsedData.vectorData.length === 1) {// Means that we have features from one layer only
						StateService.stateStore.dispatch(this.stateService.setFeatureTableData(parsedData.vectorData[0].features, true, parsedData.vectorData[0].selectedLayer, parsedData.vectorData[0].features.length, 0));
					} else {
						StateService.stateStore.dispatch(this.stateService.setFeatureInfoOperation([...parsedData.vectorData, ...parsedData.rasterData]));
					}

				}

				StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
			});

		}

	}

	getVisibleLayers(onlyIdentifiable: boolean = false, includeRasters: boolean = false): string[] {

		let visibleLayers = [];

		this.map.getLayers().forEach((currentLayer) => {

			if (this.isBaseLayer(currentLayer) // If it's a base layer 
				|| currentLayer.get('name').includes('drawLayer') // If is the draw layer
				|| currentLayer.get('isEditLayer')) { //If is an edit wfs layer
				return;
			}

			let workspaceLayer = this.stateService.getWorkspaceLayerByName(currentLayer.get('name'));
			// Check if layer is identifiable
			if (onlyIdentifiable) {
				if (!workspaceLayer.Model || !workspaceLayer.Model.identifiable) {
					return;
				}
			}

			if (!includeRasters) {
				if (!workspaceLayer.Model || workspaceLayer.Model.is_raster) {
					return;
				}
			}

			visibleLayers.push(currentLayer.get('name'));
		});

		return visibleLayers;
	}

	addMultiSelectForInfoInteraction() {

		this.multiSelectInteraction = new DragBox({
			condition: platformModifierKeyOnly
		});

		this.map.addInteraction(this.multiSelectInteraction);

		this.multiSelectInteraction.on('boxend', () => {
			// Start loading
			StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));

			let visibleLayers = this.getVisibleLayers(true);

			// Intersected features
			let extent = this.multiSelectInteraction.getGeometry().getExtent();
			this.zoomToExtent(extent);

			// this.geoserverService.getFeaturesBbox(visibleLayers, extent).subscribe(
			this.geoserverService.getFeature(visibleLayers, undefined, undefined, -1, undefined, false, false, undefined, undefined, undefined, false, extent, true)
		});

	}

	addPolygonFreehandSelectForInfoInteraction(activate: boolean) {

		if (activate) {
			this.drawInteraction = new Draw({
				source: this.drawVectorLayerSource,
				type: "Polygon",
				freehand: true,
			});

			this.map.addInteraction(this.drawInteraction);

			this.drawInteraction.on('drawend', (event: any) => {
				let drawedFeature = event.feature;

				// Start loading
				StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));

				let visibleLayers = this.getVisibleLayers(true);

				// Intersected features
				this.zoomToExtent(drawedFeature.getGeometry());

				let featureCoordinates = drawedFeature.getGeometry().getCoordinates()[0];
				let featureCoordinatesStringArray = [];

				for (let index = 0; index < featureCoordinates.length; index++) {
					const element = featureCoordinates[index];
					featureCoordinatesStringArray.push(element.join(" "));
				}

				let ecqlQuery = 'CONTAINS(the_geom,POLYGON((' + featureCoordinatesStringArray.join() + ')))';

				this.geoserverService.getFeaturePost(visibleLayers, containsFilter('the_geom', drawedFeature.getGeometry())).subscribe(
					(featuresResponce: any) => {
						StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
						let parsedData = this.geoserverService.parseMultipleFeatures(this.geoserverService.parseFeatures(featuresResponce));
						StateService.stateStore.dispatch(this.stateService.setFeatureInfoOperation(parsedData));
					}, error => {
						StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
					});

			})
		} else {
			this.clearVectorLayer();
			this.map.removeInteraction(this.drawInteraction);
		}


	}

	unregisterMapEvents() {
		if (this.mapSingleClickEventKey) {
			olObservable.unByKey(this.mapSingleClickEventKey);
			this.mapSingleClickEventKey = undefined;
		}

		if (this.multiSelectInteraction) {
			this.map.removeInteraction(this.multiSelectInteraction);
		}

	}

	isBaseLayer(layer) {

		let foundRecord;
		this.basemaps.map((basemap: any) => {
			if (basemap.name === layer.get('name')) {
				foundRecord = basemap;
			}
		})

		return foundRecord == undefined ? false : foundRecord;
	}

	/**
	 * Use the WMS GetFeatureInfo
	 * @param visibleLayers The layers to get info from
	 * @param mouseEvent The mouse event
	 */
	getFeatureInfo(visibleLayers: Array<string>, mouseEvent) {
		// let mapSize = this.map.getSize();
		// let clickPixel = mouseEvent.pixel;
		let requestArray = [];

		let visibleVectorLayers = [];
		let visibleRasterLayers = [];

		visibleLayers.map(layerName => {
			let workspaceLayer = this.stateService.getWorkspaceLayerByName(layerName);
			if (workspaceLayer.Model.is_raster) {
				visibleRasterLayers.push(layerName);
			} else {
				visibleVectorLayers.push(layerName);
			}
		});

		// Get features via WFS
		let coordinate = mouseEvent.coordinate;
		let currentZoomLevel = this.map.getView().getZoom();
		let buffer = this.createBuffer(coordinate, (4 / currentZoomLevel) * 10);

		// this.addFeaturesToVectorLayer([new Feature(buffer)]); //Just to see the buffer visually
		let filter = intersectsFilter('the_geom', buffer, 'EPSG:2100');
		requestArray.push(this.geoserverService.getFeaturePost(visibleLayers, filter));


		if (visibleRasterLayers.length != 0) {

			//Raster layers
			let httpRasterParams: HttpParams = this.createWmsGetFeatureInfoRequest(visibleRasterLayers, mouseEvent);
			requestArray.push(this.httpClient.get(this.gisServerUrl + 'wms', {
				withCredentials: true,
				responseType: 'json',
				params: httpRasterParams
			}));

		}

		return forkJoin(requestArray).pipe(
			map(featureInfoResponce => {
				let vectorData = [];
				let rasterData = [];

				// If the responce has vector layers
				if (visibleVectorLayers.length > 0) {
					let format = new GeoJSON();
					let parsedFeatures = format.readFeatures(featureInfoResponce[0]);
					vectorData = this.geoserverService.parseMultipleFeatures(parsedFeatures);
				}

				// If the responce has raster layers
				if (visibleRasterLayers.length > 0) {
					let format = new GeoJSON();
					let parsedFeatures = format.readFeatures(featureInfoResponce[1]);
					let workspaceLayer = this.stateService.getWorkspaceLayerByName(visibleRasterLayers[0]);
					rasterData = this.geoserverService.parseMultipleFeatures(parsedFeatures, workspaceLayer);
				}

				return {
					vectorData: vectorData,
					rasterData: rasterData
				};
			}));

	}

	createWmsGetFeatureInfoRequest(layers: Array<string>, mouseEvent): HttpParams {
		let mapSize = this.map.getSize();
		let clickPixel = mouseEvent.pixel;

		let httpParams: HttpParams = new HttpParams();
		httpParams = httpParams.set('service', 'wms');
		httpParams = httpParams.set('version', '1.3.0');
		httpParams = httpParams.set('request', 'GetFeatureInfo');
		httpParams = httpParams.set('outputFormat', 'application/json');

		httpParams = httpParams.set('x', Math.round(clickPixel[0]) + '');
		httpParams = httpParams.set('y', Math.round(clickPixel[1]) + '');

		httpParams = httpParams.set('width', mapSize[0]);
		httpParams = httpParams.set('height', mapSize[1]);
		httpParams = httpParams.set('query_layers', layers.join());
		httpParams = httpParams.set('layers', layers.join());
		httpParams = httpParams.set('info_format', 'application/json');
		httpParams = httpParams.set('crs', 'EPSG:2100');
		httpParams = httpParams.set('feature_count', '50');
		httpParams = httpParams.set('bbox', this.map.getView().calculateExtent(mapSize));

		return httpParams;
	}

	//================================================
	// 1. PROJ4  =====================================
	//================================================
	initproj4() {
		// Must do it for the openlayers to recognize the pro4j lib
		// openlayers.proj.setProj4(proj4);

		// Define 2100 projection
		proj4.defs('EPSG:2100', '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs');
		register(proj4);

		// let proj2100 = olProj.get('EPSG:2100');
		// proj2100.setExtent([94874.71, 3868409.44, 857398.00, 4630676.91]);

		// 2100 projection settings
		// let projection = openlayers.proj.get('EPSG:2100');
		// projection.setExtent([137050.85, 3996614.75, 751013.14, 4639438.00]);

		//Test
		// proj4.defs('EPSG:21781','+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs');
		// let projection2 = openlayers.proj.get('EPSG:21781');
		// projection2.setExtent([485071.54, 75346.36, 828515.78, 299941.84]);

		// Printouts
		// console.log(proj4);
		// console.log( proj4('EPSG:2100') );
		// console.log( openlayers.proj.get('EPSG:2100') );		
	}

	//================================================
	// 2. MAP ACTIONS ================================
	//================================================
	setBasemap(basemapName) {

		let layerObject;
		this.basemaps.map((basemap: any) => {
			if (basemap.name === basemapName) {
				layerObject = basemap;
			}
		})

		if (this.selectedBasemap) {
			let layerCollection = this.map.getLayers().forEach((layer) => {
				if (layer && layer.get('name') === this.selectedBasemap) {
					this.map.removeLayer(layer)
				}
			});
			// console.log( layerObject.layer instanceof  openlayers.layer.Tile );
		}

		this.selectedBasemap = basemapName;

		if (layerObject.layer) {
			this.map.addLayer(layerObject.layer);
			layerObject.layer.setZIndex(-1);// Set the index to lowest
		}

	}

	resizeMap() {
		setTimeout(() => {
			if (this.map) {
				this.map.updateSize();
			}
		}, 0);
	}


	//================================================
	// 3. LAYERS  ====================================
	//================================================
	addWMSService(workspace: string, layer: string) {

		let newWMSLayer = new TileLayer({
			// preload: Infinity,
			visible: true,
			source: new TileWMS({
				url: this.gisServerUrl + workspace + '/' + layer + '/wms',
				params: {
					LAYERS: workspace + ':' + layer,
					TILED: true,
					VERSION: '1.3.0'
				},
				projection: '2100'
			})
		});

		newWMSLayer.setZIndex(100);

		this.map.addLayer(newWMSLayer);
	}

	toggleGWCTileService(layer: WorkspaceLayer, index, enable) {

		if (enable) {

			let options = optionsFromCapabilities(this.geoserverService.gwcCapabilities, {
				layer: layer.Name,
				// matrixSet: 'EPSG:2100',
				// ,projection: 'EPSG:2100',
				format: 'image/png8',
			});

			let newWMSLayer = new TileLayer({
				opacity: 1,
				source: new WMTS(options)
			});

			newWMSLayer.set('name', layer.Name);
			newWMSLayer.set('isEditLayer', false);
			newWMSLayer.set('isWMSLayer', true);
			newWMSLayer.set('disableStyle', false);
			// newWMSLayer.set('id',layer.Name);
			if (layer.KeywordList.includes('ImageMosaic') || layer.KeywordList.includes('TiledGWC')) {
				newWMSLayer.setZIndex(0);
			} else {
				newWMSLayer.setZIndex(index);
			}

			this.map.addLayer(newWMSLayer);

			StateService.stateStore.dispatch(this.stateService.addOpenLayerOperation(layer));


		} else {

			this.map.getLayers().forEach((currentLayer) => {
				if (currentLayer && currentLayer.get('name') === layer.Name) {
					this.map.removeLayer(currentLayer);
					StateService.stateStore.dispatch(this.stateService.removeOpenLayerOperation(layer));
				}
			});

		}

	}

	toggleRasterLayer(layerName: string, index, enable: boolean, rasterSource: RasterSource, addToMap: boolean = true, notifyState: boolean = false) {

		if (enable) {

			let newRasterLayer = new ImageLayer({
				source: rasterSource
			});

			newRasterLayer.set('name', layerName);
			newRasterLayer.set('isEditLayer', false);
			// newRasterLayer.set('disableStyle', false);
			// newRasterLayer.setZIndex(index);

			if (addToMap) {
				this.map.addLayer(newRasterLayer);
			}

			if (notifyState) {
				StateService.stateStore.dispatch(this.stateService.addOpenLayerOperation(layerName));
			}

		} else {

			this.map.getLayers().forEach((currentLayer) => {
				if (currentLayer && currentLayer.get('name') === layerName) {
					this.map.removeLayer(currentLayer);
					if (notifyState) {
						StateService.stateStore.dispatch(this.stateService.removeOpenLayerOperation(layerName));
					}
				}
			});

		}

	}

	toggleWMSService(layer: WorkspaceLayer, index, enable) {

		if (enable) {

			// var tileGrid = createXYZ({
			// 	extent: this.projection2100.getExtent(),
			// 	tileSize: 512
			// });

			let newWMSLayer = new TileLayer({
				preload: Infinity,
				visible: true,
				opacity: (layer.Model && layer.Model.opacity) ? (layer.Model.opacity / 100) : 1,
				source: new TileWMS({
					url: this.gisServerUrl + this.workspace + '/wms',
					params: {
						LAYERS: layer.Name,
						TILED: layer.KeywordList.includes('Tiled'),
						VERSION: '1.3.0',
						FORMAT: 'image/png8',
						// WIDTH:512,
						// HEIGHT:512
						// ,STYLES  : 'polygon'
					},
					projection: 'EPSG:2100',
					// tileGrid: new TileGrid({
					// 	// extent: [-13884991, 2870341, -7455066, 6338219],
					// 	// resolutions: resolutions,
					// 	tileSize: [512, 512]
					// })
					// ,serverType: 'geoserver'
				})
			});

			newWMSLayer.set('name', layer.Name);
			newWMSLayer.set('isEditLayer', false);
			newWMSLayer.set('isWMSLayer', true);
			newWMSLayer.set('disableStyle', false);
			// newWMSLayer.set('id',layer.Name);
			if (layer.KeywordList.includes('ImageMosaic')) {
				newWMSLayer.setZIndex(0);
			} else {
				newWMSLayer.setZIndex(index);
			}

			this.map.addLayer(newWMSLayer);

			StateService.stateStore.dispatch(this.stateService.addOpenLayerOperation(layer));
		} else {

			this.map.getLayers().forEach((currentLayer) => {
				if (currentLayer && currentLayer.get('name') === layer.Name) {
					this.map.removeLayer(currentLayer);
					StateService.stateStore.dispatch(this.stateService.removeOpenLayerOperation(layer));
				}
			});

		}

	}

	toggleImageWMS(layer: WorkspaceLayer, index, enable) {

		if (enable) {

			let newWMSLayer = new ImageLayer({
				// preload: Infinity,
				visible: true,
				opacity: (layer.Model && layer.Model.opacity) ? (layer.Model.opacity / 100) : 1,
				source: new ImageWMS({
					url: this.gisServerUrl + this.workspace + '/wms',
					imageLoadFunction: (image, src) => {

						let headers = new HttpHeaders({ 'Content-Type': 'image/png8' });
						this.httpClient.get(src, {
							headers: headers,
							withCredentials: true,
							responseType: 'blob'
						}).subscribe((res: any) => {
							let object = URL.createObjectURL(res);
							let imageElement = image.getImage() as any;
							imageElement.src = object;
						});

					},
					params: {
						LAYERS: layer.Name,
						TILED: false,
						VERSION: '1.3.0',
						FORMAT: 'image/png8'
						// ,STYLES  : 'polygon'
					},
					projection: '2100',
					serverType: 'geoserver',
					ratio: 1// How much of the viewport will load
				})
			});

			newWMSLayer.set('name', layer.Name);
			newWMSLayer.set('isEditLayer', false);
			newWMSLayer.set('isWMSLayer', true);
			newWMSLayer.set('disableStyle', false);
			// newWMSLayer.set('id',layer.Name);
			if (layer.KeywordList.includes('ImageMosaic') || layer.KeywordList.includes('GeoTIFF')) {
				newWMSLayer.setZIndex(0);
			} else {
				newWMSLayer.setZIndex(index);
			}

			this.map.addLayer(newWMSLayer);

			StateService.stateStore.dispatch(this.stateService.addOpenLayerOperation(layer));
		} else {

			this.map.getLayers().forEach((currentLayer) => {
				if (currentLayer && currentLayer.get('name') === layer.Name) {
					this.map.removeLayer(currentLayer);
					StateService.stateStore.dispatch(this.stateService.removeOpenLayerOperation(layer));
				}
			});

		}

	}

	/**
	 * 
	 * @param layer The layer to fetch
	 * @param index The map index
	 * @param enable Enable=true, disable=false
	 * @param style The layer style(optional)
	 * @param strategy The loading strategy(optional)
	 * @param namePostfix The layer name postfix (optional)
	 * @param addToMap Add layer to map (optional, default=true)
	 * @param minZoom Set layers minZoom (optional)
	 */
	toggleWFSService(layer: WorkspaceLayer, index, enable, style = undefined,
		strategy = bboxStrategy, namePostfix: string = '_edit',
		addToMap: boolean = true, minZoom: number = undefined): VectorLayer<any> {

		if (enable) {

			let style = undefined;
			if (layer.Model.wfsIcon) {
				style = this.getVectorLayerStyleFunction(layer, this.scadaData);
			}
			//===================================================
			// Init Vector source
			//===================================================
			let vectorSource = new VectorSource({
				format: new GeoJSON(),
				// url: this.gisServerUrl+'wfs?'+urlPostfix,
				// projection:'2100',				
				strategy: strategy,
				loader: (extent, resolution, projection) => {

					let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

					let httpParams: HttpParams = new HttpParams({
						fromObject: {
							service: 'wfs',
							version: '2.0.0',// was 1.3.0
							request: 'GetFeature',
							typename: layer.Name,
							outputFormat: 'application/json',
							srsname: 'EPSG:2100'
						}
					});
					if (extent[0] != Infinity && extent[0] != -Infinity) {
						httpParams = httpParams.append('bbox', extent + '');
					}

					this.httpClient.post(this.gisServerUrl + 'wfs', null, {
						headers: headers,
						params: httpParams,
						withCredentials: true,
						responseType: 'json'
					}).subscribe((res: any) => {
						//Refers to the vector source
						let format = new GeoJSON({
							dataProjection: projection,
							featureProjection: projection
						});
						let features = format.readFeatures(res, { dataProjection: projection, featureProjection: projection });
						vectorSource.addFeatures(features);
					});

				}
			});

			let newWFSLayer = new VectorLayer({
				// preload: Infinity,
				declutter: true,
				visible: true,
				source: vectorSource,
				style: style,
			});

			if (minZoom) {
				newWFSLayer.setMinZoom(minZoom);
			}

			if (style) {
				newWFSLayer.setStyle(style);
			}

			newWFSLayer.set('name', layer.Name + namePostfix);
			newWFSLayer.set('isEditLayer', true);
			newWFSLayer.setZIndex(index);

			if (addToMap) {
				this.map.addLayer(newWFSLayer);
				if (layer.Model.renderAsWfs) {
					StateService.stateStore.dispatch(this.stateService.addOpenLayerOperation(layer));
				}
			}

			this.currentEditedVectorLayer = newWFSLayer;
			return newWFSLayer;
		} else {

			this.map.getLayers().forEach((currentLayer) => {
				if (currentLayer && (currentLayer.get('name') === layer.Name + '_edit')) {
					this.map.removeLayer(currentLayer);
					if (layer.Model.renderAsWfs) {
						StateService.stateStore.dispatch(this.stateService.removeOpenLayerOperation(layer));
					}
				}
			});

		}
	}

	rerenderScadaWfsLayer(layerName: string, labelData: Map<string, station>) {

		setTimeout(() => {
			let layer = this.getMapLayerByName(this.stateService.getWorkspace() + ':' + layerName + '_edit');
			let workSpaceLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':' + layerName);
			if (!layer) {
				console.debug("layer is null");
				return;
			}
			layer.setStyle(this.getVectorLayerStyleFunction(workSpaceLayer, labelData));
			layer.getSource().refresh();
		}, 3000);

	}

	getVectorLayerStyleFunction(layer: WorkspaceLayer, data: Map<string, station> = undefined) {
		this.scadaData = data;
		if (!data || data.size == 0) return null;
		let tankSvg = new Image();

		tankSvg.src = 'data:image/svg+xml,' + encodeURIComponent(layer.Model.wfsIcon.tankIcon);

		let drillingSvg = new Image();
		drillingSvg.src = 'data:image/svg+xml,' + encodeURIComponent(layer.Model.wfsIcon.drillingIcon);

		/* let flowMeterSvg = new Image();
		flowMeterSvg.src = 'data:image/svg+xml,' + encodeURIComponent(layer.Model.wfsIcon.flowMeterIcon); */

		return (feature, resolution) => {
			let imgSVG = tankSvg;
			let itemData: station;
			if (feature.get('type') === ScadaDashboardType.tank || feature.get('type') === ScadaDashboardType.pumping_station) {
				itemData = data[(feature.get('station_name'))];
			} else if (feature.get('type') === ScadaDashboardType.drilling) {
				imgSVG = drillingSvg;
				itemData = data[(feature.get('station_name'))];
			} /* else if (feature.get('type') === ScadaDashboardType.pump) {
				itemData = data.get(feature.get('station_name')).connected.get(feature.get('name'));
				let pumpSvg = new Image();

				if (!itemData.status) {
					pumpSvg.src = 'data:image/svg+xml,' + encodeURIComponent(layer.Model.wfsIcon["pumpIcon"]);
				} else {
					let running = itemData.status.running;
					if (itemData.status.illegal || itemData.status.collectiveFault) {
						running = 0;
					}
					pumpSvg.src = 'data:image/svg+xml,' + encodeURIComponent(layer.Model.wfsIcon["pump" + itemData.status.illegal + itemData.status.collectiveFault + running + itemData.status.local + itemData.status.auto]);
				}


				imgSVG = pumpSvg;
			} else if (feature.get('type') === ScadaDashboardType.flow_meter) {
				imgSVG = flowMeterSvg;
				itemData = data.get(feature.get('station_name')).connected.get(feature.get('name'));
			} */ else if (feature.get('type') === ScadaDashboardType.flow_meter || feature.get('type') === ScadaDashboardType.pump) {
				return null;
			} else {
				itemData = data[(feature.get('station_name'))];
			}

			// if(map.getView().getZoom())
			return new Style({
				image: new Icon({
					img: imgSVG,
					imgSize: [24, 24],
					scale: 2,
				}),
				text: this.createTextStyle(feature, resolution, '#333333', '#ffffff', itemData),
			});
		}
	}

	private createTextStyle(feature, resolution, fillColor, strokeColor, data: station) {
		const align = 'center';
		const baseline = 'middle';//'bottom', 'top', 'middle', 'alphabetic', 'hanging', 'ideographic'
		const size = '14px';
		const height = '1';
		const offsetX = 0;
		const offsetY = 35;
		const weight = 'bold';
		const placement = 'point';
		const maxAngle = 45;
		const overflow = false;
		const rotation = 0;
		const font = weight + ' ' + size + '/' + height + ' Noto Sans';
		const outlineWidth = 2;

		return new Text({
			textAlign: align,
			textBaseline: baseline,
			font: font,
			text: feature.get('description'), //data ? this.calculateText(feature, data) : '-',
			fill: new Fill({ color: fillColor }),
			stroke: new Stroke({ color: strokeColor, width: outlineWidth }),
			offsetX: offsetX,
			offsetY: offsetY,
			placement: placement,
			maxAngle: maxAngle,
			overflow: true,
			rotation: rotation,
		});
	};

	private calculateText(feature, data: scadaEntity) {
		let label = feature.get('description') + '\n';
		let decimals: number;
		switch (feature.get('type')) {
			case ScadaDashboardType.pump:
				decimals = 1;
				break;
			case ScadaDashboardType.tank:
				decimals = 2;
				break;
			case ScadaDashboardType.flow_meter:
				decimals = 1;
				break;
			case ScadaDashboardType.drilling:
				decimals = 2;
				break;
			case ScadaDashboardType.pumping_station:
				decimals = 2;
				break;
			default:
				break;
		}
		if (data.variables) {
			data.variables.forEach((value) => {
				label += value.toFixed(decimals);
			});
		}

		return label;
	};

	zoomToLayer(layer) {
		
		// let mapExtent = this.map.getView().calculateExtent(this.map.getSize());
		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer.get('name') === layer.Name) {
				// this.map.getView().fit( currentLayer.getSource().getExtent(),this.map.getSize() );
				this.map.getView().fit(layer.BoundingBox[1].extent, this.map.getSize());
			}
		});

	}

	setLayerOpacity(layer, opacity) {

		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer.get('name') === layer.Name) {
				currentLayer.setOpacity(opacity / 100);
			}
		});

	}

	zoomToCoordinates(lat, lng) {
		let addressGeometry = new Point([lat, lng]);
		this.zoomToGeometry(addressGeometry);
	}

	zoomToGeometry(geometry) {
		// this.map.getView().fit( geometry.getExtent(),{ maxZoom:18, size:this.map.getSize() } );
		this.map.getView().fit(geometry.getExtent(), { maxZoom: 20, size: this.map.getSize() });
	}

	zoomToGeometries(geometries: Array<any>) {
		let newExtent = extent.createEmpty();
		for (let i = 0; i < geometries.length; i++) {
			extent.extend(newExtent, geometries[i].getExtent());
		}

		this.map.getView().fit(newExtent, { maxZoom: 18, size: this.map.getSize() });
	}

	/**
	 * Calculate the features extent
	 * @param features The features to zoom to
	 * @param returnExtent if true will return the extent without zooming 
	 */
	zoomToFeatures(features: Array<Feature<Geometry>>, returnExtent: boolean = false, maxZoom: number = 18) {

		let mapExtent = this.map.getView().calculateExtent(this.map.getSize());
		let newExtent = extent.createEmpty();
		for (let i = 0; i < features.length; i++) {
			extent.extend(newExtent, features[i].getGeometry().getExtent());
		}

		if (returnExtent) {
			return newExtent;
		} else {

			if (!extent.containsExtent(mapExtent, newExtent)) {
				this.map?.getView().fit(newExtent, { maxZoom: maxZoom, size: this.map.getSize() });
			}
		}

	}

	animateToFeature(feature: Feature, zoom: number) {
		if (feature.getGeometry().getType() === 'MultiPoint') {
			this.map.getView().animate({
				center: (<MultiPoint>feature.getGeometry()).getFirstCoordinate(),
				zoom: zoom
			});
		} else {
			this.map.getView().animate({
				center: (<Point>feature.getGeometry()).getCoordinates(),//feature.getGeometry().flatCoordinates
				zoom: zoom
			});
		}

	}

	zoomToExtent(extent) {
		this.map.getView().fit(extent, { size: this.map.getSize() });
	}

	reloadVisibleLayers(reloadOnlyRaster = false) {

		this.map.getLayers().forEach((currentLayer) => {

			if (currentLayer.get('isWMSLayer')) {
				currentLayer.getSource().updateParams({ "time": Date.now() });
			}

			if (currentLayer.getSource() instanceof VectorSource && !reloadOnlyRaster) {
				currentLayer.getSource().refresh();
			}

		});

	}

	removeLayerFromMap(layerName: string) {
		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer && currentLayer.get('name') === layerName) {
				this.map.removeLayer(currentLayer);
			}
		});
	}

	getMapLayerByName(layerName: string) {
		let mapLayer = undefined;
		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer && currentLayer.get('name') === layerName) {
				mapLayer = currentLayer;
			}
		});

		return mapLayer;
	}

	//================================================
	// 4. RASTERS  ===================================
	//================================================
	// addRasterLayer() {

	// 	let untiled = new ImageLayer({
	// 		source: new ImageWMS({
	// 			ratio: 1,
	// 			url: this.serverUrl + 'tagarohori/wms',
	// 			params: {
	// 				FORMAT: 'image/png',
	// 				VERSION: '1.3.0',
	// 				TILED: true,
	// 				STYLES: '',
	// 				LAYERS: 'tagarohori:tagarohori_basemap',
	// 			},
	// 			projection: '2100'
	// 		})
	// 	});

	// 	let tiled = new TileLayer({
	// 		visible: false,
	// 		source: new TileWMS({
	// 			url: this.serverUrl + '/tagarohori/wms',
	// 			params: {
	// 				FORMAT: 'image/png',
	// 				VERSION: '1.3.0',
	// 				TILED: true,
	// 				STYLES: '',
	// 				LAYERS: 'tagarohori:tagarohori_tiled'
	// 				// ,tilesOrigin: 348996.14716433967 + "," + 4491358.807311099
	// 			},
	// 			projection: '2100'
	// 		})
	// 	});

	// 	this.map.addLayer(untiled);
	// }

	//================================================
	// 5. BASEMAPS  ==================================
	//================================================
	initBasemaps() {

		// this.basemaps.push({
		// 	name: 'stamen_terrain',
		// 	label: 'Stamen Terrain',
		// 	layer: this.createStamenBasemap(),
		// 	imageSrc: environment.assetsPrefix + 'assets/images/stamen.jpg'
		// });

		this.basemaps.push({
			name: 'bing',
			label: 'Bing Maps',
			layer: this.createBingBasemap(),
			imageSrc: environment.assetsPrefix + 'assets/images/bing.jpg'
		});

		this.basemaps.push({
			name: 'google',
			label: 'Google Hybrid',
			layer: this.createGoogleSatelliteBasemap(),
			imageSrc: environment.assetsPrefix + 'assets/images/google.jpg'
		});
		// this.basemaps.push({
		// 	name: 'ktimanet',
		// 	label: 'Ktimanet Maps',
		// 	layer: this.createKtimanetBasemap(),
		// 	imageSrc: environment.assetsPrefix + 'assets/images/ktimanet.jpg'
		// });

		this.basemaps.push({
			name: 'cartodb',
			label: 'CartoDB Maps',
			layer: this.createCartoDBBasemap(),
			imageSrc: environment.assetsPrefix + 'assets/images/carto_dark.jpg'
		});

		// this.basemaps.push({
		// 	name: 'heretopo',
		// 	label: 'HERE Topographic',
		// 	layer: this.createHereTopoBasemap(),
		// 	imageSrc: environment.assetsPrefix + 'assets/images/heretopo.jpeg'
		// });

		// this.basemaps.push({
		// 	name: 'heresat',
		// 	label: 'HERE Sattelite',
		// 	layer: this.createHereSatelliteBasemap(),
		// 	imageSrc: environment.assetsPrefix + 'assets/images/heresat.jpg'
		// });

		this.basemaps.push({
			name: 'esritopo',
			label: 'Topographic',
			layer: this.createESRITopoBasemap(),
			imageSrc: environment.assetsPrefix + 'assets/images/esritopo.jpeg'
		});

		// this.basemaps.push({
		// 	name: 'esrisat',
		// 	label: 'ESRI Sattelite',
		// 	layer: this.createESRISatelliteBasemap(),
		// 	imageSrc: environment.assetsPrefix + 'assets/images/esrisat.jpg'
		// });

		this.basemaps.push({
			name: 'osm',
			label: 'Open Street Maps',
			layer: this.createOSMBasemap(),
			imageSrc: environment.assetsPrefix + 'assets/images/osm.jpg'
		});

		this.basemaps.push({
			name: 'blank',
			label: 'No Basemap',
			layer: null,
			imageSrc: environment.assetsPrefix + 'assets/images/blank.jpg'
		});

	}

	getBaseMapList() {
		return this.basemaps;
	}

	createOSMBasemap() {
		let osmLayer = new TileLayer({
			preload: Infinity,
			source: new OSM()
		});

		osmLayer.set('name', 'osm');
		osmLayer.setZIndex(0);
		return osmLayer;
	}

	/**
	* Add a Stamen basemap
	* Available values : terrain, terrain-background, terrain-labels, terrain-lines
	*                    toner-background, toner, toner-hybrid, toner-labels, toner-lines, 
	*					 toner-lite, watercolor	
	*/
	createStamenBasemap() {
		let stamenLayer = new TileLayer({
			preload: Infinity,
			source: new Stamen({
				layer: 'watercolor'
			})
		});

		stamenLayer.set('name', 'stamen_terrain');
		stamenLayer.setZIndex(0);
		return stamenLayer;
	}

	/**
	* Obtained from https://www.bingmapsportal.com
	* Imagery set values : Road,Aerial,AerialWithLabels
	*/
	createBingBasemap() {
		let bingLayer = new TileLayer({
			preload: Infinity,
			source: new BingMaps({
				key: 'AtLMtNBvf3aK28dF-I1JwGIUzXNnYLWwFdl7x6NuYZt04kbGbDY-r2Icm0XUx6QD',
				imagerySet: 'AerialWithLabels',
				placeholderTiles: false
			})
		});

		bingLayer.set('name', 'bing');
		bingLayer.setZIndex(0);
		return bingLayer;
	}

	/**
	* Obtained from basemaps.cartocdn.com
	*/
	createCartoDBBasemap() {
		let cartodbLayer = new TileLayer({
			preload: Infinity,
			source: new XYZ({
				url: 'http://{a-c}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
			})
		});

		cartodbLayer.set('name', 'cartodb');
		cartodbLayer.setZIndex(0);
		return cartodbLayer;
	}

	/**
	* Obtained from esri.com
	*/
	createESRITopoBasemap() {
		let esriLayer = new TileLayer({
			preload: Infinity,
			source: new XYZ({
				url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' + 'World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
			})
		});

		esriLayer.set('name', 'esritopo');
		esriLayer.setZIndex(0);
		return esriLayer;
	}

	/**
	* Obtained from esri.com
	*/
	createESRISatelliteBasemap() {
		let esriLayer = new TileLayer({
			preload: Infinity,
			source: new XYZ({
				url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' + 'World_Imagery/MapServer/tile/{z}/{y}/{x}'
			})
		});

		esriLayer.set('name', 'esrisat');
		esriLayer.setZIndex(0);
		return esriLayer;
	}

	/**
	* Obtained from Ktimanet
	*/
	createKtimanetBasemap() {

		let ktimaLayer = new TileLayer({
			preload: Infinity,
			source: new TileWMS({
				url: 'http://gis.ktimanet.gr/wms/wmsopen/wmsserver.aspx',
				params: { 'LAYERS': 'basic', 'TILED': true },
				projection: 'EPSG:4326'
			})
		})

		ktimaLayer.set('name', 'ktimanet');
		ktimaLayer.setZIndex(0);
		return ktimaLayer;
	}

	/**
	* Obtained from basemaps.cartocdn.com
	*/
	createHereTopoBasemap() {

		let hereLayer = new TileLayer({
			preload: Infinity,
			source: new XYZ({
				url: 'https://{1-4}.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png?app_id=bIlxmlPTMpA89AfYr1pt&app_code=V3UGlQcNUPDhzTp8KMmBVg'
			})
		});

		hereLayer.set('name', 'heretopo');
		hereLayer.setZIndex(0);
		return hereLayer;
	}

	/**
	* Obtained from basemaps.cartocdn.com
	*/
	createHereSatelliteBasemap() {

		let hereLayer = new TileLayer({
			preload: Infinity,
			source: new XYZ({
				url: 'https://{1-4}.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png?app_id=bIlxmlPTMpA89AfYr1pt&app_code=V3UGlQcNUPDhzTp8KMmBVg'
			})
		});

		hereLayer.set('name', 'heresat');
		hereLayer.setZIndex(1);
		return hereLayer;
	}

	/**
	* Obtained from esri.com
	*/
	createGoogleSatelliteBasemap() {

		// let googleLayer = new TileLayer({
		// 	preload: Infinity,
		// 	source: new TileImage({
		// 		url: 'http://khm{0-3}.googleapis.com/kh?v=855&hl=el&x={x}&y={y}&z={z}',
		// 		// url: 'http://khm1.googleapis.com/kh?v=855&hl=el&x=18675&y=12938&z=15'

		// 	})
		// });

		let googleLayer = new TileLayer({
			preload: 3,
			visible: true,
			opacity: 1,
			source: new XYZ({
				// attributions: [new ol.Attribution({ html: '<a href=""></a>' })],
				// url: 'http://mt0.google.com/vt/lyrs=y&hl=el&x={x}&y={y}&z={z}&s=Ga'
				url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',// https://github.com/openlayers/openlayers/issues/9900
				crossOrigin: ''
				// url: 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'// Stopped working
			})
		})

		googleLayer.set('name', 'google');
		googleLayer.setZIndex(0);
		return googleLayer;
	}

	// ====================================
	// 6. VECTOR LAYER
	// ====================================
	addVectorLayer() {

		this.drawVectorLayerSource = new VectorSource();

		this.drawVectorLayer = new VectorLayer({
			source: this.drawVectorLayerSource,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.8)'
				}),
				stroke: new Stroke({
					// color: '#00BCD4',// Blue
					// color: '#9C27B0',// Purple
					// color: '#7C4DFF',// Deep Purple	
					color: '#FFEB3B',// Yellow
					width: 3
				}),
				image: new Circle({
					radius: 7,
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.8)'
					}),
					stroke: new Stroke({
						color: '#e040fb',
						width: 3
					})
				})
			})
		});


		this.drawVectorLayer.set('name', 'drawLayer');
		this.drawVectorLayer.setZIndex(99);
		this.map.addLayer(this.drawVectorLayer);
	}

	addFeaturesToVectorLayer(features: Array<Feature<Geometry>>, style: Style = undefined) {

		if (style) {
			for (let i = 0; i < features.length; i++) {
				features[i].setStyle(style);
			}
		}
		this.drawVectorLayerSource?.addFeatures(features);
	}

	onSelectFeaturesFromTable(features: Array<Feature<Geometry>>) {

		this.zoomToFeatures(features);

		// Check if editing is active and act accordingly
		let activeOperation = this.stateService.getActiveOperation();
		if (activeOperation.operation == OPERATIONS.editor_edit || activeOperation.operation == OPERATIONS.topology_editor_edit) {

			if (this.selectInteraction) {
				let selected_collection = this.selectInteraction.getFeatures();
				if (selected_collection.item(0)?.getId() != features[0].getId()) {

					selected_collection.clear();
					selected_collection.push(features[0]);

					// this.selectInteraction.dispatchEvent({
					// 	type: 'select',
					// 	selected: [features[0]],
					// 	deselected: []
					// });

					// (this.selectInteraction as any).addFeature_({
					// 	element: features[0]
					// });
				}

			}

		} else {
			this.addFeaturesToVectorLayer(features);
		}

	}

	getVectorLayer() {
		return this.drawVectorLayer;
	}

	clearVectorLayer() {
		this.drawVectorLayerSource?.clear();
	}

	/*
	 * Used for Postgres insertion
	*/
	getCurrentTimestamp() {

		var now = new Date();
		return now.getUTCFullYear() + '-'
			+ ('0' + (now.getUTCMonth() + 1)).slice(-2) + '-'
			+ ('0' + now.getUTCDate()).slice(-2)
			+ 'T' + ('0' + now.getUTCHours()).slice(-2)
			+ ':' + ('0' + now.getUTCMinutes()).slice(-2)
			+ ':' + ('0' + now.getUTCSeconds()).slice(-2)
			+ 'Z';
	}

	// ====================================
	// 8. MEASUREMENTS
	// ====================================

	/**
	* Measure length of line layer.
	* @param {ol.geom} lineLayer The line layer.
	* @return {string} The formatted length.
	*/
	// getLineLayerLength(lineLayer) {

	// 	let networkLayerWfs = this.toggleWFSService(networkLayer, this.getMap().getLayers().getLength(), true, undefined, allStrategy, '_network');
	// 	let length = getLength(line);
	// 	let output;
	// 	output = (Math.round(length * 100) / 100);
	// 	return output;
	// };

	/**
	* Format length output.
	* @param {ol.geom.LineString} line The line.
	* @return {string} The formatted length.
	*/
	getGeometryLength(line) {

		let length = getLength(line);
		let output;
		output = (Math.round(length * 100) / 100);
		return output;
	};

	/**
	* Format area output.
	* @param {ol.geom.Polygon} polygon The polygon.
	* @return {string} Formatted area.
	*/
	getGeometryArea(polygon) {

		let area = getArea(polygon);
		let output;
		output = (Math.round(area * 100) / 100);
		return output;

	};

	// ====================================
	// 9. GEOLOCATION
	// ====================================

	enableGeolocation() {

		let isAndroid = this.stateService.getIsAndroid();
		this.addGeolocationVectorLayer();

		if (!isAndroid) {
			this.startOpenlayersGeolocation();
		} else {
			this.startAndroidGeolocation();
		}

	}

	startAndroidGeolocation() {
		this.positionFeature = new Feature();

		this.androidWatchLocationId = navigator.geolocation.watchPosition((position) => {
			let updatedLatitude = position.coords.latitude;
			let updatedLongitude = position.coords.longitude;

			if (updatedLatitude != this.lastKnownLat && updatedLongitude != this.lastKnownLon) {
				this.lastKnownLat = updatedLatitude;
				this.lastKnownLon = updatedLongitude;

				let transformedCoord = transform([updatedLongitude, updatedLatitude], 'EPSG:4326', 'EPSG:2100');
				let coordinatePoint = new Point(transformedCoord);

				this.positionFeature.setGeometry(coordinatePoint);
				this.zoomToGeometry(coordinatePoint);

				// navigator.vibrate(1000);
			}

		}, (error) => {
			console.debug('code: ' + error.code, 'message: ' + error.message);
		}, { enableHighAccuracy: true });

		this.geolocationVectorLayerSource.addFeature(this.positionFeature);
	}

	startOpenlayersGeolocation() {
		this.geolocation = new Geolocation({
			projection: this.map.getView().getProjection()
		});

		this.geolocation.on('change', () => {
			console.log(this.geolocation.getAccuracy());
			// el('altitude').innerText = geolocation.getAltitude() + ' [m]';
			// el('altitudeAccuracy').innerText = geolocation.getAltitudeAccuracy() + ' [m]';
			// el('heading').innerText = geolocation.getHeading() + ' [rad]';
			// el('speed').innerText = geolocation.getSpeed() + ' [m/s]';
		});

		this.geolocation.on('error', (error: any) => {
			console.log(error.message);
		});

		this.positionFeature = new Feature();
		// this.positionFeature.setStyle( this.getIconStyle( '../ic_my_location_24px.svg' ) );

		this.geolocation.on('change:position', () => {
			let coordinates = this.geolocation.getPosition();
			let coordinatePoint = coordinates ? new Point(coordinates) : null;
			this.positionFeature.setGeometry(coordinatePoint);
			this.zoomToGeometry(coordinatePoint);
			//Reload layer
			// this.geolocationVectorLayer.getSource().updateParams({"time": Date.now()});
		});

		this.accuracyFeature = new Feature();
		this.geolocation.on('change:accuracyGeometry', () => {
			this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
		});

		this.geolocationVectorLayerSource.addFeature(this.positionFeature);
		// this.geolocationVectorLayerSource.addFeatures( this.accuracyFeature );
		this.geolocation.setTracking(true);
	}

	disableGeolocation() {
		let isAndroid = this.stateService.getIsAndroid();

		this.removeGeolocationVectorLayer();

		if (!isAndroid) {
			this.geolocation.setTracking(false);
		} else {
			navigator.geolocation.clearWatch(this.androidWatchLocationId);
		}

	}

	addGeolocationVectorLayer() {

		this.geolocationVectorLayerSource = new VectorSource();

		this.geolocationVectorLayer = new VectorLayer({
			source: this.geolocationVectorLayerSource,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.8)'
				}),
				stroke: new Stroke({
					color: '#e040fb',
					width: 3
				}),
				image: this.getIconStyle('ic_my_location_24px.svg')
			})
		});


		this.geolocationVectorLayer.set('name', 'geolocationLayer');
		this.geolocationVectorLayer.setZIndex(this.workspaceLayers.length + 1);
		this.map.addLayer(this.geolocationVectorLayer);
	}

	removeGeolocationVectorLayer() {
		this.map.removeLayer(this.geolocationVectorLayer);
	}

	getIconStyle(iconName) {
		// let port = environment.domain == 'localhost' ? ':4200' : '';
		// let iconPath = StateService.getScheme() + '//' + environment.domain + port + '/assets/images/map/' + iconName;
		let iconPath = environment.assetsPrefix + 'assets/images/map/' + iconName;

		return new Icon({
			anchor: [0.5, 46],
			anchorXUnits: 'fraction',
			anchorYUnits: 'pixels',
			src: iconPath
		});

	}

	/**
	 * Clears selected features if any
	 */
	clearSelection() {
		if (this.selectInteraction) {
			this.selectInteraction.getFeatures().clear();
		}

		this.clearVectorLayer();
	}

	// ##########################################
	// ######## FLATTEN TOPOLOGY LAYERS #########
	// ##########################################
	flattenWorkspaceLayers(workspaceLayers: Array<WorkspaceLayer>, currentEditingGroupName: string) {
		let flattenWorkspaceLayers = [];
		workspaceLayers.map((layer: any) => {
			if (layer.Layer && layer.Abstract.indexOf(currentEditingGroupName) >= 0) {
				flattenWorkspaceLayers = [...flattenWorkspaceLayers, ...layer.Layer];
			}
		});

		return flattenWorkspaceLayers;
	}

	// ##########################################
	// ############# LAYER FUNCTIONS ############
	// ##########################################

	/**
	 * Returns the value if a keyword exists and is of the form "key:value"
	 * else returns true if the keyword exists but it is of the form "key"
	 * else returns false if the keyword does not exist in the keyword list 
	 * @param layer The layer to retrieve the keyword from
	 * @param keyword The keyword
	 */
	getKeyValue(layer, keyword): any {

		for (const currentKeyword of layer.KeywordList) {

			if (currentKeyword.includes(keyword)) {

				let isSplitted = currentKeyword.split(':');

				if (isSplitted.length === 2) { // This is a key:value keyword
					let keywordValue = isSplitted[1];
					if (keywordValue.split(',').length > 0) { // This is a array keyword 
						return keywordValue.split(',');
					} else {
						return keywordValue;
					}
				} else { // This is a plain keyword
					return true;
				}

			}

		}
		return false;
	}

	/**
	 * 
	 * @param exportType kmz
	 * @param layers Array of workspace layers to export
	 * @param exportViewport If true ignores layers parameter and exports visible layers
	 */
	getExportURL(exportType: string, layers: Array<WorkspaceLayer>, exportViewport: boolean = false) {
		let service = 'WMS';
		let request = 'GetMap';
		let srs = 'EPSG:2100';
		let format = exportType
		let version = '1.1.1';
		let width = '1024';
		let height = '768';
		let workspace = this.stateService.getWorkspace();
		let bbox = layers[0].BoundingBox[1].extent.toString();
		let layerNames = [];

		if (exportViewport) {
			layerNames = this.getVisibleLayers();
			bbox = this.map.getView().calculateExtent().toString();
		} else {
			layers.forEach((layer) => {
				layerNames.push(layer.Name);
			});
		}

		let url = `${this.gisServerUrl}${workspace}/wms`
			+ `?service=${service}`
			+ `&version=${version}`
			+ `&srs=${srs}`
			+ `&request=${request}`
			+ `&layers=${layerNames.toString()}`
			+ `&styles=`
			+ `&width=${width}`
			+ `&height=${height}`
			+ `&format=${format}`
			+ `&bbox=${bbox}`;

		if (this.geoserverService.latestGetFeatureObject && this.geoserverService.latestGetFeatureObject.filter && this.geoserverService.latestGetFeatureObject.filter != '') {
			url += `&cql_filter=${this.geoserverService.latestGetFeatureObject.filter}`;
		}

		let headers = new HttpHeaders({ 'Content-Type': 'application/vnd.google-earth.kmz' });
		this.httpClient.get(url, {
			headers: headers,
			withCredentials: true,
			responseType: 'blob'
		}).subscribe(res => {
			let dataType = res.type;
			let binaryData = [];
			binaryData.push(res);
			let url = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

			let a = document.createElement("a");
			document.body.appendChild(a);
			(a as any).style = "display: none";
			a.href = url;
			a.download = `${WorkspaceLayer.layerNameWithoutUser(layers[0].Name)}_export_${DateUtils.simpleISOTimestamp()}.kmz`;
			a.click();
			window.URL.revokeObjectURL(url);
			this.layoutService.showHeaderLoader(false);
		})
	}

	printMap(layout, dpi, scale) {

		let workspace = this.stateService.getWorkspace();
		let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

		// let osm = {
		// 	baseURL: "https://a.tile.openstreetmap.org/",
		// 	maxExtent: this.map.getView().calculateExtent(this.map.getSize()),
		// 	tileSize: [256, 256],
		// 	type: "OSM",
		// 	extension: "png",
		// 	resolutions: [156543.0339, 78271.51695, 39135.758475, 19567.8792375, 9783.93961875, 4891.969809375, 2445.9849046875, 1222.99245234375, 611.496226171875, 305.7481130859375, 152.87405654296876, 76.43702827148438, 38.21851413574219, 19.109257067871095, 9.554628533935547, 4.777314266967774, 2.388657133483887, 1.1943285667419434, 0.5971642833709717]
		// }
		let basemap;
		let basemapPrintLayer;
		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer && this.isBaseLayer(currentLayer)) {
				basemap = currentLayer;
			}
		});
		if (basemap.get('name') === 'esrisat') {

			basemapPrintLayer = {
				baseURL: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/",
				maxExtent: basemap.getSource().getProjection().getExtent(),
				tileSize: [256, 256],
				type: "XYZ",
				extension: "png",
				path_format: '/${z}/${y}/${x}',//optional
				resolutions: basemap.getSource().getResolutions().slice(0, 24)
				// tileOriginCorner: 'tl',
			}
		} else if (basemap.get('name') === 'esritopo') {

			basemapPrintLayer = {
				baseURL: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/",
				maxExtent: basemap.getSource().getProjection().getExtent(),
				tileSize: [256, 256],
				type: "XYZ",
				extension: "png",
				path_format: '/${z}/${y}/${x}',//optional
				resolutions: basemap.getSource().getResolutions().slice(0, 24),
				// tileOriginCorner: 'tl',
			}
		} else if (basemap.get('name') === 'google') {

			basemapPrintLayer = {
				baseURL: "https://maps.google.com/maps/api/staticmap",
				maxExtent: basemap.getSource().getProjection().getExtent(),
				tileSize: [256, 256],
				type: "tiledGoogle",
				maptype: "hybrid",//satellite
				extension: "png",
				format: 'image/png',
				sensor: false,
				customParams: {
					key: 'AIzaSyAhxMzidgAEq0_AD8wVrlFTxeGwclB7DCs',
					style: 'feature:poi|element:labels|visibility:off'
				},
				// path_format: '/${z}/${x}/${y}',//optional
				resolutions: basemap.getSource().getResolutions().slice(0, 24)
			}
		} else if (basemap.get('name') === 'osm') {
			basemapPrintLayer = {
				baseURL: "https://b.tile.openstreetmap.org/",
				maxExtent: basemap.getSource().getProjection().getExtent(),
				tileSize: [256, 256],
				type: "OSM",
				extension: "png",
				path_format: '/${z}/${y}/${x}',//optional
				resolutions: basemap.getSource().getResolutions().slice(0, 24),
				// scale:'1:1,000'
				// tileOriginCorner: 'tl',
			}
		} else {
			this.snackBarService.setMessage("Παρακαλώ επιλέξτε google,esrisat, esritopo ή OpenStreetMap ως υπόβαθρο.", 4000);
		}


		const visibleLayerNames = this.sortLayerNamesInReverseWorkspaceOrder(this.getVisibleLayers());
		let layersConf = [
			basemapPrintLayer,
			{
				baseURL: this.gisServerUrl + workspace + "/wms",
				opacity: 1,
				singleTile: true,
				type: "WMS",
				layers: visibleLayerNames,
				customParams : {
					CQL_FILTER : this.stateService.getCombinedLayerFilter(visibleLayerNames)
				},
				format: "image/png8",
				styles: [""],
			}
		];

		let bbox = this.map.getView().calculateExtent(this.map.getSize())
		let transformedLL = transform([bbox[0], bbox[1]], 'EPSG:2100', 'EPSG:3857');
		let transformedUR = transform([bbox[2], bbox[3]], 'EPSG:2100', 'EPSG:3857');
		let transformedCenter = transform(this.map.getView().getCenter(), 'EPSG:2100', 'EPSG:3857');

		let page0: any = {
			// bbox: [...transformedLL, ...transformedUR], // This works and calculates automatically the scale. If used both center and scale should be ommited
			// center: transformedCenter,
			// scale: scale.scale,
			rotation: 0,
			// mapTitle: "Χάρτης Κλίμακα " + scale.name,
			scaleTitle: "",
			comment: "A custom comment"
		};

		if (scale.auto) {
			page0.bbox = [...transformedLL, ...transformedUR];

			// Snap scale to available
			const currentMapScale = this.scaleLineControl.getScaleForResolution();
			const scales = this.geoserverService.printCapabilities.scales;
			let scaleDelta = Math.abs(currentMapScale - scales[0].value);
			let nearestScaleToCurrentMapScale = scales[0];
			let delta;
			for (const scale of scales) {
				delta = Math.abs(currentMapScale - scale.value);
				if (delta < scaleDelta) {
					scaleDelta = delta;
					nearestScaleToCurrentMapScale = scale;
				}
			}

			page0.mapTitle = "Χάρτης Κλίμακα " + nearestScaleToCurrentMapScale.name;
		} else {
			page0.scale = scale.value;
			page0.center = transformedCenter;
			page0.mapTitle = "Χάρτης Κλίμακα " + scale.name;
		}

		let printConf = {
			units: 'm',
			srs: 'EPSG:3857',
			layout: layout,
			// geodetic: true,
			outputFilename: 'waterpillar_print',
			dpi: dpi,
			layers: layersConf,
			pages: [page0],
			mapCopyright: "",
			legends: []
		}


		let httpParams: HttpParams = new HttpParams();
		httpParams = httpParams.set('spec', JSON.stringify(printConf));

		// Since this is a POST request the payload (httpParams) should be sent as data and not as URL query params to prevent encoding issues
		let printObservable = this.httpClient.post(this.gisServerUrl + "pdf/create.json", httpParams, {
			headers: headers,
			withCredentials: true,
			responseType: 'json'
		});

		return printObservable;
	}

	printMapSVG() {

		this.map.once('rendercomplete', () => {

			let mapCanvas = document.createElement('canvas');
			let size = this.map.getSize();
			mapCanvas.width = size[0];
			mapCanvas.height = size[1];
			let mapContext = mapCanvas.getContext('2d');
			Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), (canvas) => {
				if (canvas.width > 0) {
					let opacity = canvas.parentNode.style.opacity;
					mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
					let transform = canvas.style.transform;
					// Get the transform parameters from the style's transform matrix
					let matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
					// Apply the transform to the export map context
					CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
					mapContext.drawImage(canvas, 0, 0);
				}
			});

			let link: any = document.getElementById('image-download');
			link.href = mapCanvas.toDataURL();
			link.click();

		});
		this.map.renderSync();
	}

	getIcon(legendIconUri, bgColor: string = "0x273A4D", fontColor: string = "0x333333", dpi: number = 91) {
		//let legendIconUri = layer.Style[0].LegendURL[0].OnlineResource;

		legendIconUri = legendIconUri.replace(':80', '');
		legendIconUri += `&LEGEND_OPTIONS=bgColor:${bgColor};dpi:${dpi};fontColor:${fontColor};fontAntiAliasing:true`;

		let headers = new HttpHeaders({ 'Content-Type': 'image/png' });
		return this.httpClient.get(legendIconUri, {
			headers: headers,
			withCredentials: true,
			responseType: 'blob'
		})
	}

	createBuffer(coordinate, bufferMeters) {
		let coordinatePoint = new Point(coordinate);
		let jstsGeom = this.jstsParser.read(coordinatePoint);
		let buffered = BufferOP.bufferOp(jstsGeom, bufferMeters);

		let olBuffer = this.jstsParser.write(buffered);
		return olBuffer;
	}

	// Required by print function, since it adds layers in reverse order on printed media
	sortLayerNamesInReverseWorkspaceOrder(layerNamesList: string[]): string[] {
		const sortedByLayerOrder = [];
		this.stateService.getWorkspaceLayers().map(g => g.Layer).flat().map(l => l.Name).forEach(
			layer => {
				if (layerNamesList.includes(layer)) {
					sortedByLayerOrder.unshift(layer);
				}
			}
		);
		return sortedByLayerOrder;
	}
}
