import { Component, HostBinding, Input } from '@angular/core';
import { FieldConfigDeya } from 'app/mr-form/models/field-config.interface';

@Component({
  selector: 'form-multi-value-indicator',
  templateUrl: './form-multi-value-indicator.component.html'
})
export class FormMultiValueIndicatorComponent {
  @HostBinding('style.display') get display() {
    return this.show ? 'block' : 'none';
  }
  @Input()
  config: FieldConfigDeya;
  @Input()
  show = false;
}
