// Angular imports
import { Component, OnInit, AfterViewInit, Inject, Input, OnDestroy, ElementRef } from '@angular/core';

// App imports 
import { MapService } from '../map.service';
import { StateService } from '../../core/state.service';
import { OPERATIONS } from '../../models';

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
	private map: any;
	public mapStyle: Object;
	public mapCursor: string;
	private observer: ResizeObserver;
	@Input() isInDashBoard: boolean = false;

	constructor(private mapService: MapService,
		private stateService: StateService,
		private el: ElementRef) { }

	ngOnInit() {
		StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.mapCursor = 'default';

		setTimeout(() => {
			this.observe();
		}, 2000);

	}

	updateFromState() {
		//Operations actions
		let activeOperation = this.stateService.getActiveOperation();
		if (activeOperation.operation == OPERATIONS.editor_draw
			|| activeOperation.operation == OPERATIONS.editor_edit
			|| activeOperation.operation == OPERATIONS.topology_editor_draw) {
			this.mapCursor = 'crosshair';
		} else {
			this.mapCursor = 'default';
		}

	}

	observe() {
		this.observer = new ResizeObserver((mutations) => {
			this.mapService.resizeMap();
		});

		this.observer.observe(this.el.nativeElement);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.map = this.mapService.bootstrapMap();
			this.mapService.resizeMap();
		}, 0);
	}

	ngOnDestroy() {
		if (this.observer) {
			this.observer.disconnect();
		}	
	}

}
