import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { EpanetLineChartMargin, epanetElementType } from "../../models";
import { StateService } from "../../core/state.service";
import { cloneDeep } from "lodash-es";
import * as d3 from "d3";
import { LinkResults, NodeResults } from "epanet-js";

@Component({
    selector: "app-line-chart",
    templateUrl: "./line-chart.component.html",
    styleUrls: ["./line-chart.component.css"],
})
export class LineChartComponent implements OnInit, OnDestroy {
    private stateUnsubscription;
    private selectionTimeStamp: number;
    public linkChartAttributesOptions: Array<string>;
    public nodeChartAttributesOptions: Array<string>;
    public epanetElementType: epanetElementType;
    public selectedAttribute: string;
    public foundLink: LinkResults;
    public foundNode: NodeResults;
    private totalDuration: number;
    private reportingTimeStep: string;
    private reportStartTime: string;
    private measurementUnits: string;
    private massUnits: string;
    private yDomain: Array<number>;

    @Input() chartContainerID: string;

    constructor(private stateService: StateService) { }

    ngOnInit() {
        this.nodeChartAttributesOptions = [
            "demand",
            "head",
            "pressure",
            "waterQuality",
        ];
        this.linkChartAttributesOptions = [
            "avgWaterQuality",
            "flow",
            "friction",
            "headloss",
            "reactionRate",
            "setting",
            "status",
            "velocity",
        ];
        
		this.stateUnsubscription = StateService.stateStore.subscribe(() => {
            this.updateFromState();
        });

        this.updateFromState();
        
    }

    ngOnDestroy() {
        this.stateUnsubscription();
    }

    updateFromState() {
        let formStateData = cloneDeep(this.stateService.getFormData());
        let epanetResults = this.stateService.getEpanetResults();
        let epanetOptions = this.stateService.getEpanetOptions();

        //if form has no data clear last selected features and reset timestamps
        if (formStateData?.features.length === 0) {
            this.selectionTimeStamp = 0;
        }

        if (
            !!formStateData &&
            !!formStateData.selectedLayer &&
            formStateData.selectedLayer.Model.isEpanetLayer &&
            this.selectionTimeStamp != formStateData.timestamp
        ) {
            this.totalDuration = epanetOptions?.get("times")["Total Duration"];
            this.reportingTimeStep = epanetOptions?.get("times")["Reporting Time Step"];
            this.reportStartTime = epanetOptions?.get("times")["Report Start Time"];
            this.measurementUnits = epanetOptions?.get("hydraulics")["Flow Units"];
            this.massUnits = epanetOptions?.get("quality")["Mass Units"];

            this.selectionTimeStamp = formStateData.timestamp;
            let lastFeature =
                formStateData.features[formStateData.features.length - 1];

            this.foundLink = epanetResults?.results.links.find(
                (element) => element.id == lastFeature.id_
            );
            this.foundNode = epanetResults?.results.nodes.find(
                (element) => element.id == lastFeature.id_
            );

            if (this.foundLink) {
                this.renderChart(this.foundLink);
            } else if (this.foundNode) {
                this.renderChart(this.foundNode);
            }
        }
    }
    
    isNodeResults(results: NodeResults | LinkResults): results is NodeResults {
        return (results as NodeResults).demand !== undefined;
    }

    getUnitsOfMeasurement(option: string): string {
        if (
            this.measurementUnits === "LPS" ||
            this.measurementUnits === "LPM" ||
            this.measurementUnits === "MLD" ||
            this.measurementUnits === "CMH" ||
            this.measurementUnits === "CMD"
        ) {
            switch (option) {
                case "demand":
                    return this.measurementUnits;
                case "head":
                    return "m";
                case "pressure":
                    return "m";
                case "waterQuality":
                    return this.massUnits;
                case "avgWaterQuality":
                    return this.massUnits;
                case "flow":
                    return this.measurementUnits;
                case "friction":
                    return "";
                case "headloss":
                    return "m/km";
                case "reactionRate":
                    return "mg/L/d";
                case "setting":
                    return "";
                case "status":
                    return "";
                case "velocity":
                    return "m/s";
                default:
                    return "";
            }
        } else {
            switch (option) {
                case "demand":
                    return this.measurementUnits;
                case "head":
                    return "ft";
                case "pressure":
                    return "psi";
                case "waterQuality":
                    return this.massUnits;
                case "avgWaterQuality":
                    return this.massUnits;
                case "flow":
                    return this.measurementUnits;
                case "friction":
                    return "";
                case "headloss":
                    return "ft/Kft";
                case "reactionRate":
                    return "mg/L/d";
                case "setting":
                    return "";
                case "status":
                    return "";
                case "velocity":
                    return "ft/s";
                default:
                    return "";
            }
        }
    }

    renderChart(results: NodeResults | LinkResults) {
        d3.select("#line-chart").selectAll("*").remove();

        let chartData = [];

        if (this.isNodeResults(results)) {
            this.selectedAttribute = this.nodeChartAttributesOptions.includes(
                this.selectedAttribute
            )
                ? this.selectedAttribute
                : this.nodeChartAttributesOptions[2];
            chartData = results[this.selectedAttribute];
        } else {
            this.selectedAttribute = this.linkChartAttributesOptions.includes(
                this.selectedAttribute
            )
                ? this.selectedAttribute
                : this.linkChartAttributesOptions[1];
            chartData = results[this.selectedAttribute];
        }

        chartData = chartData.map((value, index) => {
            return { time: index, value: value };
        });

        const [hourTimeStep, minuteTimeStep] = this.reportingTimeStep
            .split(":")
            .map(Number);
        const reportingTimeStep = hourTimeStep * 60 + minuteTimeStep;

        const [hourStartTime, minuteStartTime] = this.reportStartTime
            .split(":")
            .map(Number);
        const reportStartTime = hourStartTime * 60 + minuteStartTime;

        let timeAccumulator = 0;

        // handling case where report start time is not 0
        if (reportStartTime !== 0) {
            chartData[0].time = hourStartTime + minuteStartTime / 100;
            timeAccumulator += reportStartTime;
        }

        // rendering chart according to reporting time step
        for (let i = 1; i < chartData.length; i++) {
            timeAccumulator += reportingTimeStep; // Increment time accumulator by reportingStep

            let hours = Math.floor(timeAccumulator / 60); // Convert accumulated minutes back to hours
            let minutes = timeAccumulator % 60; // Get remaining minutes

            chartData[i] = { time: hours + minutes / 100, value: chartData[i].value };
        }
        // console.log(chartData);

        // ==================================================
        // ====================== BODY ======================
        // ==================================================

        // Declare the chart dimensions and margins.
        const width = 280;
        const height = 200;
        const margin: EpanetLineChartMargin = {
            top: 20,
            right: 15,
            bottom: 20,
            left: 40,
        };

        const svg = d3
            .select("#line-chart")
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        // ==================================================
        // ====================== Y AXIS ====================
        // ==================================================

        const hasNegativeValues = chartData.some((data) => data.value < 0); //checks if there are negative values in chartData.value

        let minValue: number, maxValue: number;

        if (hasNegativeValues) {
            minValue = Math.floor(d3.min(chartData, (d) => d.value) / 100) * 100;
            maxValue = Math.ceil(d3.max(chartData, (d) => d.value) / 100) * 100;
            if (maxValue < 0) {
                maxValue = 0;
            }
        } else {
            minValue = 0;
            maxValue = Math.ceil(d3.max(chartData, (d) => d.value) / 100) * 100;
        }

        this.yDomain = [minValue, maxValue];

        const y = d3
            .scaleLinear()
            .domain(this.yDomain)
            .range([height - margin.bottom, margin.top]);

        // Add the y-axis, remove the domain line, add grid lines and a label.
        svg
            .append("g")
            .attr("transform", `translate(${margin.left + 10},0)`)
            .call(d3.axisLeft(y).ticks(height / 25))
            .style("font-family", "Open Sans")
            .call((g) => g.select(".domain").remove())
            .call((g) =>
                g
                    .selectAll(".tick line")
                    .clone()
                    .attr("x2", width - margin.left - margin.right - 20)
                    .attr("stroke-opacity", 0.1)
            )
            .call((g) =>
                g
                    .append("text")
                    .attr("x", -95)
                    .attr("y", -40)
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "start")
                    .attr("transform", "rotate(-90)")
                    .style("font-size", "12px")
                    .text(this.getUnitsOfMeasurement(this.selectedAttribute))
            );

        // ==================================================
        // ====================== X AXIS ====================
        // ==================================================

        const x = d3
            .scaleLinear()
            .domain([0, this.totalDuration])
            .range([margin.left, width - margin.right - 20]);

        let ticks: number;
        if (this.totalDuration <= 10) {
            ticks = this.totalDuration;
        } else if (this.totalDuration <= 24) {
            ticks = this.totalDuration / 2;
        } else if (this.totalDuration <= 48) {
            ticks = this.totalDuration / 4;
        } else if (this.totalDuration <= 72) {
            ticks = this.totalDuration / 6;
        } else {
            ticks = width / 40;
        }

        // Add the x-axis.
        svg
            .append("g")
            .attr("transform", `translate(10,${y(0)})`)
            .call(d3.axisBottom(x).ticks(ticks).tickSizeOuter(0))
            .style("font-family", "Open Sans")
            .call((g) =>
                g
                    .append("text")
                    .attr("x", width - 10) // Adjust x position to align with the far right
                    .attr("y", 3) // Adjust y position as needed
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "end")
                    .text("Hrs")
            );

        // ==================================================
        // ====================== LINE ======================
        // ==================================================

        // Declare the line generator.
        const line = d3
            .line<{ time: number; value: number }>()
            .x((d) => x(d.time) + 10)
            .y((d) => y(d.value));

        // Append a path for the line.
        svg
            .append("path")
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 1.5)
            .attr("d", line(chartData));
    }
    
}
