import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home/home.component';
import { NotauthorizedComponent } from './core/notauthorized/notauthorized.component';
import { AdminComponent } from './admin/admin/admin.component';
import { ActiveScreenManagementService } from './core/active-screen-management.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { PlatformGuard } from './core/platform.guard';
import { KeycloakService } from 'keycloak-angular';
import { AuthGuard } from './core/auth.guard';
import { environment } from '../environments/environment';

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'disabled', component: HomeComponent, canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard] },
  { path: 'topology', loadChildren: () => import('app/topology/topology.module').then(m => m.TopologyModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'drainage', loadChildren: () => import('app/drainage/drainage.module').then(m => m.DrainageModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'epanet', loadChildren: () => import('app/epanet/epanet.module').then(m => m.EpanetModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'documents', loadChildren: () => import('app/docs/docs.module').then(m => m.DocsModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'capta', loadChildren: () => import('app/capta/capta.module').then(m => m.CaptaModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'xilog', loadChildren: () => import('app/xilog/xilog.module').then(m => m.XilogModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'scada', loadChildren: () => import('app/scada/scada.module').then(m => m.ScadaModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'android', loadChildren: () => import('app/android/android.module').then(m => m.AndroidModule), canActivate: [AuthGuard, ActiveScreenManagementService, PlatformGuard], data: { roles: ['waterpillar_user'] } },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard, PlatformGuard], data: { roles: ['waterpillar_admin'] } },
  { path: '404', component: NotFoundComponent },
  { path: 'notauthorized', component: NotauthorizedComponent },
  { path: '**', component: HomeComponent }
];

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloackEndpoint,
        realm: 'smartville',
        clientId: 'waterpillar',
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
      bearerExcludedUrls: ['hereapi.com']
    });
}

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService],
  }],
  declarations: []
})
export class AppRoutingModule { }
