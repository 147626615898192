import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapModule } from '../map/map.module';
import { MaterialModule } from '../material/material.module';
import { TopologyService } from '../map/topology-editor/topology.service';
import { HomeComponent } from './home/home.component'
import { SharedModule } from 'app/shared/shared.module';
@NgModule({
  imports: [
    CommonModule,
    MapModule,
    MaterialModule,
    SharedModule
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent],
  providers:[TopologyService]
})
export class HomeModule { }
