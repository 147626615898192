<ng-container *ngIf="selectedLayer">
  <div class="flex flex-col gap-2">
    <app-editor-controls #editorControls [layerName]="selectedLayer.Name" [hideControls]="true" [canSplit]="canSplit"
      [selectedLayer]="selectedLayer" (userAction)="onUserAction($event)">
    </app-editor-controls>

    <app-zone-editor #zoneEditor (userAction)="onZoneAction($event)" [isDrawing]="isDrawing">
    </app-zone-editor>

    <app-coord-editor *ngIf="activeOperation === 2 || activeOperation === 3" [operation]="activeOperation"
      (editCoordinate)="onEditCoordinate($event)" (addCoordinate)="onAddCoordinate($event)"></app-coord-editor>
  </div>
</ng-container>