import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StateService } from './state.service';
import { GeoserverService } from '../core/geoserver.service';

// Material imports
import { SnackBarService } from './snack-bar.service';
import { Announcement, WorkspaceLayer } from 'app/models';
import { Geometry, Point, MultiPoint, Polygon } from 'ol/geom';
import { Feature } from 'ol';

@Injectable()
export class ApiService {

    private apiEndpoint;
    public storageUrl;

    constructor(private stateService: StateService,
        private httpClient: HttpClient,
        private snackBarService: SnackBarService,
        private geoserverService: GeoserverService) {
        this.apiEndpoint = StateService.getScheme() + '//' + environment.apiDomain + environment.apiPath;
        this.storageUrl = StateService.getScheme() + '//' + environment.apiDomain + environment.storageEndpoint;
    }

    /**
     * Stores a file in server that is associated with a spatial record
     * @param file 
     * @param filename 
     */
    uploadFile(file, filename) {

        let formData: FormData = new FormData();
        formData.append('uploadFile', file, filename);
        formData.append('workspace', this.stateService.getWorkspace());

        let postObservable = this.httpClient.post(this.apiEndpoint + environment.uploadEndpoint, formData);
        return postObservable;
    }

    /**
     * Fetch the user document tree
     * i.e. http://api.water-pillar.com/storage/makrohori/docs/test1/7ylVGakDGT84tc7h9EhbEbuGPpvdMQ7i8iVnExPJ.pdf
     */
    fetchUserDocTree() {
        let userData = this.stateService.getUserData();
        let formData: FormData = new FormData();
        formData.append('username', userData.username);

        this.httpClient.post(this.apiEndpoint + environment.docsEndpoint, formData).subscribe(responce => {
            StateService.stateStore.dispatch(this.stateService.setUserDocsOperation(responce));
        });
    }

    /**
     * Fetch a a file from workspace storage root
     * i.e. http://api.water-pillar.com/storage/makrohori/docs/filename.txt
     */
    fetchUserDocFromRoot(filename: string) {
        let formData: FormData = new FormData();
        formData.append('workspace', this.stateService.getWorkspace());
        formData.append('filename', filename);

        return this.httpClient.post(this.apiEndpoint + environment.docsGetFileFromRoot,
            formData,
            { responseType: 'text' });
    }

    /**
     * Uploads a user document. For use in docs screen
     * @param dirPath 
     * @param alias 
     * @param document 
     */
    uploadUserDocument(dirPath: string, alias: string, document: any) {
        let userData = this.stateService.getUserData();
        let workspace = this.stateService.getWorkspace();

        let formData: FormData = new FormData();
        formData.append('username', userData.username);
        formData.append('workspace', workspace);
        formData.append('dir_path', dirPath);
        formData.append('alias', alias);
        formData.append('upload_doc', document);

        this.httpClient.post(this.apiEndpoint + environment.docUploadEndpoint, formData).subscribe(responce => {
            this.fetchUserDocTree();
        });

    }

    /**
     * Deletes a user document. For use in docs screen
     * @param dirPath 
     * @param filename 
     */
    deleteUserDocument(dirPath: string, filename: string) {
        let userData = this.stateService.getUserData();
        let workspace = this.stateService.getWorkspace();

        let formData: FormData = new FormData();
        formData.append('username', userData.username);
        formData.append('workspace', workspace);
        formData.append('dir_path', dirPath);
        formData.append('filename', filename);

        this.httpClient.post(this.apiEndpoint + environment.docDeleteEndpoint, formData).subscribe(responce => {
            this.fetchUserDocTree();
        });

    }

    prepareEpanetFile(polygon: Polygon, epanetOptions: Map<string, any>) {
        let formData: FormData = new FormData();
        let bbox = [];

        polygon.getCoordinates()[0].forEach(coordinate => {
            bbox.push(coordinate.join(' '));
        })
        formData.append('network_container', bbox.join(','));
        formData.append('hydraulic_options', JSON.stringify(epanetOptions.get("hydraulics")));
        formData.append('quality_options', JSON.stringify(epanetOptions.get("quality")));
        formData.append('reactions_options', JSON.stringify(epanetOptions.get("reactions")));
        formData.append('times_options', JSON.stringify(epanetOptions.get("times")));
        formData.append('energy_options', JSON.stringify(epanetOptions.get("energy")));
        return this.httpClient.post(this.apiEndpoint + environment.epanetPrepareEndpoint + this.stateService.getWorkspace(), formData);
    }

    findClusters() {
        let formData: FormData = new FormData();
        return this.httpClient.post(this.apiEndpoint + environment.epanetFindClusters + this.stateService.getWorkspace(), formData);
    }

    getElevationByPoint(feature: Feature) {
        let formData: FormData = new FormData();
        formData.append('point_coordinates', (feature.getGeometry() as MultiPoint).getCoordinates()[0].join(' '));
        return this.httpClient.post(this.apiEndpoint + environment.epanetGetElevation, formData);
    }

    calculateElevationForLayer(layer_name) {
        let workspace = this.stateService.getWorkspace();
        let formData: FormData = new FormData();
        formData.append('layer_name', layer_name);
        return this.httpClient.post(this.apiEndpoint + environment.epanetCalculateElevationForLayer + workspace, formData);
    }

    // runEpanet() {
    // 	return this.httpClient.get(this.apiEndpoint + environment.epanetRunEndpoint + StateService.getLocalStorageItem('username'));
    // }

    /**
     * Query sensors measurements. For use in scada query
     * @param dirPath 
     * @param filename 
     */
    getSensorsData(layerName: string, sensorsIds: Array<number>, dateFrom: string, dateTo: string, grouping: string, endpoint: string = environment.sensorsEndpoint) {
        let workspace = this.stateService.getWorkspace();
        let formData: FormData = new FormData();
        formData.append('layerName', layerName.split(':')[1]);
        formData.append('sensorsIds', sensorsIds.join(','));
        formData.append('dateFrom', dateFrom);
        formData.append('dateTo', dateTo);
        formData.append('grouping', grouping);

        return this.httpClient.post(this.apiEndpoint + endpoint + workspace, formData);
    }

    /**
     * Uploads a csv file in server to be imported to the selected layer
     * @param layerName The layer name to import to
     * @param file The csv
     */
    uploadCsv(layerName: string, file: File) {

        let formData: FormData = new FormData();
        let workspace = this.stateService.getWorkspace();
        formData.append('upload_csv', file);
        formData.append('layer_name', layerName);
        formData.append('workspace', workspace);

        let postObservable = this.httpClient.post(this.apiEndpoint + environment.uploadCSVEndpoint, formData);

        return postObservable;
    }

    /**
     * Create supplies. For use in topology create supplies component
     * @param hydrometerIds 
     */
    createSupplies(hydrometerIds: Array<string>) {
        let workspace = this.stateService.getWorkspace();
        let formData: FormData = new FormData();
        formData.append('hydrometer_ids', hydrometerIds.join(','));

        return this.httpClient.post(this.apiEndpoint + environment.createSuppliesEndpoint + workspace, formData);
    }

    /**
     * Performs a topology check. For use in topology screen
     */
    performTopologyCheck(checkEndpoint: string) {
        let workspace = this.stateService.getWorkspace();
        let formData: FormData = new FormData();
        // formData.append('hydrometer_ids', hydrometerIds.join(','));

        return this.httpClient.post(this.apiEndpoint + checkEndpoint + workspace, formData);
    }

    /**
     * Create supplies. For use in topology create supplies component
     * @param hydrometerIds 
     */
    getLineLayerLength(workspaceLayer: WorkspaceLayer, filter: string = "") {
        let workspace = this.stateService.getWorkspace();
        let formData: FormData = new FormData();
        formData.append('filter', filter);

        return this.httpClient.post(this.apiEndpoint + environment.lineLayerLengthWithFilterEndpoint + workspace + "/" + workspaceLayer.Name.split(':')[1], formData);
    }

    /**
     * Stores a file in server that is associated with a spatial record
     * @param file 
     * @param filename 
     */
    createAnnouncement(form: Announcement) {

        let workspace = this.stateService.getWorkspace();
        let smartvilleEndpoint = null;
        if (environment.smartvilleApiEndpoint[workspace]) {
            smartvilleEndpoint = environment.smartvilleApiEndpoint[workspace];
        }
        // this.apiEndpoint + environment.pillarProxyEndpoint

        if (smartvilleEndpoint) {

            let formData: FormData = new FormData();
            formData.append('url', smartvilleEndpoint);
            formData.append('announcement', JSON.stringify(form));

            let postObservable = this.httpClient.post(this.apiEndpoint + environment.pillarProxyEndpoint, formData).subscribe((responce: any) => {
                this.snackBarService.setMessage($localize`Η δημιουργία ανακοίνωσης ήταν επιτυχής`, 4000);
            });
        } else {
            this.snackBarService.setMessage($localize`Η υπηρεσία ανακοινώσεων δεν είναι ενεργή στο λογαριασμό σας. Παρακαλούμε επικοινωνήστε με τον διαχειριστή.`, 4000);
        }

    }

}
