<!-- <div class="map-status-text mat-elevation-z2 theme-bg">
	{{statusMessage}}
</div> -->

<div class="rounded px-2 text-base hover:bg-white/30 text-black dark:hover:bg-black/30 dark:text-white" [ngSwitch]="activeOperation.operation">
  <ng-container *ngSwitchCase="operations.editor_active" i18n="Active Mode: Edit on | Currently active mode edit on">
    Επεξεργασία ανοιχτή
  </ng-container>
  <ng-container *ngSwitchCase="operations.topology_editor_active"
    i18n="Active Mode: Topology edit on | Currently active mode Topology edit on">
    Τοπολογική επεξεργασία ανοιχτή
  </ng-container>
  <ng-container *ngSwitchCase="operations.editor_edit" i18n="Active Mode: Edit | Currently active mode Edit">
    Επεξεργασία
  </ng-container>
  <ng-container *ngSwitchCase="operations.editor_draw" i18n="Active Mode: Draw | Currently active mode draw">
    Σχεδίαση
  </ng-container>
  <ng-container *ngSwitchCase="operations.info"
    i18n="Active Mode: Identification | Currently active mode identification">
    Αναγνώριση
  </ng-container>
  <ng-container *ngSwitchCase="operations.measure" i18n="Active Mode: Measurement | Currently active mode measurement">
    Μέτρηση
  </ng-container>
  <ng-container *ngSwitchCase="operations.topology_editor_draw"
    i18n="Active Mode: Topology Draw | Currently active mode topology draw">
    Τοπολογική σχεδίαση
  </ng-container>
  <ng-container *ngSwitchCase="operations.topology_editor_edit"
    i18n="Active Mode: Topology Edit | Currently active mode topology edit">
    Τοπολογική επεξεργασία
  </ng-container>
  <ng-container *ngSwitchCase="operations.table_update"
    i18n="Active Mode: Array Edit | Currently active mode array edit">
    Επεξεργασία στοιχείων πίνακα
  </ng-container>
  <ng-container *ngSwitchCase="operations.network_detection"
    i18n="Active Mode: Network Fault Detection | Currently active mode network fault detection">
    Εντοπισμός βλάβης δικτύου ύδρευσης
  </ng-container>
  <ng-container *ngSwitchCase="operations.drainage_detection"
    i18n="Active Mode: Drainage Network Detection | Currently active mode drainage network detection">
    Εντοπισμός δικτύου αποχέτευσης
  </ng-container>
  <ng-container *ngSwitchCase="operations.drainage_updown_detection"
    i18n="Active Mode: Upstream/Downstream Detection | Currently active mode upstream/downstream detection">
    Εντοπισμός ανάντη/κατάντη
  </ng-container>
  <ng-container *ngSwitchCase="operations.topology_audit"
    i18n="Active Mode: Topology Audit | Currently active mode topology audit">
    Τοπολογικός έλεγχος
  </ng-container>
  <ng-container *ngSwitchCase="operations.epanet_mode" i18n="Active Mode: Epanet | Currently active mode Epanet">
    Epanet
  </ng-container>
  <ng-container *ngSwitchCase="operations.create_supplies">
    Αυτόματη δημιουργία παροχών
  </ng-container>
  <ng-container *ngSwitchDefault i18n="Active Mode: Unknown | Currently active mode unknown">
    Άγνωστο
  </ng-container>
</div>
