import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Event, Router, NavigationEnd, RouterEvent } from '@angular/router';

import { StateService } from './core/state.service';
import { AuthenticationService } from './core/authentication.service';
import { ActiveScreenManagementService } from './core/active-screen-management.service';
import { ActiveScreenRoute } from './models';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { HelpDialogComponent } from './shared/help-dialog/help-dialog.component';
import {  MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TopologyUiService } from './core/topology-ui.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from '../environments/environment';
import { LayoutService } from './core/layout.service';
import { FormDataService } from './shared/services/form-data.service';
import { TimeseriesToggleService } from 'app/shared/services/timeseries-toggle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	private title: string = 'Water pillar loading';
	private stateUnsubscription: any;
	public loading: boolean;
	public blocking: boolean;
	public isAdmin: boolean;
	public username: string;
	public userDisplayName: string;
	public userData: any;
	public initialized: boolean;
	activeScreenRoutes: ActiveScreenRoute[];
	private routingSubscription: Subscription;
	public currentRouteName: string;
	public assetsPrefix:string;

	private helpDialog: MatDialogRef<HelpDialogComponent>;

	constructor(private stateService: StateService,
		private authenticationService: AuthenticationService,
		private asmService: ActiveScreenManagementService,
		private router: Router,
		private topologyUiService: TopologyUiService,
		private overlayContainer: OverlayContainer,
		public dialogService: MatDialog,
		private layoutService: LayoutService,
		private formDataService: FormDataService,
		private timeseriesTs: TimeseriesToggleService ) {

			this.bindEvents();
			this.assetsPrefix = environment.assetsPrefix;
	}

	// This binding allows dynamic theme switching
	@HostBinding('class') componentCssClass: string;

	onSetTheme(theme) {
		if (!theme) {
			theme = this.componentCssClass === 'light-theme' ? 'dark-theme' : 'light-theme'
		}

		// remove old theme class and add new theme class
		// we're removing any css class that contains '-theme' string but your theme classes can follow any pattern
		const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
		const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
		if (themeClassesToRemove.length) {
		overlayContainerClasses.remove(...themeClassesToRemove);
		}
		overlayContainerClasses.add(theme);

		this.componentCssClass = theme;

		StateService.setLocalStorageItem('selectedTheme', theme);
		document.dispatchEvent(new Event('themeChange', {bubbles: true}));

    // Add dark class to body for tailwind dark mode support
    if (theme === "dark-theme") {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
	}

	ngOnInit() {
		this.onSetTheme(StateService.getLocalStorageItem('selectedTheme') || 'dark-theme');
		this.loading = true;
		this.blocking = true;
		this.initialized = false;
		const isAndroid = this.stateService.getState().isAndroid;
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.routingSubscription = this.asmService.activeScreenRoutes$.pipe(
			map(screens => screens.filter(s => s.route !== '/android' && !isAndroid))
		).subscribe(routes => this.activeScreenRoutes = routes);

		// Update state with current active module id on every navigation
		this.router.events.pipe(
			filter((e: Event | RouterEvent) => e instanceof NavigationEnd),
			startWith(new RouterEvent(-1, this.router.url))
		).subscribe((routerEvent: RouterEvent) => {
			const activeScreenId = this.asmService.getScreenIdFromRoute(routerEvent.url);
			StateService.stateStore.dispatch(this.stateService.setActiveModuleOperation(activeScreenId));
			StateService.stateStore.dispatch(this.stateService.setFeatureInfoOperation([]));
			StateService.stateStore.dispatch( this.stateService.setFeatureTableData( [],false ) );
			this.timeseriesTs.hideTimeseriesWidget();

			// this.formDataService.setData(null);
			StateService.stateStore.dispatch(this.stateService.setFormDataOperation(null));
			// this.formDataService.updateTable(null);
			this.layoutService.toggleTable(false);
			this.layoutService.toggleMultiTable(false);
			this.layoutService.formToggle(false);
		});

		this.asmService.currentRouteName$.subscribe(
			name => this.currentRouteName = name
		);

		StateService.stateStore.dispatch(this.stateService.loadUserStateOperation());
	}

	ngAfterViewInit() {
		// this.onSetTheme(StateService.getLocalStorageItem('selectedTheme'));
	}

	updateFromState() {
		this.loading = this.stateService.getLoading().isLoading;
		this.blocking = this.stateService.getLoading().blocking;
		this.isAdmin = this.stateService.getIsAdmin();

		if (!this.initialized) {
			this.userData = this.stateService.getUserData();
			if (this.userData.username) {
				this.username = this.userData.username;//this.stateService.getLocalStorageItem('username');
				this.userDisplayName = !this.userData.name ? "Ανώνυμε" : this.userData.name + " " + this.userData.surname;
				this.initialized = true;
			}
		}
	}

	ngOnDestroy() {
		this.stateUnsubscription();
		this.routingSubscription.unsubscribe();
	}

	onHelpButton() {
		this.helpDialog = this.dialogService.open(HelpDialogComponent, {
			minWidth: '80vw',
			minHeight: '90vh',
			panelClass: 'help-dialog',
			autoFocus: true
		});
	}

	private bindEvents() {
        // Here we register our callbacks for the lifecycle events we care about
        document.addEventListener('deviceready', ()=>{
			StateService.stateStore.dispatch(this.stateService.setIsAndroidOperation(true));
		}, false);
		// StateService.stateStore.dispatch(this.stateService.setIsAndroidOperation(true));
        // document.addEventListener('pause', this.onPause, false);
        // document.addEventListener('resume', this.onResume, false);
    }
}
