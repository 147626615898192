import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavButtons } from 'app/models';

@Component({
  selector: 'app-nav-buttons',
  templateUrl: './nav-buttons.component.html',
  styleUrls: ['./nav-buttons.component.scss']
})
export class NavButtonsComponent {

  @Input() navBtnList: Array<NavButtons> = [];
  @Input() navSelected: NavButtons;
  @Output() navChange: EventEmitter<NavButtons> = new EventEmitter();

  navButtons = NavButtons;

  iconMap = {
    [NavButtons.layers]: 'layers',
    [NavButtons.filters]: 'filter_list',
    [NavButtons.scada_filters]: 'scatter_plot',
    [NavButtons.tp_error_detection]: 'bug_report',
    [NavButtons.tp_audit]: 'slideshow',
    [NavButtons.create_supplies]: 'add_road',
    [NavButtons.epanet]: 'device_hub',
    [NavButtons.drainage_detection]: 'transform',
    [NavButtons.drainage_updownstream]: 'call_split',
    [NavButtons.shortest_path]: 'linear_scale',
    [NavButtons.document_tree]: 'folder_open',
    [NavButtons.measurement]: 'square_foot',
    [NavButtons.print]: 'print',
    [NavButtons.transform_coordinates]: 'compare_arrows'
  }

  onNavChange(nav: NavButtons) {
    this.navChange.emit(nav);
  }

}
