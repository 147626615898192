import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'date-input',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DateInputComponent,
    multi: true
  }],
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit, ControlValueAccessor {

  @Input() name: string;
  @Input() alias: string;
  @Input() multiValueSelection: string;
  @Output() blur: EventEmitter<void> = new EventEmitter();
  @Output() focus: EventEmitter<void> = new EventEmitter();
  private value: any;
  private onChange: Function;
  public disabled: boolean = false;
  @ViewChild('matInputRef', { static: true }) matInputRef;
  @ViewChild('picker', { static: true }) picker : MatDatepicker<Date>;

  constructor() { }

  ngOnInit() {
  }

  writeValue(value) {
    if(!!value) {
      const date = new Date(value);
      this.picker.startAt = date;
      if (!this.value) {
        setTimeout(() => this.picker.select(date));
      }
      this.value = date.toLocaleDateString(); 
      this.matInputRef.nativeElement.value = this.value;
    }
    else {
      this.value = null;
      this.matInputRef.nativeElement.value = '';
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

  setDisabledState(isDisabled: boolean){
		this.disabled = isDisabled;
  }
  
  onDateChanged(event: MatDatepickerInputEvent<any>) {
    // TODO : Decide what server saves both the following formats do not save correctly

    // const newDate = new Date(Date.parse(event.value));
    // this.onChange(newDate.toISOString());
    // this.writeValue(newDate.toISOString());

    // Previous Stavros code
    const pickerDate: Date = event.value;
    const newDate = pickerDate.getFullYear()+'-'
      + ('0' +(pickerDate.getMonth()+1)).slice(-2) +'-'
      +('0' + pickerDate.getDate()).slice(-2)
      +'Z';  
    this.onChange(newDate);
    this.writeValue(newDate);
  }
}
