import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TimeseriesToggleService } from '../services/timeseries-toggle.service';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from 'app/core/layout.service';
import { NavButtons } from 'app/models';
import { StateService } from 'app/core/state.service';

@Component({
	selector: 'layout-component',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

	private destroy$: Subject<void> = new Subject();
	public graphHeight = 0;
	public sideBarOpened = false;
	public navSelected: NavButtons;
	public tableHeight: number = 400;
	public featureTableVisible = false;
	public topologyResultsVisible = false;
	public multiTableVisible = false;
	public formVisible = false;
	private visibleTableLayerName?: string;

	@Input() navBtnList: Array<NavButtons> = [NavButtons.layers, NavButtons.filters];

	@Input() isInDocuments: boolean;

	@Input() dashboardMode: boolean = false;

	@Output() navChange: EventEmitter<NavButtons> = new EventEmitter();
	constructor(private stateService: StateService, private timeseriesService: TimeseriesToggleService, public layoutService: LayoutService) { }


	ngOnInit() {
		this.timeseriesService.toggleTimeseriesWidget$.pipe(
			takeUntil(this.destroy$)
		).subscribe(show => this.toggleTimeSeriesWidget(show));

		this.layoutService.tableVisible$.pipe(
			takeUntil(this.destroy$)
		).subscribe(show => this.toggleTable(show));

		this.layoutService.multiTableVisible$.pipe(
			takeUntil(this.destroy$)
		).subscribe(show => this.toggleMultiTable(show));

		this.layoutService.formVisible$.pipe(
			takeUntil(this.destroy$)
		).subscribe(show => this.formVisible = show);

		this.layoutService.topologyResultsVisible$.pipe(
			takeUntil(this.destroy$)
		).subscribe(show => this.toggleTopologyResults(show));

		this.layoutService.tableHeight$.pipe(
			takeUntil(this.destroy$)
		).subscribe(tableHeight => this.tableHeight = tableHeight);

		this.layoutService.sideContent$.pipe(
			takeUntil(this.destroy$)
		).subscribe(sideContent => this.onNavChange(sideContent));

		combineLatest([
			this.layoutService.dataTableLayerTitle$,
			this.layoutService.tableVisible$
		]).pipe(
			takeUntil(this.destroy$)
		).subscribe(
			([tableLayerTitle, tableVisible]) => {
				this.visibleTableLayerName = tableVisible ? tableLayerTitle : undefined;
			}
		);
	}

	onNavChange(nav: NavButtons, eventFromNavMenu = false) {
		this.sideBarOpened = this.navSelected === nav ? !this.sideBarOpened : true;
		this.navChange.emit(nav);
		this.navSelected = this.sideBarOpened ? nav : undefined;
		if (eventFromNavMenu && this.visibleTableLayerName) {
			StateService.stateStore.dispatch(this.stateService.setLayerFilterNameOperation(this.visibleTableLayerName));
		}
		this.layoutService.setSidebarStatus(this.sideBarOpened);
	}

	onPositionChanged() {
	}

	toggleTimeSeriesWidget(show: boolean) {
		this.graphHeight = !!show ? this.timeseriesService.TIMESERIES_HEIGHT : 0;
	}

	toggleTable(show: boolean) {
		this.multiTableVisible = this.multiTableVisible && show ? false : this.multiTableVisible;
		this.featureTableVisible = show;
	}

	toggleMultiTable(show: boolean) {
		this.featureTableVisible = this.featureTableVisible && show ? false : this.featureTableVisible;
		setTimeout(() => this.multiTableVisible = show, 10);
	}

	toggleTopologyResults(show: boolean) {
		if (show) {
			this.featureTableVisible = false;
			this.multiTableVisible = false;
			// this.layoutService.toggleMultiTable(false);
			// this.layoutService.toggleTable(false);
		}
		this.topologyResultsVisible = show;
	}

}
