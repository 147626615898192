// Angular imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// App imports
import { HomeModule } from './home/home.module';
import { AdminModule } from './admin/admin.module';
import { CoreModule } from './core/core.module';
import { MapModule } from './map/map.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppComponent } from './app.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { AvatarModule } from 'ngx-avatars';
import { BootstrapComponent } from './bootstrap/bootstrap.component';


@NgModule({
  declarations: [
    NotFoundComponent,
    AppComponent,
    HeaderMenuComponent,
    BootstrapComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HomeModule,
    AdminModule,
    CoreModule.forRoot(),
    MapModule.forRoot(),
    MaterialModule,
    SharedModule,
    AppRoutingModule,
    AvatarModule,
  ],
  exports: [
  ],
  providers: [],
  bootstrap: [BootstrapComponent]
})
export class AppModule { }
