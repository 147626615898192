import { SafeResourceUrl } from '@angular/platform-browser/';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { FormControl } from '@angular/forms'
import { Time } from '@angular/common';
import { EpanetResults } from 'epanet-js';
// import Type from 'ol/geom/Geometry';

export enum GeometryType {
    Point = "Point",
    LineString = "LineString",
    LinearRing = "LinearRing",
    Polygon = "Polygon",
    MultiPoint = "MultiPoint",
    MultiLineString = "MultiLineString",
    MultiPolygon = "MultiPolygon",
    GeometryCollection = "GeometryCollection",
    Circle = "Circle",
}

export class QueryClass {
    layer: any;
    outputProperties: Array<string>;
    filter: string;
    count: number;
    startIndex: number;
    sortPropertyName: string;
    sortOrder: boolean;
}

export enum ActiveScreenIDs {
    admin = 1,
    home = 2,
    epanet = 3,
    topology = 4,
    capta = 5,
    drainage = 6,
    docs = 7,
    android = 8,
    xilog = 9,
    scada = 10,
    disabled = -1
}

export enum ScadaDashboardType {
    none = 0,
    tank = 1,
    drilling = 2,
    pumping_station = 3,
    pump = 4,
    flow_meter = 5
}

// =========================
// ======= ALARMS ==========
// =========================
export interface Alarms {
    differenceAlarms?: Array<DifferenceAlarm>;
    outlierAlarms?: Array<OutlierAlarms>;
    notConnectedAlarms?: Array<NotConnectedAlarm>;
}

export interface DifferenceAlarm {
    sensorName: string;
    date: string;
    ch1: number;
    ch2: number;
    difference: number;
}

export interface OutlierAlarms {
    sensorName: string;
    variableName: string;
    date: string;
    value: number;
}

// =========================
// ====== END ALARMS =======
// =========================

export interface scadaEntity {
    id: string;
    variables: Map<string, number>;
    status?: scadaEntityStatus;
    connected?: Map<string, scadaEntity>;
    alias?: String;
    type?: ScadaDashboardType;
}


export interface scadaEntityStatus {
    running?: number; //16
    collectiveFault?: number; //15
    illegal?: number; //?
    locking?: number; //?

    local?: number; //6
    remote?: number; //5
    auto?: number;//?
}

export interface NotConnectedAlarm {
    sensorName: string,
    date: string
}

export interface AlarmsAndProcessedDataset {
    alarms: Map<string, Alarms>;
    dataset: Array<any>;
    variables?: Array<any>;
}
// ===========================================
// ======= NEW SCADA MODEL  ==================
// ===========================================

export enum scadaVariableType {
    electric = 1,
    quantitative = 2,
    quality = 3
}

export interface scadaVariable {
    id: string;
    type: scadaVariableType;
}

export interface station {
    id: string;
    alias: string;
    alias_en: string;
    variables?: Array<scadaVariable>;
    groups?: Map<string, {
        alias: String;
        variables: Array<scadaVariable>
    }>;
}

// ==========================================
// ======= END OF NEW SCADA MODEL  ==========
// ==========================================


// ===========================================
// ============= USER INFO  ==================
// ===========================================
export interface userInfo {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    attributes: userAttributes;
}

export interface userAttributes {
    waterpillar_info: waterpillarInfo;
}

export interface waterpillarInfo {
    logo_url: string;
    workspace_name: string;
    max_zoom_level: string;
    available_modules: Array<string>;
}

// =========================
// ======= FORMS  ==========
// =========================

export interface HydraulicOptions {
    "Flow Units": FormControl<string>;
    "Headloss Formula": FormControl<string>;
    "Specific Gravity": FormControl<number>;
    "Relative Viscosity": FormControl<number>;
    "Maximum Trials": FormControl<number>;
    "Accuracy": FormControl<number>;
    "If Unbalanced": FormControl<string>;
    "Default Pattern": FormControl<string>;
    "Demand Multiplier": FormControl<number>;
    "Emitter Exponent": FormControl<number>;
    "Status Report": FormControl<string>;
    "Max Head Error": FormControl<number>;
    "Max Flow Change": FormControl<number>;
    "Demand Model": FormControl<string>;
    "Minimum Pressure": FormControl<number>;
    "Required Pressure": FormControl<number>;
    "Pressure Exponent": FormControl<number>;
    "Check Frequency": FormControl<number>;
    "Max Check": FormControl<number>;
    "Damping Limit": FormControl<number>;
}

export interface QualityOptions {
    "Parameter": FormControl<string>;
    "Mass Units": FormControl<string>;
    "Relative Diffusivity": FormControl<number>;
    "Trace Node": FormControl<string>;
    "Quality Tolerance": FormControl<number>;
}

export interface ReactionsOptions {
    "Bulk Reaction Order": FormControl<number>;
    "Wall Reaction Order": FormControl<string>;
    "Global Bulk Coefficient": FormControl<number>;
    "Global Wall Coefficient": FormControl<number>;
    "Limiting Concentration": FormControl<number>;
    "Wall Coefficient Correlation": FormControl<number>;
}

export interface TimesOptions {
    "Total Duration": FormControl<string>;
    "Hydraulic Time Step": FormControl<string>;
    "Quality Time Step": FormControl<string>;
    "Pattern Time Step": FormControl<string>;
    "Pattern Start Time": FormControl<string>;
    "Reporting Time Step": FormControl<string>;
    "Report Start Time": FormControl<string>;
    "Clock Start Time": FormControl<string>;
    "Statistic": FormControl<string>;
}

export interface EnergyOptions {
    "Pump Efficiency": FormControl<number>;
    "Energy Price": FormControl<number>;
    "Price Pattern": FormControl<string>;
    "Demand Charge": FormControl<number>;
}

export interface FormDropdownOptions {
    "Flow Units": Array<string>,
    "Headloss Formula": Array<string>,
    "If Unbalanced": Array<string>,
    "Status Report": Array<string>,
    "Demand Model": Array<string>,
    "Parameter": Array<string>,
    "Mass Units": Array<string>,
    "Wall Reaction Order": Array<string>,
    "Statistic": Array<string>
}

export interface AnnouncementForm {
    "description": FormControl<string>,
    "title": FormControl<string>,
    "address": FormControl<string>,
    "start_date": FormControl<Date>,
    "end_date": FormControl<Date>,
    "from_hour": FormControl<Time>,
    "to_hour": FormControl<Time>,
    "push_notification": FormControl<boolean>,
    "email_notification": FormControl<boolean>
}

export interface QualitySensorsForm {
    "start_date": FormControl<Date>,
    "end_date": FormControl<Date>,
    "from_hour": FormControl<string>,
    "to_hour": FormControl<string>,
    "relative_date_range": FormControl<string>
    "relative_groupings": FormControl<string>
}

export interface RelativeGroupings {
    "name": string,
    "alias": string
}


export const formsDisabledFields = ['last_user', 'shape_length', 'shape_area', 'last_date', 'id'];
export const formsOmitedFields = ['the_geom'];

export interface FormTransaction {
    timestamp: string;
    formOperations: Array<FormOperation | FormOperationsGroup>;
}

export interface FormOperationsGroup {
    timestamp: string;
    description: string;
    operations: Array<FormOperation>;
}
export interface FormOperation {
    layer: WorkspaceLayer;
    layerModel: LayerModel;
    feature: any;
    formHeader: string;
    timestamp: string;
    dirty: boolean;
    action: EDIT_ACTIONS;
    silent?: boolean; // Interact with user or not
}

export interface ActiveScreenRoute {
    name: string;
    route: string;
    id: ActiveScreenIDs;
    navButtons: NavButtons[];
}

export class WorkspaceGroup {
    Title?: string;
    Abstract?: string;
    KeywordList?: Array<string>;
    CRS?: Array<string>;
    EX_GeographicBoundingBox?: Array<number>;
    BoundingBox?: Array<any>;
    Layer?: Array<WorkspaceLayer>;
    queryable?: boolean;
    opaque?: boolean;
    noSubsets?: boolean;
}

export class WorkspaceLayer {
    Title?: string; // Readable layer title
    Abstract?: string;
    KeywordList?: Array<string>;
    CRS?: Array<string>;
    EX_GeographicBoundingBox?: Array<number>;
    BoundingBox?: Array<any>;
    Layer?: Array<WorkspaceLayer>;
    queryable?: boolean;
    opaque?: boolean;
    noSubsets?: boolean;
    Style?: Array<Object>;
    Name?: string; // Geoserver DB table name
    Model?: LayerModel;

    /**
     * This function returns the layer name(geoserver) without user/location information\
     * For example for input `makrohori:switches` it will return `switches`
    */
    static layerNameWithoutUser(layerWithUserName: string): string {
        return (layerWithUserName.split(':')[1] ?? layerWithUserName);
    }
}

export class FeatureTableDataModel {
    timestamp: number;
    features: Array<Feature>;
    featureTableVisible?: boolean;
    selectedLayer: WorkspaceLayer;
    totalFeatures: number;
    selectedFeatures?: {
        timestamp: number;
        selectedLayer: WorkspaceLayer;
        features: Array<Feature>;
    };
    startIndex?: number;
    eventFrom?: selectEventSource;
    filter?: string;
}

// export interface ModalDialogData {
//     name: string,
//     model: LayerModel,
//     layer: WorkspaceLayer,
//     rowData: Array<any>,
//     feature: any,
//     multiCell?: string,
//     action?: EDIT_ACTIONS,
//     currentIndex?: number,
//     totalOperations?: number
// }

export interface FormDataModel {
    timestamp: number;
    features: Array<Feature<Geometry>>;// We need an array here because we need to have all selected features in case of multi editing
    title: string;
    selectedLayer: WorkspaceLayer;
    multiCell?: string;
    action?: EDIT_ACTIONS;
    currentIndex?: number;
    totalOperations?: number;
    eventFrom?: selectEventSource;
    rowData?: Array<any>;
}

export enum selectEventSource {
    map = 1,
    table = 2
}

export class LayerModel {
    fields: Array<LayerField>;
    field_order: Array<string>;
    geometryType: GeometryType;
    edit_actions: Array<EDIT_ACTIONS>;
    is_edit: boolean;
    is_topology_edit: boolean;
    on_edit_snap_to?: Array<string>;
    on_edit_split?: Array<string>;
    timeseries_graph?: Object;
    opacity?: number;
    show_on_init?: boolean;
    is_open?: boolean;
    render_type?: string
    identifiable?: boolean; // if true -> Select from map and highlight
    open_form_on_identify?: boolean; // Default:true, if false -> SOpen form  (Used only in single selection) 
    snap_always?: boolean;
    can_upload_csv?: boolean;
    hasAttributeTable?: boolean;
    sensorLayerType?: string | Array<string>;
    is_raster?: boolean;
    checkboxesVisible: boolean; // if true -> a.show checkboxes and b.default multiselect 
    renderChlorium?: boolean;
    canMoveTo: Array<string>; // Move features between layers
    renderAsWfs: boolean;
    wfsIcon: scadaItemIcons;
    openDashboardItemOnSelect: boolean;
    onVectorClickSelectLocalFeature: boolean;
    scada_config: any;
    is_epanet_layer: boolean;
    audits:Array<AuditModel>;
    ecql_filter: string | null;
    ecql_applied: boolean;
}
export class AuditModel {
    type: string;// {'orphanCheck' |'...' |'...' }
    alias: string;
    relatedActions:Array<ActionModel>
}

export class ActionModel {
    type: string;// {'snap' |'...' |'...' }
    alias: string;
}

export class scadaItemIcons {
    tankIcon: string;
    drillingIcon: string;
    pumpStationIcon: string;
    pumpIcon: string;
    flowMeterIcon: string;
}

export class UserDocModel {
    id: number;
    filename: string;
    path: string;
    alias: string;
}

export class LayerField {
    localType: string;  // {'string' |'date' |'number' |'int' |'boolean' |'date-time' |'file' |'domain' }
    maxOccurs: number;  // ?
    minOccurs: number;  // ?
    name: string;       // Field name/ID (unique identifier)
    nillable: boolean;  // DB value can be NULL
    type: string;       // ?
    visible: boolean;   // Should be displayed?
    editable: boolean;  // Edit is enabled/disabled?
    alias: string;       // Field alias
    domain?: DomainModel[]; // Field domain
    validationString?: string;
    validationMessage?: string;
}

export interface DomainModel {
    name: string;
    alias: string;
}

export interface DomainValue {
    name: string;
    alias: string;
}

export enum EDIT_ACTIONS {
    create = "create",
    update = "update",
    delete = "delete"
}

export enum NETWORK_DETECT_ACTIONS {
    enable = "enable",
    detect = "detect",
    export = "export",
    off = "off"
}

export enum CREATE_SUPPLIES_ACTIONS {
    enable = "enable",
    create = "create",
    off = "off"
}

export enum DRAINAGE__STREAM_DETECT_ACTIONS {
    placefinal = "placefinal",
    placemiddle = "placemiddle",
    detect = "detect",
    export = "export",
    off = "off"
}

export enum CRUD_OPERATION {
    create = "create",
    update = "update",
    delete = "delete"
}

export enum ZONE_EDIT_ACTIONS {
    zone_draw = "zone_draw",
    zone_clear_all = "zone_clear_all",
    zone_clear_intersections = "zone_clear_intersections"
}

export enum EDITOR_MODES {
    draw,
    edit,
    delete,
    stop
}

export enum OPERATIONS {
    info,
    editor_active,
    editor_draw,
    editor_edit,
    editor_delete,
    topology_editor_active,
    topology_editor_draw,
    topology_editor_edit,
    topology_editor_delete,
    measure,
    table_create,
    table_update,
    table_delete,
    table_multi_update,
    draw_buffer,
    network_detection,
    drainage_detection,
    drainage_updown_detection,
    topology_audit,
    create_supplies,
    epanet_mode
}

export interface SortEvent {
    sortBy: string;
    sortOrder: boolean;
}

export enum KEY_CODE {
    CTRL = 17,
    SHIFT = 16,
    ENTER = 13,
    SPACE = 32,
}

export interface TopologyResult {
    featuresTimestamp?: number,
    features?: any[],
    selectedLayer?: WorkspaceLayer,
    layerModel?: LayerModel,
    totalFeatures?: number,
    startIndex?: number,
}

export interface ViewerFile {
    type: "Image" | "Document";
    src: string | SafeResourceUrl;
}

export interface Doc {
    id: number;
    path: string;
    filename: string;
    alias: string;
}

export interface DocNode {
    name: string;
    alias?: string;
    filename?: string;
    children?: DocNode[];
}

export interface TransformedDoc {
    fullPath: string;
    pathDisplayName: string;
    id: number;
    path: string;
    paths?: string[];
    filename: string;
    alias: string;
}

export interface UserState {
    screenStates: Array<ScreenState>;
    firstRun: boolean;
}

export interface ScreenState {
    screenId: number;
    mapExtent?: [number, number, number, number];
    openLayers?: Array<WorkspaceLayer>;
    layerOrder?: Array<WorkspaceLayer>;// Groups
    basemapName?: string;
    layerFilters?: Array<{layerName: string, filter: string, applied: boolean}>;
}

export interface PieSlice {
    value: number;
    text: string;
}

export interface SensorMeasurement {
    id?: number;
    value?: number;
    isOutlier?: number;
    chlorineDiff?: number;
    timestamp: string;
    parsedDate: Date;
    sensorName?: string;
    variableName?: string;
    alias?: string;
    unit?: string;
}

export interface SensorMeasurementStatistics {
    date?: Date;
    min?: number;
    max?: number;
    average?: number;
}

export interface LayerControlState {
    creating: boolean;
    editing: boolean;
    topologyEditing: boolean;
    visible: boolean;
    opacityChanging: boolean;
    dataTableShowing?: boolean;
    layerFilter?: string;
    enableFilter?: boolean;
}

export enum LayerControl {
    CREATE,
    ENABLE,
    EDIT,
    TOPOLOGY_EDIT,
    OPACITY,
    FOCUS,
    TIMESERIES,
    EXPORT,
    TABLE,
    FILTER
}

export interface LayerControlEvent {
    event: LayerControl;
    value?: any;
}

export type FilterEventType = 'EDIT' | 'DELETE' | 'ENABLE' | 'DISABLE';

export enum NavButtons {
    'layers',
    'filters',
    'scada_filters',
    'tp_error_detection',
    'tp_audit',
    'create_supplies',
    'epanet',
    'drainage_detection',
    'drainage_updownstream',
    'shortest_path',
    'document_tree',
    'measurement',
    'transform_coordinates',
    'settings',
    'print'
}

export interface coordinate {
    lat: number;
    lon: number;
}

export interface DateRange {
    fromDate: Date;
    toDate: Date;
    toHour?: string;
}

export interface LineMeasurements {
    sensorData: Map<any, any>;
    rawData: Array<SensorMeasurement>;
    sensorNames: Array<string>;
    variables?: Map<string, any>;
}

export interface TableActionObject {
    type?: string;
    destinationLayer?: WorkspaceLayer;
    features?: any[];
}

export interface FeatureInfo {
    tables?: Array<FeatureTableDataModel>;
    featureInfoVisible?: boolean;
    timestamp: number;
}

export interface Announcement {
    description: {
        el: string,
        en: string,
        de: string
    };
    title: {
        el: string,
        en: string,
        de: string
    };
    address: {
        el: string,
        en: string,
        de: string
    };
    email_notification: number;
    push_notification: number;
    start_date: string;
    end_date: string;
    from_hour: string;
    to_hour: string;
    locations: Array<{ latitude: string, longitude: string }>;
}

// ===========================================
// ================ EPANET  ==================
// ===========================================

export interface EpanetColors {
    black: number[],
    green: number[],
    yellow: number[],
    blue: number[],
    cyan: number[],
    red: number[]
}

export interface PaintOptionsLegend {
    option: string,
    thresholdBlue: string,
    thresholdCyan: string,
    thresholdGreen: string,
    thresholdYellow: string,
    thresholdRed: string
}

export enum epanetElementType {
    link = 0,
    node = 1,
}

export interface epanetResultsAndOptions {
    epanetResults: EpanetResults,
    epanetOptions:Map<string, any>
}

  export interface EpanetLineChartMargin {
    top: number,
    right: number,
    bottom: number,
    left: number
  }

export const drop = '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M12,2c-5.33,4.55-8,8.48-8,11.8c0,4.98,3.8,8.2,8,8.2s8-3.22,8-8.2C20,10.48,17.33,6.55,12,2z M7.83,14 c0.37,0,0.67,0.26,0.74,0.62c0.41,2.22,2.28,2.98,3.64,2.87c0.43-0.02,0.79,0.32,0.79,0.75c0,0.4-0.32,0.73-0.72,0.75 c-2.13,0.13-4.62-1.09-5.19-4.12C7.01,14.42,7.37,14,7.83,14z"/></svg>';


export type ExportFileExtension = '.kmz' | '.csv' | '.zip';

export interface ExportFileFormat {
    label: string;
    value: ExportFileExtension;
};

export class DateUtils {
  static simpleISOTimestamp(): string {
    return new Date().toLocaleString( 'sv' ).replace(' ', '_').replace(/\:/g, '-');
  }
}
