import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOCALE_ID, Inject } from '@angular/core';

// App imports 
import { StateService } from '../core/state.service';
import { MapService } from '../map/map.service';
import { GeoserverService } from '../core/geoserver.service';
import { environment } from '../../environments/environment';

// Openlayers imports
import { WFS, GML } from 'ol/format';
import { getLength, getArea } from 'ol/sphere.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import MultiPoint from 'ol/geom/MultiPoint';
import Projection from 'ol/proj/Projection';
import { Observable } from 'rxjs';
import { delay, shareReplay } from 'rxjs/operators';

// Material imports
import { SnackBarService } from './snack-bar.service';
import { WorkspaceLayer, CRUD_OPERATION, FormOperation, EDIT_ACTIONS, OPERATIONS } from 'app/models';

@Injectable()
export class ModelOperationsService {

    private gisServerUrl: string;

    constructor(@Inject(LOCALE_ID) private localeId: string, private mapService: MapService,
        private geoserverService: GeoserverService,
        private stateService: StateService,
        private httpClient: HttpClient,
        private snackBarService: SnackBarService) {
        this.gisServerUrl = StateService.getScheme() + '//' + environment.geoserverDomain + '/' + 'geoserver/';
    }

    // ====================================
    // 1. OPERATIONS
    // ====================================

    /**
     * 
     */
    async batchPersist(formOperations: Array<FormOperation>) {

        for (let index = 0; index < formOperations.length; index++) {
            const operation = formOperations[index];

            if (operation.action === EDIT_ACTIONS.create) {
                await this.persist(operation.layer, null, [operation.feature], null, null, true, false).toPromise();
            } else if (operation.action === EDIT_ACTIONS.update) {
                this.persist(operation.layer, [operation.feature], null, null, null, true, false).toPromise();
            } else if (operation.action === EDIT_ACTIONS.delete) {
                if (Array.isArray(operation.feature)) {
                    this.persist(operation.layer, null, null, operation.feature, null, true, false).toPromise();
                } else {
                    this.persist(operation.layer, null, null, [operation.feature], null, true, false).toPromise();
                }
            }

        }

        this.mapService.reloadVisibleLayers(true);
    }

    /**
     * 
     * @param layer The workspace layer
     * @param updateFeatures Update features
     * @param addFeatures Added features
     * @param deleteFeatures Deleted features
     */
    persist(layer: WorkspaceLayer, updateFeatures: any = null,
        addFeatures: any = null, deleteFeatures: any = null,
        userMessage: string = null, runAfterPersistActions: boolean = true,
        reloadMap: boolean = true) {

        let xs = new XMLSerializer();

        // Create nodes
        let nodes = this.setFeatureAdminProperties(layer, updateFeatures, addFeatures, deleteFeatures);
        let payload = xs.serializeToString(nodes);
        // let correctedPayload = payload.replace('feature:', layer.Name.split(':')[0] + ':');//WHY????????
        // let correctedPayload = payload.replace('feature:', '');//WHY????????
        let correctedPayload = payload.replace(new RegExp('feature:', 'g'), '');

        // if(addFeatures){
        // 	let layerName = layer.Name.split(':')[1];
        // 	correctedPayload = payload.replace( new RegExp(layerName, 'g'), layer.Name);
        // 	correctedPayload = correctedPayload.replace('xmlns', 'xmlns:'+layer.Name.split(':')[0]);
        // }

        let request = this.post(correctedPayload);

        request.subscribe(
            responce => { },
            error => { console.log(error); },
            () => {
                if (userMessage === '') {

                } else if (userMessage) {
                    this.snackBarService.setMessage(userMessage, 4000);
                } else if (addFeatures) {
                    this.snackBarService.setMessage(this.localeId === 'en' ? 'Items created successfully' : 'Τα στοιχεία δημιουργήθηκαν επιτυχώς', 4000);
                } else if (deleteFeatures) {
                    this.snackBarService.setMessage(this.localeId === 'en' ? 'Items deleted successfully' : 'Τα στοιχεία διεγράφησαν επιτυχώς', 4000);
                } else if (updateFeatures) {
                    this.snackBarService.setMessage(this.localeId === 'en' ? 'Items updated successfully' : 'Τα στοιχεία ανανεώθηκαν επιτυχώς', 4000);
                }

                if (runAfterPersistActions) {
                    this.afterPersistActions(layer, updateFeatures, addFeatures, deleteFeatures, userMessage);
                }

                if (reloadMap) {
                    this.mapService.reloadVisibleLayers(true);
                }

            }
        )

        // To be deleted : Remove deprecated toPromise()
        // request.toPromise().then((result) => {
        // 	// console.log(result);
        // 	if (userMessage === '') {

        // 	} else if (userMessage) {
        // 		this.snackBarService.setMessage(userMessage, 4000);
        // 	} else if (addFeatures) {
        // 		this.snackBarService.setMessage('Τα στοιχεία δημιουργήθηκαν επιτυχώς', 4000);
        // 	} else if (deleteFeatures) {
        // 		this.snackBarService.setMessage('Τα στοιχεία διεγράφησαν επιτυχώς', 4000);
        // 	} else if (updateFeatures) {
        // 		this.snackBarService.setMessage('Τα στοιχεία ανανεώθηκαν επιτυχώς', 4000);
        // 	}

        // 	if (runAfterPersistActions) {
        // 		this.afterPersistActions(layer, updateFeatures, addFeatures, deleteFeatures, userMessage);
        // 	}

        // 	this.mapService.reloadVisibleLayers(true);
        // });

        return request;
    }

    /**
     * Adds a specific property to each feature if the current operational mode is `epanet_mode`. 
     * This property is used to indicate that the feature belongs to the Epanet mode.
     * @param parsedData
     */
    enrichFeaturePropertiesBySelectedMode(parsedData: any) {
        let activeOperation = this.stateService.getActiveOperation();
        if (activeOperation.operation === OPERATIONS.epanet_mode) {
            parsedData.forEach((feature: any) => {
                (feature as any).isEpanetFeature = true;
            });
        }
    }


    /**
     * There can only be only one dataset per call(i.e. only updateFeatures)
     * @param layer 
     * @param updateFeatures 
     * @param addFeatures 
     * @param deleteFeatures 
     * @param userMessage 
     * @param updateSliceInState 
     */
    private afterPersistActions(layer: WorkspaceLayer, updateFeatures: any = null, addFeatures: any = null, deleteFeatures: any = null, userMessage: string = null) {

        if (addFeatures) {
            // Refetch page
            // this.geoserverService.getFeature(layer, undefined, undefined, undefined, undefined, false, false, undefined, undefined, undefined, true);
        } else if (updateFeatures) {

            let ecqlQuery = '';
            for (let index = 0; index < updateFeatures.length; index++) {
                const feature = updateFeatures[index];
                index === 0 ? ecqlQuery += 'id=' + feature.get('id') : ecqlQuery += ' OR id=' + feature.get('id');
            }

            // 1. Fetch updated features
            this.geoserverService.getFeature(layer, undefined, ecqlQuery).subscribe(
                (featuresResponce: any) => {
                    // 2. Replace records in state where found. Either in feature table or multitable(featureInfo)
                    let parsedData = this.geoserverService.parseFeatures(featuresResponce);
                    this.enrichFeaturePropertiesBySelectedMode(parsedData);
                    StateService.stateStore.dispatch(this.stateService.updateData(parsedData, layer, CRUD_OPERATION.update));
                    // console.log(featuresResponce);
                })

        } else if (deleteFeatures) {
            // Remove form data so the form closes
            StateService.stateStore.dispatch(this.stateService.setFormDataOperation(null));

            // Decide what to refetch base on state
            const featureTableData = this.stateService.getFeatureTableData();
            const featureInfoData = this.stateService.getFeatureInfoNew();

            if (featureTableData.features.length > deleteFeatures.length) {
                this.geoserverService.getFeature(layer, undefined, undefined, undefined, undefined, false, false, undefined, undefined, undefined, true);
            } else if (featureTableData.features.length === deleteFeatures.length) {
                StateService.stateStore.dispatch(this.stateService.setFeatureTableData([], false));
                StateService.stateStore.dispatch(this.stateService.setFeatureTableSelectedFeatures(layer, []));
            } else if (featureInfoData.tables.length > 0) {
                StateService.stateStore.dispatch(this.stateService.setFeatureInfoOperation([]));
                // Set timeout because the second dispatch call does not see the state results of the first yet   
                setTimeout(() => { StateService.stateStore.dispatch(this.stateService.setFeatureInfoSelectedFeatures(layer, [])); }, 100);
            }

        }
    }

    setFeatureAdminProperties(layer, updateFeatures: Array<any> = null, addFeatures: Array<any> = null, deleteFeatures: Array<any> = null) {
        let nodes;
        let formatWFS = new WFS();
        let formatGML = new GML({
            featureNS: layer.Name.split(':')[0],//this.getScheme() + '//' + environment.geoserverDomain + '/' + layer.Name.split(':')[0]
            featureType: layer.Name,//layer.Name.split(':')[1]
            srsName: 'EPSG:2100'
            // featurePrefix:layer.Name.split(':')[0]
        });


        if (addFeatures) {

            let olFeatures = [];
            for (let i = 0; i < addFeatures.length; i++) {
                let currentFeature = addFeatures[i];

                // Clone properties
                let featureProperties;
                if (currentFeature instanceof Feature) {
                    featureProperties = { ...currentFeature.getProperties() };
                    if (this.modelHasProperty(layer.Model, 'shape_length')) { featureProperties.shape_length = this.getGeometryLength(currentFeature.getGeometry()); }
                    if (this.modelHasProperty(layer.Model, 'shape_area')) { featureProperties.shape_area = this.getGeometryArea(currentFeature.getGeometry()); }

                    // Update x,y properties if present
                    if (layer.Model.geometryType === "Point" && this.modelHasProperty(layer.Model, 'x') && this.modelHasProperty(layer.Model, 'y')) {
                        featureProperties.x = (currentFeature.getGeometry() as Point).getFirstCoordinate()[0][0];
                        featureProperties.y = (currentFeature.getGeometry() as Point).getFirstCoordinate()[0][1];
                    } else if (layer.Model.geometryType === "MultiPoint" && this.modelHasProperty(layer.Model, 'x') && this.modelHasProperty(layer.Model, 'y')) {
                        featureProperties.x = (currentFeature.getGeometry() as MultiPoint).getFirstCoordinate()[0];
                        featureProperties.y = (currentFeature.getGeometry() as MultiPoint).getFirstCoordinate()[1];
                    }
                } else {
                    // featureProperties = { ...currentFeature.properties };
                    console.debug('non feature should not arrive here');
                }

                // Set properties
                if (this.modelHasProperty(layer.Model, 'last_user')) { featureProperties.last_user = this.stateService.getUser(); }
                if (this.modelHasProperty(layer.Model, 'last_date')) { featureProperties.last_date = this.getCurrentTimestamp(); }

                // Create feature
                let featureToInsert = new Feature(featureProperties);
                featureToInsert.set('the_geom', featureProperties.geometry);//Extra caution!
                featureToInsert.setGeometryName('the_geom');
                // featureToInsert.unset('geometry');
                // featureToInsert.setGeometry(featureProperties.geometry);
                // featureToInsert.setId(currentFeature.ol_uid);
                olFeatures.push(featureToInsert);
            }

            // nodes = formatWFS.writeTransaction(olFeatures, null, null, formatGML);
            nodes = formatWFS.writeTransaction(olFeatures, null, null, {
                featureNS: layer.Name.split(':')[0],
                featureType: layer.Name,
                srsName: 'EPSG:2100',
                featurePrefix: '',
                nativeElements: [],
                gmlOptions: {
                    featureNS: layer.Name.split(':')[0],
                    featureType: layer.Name,
                    srsName: 'EPSG:2100'
                }
            });
        } else if (updateFeatures) {

            let olFeatures = [];
            for (let i = 0; i < updateFeatures.length; i++) {
                let currentFeature = updateFeatures[i];

                // Clone properties
                let featureProperties;
                if (currentFeature instanceof Feature) {
                    featureProperties = { ...currentFeature.getProperties() };
                    if (this.modelHasProperty(layer.Model, 'shape_length')) { featureProperties.shape_length = this.getGeometryLength(currentFeature.getGeometry()); }
                    if (featureProperties.hasOwnProperty('shape_area')) { featureProperties.shape_area = this.getGeometryArea(currentFeature.getGeometry()); }

                    // Update x,y properties if present
                    if (layer.Model.geometryType === "Point" && this.modelHasProperty(layer.Model, 'x') && this.modelHasProperty(layer.Model, 'y')) {
                        featureProperties.x = (currentFeature.getGeometry() as Point).getFirstCoordinate()[0];
                        featureProperties.y = (currentFeature.getGeometry() as Point).getFirstCoordinate()[1];
                    } else if (layer.Model.geometryType === "MultiPoint" && this.modelHasProperty(layer.Model, 'x') && this.modelHasProperty(layer.Model, 'y')) {
                        featureProperties.x = (currentFeature.getGeometry() as MultiPoint).getFirstCoordinate()[0];
                        featureProperties.y = (currentFeature.getGeometry() as MultiPoint).getFirstCoordinate()[1];
                    }
                } else {
                    featureProperties = { ...currentFeature.properties };
                }

                // Set properties
                if (this.modelHasProperty(layer.Model, 'last_user')) { featureProperties.last_user = this.stateService.getUser(); }
                if (this.modelHasProperty(layer.Model, 'last_date')) { featureProperties.last_date = this.getCurrentTimestamp(); }

                // Create feature				
                // featureToUpdate.setGeometry( this.createGeometry( currentFeature.geometry )  );
                // if (featureToUpdate.get('shape_length')) { featureProperties.set('shape_length',this.getGeometryLength(currentFeature.getGeometry())); }

                let featureToUpdate = new Feature(featureProperties);
                featureToUpdate.setGeometryName('the_geom');
                featureToUpdate.setId(currentFeature instanceof Feature ? currentFeature.getId() : currentFeature.id);
                olFeatures.push(featureToUpdate);
            }

            // nodes = formatWFS.writeTransaction(null, olFeatures, null, formatGML);
            nodes = formatWFS.writeTransaction(null, olFeatures, null, {
                featureNS: layer.Name.split(':')[0],
                featureType: layer.Name,
                srsName: 'EPSG:2100',
                featurePrefix: '',
                nativeElements: [],
                gmlOptions: {
                    featureNS: layer.Name.split(':')[0],
                    featureType: layer.Name,
                    srsName: 'EPSG:2100'
                }
            });
        } else if (deleteFeatures) {
            let olFeatures = [];
            for (let i = 0; i < deleteFeatures.length; i++) {
                let currentFeature = deleteFeatures[i];
                // Clone properties
                let featureProperties;
                if (currentFeature instanceof Feature) {
                    featureProperties = { ...currentFeature.getProperties() };
                } else {
                    featureProperties = { ...currentFeature.properties };
                }

                // Set properties
                if (this.modelHasProperty(layer.Model, 'last_user')) { featureProperties.last_user = this.stateService.getUser(); }
                if (this.modelHasProperty(layer.Model, 'last_date')) { featureProperties.last_date = this.getCurrentTimestamp(); }

                // Create feature
                let featureToDelete = new Feature(featureProperties);
                featureToDelete.setGeometryName('the_geom');
                featureToDelete.setId(currentFeature instanceof Feature ? currentFeature.getId() : currentFeature.id);
                olFeatures.push(featureToDelete);
            }

            // nodes = formatWFS.writeTransaction(null, null, olFeatures, formatGML);
            nodes = formatWFS.writeTransaction(null, null, olFeatures, {
                featureNS: layer.Name.split(':')[0],
                featureType: layer.Name,
                srsName: 'EPSG:2100',
                featurePrefix: '',
                nativeElements: [],
                gmlOptions: {
                    featureNS: layer.Name.split(':')[0],
                    featureType: layer.Name,
                    srsName: 'EPSG:2100'
                }
            });
            this.mapService.clearSelection();
        }

        return nodes;
    }

    post(body) {
        // Start loading
        StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));

        let request = this.httpClient.post(this.gisServerUrl + 'wfs', body, { responseType: 'text' }).pipe(shareReplay(1));
        // let delayedRequest = request.pipe(delay(2000),shareReplay(1));

        // request.toPromise()
        // 	.then((result) => {
        // 		StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
        // 	})
        // 	.catch(error => {
        // 		console.log(error);
        // 	});

        request.subscribe(
            responce => { },
            error => { console.log(error); },
            () => { StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false)); }
        );

        return request;
    }


    /** 
    * Used for Postgres insertion
    */
    getCurrentTimestamp() {

        var now = new Date();
        return now.getUTCFullYear() + '-'
            + ('0' + (now.getUTCMonth() + 1)).slice(-2) + '-'
            + ('0' + now.getUTCDate()).slice(-2)
            + 'T' + ('0' + (now.getUTCHours() + 2)).slice(-2)
            + ':' + ('0' + now.getUTCMinutes()).slice(-2)
            + ':' + ('0' + now.getUTCSeconds()).slice(-2)
            + 'Z';
    }

    modelHasProperty(model, propertyName) {

        for (let index = 0; index < model.fields.length; index++) {
            const field = model.fields[index];
            if (field.name === propertyName) {
                return true;
            }
        }

        return false;
    }

    // ====================================
    // 2. MEASUREMENTS
    // ====================================

    /**
    * Format length output.
    * @param {openlayers.geom.LineString} line The line.
    * @return {string} The formatted length.
    */
    getGeometryLength(line) {

        let length = getLength(line, {
            projection: new Projection({ code: 'EPSG:2100' })
        });
        let output;
        output = (Math.round(length * 100) / 100);
        return output;
    };

    /**
    * Format area output.
    * @param {openlayers.geom.Polygon} polygon The polygon.
    * @return {string} Formatted area.
    */
    getGeometryArea(polygon) {

        let area = getArea(polygon, {
            projection: new Projection({ code: 'EPSG:2100' })
        });
        let output;
        output = (Math.round(area * 100) / 100);
        return output;

    };

    public updateMultiTableData(layer: WorkspaceLayer, updatedFeatures: any[]) {
        this.afterPersistActions(layer, updatedFeatures);
    }

}
