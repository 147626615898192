<h2 mat-dialog-title class="mb-3" i18n="Export to file | Export to file">Εξαγωγή σε αρχείο</h2>

<div mat-dialog-content>

  <label id="file-format-radio-group-label" i18n="File format">Τύπος αρχείου</label>
  <mat-radio-group
    aria-labelledby="file-format-radio-group-label"
    class="file-format-radio-group"
    [(ngModel)]="selectedFileFormat">
    <mat-radio-button 
      *ngFor="let format of availableFormats"
      class="file-format-radio-button"
      [value]="format"
    >{{format.label}}</mat-radio-button>
  </mat-radio-group>

  <!-- TODO: un-comment this code to enable file name editing -->
  <!--
  <mat-form-field class="file-format-suffix" [style.--left]="fileInputWidth" style.--content="'{{selectedFileFormat?.value}}'">
    <mat-label i18n="File name">Όνομα αρχείου</mat-label>
    <input #fileNameInputRef matInput type="text" [ngModel]="fileName" (ngModelChange)="onFileNameChange($event.value)" />
  </mat-form-field>
  -->

</div>

<div mat-dialog-actions class="flex justify-end">

  <div class="button-wrapper">
    <button mat-stroked-button i18n="Cancel | Cancel" [mat-dialog-close]="">Ακύρωση</button>
    <button mat-flat-button color="accent" [mat-dialog-close]="onAccept()">
      Εξαγωγή
    </button>
  </div>

</div>
