import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-decide-dialog',
  templateUrl: './decide-dialog.component.html',
  styleUrls: ['./decide-dialog.component.scss']
})
export class DecideDialogComponent implements OnInit {

    // Define the data property with default values
    data: { title: string, message: string, cancel: string, confirm: string } = {
        title: $localize `Επιβεβαίωση διαγραφής στοιχείου`,
        message: $localize `Η ενέργεια αυτή είναι μη αναστρέψιμη. Είστε σίγουρος/η για την διαγραφή του στοιχείου;`,
        cancel: $localize `Ακύρωση`,
        confirm: $localize `Διαγραφή`
    };

  constructor(
    public dialogRef: MatDialogRef<DecideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: { title: string, message: string, cancel: string, confirm: string }) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.data = {
        title: this.injectedData?.title || this.data.title,
        message: this.injectedData?.message || this.data.message,
        cancel: this.injectedData?.cancel || this.data.cancel,
        confirm: this.injectedData?.confirm || this.data.confirm
    };
  }

}
