import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, LOCALE_ID, Inject } from '@angular/core';
import { ActiveScreenRoute } from 'app/models';
import { HelpDialogComponent } from '../shared/help-dialog/help-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'app/core/authentication.service';
import { Router } from '@angular/router';
import { StateService } from 'app/core/state.service';
import { environment } from 'environments/environment';
import { LayoutService } from 'app/core/layout.service';


@Component({
	selector: 'app-header-menu',
	templateUrl: './header-menu.component.html',
	styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, OnChanges {
	@Input() activeScreenRoutes: ActiveScreenRoute[];
	@Input() userDisplayName: string;
	@Input() isAdmin: boolean;
	@Input() username: string;
	@Input() currentRouteName :string;
	@Output() setTheme: EventEmitter<void> = new EventEmitter();
	currentUrl: string;
	public docsRouteActive: boolean = false;
	public sensorsRouteActive: ActiveScreenRoute[] = [];
	public activeUrl: string = "";
	public baseFolder: string = "";

	public langMap = {
		'en': "English",
		'el': "Ελληνικά",
		'de': "Deutsch",
	};

	public apps: Array<{ image: string, url: string, name: string }> = new Array<{ image: string, url: string, name: string }>();

	constructor(@Inject(LOCALE_ID) public localeId: string, public dialogService: MatDialog, private authenticationService: AuthenticationService, private router: Router, private stateService: StateService, public layoutService: LayoutService) {
		this.activeUrl = this.router.url;
		this.localeId !== 'el' ? this.baseFolder = "\\" + this.localeId : "\\";
	}

	ngOnInit() { this.getActivesApps() }

	private getActivesApps() {
		let client = this.stateService.getWorkspace();
		if (!!environment.smartvilleUrl[client]) {
			this.apps.push({ image: this.baseFolder + '\\assets\\images\\smartville.png', url: environment.smartvilleUrl[client], name: 'SmartVille' });
		}
		if (!!environment.theArray[client]) {
			this.apps.push({ image: this.baseFolder + '\\assets\\images\\array.png', url: environment.theArray[client], name: 'The Array' });
		}
		if (!!environment.symmetry[client]) {
			this.apps.push({ image: this.baseFolder + '\\assets\\images\\symmetry.png', url: environment.symmetry[client], name: 'Symmetry' });
		}
		if (!!environment.safeWaterPlan[client]) {
			this.apps.push({ image: this.baseFolder + '\\assets\\images\\safe-water-plan.png', url: environment.safeWaterPlan[client], name: 'Safe Water Plan' });
		}
		if (!!environment.balloonWorks[client]) {
			this.apps.push({ image: this.baseFolder + '\\assets\\images\\balloon-works.png', url: environment.balloonWorks[client], name: 'Balloon Works' });
		}

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['activeScreenRoutes']) {
			this.activeUrl = this.router.url;
			this.docsRouteActive = this.activeScreenRoutes.filter(record => {
				return record.route === '/documents';
			}).length > 0 ? true : false;

			this.sensorsRouteActive = this.activeScreenRoutes.filter(record => {
				return record.route === '/capta' || record.route === '/xilog' || record.route === '/scada';
			})

			this.activeScreenRoutes = this.activeScreenRoutes.filter(r => r.route !== '/documents' && r.route !== '/capta' && r.route !== '/xilog' && r.route !== '/scada' && r.route !== '/android');
			this.currentUrl = this.router.url;
		}
	}

	onHelpButton() {
		this.dialogService.open(HelpDialogComponent, {
			minWidth: '80vw',
			minHeight: '90vh',
			panelClass: 'help-dialog',
			autoFocus: true
		});
	}

	goToSmartVille() {
		let client = this.stateService.getWorkspace();
		if (client === "kos") {
			window.open(environment.smartvilleUrl.kos, '_blank');
		}
		else if (client === "makrohori") {
			window.open(environment.smartvilleUrl.makrohori, '_blank');
		}
	}

	newTab(url: string) {
		window.open(url, '_blank');
	}

	changeLang(localeid: string) {
		if (localeid === 'el') {
			window.open(window.location.origin + this.activeUrl, '_self');

		} else {
			window.open(window.location.origin + '/' + localeid + this.activeUrl, '_self');
		}

	}

	logout() {
		this.authenticationService.geoserverLogout();
	}
}
