<div class="dynamic-field relative" [formGroup]="formGroup">
  <form-multi-value-indicator
    [show]="indicateMultiValueSelection"
    [config]="config">
  </form-multi-value-indicator>
  <date-input [multiValueSelection]="indicateMultiValueSelection"
              [formControlName]="config.name"
              [name]="config.name"
              [alias]="config.alias"
              (blur)="onFieldBlur()"
              (focus)="onFieldFocus()">
  </date-input>
</div>