<h5>Ανέβασμα αρχείου λογαριασμών.</h5>

<!-- FILE CONTROL -->
<div class="file-field input-field">

	<input id="fileUpload" type="hidden">

	<div class="btn">
		<span>File</span>
		<input (change)="onSelectFile($event)" type="file">
	</div>
	<div class="file-path-wrapper">
		<input #fileInput class="file-path validate" placeholder="Upload file" type="text">
	</div>

</div>


<button mat-raised-button color="primary" *ngIf="file" (click)="uploadFile()">Ανεβασμα αρχειου</button>

<div *ngIf="progressVisible">
	<!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
</div>