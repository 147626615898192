import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { MaterialModule } from '../material/material.module';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormDateComponent } from './components/form-date/form-date.component';
import { FormFileComponent } from './components/form-file/form-file.component';
import { DateInputComponent } from './components/form-date/date-input/date-input.component';
import { FileInputComponent } from './components/form-file/file-input/file-input.component';
import { MrTableComponent } from './components/mr-table/mr-table.component';
import { TolinkPipe } from './components/mr-table/tolink.pipe';
import { RowSelectedPipe } from './components/mr-table/row-selected.pipe';
import { MrTableFormDialogComponent } from './components/mr-table-form-dialog/mr-table-form-dialog.component';
import { MrTableTsComponent } from './components/mr-table-ts/mr-table-ts.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlGr } from './models/mat-paginator-intl-gr';
import { DropdownInputComponent } from './components/form-select/dropdown-input/dropdown-input.component';
import { MrTableSpComponent } from './components/mr-table-sp/mr-table-sp.component';
import { SharedModule } from "../shared/shared.module";
import { FormMultiValueIndicatorComponent } from './components/form-multi-value-indicator/form-multi-value-indicator.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxComponent,
    FormDateComponent,
    FormFileComponent,
    DateInputComponent,
    FileInputComponent,
    MrTableComponent,
    TolinkPipe,
    RowSelectedPipe,
    MrTableFormDialogComponent,
    MrTableTsComponent,
    DropdownInputComponent,
    MrTableSpComponent,
    FormMultiValueIndicatorComponent
  ],
  exports: [
    DynamicFormComponent,
    MrTableComponent,
    MaterialModule,
    MrTableTsComponent,
    MrTableSpComponent,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlGr }],
})
export class MrFormModule { }
