import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { coordinate, OPERATIONS } from '../../models';
import { SnackBarService } from 'app/core/snack-bar.service';
import { StateService } from '../../core/state.service';
import Geometry from 'ol/geom/Geometry';
import { LineString, Point, MultiLineString, Polygon, MultiPolygon, MultiPoint } from 'ol/geom';
@Component({
	selector: 'app-coord-editor',
	templateUrl: './coord-editor.component.html',
	styleUrls: ['./coord-editor.component.scss']
})
export class CoordEditorComponent implements OnInit {

	public lat: number;
	public lon: number;
	@Input() operation: OPERATIONS;

	private pointGeometry: Geometry;
	private stateUnsubscription: any;
	@Output() addCoordinate: EventEmitter<coordinate> = new EventEmitter();
	@Output() editCoordinate: EventEmitter<coordinate> = new EventEmitter();

	constructor(private snackBarService: SnackBarService,
		private stateService: StateService,) { }

	ngOnInit(): void {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.updateFromState();
	}

	updateFromState() {

		if (!!this.stateService.getFormData()?.features[0]) {
			let pointGeometry = (this.stateService.getFormData().features[0].getGeometry() as MultiPoint);
			this.lat = pointGeometry.getFirstCoordinate()[0];
			this.lon = pointGeometry.getFirstCoordinate()[1];
		}
	}

	insertCoord() {
		// Check for validity
		// let latRegex: RegExp = new RegExp('^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$');

		if (!this.lat || this.lat < 94874.71 || this.lat > 857398.00) {
			this.snackBarService.setMessage('Οι συντεταγμένες είναι εκτός ορίων ΕΓΣΑ', 4000);
		}

		if (!this.lon || this.lon < 3868409.44 || this.lon > 4630676.91) {
			this.snackBarService.setMessage('Οι συντεταγμένες είναι εκτός ορίων ΕΓΣΑ', 4000);
		}

		if (this.operation === OPERATIONS.topology_editor_draw || this.operation === OPERATIONS.editor_draw) {
			this.addCoordinate.emit({
				lat: this.lat,
				lon: this.lon
			});
		}
		else {
			this.editCoordinate.emit({
				lat: this.lat,
				lon: this.lon
			});
		}
	}

	ngOnDestroy() {
		this.stateUnsubscription();
	}

}
