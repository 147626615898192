import { Component, OnInit } from '@angular/core';

import { StateService } from 'app/core/state.service';
import { LayoutService } from 'app/core/layout.service';
import { ActiveScreenIDs, NavButtons } from 'app/models';
import { MapService } from '../../map/map.service';
import { ActiveScreenManagementService } from 'app/core/active-screen-management.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event)'
  }
})
export class HomeComponent implements OnInit {
  sideContent: NavButtons;
  navButtons = NavButtons;
  navBtnList = ActiveScreenManagementService.getAvailableNavButtons(ActiveScreenIDs.home);
  multiTableVisible: boolean;
  tableVisible: boolean;

  private stateUnsubscription;
  private freehandSelectActive = false;

  constructor(
    private layoutService: LayoutService,
    private mapService: MapService) { }

  ngOnInit() {
    this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
  }

  ngOnDestroy() {
    this.stateUnsubscription();
  }

  onNavChanged(nav: NavButtons) {
    this.sideContent = this.sideContent === nav ? undefined : nav;
  }

  updateFromState() {
    this.layoutService.handleTableAndFormToggle();
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    this.freehandSelectActive = !this.freehandSelectActive;

    if ((event.ctrlKey || event.metaKey) && (event.shiftKey || event.metaKey) && event.key == "K") {
      this.mapService.addPolygonFreehandSelectForInfoInteraction(this.freehandSelectActive);
    }
  }

}
