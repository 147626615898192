// Angular imports
import { Component, OnInit } from '@angular/core';

// App imports 
import { MapService } from '../../map/map.service';
import { StateService } from '../../core/state.service';

@Component({
	selector: 'app-import-bills',
	templateUrl: './import-bills.component.html',
	styleUrls: ['./import-bills.component.css']
})
export class ImportBillsComponent implements OnInit {
	public file:File;
	private uploadUrl = 'deya_parser/import_bills.php';
	public progressVisible          : boolean;

	constructor( private mapService: MapService, private stateService: StateService ) { }

	ngOnInit() {
		
	}

	/*
	 * Keep the file in case the user changes it
	*/
	onSelectFile( event ){

		let fileList: FileList = event.target.files;
	    if(fileList.length > 0) {
	        this.file = fileList[0];	        
	    }

	}

	/*
	* Upload and update the form
	*/
	uploadFile(){
		// let formData:FormData = new FormData();
        // formData.append('uploadFile', this.file, this.file.name);
        // // formData.append('workspace', this.stateService.getWorkspace() );
        // formData.append('workspace', 'veroia' );
        // let headers = new Headers();
        // /** No need to include Content-Type in Angular 4,5 */
        // // headers.append('Content-Type', 'multipart/form-data');
        // headers.append('Accept', 'application/json');
        // let options = new RequestOptions({ headers: headers });
        // this.progressVisible = true;

        // // this.http.post( this.mapService.serverUrl+this.uploadUrl ,formData,options)
        // this.http.post( this.mapService.serverUrl+this.uploadUrl ,formData,options)
		// .subscribe( (res: Response)=>{
		// 	// console.log(res.json());
		// 	// let jsonRes = res.json();
		// 	this.file = undefined;
		// 	this.progressVisible = false;
		// });    

	}

}
