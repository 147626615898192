import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import * as d3 from 'd3';

// App imports 
import { StateService } from '../../core/state.service';
import { MapService } from '../map.service';
import { ActiveScreenManagementService } from '../../core/active-screen-management.service';
import { GeoserverService } from '../../core/geoserver.service';
import { WorkspaceLayer, LayerModel } from '../../models';

// Openlayers imports
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import { all as allStrategy } from 'ol/loadingstrategy.js';
import * as olObservable from 'ol/Observable.js';
import { TimeseriesToggleService } from 'app/shared/services/timeseries-toggle.service';

import { Style } from 'ol/style';
@Component({
	selector: 'app-timeseries-graph',
	templateUrl: './timeseries-graph.component.html',
	styleUrls: ['./timeseries-graph.component.scss']
})
export class TimeseriesGraphComponent implements OnInit, OnDestroy {
	private stateUnsubscription: any;
	private workspaceLayersInitialized: boolean;
	private map: Map;
	public workspaceLayers: Array<WorkspaceLayer>;
	private timeseriesTimeScale;
	private timeseriesStateTimestamp: number;
	private timeseriesGraphWfsLayer: VectorLayer<any>;
	private allLayerFeatures: Array<Feature>;
	private timeseriesBrush: any;
	private timeseriesGraph: any;
	private brushElement;
	private timerIndex;
	private playIntervalHandler;
	public playButtonIcon: string;

	constructor(private el: ElementRef, private geoserverService: GeoserverService, private mapService: MapService,
		private stateService: StateService, private asmService: ActiveScreenManagementService,
		private timeseriesTs: TimeseriesToggleService) {
		this.workspaceLayers = [];
		this.timerIndex = 0;
		this.playButtonIcon = "play_arrow";
	}

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
	}

	ngOnDestroy() {
		this.stateUnsubscription();
	}

	updateFromState() {

		if (!this.workspaceLayersInitialized && this.mapService.getMap() && this.stateService.getState().workspaceModelsLoaded) {
			this.map = this.mapService.getMap();
			let workspaceLayers;
			// Filter by screen then by current edited group
			workspaceLayers = this.asmService.filterLayersByScreen(this.stateService.getWorkspaceLayers());
			workspaceLayers = this.flattenWorkspaceLayers(workspaceLayers);

			this.workspaceLayers = workspaceLayers;
			this.workspaceLayersInitialized = true;
		}

		// Get timeseries data if available
		let timeseriesGraphData = this.stateService.getTimeseriesGraph();
		if (timeseriesGraphData && timeseriesGraphData.visible && timeseriesGraphData.timestamp && timeseriesGraphData.timestamp !== this.timeseriesStateTimestamp) {
			this.timeseriesStateTimestamp = timeseriesGraphData.timestamp;
			let layer = this.getWorkspaceLayerByName(timeseriesGraphData.layerName);

			this.timeseriesGraphWfsLayer = this.mapService.toggleWFSService(layer, this.mapService.getMap().getLayers().getLength(), true, undefined, allStrategy, '_timeseries');
			this.timeseriesGraphWfsLayer.setStyle((feature, resolution) => {
				// return this.geoserverService.generateVectorStyle('point', 'circle', '#2196F3', '#000000', 2, (feature.get('value')/10) );
				return this.geoserverService.generateVectorStyle('point', 'circle', '#2196F3', '#000000', 2, (feature.get(layer.Model.timeseries_graph['value']) / 10));
			});

			// On layer load render graph
			this.layerLoadedPromise(this.timeseriesGraphWfsLayer).then(() => {
				this.allLayerFeatures = this.timeseriesGraphWfsLayer.getSource().getFeatures();
				this.timeseries_chart(layer.Model, this.allLayerFeatures);
			});

		} else if (timeseriesGraphData && !timeseriesGraphData.visible) {
			// Hide widget
			this.reset();
		}

	}

	layerLoadedPromise(vectorLayer) {

		return new Promise((resolve, reject) => {
			let listenerKey = vectorLayer.getSource().on('change', (e) => {
				if (vectorLayer.getSource().getState() == 'ready') {
					olObservable.unByKey(listenerKey);
					resolve(null);
				}
			});
		});

	}

	reset() {
		this.mapService.getMap().removeLayer(this.timeseriesGraphWfsLayer);
	}

	flattenWorkspaceLayers(workspaceLayers) {
		let flattenedWorkspaceLayers = [];
		workspaceLayers.map((layer: any) => {
			if (layer.Layer) {
				flattenedWorkspaceLayers = [...flattenedWorkspaceLayers, ...layer.Layer];
			} else {
				flattenedWorkspaceLayers.push(layer);
			}
		});

		return flattenedWorkspaceLayers;
	}

	getWorkspaceLayerByName(layerName: string) {
		for (let index = 0; index < this.workspaceLayers.length; index++) {
			const layer = this.workspaceLayers[index];
			if (layer.Name === layerName) {
				return layer;
			}
		}

		return null;
	}

	timeseries_chart(layerModel: LayerModel, data: Array<any>) {
		if (!this.el.nativeElement.parentElement) return;

		const containerWidth = this.el.nativeElement.parentElement.clientWidth;
		const containerHeight = this.el.nativeElement.parentElement.clientHeight;

		// set the dimensions and margins of the graph 
		let margin = { top: 15, right: 45, bottom: 25, left: 50 },
			width = containerWidth - margin.left - margin.right,
			height = containerHeight - margin.top - margin.bottom;

		// parse the date / time 
		let parseTime = d3.timeParse("%Y/%m/%d %H:%M:%S.%L");

		// set the ranges 
		let x = d3.scaleTime().range([0, width]);
		this.timeseriesTimeScale = x;
		let y = d3.scaleLinear().range([height, 0]);

		// define the line 
		let valueline = d3.line()
			.x((d: any) => {
				return x(d.get('parsedDate'));
			})
			.y((d: any) => {
				return y(d.get('parsedValue'));
			});

		// append the svg object to the body of the page 
		// appends a 'group' element to 'svg' 
		// moves the 'group' element to the top left margin 
		this.timeseriesGraph = d3.select("#timeseriesChart")
			.append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		// format the data 	
		data.forEach((d: any) => {
			d.set('parsedDate', parseTime(d.get(layerModel.timeseries_graph['time'])))
			d.set('parsedValue', + d.get(layerModel.timeseries_graph['value']));
		});

		// Sort the data by date
		data.sort(function (a, b) {
			return a.get('parsedDate') - b.get('parsedDate');
		});


		let timeExtent = d3.extent(data, (d: any) => { return d.get('parsedDate'); });
		let beginDate = new Date(timeExtent[0]);
		// beginDate.setDate(beginDate.getDate()-1);
		let endDate = new Date(timeExtent[1]);
		// endDate.setDate(endDate.getDate()+1);
		x.domain([beginDate, endDate]);

		// Scale the range of the data 
		// Extent return the minimum and maximum values of the data		
		// x.domain(d3.extent(data, (d: any) => { return d.get('parsedDate'); }));
		y.domain([0, d3.max(data, (d: any) => { return d.get('parsedValue'); }) + 1]);

		// Add the valueline path. 
		// YOU CAN OMMIT THIS AND LEAVE ONLY THE SCATTERPLOT
		// svg.append("path")
		// 	.data([data])
		// 	.attr("class", "line")
		// 	.attr("d", <any>valueline);

		// Add the scatterplot 
		this.timeseriesGraph.selectAll("dot")
			.data(data)
			.enter()
			.append("circle")
			.attr("class", "timeseries-circle")
			.attr("r", 3)
			.attr("cx", function (d) { return x(d.get('parsedDate')); })
			.attr("cy", function (d) { return y(d.get('parsedValue')); });

		// Add the X Axis 
		this.timeseriesGraph.append("g")
			.attr("class", "axis")
			.attr("transform", "translate(0," + height + ")")
			.call(d3.axisBottom(x).ticks(24).tickFormat(d3.timeFormat("%H:%m")));
		// .call(d3.axisBottom(x).ticks(10).tickFormat(d3.timeFormat("%d-%m")));

		// Add this for text rotation
		// .selectAll("text")
		// .style("text-anchor", "end")
		// .attr("dx", "-.8em")
		// .attr("dy", ".15em")
		// .attr("transform", "rotate(-65)");

		// Add the Y Axis 
		this.timeseriesGraph.append("g")
			.attr("class", "axis")
			.call(d3.axisLeft(y).ticks(3));

		// Y axis label	
		this.timeseriesGraph.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", 0 - margin.left)
			.attr("x", 0 - (height / 2))
			.attr("dy", "1em")
			.attr("font-size","12")
			.style("text-anchor", "middle")
			.text("Παροχή");

		// Add brushing	
		this.timeseriesBrush = d3.brushX()
			.extent([[0, 0], [width, height]])
			.on("end", () => {
				this.brushended;
			});

		this.brushElement = this.timeseriesGraph.append("g")
			.attr("class", "brush")
			.call(this.timeseriesBrush);


	}

	brushended(event) {

		let selection = event.selection;
		if (selection !== null) {
			var timeExtent = event.selection.map(this.timeseriesTimeScale.invert, this.timeseriesTimeScale);
			// console.debug(timeExtent);			

			// let featureStyle = this.geoserverService.generateVectorStyle('point', 'circle', '#e65100', '#616161', 2, 8);
			let hiddenStyle = new Style({});
			this.timeseriesGraphWfsLayer.getSource().forEachFeature((feature) => {
				if (feature.get('parsedDate') >= timeExtent[0] && feature.get('parsedDate') <= timeExtent[1]) {
					let featureStyle = this.geoserverService.generateVectorStyle('point', 'circle', 'rgb(33, 150, 243, 0.5)', '#000000', 2, (feature.get('parsedValue')));
					feature.setStyle(featureStyle);
				} else {
					feature.setStyle(hiddenStyle);
				}
			});

		}

	}

	onCloseBtn() {
		this.timeseriesTs.hideTimeseriesWidget();
	}

	onPlay() {
		if (this.playIntervalHandler) {
			clearInterval(this.playIntervalHandler);
			this.playIntervalHandler = null;
			this.playButtonIcon = "play_arrow";
			return;
		}

		// Init interval
		this.playButtonIcon = "pause";
		this.playIntervalHandler = setInterval(() => {
			let firstTime = this.allLayerFeatures[this.timerIndex].get('parsedDate');
			firstTime = firstTime.getTime() - 5 * 60000;
			let secondtime = this.allLayerFeatures[this.timerIndex + 7].get('parsedDate');
			secondtime = secondtime.getTime() - 5 * 60000;
			this.timeseriesBrush.move(this.brushElement, [firstTime, secondtime].map(this.timeseriesTimeScale));

			this.timerIndex += 7;
			if (!this.allLayerFeatures[this.timerIndex + 7]) {
				this.timerIndex = 0;
			}
		}, 1000);
	}

}
