import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/core/authentication.service';
@Component({
  selector: 'app-notauthorized',
  templateUrl: './notauthorized.component.html',
  styleUrls: ['./notauthorized.component.css']
})
export class NotauthorizedComponent implements OnInit {

  public login;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this.login = environment.production ? environment.domain : environment.domain + ":4200";
  }

  logout(): void {
    this.authenticationService.geoserverLogout();
  }


}
