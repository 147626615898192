import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tolink'
})
export class TolinkPipe implements PipeTransform {
  private urlRegex: RegExp = new RegExp('^(http|https)://');

  transform(value: any, args?: any): any {
    if(!!value && this.urlRegex.test(value)) {
      return `<a class="td-link" target="_blank" href="${value}">File</a>`;
    }
    else {
      return value;
    }
  }
}
