import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { DomainModel } from 'app/models';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DropdownInputComponent,
    multi: true
  }],
})
export class DropdownInputComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() placeHolder: string;
  @Input() domain: DomainModel[];
  @Input() editable: boolean;
  @Output() blur: EventEmitter<void> = new EventEmitter();
  @Output() focus: EventEmitter<void> = new EventEmitter();

  private onChange: Function;

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger, static: true }) autoComplete: MatAutocompleteTrigger;
  filteredOptions: Observable<DomainModel[]>;

  @ViewChild('inputRef', { static: true }) inputRef: ElementRef;

  writeValue(newVal) {
    this.inputRef.nativeElement.value = (this.domain.find(d => d.name == (newVal ?? "").toString()) || {alias: newVal}).alias
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

  onOptionSelected(select: MatAutocompleteSelectedEvent) {
    this.onChange(this.domain.find(d => d.alias === select.option.value).name);
  }

  onArrowClick() {
    this.autoComplete.panelOpen ? this.autoComplete.closePanel() : this.autoComplete.openPanel();
  }

  onType() {
    this.onChange(this.inputRef.nativeElement.value);
  }
}
