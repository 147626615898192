<div class="dynamic-field relative" [formGroup]="formGroup">
  <form-multi-value-indicator
    [show]="indicateMultiValueSelection"
    [config]="config">
  </form-multi-value-indicator>

  <div [ngClass]="{'opacity-0': indicateMultiValueSelection, 'flex items-center gap-2': config.name === 'elevation'}">
    <mat-form-field *ngIf="config.localType === 'string'">
      <mat-label>{{config.alias || config.name}}</mat-label>
      <textarea type="text" matInput [formControlName]="config.name" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" (blur)="onFieldBlur()" (focus)="onFieldFocus()">
      </textarea>
    </mat-form-field>

    <mat-form-field *ngIf="config.localType === 'number' || config.localType === 'int'">
      <mat-label>{{config.alias || config.name}}</mat-label>
      <input type="number" matInput [formControlName]="config.name" (blur)="onFieldBlur()" (focus)="onFieldFocus()">
    </mat-form-field>

    <button mat-icon-button *ngIf="config.name === 'elevation'" (click)="onCalculateElevation()"
            i18n-title="Calculate elevation | Calculate elevation" aria-label="Landscape" [disabled]="!config.editable">
      <mat-icon>landscape</mat-icon>
    </button>

    <div *ngIf="formGroup.invalid">
        <mat-error *ngIf="formGroup.controls[this.config.name]?.errors?.pattern" class="alert alert-danger">
        {{config.validationMessage}}
        </mat-error>
    </div>
  </div>
</div>
