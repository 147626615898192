<div class="card-style bg-light-500/[.85] dark:bg-dark-800/[.85]">
  <h3 class="mb-2 font-light" i18n="Background | Background">Υπόβαθρο</h3>

  <ng-container *ngFor="let basemap of basemaps">
    <div class="basemap-selection" [ngClass]="{
            'basemap-selection-selected': selectedBasemap === basemap.label
          }" (click)="onBasemapSelect(basemap)">
      <div class="basemap-image-wrapper">
        <img class="basemap-image" src="{{
                basemap.imageSrc ||
                  assetsPrefix + 'assets/images/basemap_placeholder.png'
              }}" />
      </div>
      <div class="basemap-label">
        {{ basemap.label }}
      </div>
    </div>
  </ng-container>
</div>
