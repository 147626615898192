import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  public setMessage(message: string, sec: number){
    this.snackBar.open(message, 'ΟΚ', { duration: sec });
  }

}
