// Angular imports
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// App imports 
import { StateService } from '../../core/state.service';
import { MapService } from '../map.service';
import { GeoserverService } from '../../core/geoserver.service';
import { ActiveScreenManagementService } from '../../core/active-screen-management.service';
import { LayerField, WorkspaceLayer, WorkspaceGroup } from '../../models';
import { SnackBarService } from 'app/core/snack-bar.service';
import { shareReplay } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

export type FilterResultFormat = 'application/json' | 'csv';
@Component({
	selector: 'app-query',
	templateUrl: './query.component.html',
	styleUrls: ['./query.component.css']
})
export class QueryComponent implements OnInit {

	private stateUnsubscription;
	private initialized: boolean;
	public workspaceGroups: Array<WorkspaceGroup>;
	public workspaceLayers: Array<WorkspaceLayer>;
	public layerProperties: Array<LayerField>;
	public selectedGroup: WorkspaceGroup;
	public selectedLayer: any;
	public selectedProperty: any;
	public selectedPropertyValue: string;
	private selectedPropertyDate: string;
	public ecqlQuery: string;
	public distinctPropertyValues: Array<any>;
	public filteredPropertyValues: Array<any>;
	public selectedPropertyIsDate: boolean;
	public spatialFilterEnabled: boolean;
	public fetchAll: boolean;
	@ViewChild('datepicker') datepicker: ElementRef;
	@ViewChild('propertyValueSelect') propertyValueSelect: MatSelect;
	private queryStartIndex: number;
	private queryCount: number;
	public dataFormats: Array<{alias: string, value: FilterResultFormat}>;
	private selectedDataFormat: FilterResultFormat;
	private delimiterRegEx: RegExp = /(<=|<>|LIKE|<|=|AND|>|>=|OR|BEFORE|AFTER|DURING)/gmi;

	constructor(private geoserverService: GeoserverService, private mapService: MapService,
		private stateService: StateService, public snackBarService: SnackBarService,
		private asmService: ActiveScreenManagementService) {

		this.workspaceGroups = [];
		this.workspaceLayers = [];
		this.layerProperties = [];
		this.selectedGroup = undefined;
		this.selectedLayer = undefined;
		this.selectedProperty = undefined;
		this.selectedPropertyValue = null;
		this.ecqlQuery = '';
		this.distinctPropertyValues = [];
		this.selectedPropertyIsDate = false;
		this.initialized = false;
		this.spatialFilterEnabled = false;
		this.fetchAll = false;
		this.dataFormats = [{
			alias: 'Πίνακας',
			value: 'application/json'
		}, {
			alias: 'CSV',
			value: 'csv'
		}];
		this.selectedDataFormat = 'application/json';
		// this.queryStartIndex = 0;
		// this.queryCount      = 100;
		// this.queryTotal      = -1;
	}

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.updateFromState();
	}

	updateFromState() {

		if (!this.initialized && this.stateService.getState().workspaceModelsLoaded) {
			let allGroups = this.stateService.getWorkspaceLayers();
			this.workspaceGroups = this.asmService.filterLayersByScreen(allGroups);
			if (this.workspaceGroups && this.workspaceGroups.length > 0) {
				this.initialized = true;
			}
		}
		
		if (this.initialized) {
			const lastFilteredLayerName = this.stateService.getState().lastFilteredLayerName;
			const lastFilteredLayerGroup: WorkspaceGroup = this.workspaceGroups.find(group => group.Layer.some(layer => layer.Name === lastFilteredLayerName));
			const lastFilteredLayer: WorkspaceLayer = lastFilteredLayerGroup?.Layer?.find(layer => layer.Name === lastFilteredLayerName);
			if (lastFilteredLayer) {
				if (lastFilteredLayer.Name !== this.selectedLayer?.Name) {
					this.setSelectedLayerFilterToForm(lastFilteredLayerGroup, lastFilteredLayer);
				}
			}
		}
	}

	flattenWorkspaceLayers(workspaceLayers) {
		let flattenWorkspaceLayers = [];
		workspaceLayers.map((layer: any) => {
			if (layer.Layer) {
				flattenWorkspaceLayers = [...flattenWorkspaceLayers, ...layer.Layer];
			} else {
				flattenWorkspaceLayers.push(layer);
			}
		});

		return flattenWorkspaceLayers;
	}

	ngOnDestroy() {
		this.stateUnsubscription();
	}

	/**
	*  Clear query form
	*/
	onClearQueryForm() {
		this.selectedDataFormat = 'application/json';
		this.selectedGroup = null;
		this.selectedLayer = null;
		this.selectedProperty = null;
		this.layerProperties = null;
		this.selectedPropertyIsDate = false;
		this.selectedPropertyValue = null;
		this.clearEcql();
	}

	/**
	*  Select group
	*/
	onGroupSelect(selectedGroup: WorkspaceGroup) {
		this.selectedGroup = selectedGroup;
		this.workspaceLayers = selectedGroup.Layer;
	}

	/**
	*  Select layer
	*/
	onLayerSelect(selectedLayer: WorkspaceLayer) {
		this.selectedLayer = selectedLayer;
		this.layerProperties = selectedLayer.Model.fields;
		this.distinctPropertyValues = [];
	}

	/*
	*  Select property
	*/
	onPropertySelect(selectedProperty) {

		this.selectedProperty = selectedProperty;
		if (selectedProperty.localType === 'date-time' || selectedProperty.localType === 'date') {
			this.selectedPropertyIsDate = true;
			return;
		} {
			this.selectedPropertyIsDate = false;
		}

		const getFeature$ = this.geoserverService.getFeature(this.selectedLayer, [selectedProperty.name], 'INCLUDE', -1).pipe(shareReplay());
		//Fetch property unique values
		getFeature$.subscribe(
			(featuresResponce: any) => {
				let features = featuresResponce.features;

				this.distinctPropertyValues = [];

				for (let index = 0; index < features.length; index++) {
					const currentFeature = features[index];
					let propertyValue = currentFeature.properties[selectedProperty.name];
					if (!this.distinctPropertyValues.includes(propertyValue)) {
						this.distinctPropertyValues.push(propertyValue);

						if (this.distinctPropertyValues.length >= 200) {
							break;
						}
					}

				}

			});
		return getFeature$;
	}

	/*
	*  Select property value
	*/
	onPropertyValueSelect(selectedPropertyValue, updateQuery = true) {
		this.selectedPropertyValue = selectedPropertyValue;
		if (!updateQuery) {
			return;
		}
		switch (this.selectedProperty.localType) {
			case "int":
				this.ecqlQuery += this.selectedPropertyValue;
				break;
			case "string":
				this.ecqlQuery += '\'' + this.selectedPropertyValue + '\'';
				break;
			case "date-time":
				this.ecqlQuery += this.selectedPropertyValue;
				break;
			default:
				this.ecqlQuery += '\'' + this.selectedPropertyValue + '\'';
				break;
		}

	}

	/*
	*  Select date
	*/
	onPropertyDateSelect(type: string, event: any) {
		this.selectedPropertyDate = event.value.toISOString();
		this.ecqlQuery += this.selectedPropertyDate;
	}

	onDatePicked(event) {
		// console.log(event);
	}

	onDataFormatSelect(dataFormat) {
		this.selectedDataFormat = dataFormat;
	}

	query() {
		this.queryStartIndex = 0;
		if (this.fetchAll) {
			this.queryCount = -1;

		} else {
			this.queryCount = undefined;
		}
		this.submitEcqlQuery(this.queryCount, this.queryStartIndex);
	}

	/*
	 * SUBMIT ECQL FILTER
	*/
	submitEcqlQuery(queryCount: number, queryStartIndex: number) {
		const fetchAll = queryCount === -1;

		if (!this.selectedLayer) {
			this.snackBarService.setMessage($localize`Παρακαλώ επιλέξτε επίπεδο.`, 4000);
			return;
		}

		let queryForSubmission = this.ecqlQuery;
		if (this.spatialFilterEnabled) {
			let bbox = this.mapService.getMap().getView().calculateExtent(this.mapService.getMap().getSize());
			queryForSubmission += queryForSubmission === '' ? 'BBOX( the_geom, ' + bbox + ')' : ' AND BBOX( the_geom, ' + bbox + ')';
		}

		StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));

		if (this.selectedDataFormat == 'application/json') {
      this.mapService.setLayerEcqlFilter(this.selectedLayer, !!queryForSubmission ? queryForSubmission : null, !!queryForSubmission ? 'ENABLE' : 'DISABLE');
			// Based on fetchAll we do pagination on the client (true) or the server (false)
			this.geoserverService.getFeature(this.selectedLayer, undefined, queryForSubmission, queryCount, undefined,
				undefined, undefined, undefined, undefined, !fetchAll, undefined, undefined, fetchAll)
				.subscribe((responce: any) => {
				}, error => {
					this.snackBarService.setMessage($localize`Κάποιο λάθος παρουσιάστηκε. Παρακαλούμε ελέγξτε την εγκυρότητα του ερωτήματος`, 4000);
				});
		} else {
			let csvFields=this.selectedLayer.Model.fields
			.filter(field=>field.name!='id'?field.name:false)
			.map(field=>field.name);

			this.geoserverService.getFeature(this.selectedLayer, csvFields, queryForSubmission, queryCount, undefined,
				undefined, undefined, undefined, undefined, undefined,
				undefined, undefined, undefined, this.selectedDataFormat).subscribe((response: any) => {
					this.geoserverService.convertToFileAndExport(response);
				}, error => {
					this.snackBarService.setMessage($localize`Κάποιο λάθος παρουσιάστηκε. Παρακαλούμε ελέγξτε την εγκυρότητα του ερωτήματος`, 4000);
				});
		}


	}

	nextQueryPage() {
		this.queryStartIndex = this.queryStartIndex + this.queryCount;
		this.submitEcqlQuery(this.queryCount, this.queryStartIndex);
	}

	clearEcql() {
		this.ecqlQuery = '';
	}

	/*
	*  SELECT OPERATOR
	*/
	operatorClick(operatorCode: number) {

		if (!this.selectedProperty) {
			let message =
				this.snackBarService.setMessage($localize`Παρακαλώ επιλέξτε επίπεδο και ιδιότητα πρώτα.`, 4000);
			return;
		}

		if (operatorCode % 2 === 0) {
			let operatorString = this.operatorToString(operatorCode);
			this.ecqlQuery += this.selectedProperty.name + operatorString;
		} else {
			let operatorString = this.operatorToString(operatorCode);
			this.ecqlQuery += operatorString;
		}

	}


	/*
	*  Returns the lexicographic operator
	*/
	operatorToString(operatorCode: number) {

		let operatorText = '';
		switch (operatorCode) {
			case 0:
				operatorText = ' = ';
				break;
			case 2:
				operatorText = ' <> ';
				break;
			case 18:
				operatorText = ' LIKE ';
				break;
			case 4:
				operatorText = ' < ';
				break;
			case 6:
				operatorText = ' <= ';
				break;
			case 3:
				operatorText = ' AND ';
				break;
			case 8:
				operatorText = ' > ';
				break;
			case 10:
				operatorText = ' >= ';
				break;
			case 5:
				operatorText = ' OR ';
				break;
			case 12:
				operatorText = ' BEFORE ';
				break;
			case 14:
				operatorText = ' AFTER ';
				break;
			case 16:
				operatorText = ' DURING ';
				break;
		}

		return operatorText;
	}

	private setSelectedLayerFilterToForm(lastFilteredLayerGroup: WorkspaceGroup, lastFilteredLayer: WorkspaceLayer) {
		this.onClearQueryForm();
		this.onGroupSelect(lastFilteredLayerGroup);
		this.onLayerSelect(lastFilteredLayer);
		this.ecqlQuery = lastFilteredLayer.Model?.ecql_filter ?? '';
		// Restore the query from the ECQL filter string to the query form
		if (this.ecqlQuery) {
			// Find the ECQL query's field property and its selected value
			// The filter's structure is:
			//      0          1        2
			// {property} {operator} {value}
			// So we can split the string with the operator as delimiter (see: delimiterRegEx), and get the 1st and 3d element of the resulting array
			const firstFilterProperty = this.ecqlQuery.split(this.delimiterRegEx)[0].trim();
			const firstFilterValue = this.ecqlQuery.split(this.delimiterRegEx)[2].trim().replaceAll(`'`, '');
			// Find the field property in the layerProperties array
			const selectedFieldProperty = this.layerProperties.find(property => property.name === firstFilterProperty || property.alias === firstFilterProperty);
			if (selectedFieldProperty) {
				// Auto select the property on the MatSelect component and subscribe to the returning observable (available values fetch)
				this.onPropertySelect(selectedFieldProperty).subscribe(
					() => {
						// Try to match the query's value with the properties available values
						if (firstFilterValue) {
							const val = this.distinctPropertyValues.find(v => v?.toString() === firstFilterValue);
							// Auto select the value to the MatSelect component
							this.onPropertyValueSelect(val, false);
						}
					}
				);
			} else {
				console.warn('ECQL filter exists but could not extract the selected filter property');
			}
		}
	}
}
