// Angular imports
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'

// App imports 
import { StateService } from './state.service';
import {
    QueryClass, formsDisabledFields, formsOmitedFields, WorkspaceLayer,
    selectEventSource, LayerField, FeatureTableDataModel,
    DateUtils
} from '../models';
import { environment } from '../../environments/environment';

// Openlayers imports
import { Style, Fill, Stroke, Circle, RegularShape } from 'ol/style';
import { WMSCapabilities, WMTSCapabilities, GeoJSON, WFS } from 'ol/format';


import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import { shareReplay } from 'rxjs/operators'
import { LayoutService } from './layout.service';

@Injectable()
export class GeoserverService {

    private gisServerUrl: string;
    public latestGetFeatureObject: QueryClass;
    private stateUnsubscription;
    public gwcCapabilities;
    public printCapabilities;

    constructor(private stateService: StateService, private httpClient: HttpClient, private layoutService: LayoutService) {
      
        this.gisServerUrl = StateService.getScheme() + '//' + environment.geoserverDomain + '/' + 'geoserver/';
        this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });

    }

    updateFromState() {

        if (this.stateService.getUser()) {

            this.stateUnsubscription();
            this.loadServices().then(() => {
                StateService.stateStore.dispatch(this.stateService.setWorkspaceLayerModelsLoadedOperation(true));
            }, () => {
                console.debug('Not connected');
            });
        }
    }

    loadServices() {
        let layerModelsPromises: Array<Promise<any>> = [];

        let capabilitiesPromise = new Promise((resolve, reject) => {

            this.getCapabilities().subscribe(
                capabilities => {
                    let parsedCapabilities: any = this.extractData(capabilities);

                    if (!parsedCapabilities.Capability.Layer.Layer) {
                        reject();
                        return;
                    }

                    // Set state
                    StateService.stateStore.dispatch(this.stateService.setAllLayersOperation(parsedCapabilities.Capability.Layer.Layer));

                    // Fetch all models
                    let workspaceGroups: Array<WorkspaceLayer> = parsedCapabilities.Capability.Layer.Layer;
                    for (let i = 0; i < workspaceGroups.length; i++) {
                        const group = workspaceGroups[i];
                        if (group.Layer) {// Is group
                            for (let j = 0; j < group.Layer.length; j++) {
                                const currentLayer = group.Layer[j];
                                // if (!this.getKeyValue(currentLayer, 'is_view')) {
                                layerModelsPromises.push(this.fetchModel(currentLayer.Name));
                                // }
                            }
                        }
                    }

                    Promise.all(layerModelsPromises).then(() => {
                        resolve(null);
                    });

                },
                error => {
                    console.log(error);
                });

        });

        let gwcPromise = new Promise((resolve, reject) => {

            this.getWMSCCapabilities().subscribe(capabilities => {
                let parser = new WMTSCapabilities();
                var result = parser.read(capabilities);
                this.gwcCapabilities = result;
                resolve(null);
            });
        });

        let printCapabilitiesPromise = new Promise((resolve, reject) => {

            this.getPrintCapabilities().subscribe(capabilities => {
                this.printCapabilities = capabilities;
                resolve(null);
            });
        });

        return Promise.all([capabilitiesPromise, gwcPromise, printCapabilitiesPromise]);
    }

    //=====================================================
    // 1. DISCOVER   ======================================
    //===================================================== 

    getCapabilities(layer: string = undefined) {
        return this.httpClient.get(this.gisServerUrl + 'wms', {
            withCredentials: true,
            responseType: 'text',
            params: {
                service: 'wms',
                version: '2.0.0',
                request: 'GetCapabilities'
            }
        });
    }

    getWMSCCapabilities() {
        return this.httpClient.get(this.gisServerUrl + 'gwc/service/wmts', {
            withCredentials: true,
            responseType: 'text',
            params: {
                service: 'wmts',
                version: '1.0.0',
                request: 'GetCapabilities'
            }
        });
    }

    getPrintCapabilities() {
        return this.httpClient.get(this.gisServerUrl + 'pdf/info.json', {
            withCredentials: true,
            responseType: 'json'
        });
    }

    private extractData(data) {
        // use the tool to parse the data
        let formatter = new WMSCapabilities();
        let capabilities = (formatter.read(data));

        return capabilities;
    }

    //=====================================================
    // 2. QUERY ENGINE   ==================================
    //===================================================== 
    /**
     * Submit a describeFeatureType request to get the properties of a layer	 
    **/
    describeFeatureType(layer: string = undefined) {

        return this.httpClient.get(this.gisServerUrl + 'wfs', {
            withCredentials: true,
            responseType: 'json',
            params: {
                service: 'wfs',
                version: '2.0.0',
                request: 'DescribeFeatureType',
                outputFormat: 'application/json',
                typename: layer
            }
        });

    }

    /**
     * 
     * @param outputFormat csv|shape-zip
     * @param layer 
     * @returns 
     */
    exportWfs(outputFormat: string, layer: WorkspaceLayer) {

        const typesMap = new Map();

        typesMap.set('csv', {
            responseType: 'text',
            fileExtension: 'csv'
        });
        typesMap.set('shape-zip', {
            responseType: 'blob',
            fileExtension: 'zip'
        });

        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.set('service', 'wfs');
        httpParams = httpParams.set('version', '2.0.0');
        httpParams = httpParams.set('request', 'GetFeature');
        httpParams = httpParams.set('outputFormat', outputFormat);
        httpParams = httpParams.set('typename', layer.Name);


        if (this.latestGetFeatureObject && this.latestGetFeatureObject.filter && this.latestGetFeatureObject.filter != '') {
            httpParams = httpParams.set('cql_filter', this.latestGetFeatureObject.filter);
        }

        switch (outputFormat) {
            case 'csv':
                this.getWfsBlobFile(outputFormat, layer, httpParams, typesMap)
                break;
            case 'shape-zip':
                this.getWfsBlobFile(outputFormat, layer, httpParams, typesMap)
            default:
                break;
        }

    }

    getWfsBlobFile(outputFormat: string, layer: WorkspaceLayer, httpParams: HttpParams, typesMap: Map<string, any>) {

        this.httpClient.get(this.gisServerUrl + 'wfs', {
            withCredentials: true,
            responseType: typesMap.get(outputFormat).responseType,
            params: httpParams
        }).subscribe((res: any) => {

            let dataType = res.type;
            let binaryData = [];
            binaryData.push(res);
            let url = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

            let a = document.createElement("a");
            document.body.appendChild(a);
            (a as any).style = "display: none";
            a.href = url;
            a.download = `${WorkspaceLayer.layerNameWithoutUser(layer.Name)}_export_${DateUtils.simpleISOTimestamp()}.${typesMap.get(outputFormat).fileExtension}`;
            a.click();
            window.URL.revokeObjectURL(url);
            this.layoutService.showHeaderLoader(false);
        });
    }

    /**
     * Submit a describeCoverage request to get the properties of a coverage	 
    **/
    describeCoverage(layer: string = undefined) {

        // http://water-pillar.com/geoserver/wcs?service=wcs&version=1.1.1&request=DescribeCoverage&outputFormat=application/json&identifiers=paros:altitude
        return this.httpClient.get(this.gisServerUrl + 'wcs', {
            withCredentials: true,
            responseType: 'text',
            params: {
                service: 'wcs',
                version: '1.1.1',
                request: 'DescribeCoverage',
                identifiers: layer
            }
        });

    }


    /**
     * Fetch model and enrich state workspace layer with it
     * @param layerName The layer name
     */
    fetchModel(layerName: string = undefined) {

        let modelPromise = new Promise((resolve, reject) => {

            // Do not fetch models for the following
            let workspaceLayer = this.stateService.getWorkspaceLayerByName(layerName);
            if (this.getKeyValue(workspaceLayer, 'ImageMosaic')) {
                resolve(null);
                return;
            }

            if (this.getKeyValue(workspaceLayer, 'GeoTIFF')) {

                let coverageIndexField = {
                    name: "GRAY_INDEX",
                    localType: "int",
                    disabled: true,
                    editable: false,
                    visible: true,
                    alias: "Raster value"
                }

                // Get workspaceLayer
                let workspaceLayer = this.stateService.getWorkspaceLayerByName(layerName);

                // Get opacity
                let opacity = this.getKeyValue(workspaceLayer, 'opacity');

                // Get show on init
                let showOnInit = this.getKeyValue(workspaceLayer, 'show');

                // Get identify
                let identifiable = this.getKeyValue(workspaceLayer, 'no_identify');

                // Get open_form_on_identify
                let openForm = this.getKeyValue(workspaceLayer, 'no_form_on_identify');

                // Get snap always
                let snapAlways = this.getKeyValue(workspaceLayer, 'snap_always');

                // Get can upload csv
                let uploadCsv = this.getKeyValue(workspaceLayer, 'can_upload_csv');

                // Get open attribute table
                let openAttrTable = this.getKeyValue(workspaceLayer, 'no_attribute_table');

                // Get table checkboxes visible
                let checkboxesVisible = this.getKeyValue(workspaceLayer, 'checkboxes_visible');

                // Get render chlorium
                let renderChlorium = this.getKeyValue(workspaceLayer, 'render_chlorium');

                // Can move to
                let canMoveTo = this.getKeyValue(workspaceLayer, 'can_move_to');

                // Is epanet layer
                let isEpanetLayer = this.getKeyValue(workspaceLayer, 'is_epanet_layer');

                // Audits
                let audits = this.getKeyValue(workspaceLayer, 'audits');

                let enrichedWorspaceModel = {
                    ...workspaceLayer, ...{
                        Model: {
                            fields: [coverageIndexField],
                            geometryType: false,
                            edit_actions: [],
                            is_edit: false,
                            is_topology_edit: false,
                            on_edit_snap_to: false,
                            on_edit_split: false,
                            timeseries_graph: false,
                            opacity: opacity,
                            show_on_init: showOnInit,
                            identifiable: !identifiable,
                            open_form_on_identify: !openForm,
                            snap_always: snapAlways,
                            can_upload_csv: uploadCsv,
                            hasAttributeTable: !openAttrTable,
                            sensorLayerType: false,
                            is_raster: true,
                            checkboxesVisible: checkboxesVisible,
                            renderChlorium: renderChlorium,
                            canMoveTo: canMoveTo,
                            isEpanetLayer: isEpanetLayer,
                            audits: audits,
                            ecql_filter: null,
                            ecql_applied: false
                        }
                    }
                };

                StateService.stateStore.dispatch(this.stateService.setWorkspaceLayerOperation(enrichedWorspaceModel));
                resolve(null);
                return;
            }


            // Fetch model
            this.describeFeatureType(layerName).subscribe(
                (layerInfoResponce: any) => {
                    let layerPropertiesNotNormalized = layerInfoResponce.featureTypes[0].properties;

                    // Get workspaceLayer
                    let workspaceLayer = this.stateService.getWorkspaceLayerByName(layerName);

                    //Discover layer type by the_geom type
                    let geomProperty = this.getLayerPropertyByName(layerPropertiesNotNormalized, 'the_geom');

                    let layerProperties = this.removeLayerModelProperties(layerPropertiesNotNormalized);
                    layerProperties = this.normalizeLayerModelProperties(layerProperties, workspaceLayer);

                    // Get order
                    let fieldOrder = this.getKeyValue(workspaceLayer, 'field_order');
                    if (fieldOrder && fieldOrder.length > 0) {
                        const ordered = [];
                        const rest = [];

                        layerProperties.forEach((field: LayerField, index) => {
                            if (fieldOrder.includes(field.name)) {
                                ordered[fieldOrder.indexOf(field.name)] = field;
                            } else {
                                rest.push(field);
                            }
                        });
                        layerProperties = [...ordered, ...rest];
                    }

                    // Get is_edit
                    let isEditable = this.getKeyValue(workspaceLayer, 'is_edit');

                    // Get is_topology_edit
                    let isTopoEditable = this.getKeyValue(workspaceLayer, 'is_topology_edit');

                    //Get edit_actions if any
                    let edit_actions = this.getKeyValue(workspaceLayer, 'edit_actions');
                    if (!edit_actions) {
                        edit_actions = [];
                    }

                    //Get on edit snap to 
                    let on_edit_snap_to = this.getKeyValue(workspaceLayer, 'on_edit_snap_to');
                    if (!on_edit_snap_to) {
                        on_edit_snap_to = [];
                    }

                    //Get on edit split 
                    let on_edit_split = this.getKeyValue(workspaceLayer, 'on_edit_split');
                    if (!on_edit_split) {
                        on_edit_split = [];
                    }

                    // Get timeseries graph
                    let timeseriesGraph = this.getKeyValue(workspaceLayer, 'timeseries_graph');

                    // Get opacity
                    let opacity = this.getKeyValue(workspaceLayer, 'opacity');

                    // Get show on init
                    let showOnInit = this.getKeyValue(workspaceLayer, 'show');

                    // Get identify
                    let identifiable = this.getKeyValue(workspaceLayer, 'no_identify');

                    // Get open_form_on_identify
                    let openForm = this.getKeyValue(workspaceLayer, 'no_form_on_identify');

                    // Get snap always
                    let snapAlways = this.getKeyValue(workspaceLayer, 'snap_always');

                    // Get can upload csv
                    let uploadCsv = this.getKeyValue(workspaceLayer, 'can_upload_csv');

                    // Get open attribute table
                    let openAttrTable = this.getKeyValue(workspaceLayer, 'no_attribute_table');

                    // Get sensor layer
                    let sensorLayerType = this.getKeyValue(workspaceLayer, 'sensors_layer');

                    // Get table checkboxes visible
                    let checkboxesVisible = this.getKeyValue(workspaceLayer, 'checkboxes_visible');

                    // Get render chlorium
                    let renderChlorium = this.getKeyValue(workspaceLayer, 'render_chlorium');

                    // Can move to
                    let canMoveTo = this.getKeyValue(workspaceLayer, 'can_move_to');

                    // Render_as_wfs
                    let renderAsWfs = this.getKeyValue(workspaceLayer, 'render_as_wfs');

                    // Wfs icon
                    let wfsIcon = this.getKeyValue(workspaceLayer, 'wfs_icon');

                    // Open dashboard on click
                    let open_dashboard_item_on_select = this.getKeyValue(workspaceLayer, 'open_dashboard_item_on_select');

                    // Open from on vector feature
                    let onVectorClickSelectLocalFeature = this.getKeyValue(workspaceLayer, 'on_vector_click_select_local_feature');

                    // Scada config
                    let scada_config = this.getKeyValue(workspaceLayer, 'scada_config');

                    // Is epanet layer
                    let isEpanetLayer = this.getKeyValue(workspaceLayer, 'is_epanet_layer');

                    // Audits
                    let audits = this.getKeyValue(workspaceLayer, 'audits');

                    // Fetch style
                    // this.getStyle(layerName).then( (responce:any)=>{
                    // 	let format = new openlayers.format.XML();
                    // 	let sld = format.read(responce._body);
                    // 	console.log( sld );
                    // });

                    let enrichedWorspaceModel = {
                        ...workspaceLayer, ...{
                            Model: {
                                fields: layerProperties,
                                field_order: fieldOrder,
                                geometryType: geomProperty.type.substring(4, geomProperty.type.length),
                                edit_actions: edit_actions,
                                is_edit: isEditable,
                                is_topology_edit: isTopoEditable,
                                on_edit_snap_to: on_edit_snap_to,
                                on_edit_split: on_edit_split,
                                timeseries_graph: timeseriesGraph,
                                opacity: opacity,
                                show_on_init: showOnInit,
                                identifiable: !identifiable,
                                open_form_on_identify: !openForm,
                                snap_always: snapAlways,
                                can_upload_csv: uploadCsv,
                                hasAttributeTable: !openAttrTable,
                                sensorLayerType: sensorLayerType,
                                is_raster: false,
                                checkboxesVisible: checkboxesVisible,
                                renderChlorium: renderChlorium,
                                canMoveTo: canMoveTo,
                                renderAsWfs: renderAsWfs,
                                wfsIcon: wfsIcon,
                                openDashboardItemOnSelect: open_dashboard_item_on_select,
                                onVectorClickSelectLocalFeature: onVectorClickSelectLocalFeature,
                                scada_config: scada_config,
                                isEpanetLayer: isEpanetLayer,
                                audits: audits,
                                ecql_filter: null,
                                ecql_applied: false
                            }
                        }
                    };

                    StateService.stateStore.dispatch(this.stateService.setWorkspaceLayerOperation(enrichedWorspaceModel));
                    resolve(null);
                });

        });

        return modelPromise;
    }

    getLayerPropertyByName(layerProperties: Array<any>, propertyName: string) {

        for (let index = 0; index < layerProperties.length; index++) {
            if (layerProperties[index].name === 'the_geom' || layerProperties[index].type === 'gml:Geometry') {
                return layerProperties[index];
            }
        }

        return false;
    }

    removeLayerModelProperties(properties: Array<any>) {
        let layerProperties = [];

        for (let index = 0; index < properties.length; index++) {
            const currentProperty = properties[index];
            if (!formsOmitedFields.includes(currentProperty.name)) {
                layerProperties.push(currentProperty);
            }
        }

        return layerProperties;
    }

    normalizeLayerModelProperties(properties: Array<any>, workspaceLayer: WorkspaceLayer) {
        let layerProperties = [];

        for (let index = 0; index < properties.length; index++) {
            const currentProperty = properties[index];
            if (formsDisabledFields.includes(currentProperty.name)) {
                currentProperty.disabled = true;
            } else {
                currentProperty.disabled = false;
            }

            //Check for file type and change it
            if (currentProperty.name === "file" || currentProperty.name === "photo1" || currentProperty.name === "photo2") {
                currentProperty.localType = "file";
            }

            layerProperties.push(currentProperty);
        }

        // Normalize visibility
        let editableFields = this.getKeyValue(workspaceLayer, 'editable_fields');
        // Get aliases
        let aliases = this.getKeyValue(workspaceLayer, 'field_alias');

        // Get validations
        let validations = this.getKeyValue(workspaceLayer, 'field_validations');

        //Get domains
        let domains = this.getKeyValue(workspaceLayer, 'domains');

        for (let i = 0; i < layerProperties.length; i++) {

            if (editableFields && !editableFields.length) {
                console.log("Error in keyword in ", workspaceLayer);
            } else if (editableFields && editableFields.includes(layerProperties[i].name)) {
                layerProperties[i].editable = true;
                layerProperties[i].visible = true;
            } else if (formsOmitedFields.includes(layerProperties[i].name)) {
                layerProperties[i].editable = false;
                layerProperties[i].visible = false;
            } else {
                layerProperties[i].editable = false;
                layerProperties[i].visible = true;
            }

            // Check for alias
            if (aliases) {
                let aliasIndex = aliases[0].indexOf(layerProperties[i].name);
                if (aliasIndex >= 0) {
                    layerProperties[i].alias = aliases[1][aliasIndex];
                }
            }

            // Check for validations
            if (validations) {
                let validationIndex = validations[0].indexOf(layerProperties[i].name);
                if (validationIndex >= 0) {
                    layerProperties[i].validationString = validations[1][validationIndex].replaceAll('/', '\\');
                    layerProperties[i].validationMessage = validations[2][validationIndex];
                }
            }

            // Check for domain
            if (domains) {
                let fieldDomain = domains.filter(domain => domain.name === layerProperties[i].name);
                if (fieldDomain && fieldDomain.length > 0) {
                    layerProperties[i].domain = fieldDomain[0].values;
                }
            }

        }

        return layerProperties;
    }

    /**
     * GET FEATURE 
     * @param layer The workspace layer
     * @param outputProperties String array with feature properties
     * @param filter The ecql filter
     * @param count Results number(default 100)
     * @param startIndex Start from index
     * @param next Next page(boolean)
     * @param previous Previous page(boolean)
     * @param sortPropertyName Sort by property name
     * @param sortOrder Sort order(true=ASC|false=DESC)
     * @param setFeatureTableData Set the feature table data(boolean)
     * @param reload Reload same page(boolean)
     * @param bbox bbox Array<number>
     * @param setFeatureInfoData Set the feature table-multi data (boolean)
     * @param outputFormat Server response format. JSON by default, file otherwise
    */
    getFeature(layer: any, outputProperties: Array<string> = undefined,
        filter: string = undefined, count: number = 100, startIndex: number = 0,
        next: boolean = undefined, previous: boolean = undefined,
        sortPropertyName: string = undefined, sortOrder: boolean = undefined,
        setFeatureTableData: boolean = undefined, reload: boolean = undefined,
        bbox: Array<number> = undefined, setFeatureInfoData: boolean = undefined, outputFormat = 'application/json') {

        if (sortPropertyName) {
            layer = this.latestGetFeatureObject.layer;
            outputProperties = this.latestGetFeatureObject.outputProperties;
            filter = this.latestGetFeatureObject.filter;

            // Save the query for pagination reasons
            this.latestGetFeatureObject.startIndex = 0;
            this.latestGetFeatureObject.sortPropertyName = sortPropertyName;
            this.latestGetFeatureObject.sortOrder = sortOrder;

        } else if (reload) {

            if (this.latestGetFeatureObject) {
                layer = this.latestGetFeatureObject.layer;
                outputProperties = this.latestGetFeatureObject.outputProperties;
                filter = this.latestGetFeatureObject.filter;
                startIndex = this.latestGetFeatureObject.startIndex;
                sortPropertyName = this.latestGetFeatureObject.sortPropertyName;
                sortOrder = this.latestGetFeatureObject.sortOrder;
            }

        } else if (next) {

            layer = this.latestGetFeatureObject.layer;
            outputProperties = this.latestGetFeatureObject.outputProperties;
            filter = this.latestGetFeatureObject.filter;
            startIndex = this.latestGetFeatureObject.startIndex + count;
            sortPropertyName = this.latestGetFeatureObject.sortPropertyName;
            sortOrder = this.latestGetFeatureObject.sortOrder;

            this.latestGetFeatureObject.startIndex = startIndex;

        } else if (previous) {

            layer = this.latestGetFeatureObject.layer;
            outputProperties = this.latestGetFeatureObject.outputProperties;
            filter = this.latestGetFeatureObject.filter;
            startIndex = this.latestGetFeatureObject.startIndex - count;
            sortPropertyName = this.latestGetFeatureObject.sortPropertyName;
            sortOrder = this.latestGetFeatureObject.sortOrder;

            this.latestGetFeatureObject.startIndex = startIndex;

        } else {
            // Save the query for pagination reasons
            this.latestGetFeatureObject = {
                layer: layer,
                outputProperties: outputProperties,
                filter: filter,
                count: count,
                startIndex: startIndex,
                sortPropertyName: sortPropertyName,
                sortOrder: sortOrder
            };
        }

        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.set('service', 'wfs');
        httpParams = httpParams.set('version', '2.0.0');
        httpParams = httpParams.set('request', 'GetFeature');
        httpParams = httpParams.set('outputFormat', outputFormat);

        // Set the layer
        if (layer) {
            if (Array.isArray(layer)) {
                httpParams = httpParams.set('typename', layer.toString());
            } else {
                httpParams = httpParams.set('typename', layer.Name);
            }

        }

        // Set output properties
        if (outputProperties) {
            httpParams = httpParams.set('propertyname', outputProperties.join(','));
        }

        // Set the filter
        let combinedLayerFilter = filter ?? this.stateService.getCombinedLayerFilter(Array.isArray(layer) ? layer : [layer.Name]);
        if (!!bbox) {
            combinedLayerFilter = combinedLayerFilter.split(';')
                .map(layerFilter => `${layerFilter} AND bbox(the_geom,${bbox.toString()})`)
                .join(';');
        }

        if (combinedLayerFilter) {
            httpParams = httpParams.set('cql_filter', combinedLayerFilter);
        }

        if (sortPropertyName) {
            httpParams = httpParams.set('sortBy', sortPropertyName + ' ' + (sortOrder ? 'A' : 'D'));
        }

        // Pagination options
        if (count != -1) {
            httpParams = httpParams.set('count', '' + count);
        }

        httpParams = httpParams.set('startIndex', '' + startIndex);

        let getFeatureObservable;
        if (outputFormat == 'application/json') {
            getFeatureObservable = this.httpClient.get(this.gisServerUrl + 'wfs', {
                withCredentials: true,
                responseType: 'json',
                params: httpParams
            }).pipe(shareReplay());
        } else {
            getFeatureObservable = this.httpClient.get(this.gisServerUrl + 'wfs', {
                withCredentials: true,
                responseType: 'text' as const,
                params: httpParams
            }).pipe(shareReplay());
        }


        if (next || previous || reload || sortPropertyName) {
            getFeatureObservable.subscribe(
                (featuresResponce: any) => {
                    StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
                    let parsedData = this.parseFeatures(featuresResponce);
                    StateService.stateStore.dispatch(this.stateService.setFeatureTableData(parsedData, true, layer, featuresResponce.totalFeatures, startIndex, filter));
                }, error => {
                    StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
                });
        } else if (setFeatureTableData) {
            getFeatureObservable.subscribe(
                (featuresResponce: any) => {
                    StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
                    let parsedData = this.parseFeatures(featuresResponce);
                    StateService.stateStore.dispatch(this.stateService.setFeatureTableData(parsedData, true, layer, featuresResponce.totalFeatures, 0, filter));
                }, error => {
                    StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
                });
        } else if (setFeatureInfoData) {
            getFeatureObservable.subscribe(
                (featuresResponce: any) => {
                    StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
                    let parsedData = this.parseMultipleFeatures(this.parseFeatures(featuresResponce));
                    StateService.stateStore.dispatch(this.stateService.setFeatureInfoOperation(parsedData));
                }, error => {
                    StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
                });
        }

        return getFeatureObservable;
    }

    convertToFileAndExport(responce) {
        let dataType = responce.type;
        let binaryData = [];
        binaryData.push(responce);
        let url = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

        let a = document.createElement("a");
        document.body.appendChild(a);
        (a as any).style = "display: none";
        a.href = url;
        a.download = 'export.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    getFeaturePost(visibleLayers: any, filter: any) {

        // generate a GetFeature request
        let formatWFS = new WFS();
        const featureRequest = formatWFS.writeGetFeature({
            srsName: 'EPSG:2100',
            maxFeatures: 100,
            featureNS: '',
            featurePrefix: this.stateService.getWorkspace(),
            featureTypes: visibleLayers,
            outputFormat: 'application/json',
            filter: filter
            // filter: likeFilter('description', 'test')
            // andFilter(
            // 	likeFilter('on_mo', 'BLASSOPOULO*'),
            // 	// equalToFilter('waterway', 'riverbank')
            // )
        });

        // Trying to get the right responce but it seems that the drawedGeometry is not right
        return this.httpClient.post(this.gisServerUrl + 'wfs', new XMLSerializer().serializeToString(featureRequest), {
            withCredentials: true,
            responseType: 'json'
        }).pipe(shareReplay());

    }

    flattenWorkspaceLayers(workspaceLayers: Array<WorkspaceLayer>) {
        let flattenWorkspaceLayers = [];
        workspaceLayers.map((layer: any) => {
            if (layer.Layer) {
                flattenWorkspaceLayers = [...flattenWorkspaceLayers, ...layer.Layer];
            }
        });
        return flattenWorkspaceLayers;
    }

    parseFeatures(featuresResponce: any) {
        let format = new GeoJSON();
        let parsedFeatures = format.readFeatures(featuresResponce);

        return parsedFeatures;
    }

    parseMultipleFeatures(features: Array<Feature<Geometry>>, rasterLayer: WorkspaceLayer = null): Array<FeatureTableDataModel> {

        let featureInfoArray: Array<FeatureTableDataModel> = [];
        // Parse features into a map
        let featuresMap = new Map<string, Array<Feature<Geometry>>>();
        for (let index = 0; index < features.length; index++) {
            const feature: any = features[index];
            let featureId = rasterLayer ? 1 : feature.id_;
            let featureLayerName = rasterLayer ? rasterLayer.Name : featureId.split('.')[0];

            let featuresMapEntry = featuresMap.get(featureLayerName);
            if (featuresMapEntry) {
                featuresMapEntry.push(feature);
            } else {
                featuresMap.set(featureLayerName, [feature])
            }
        }

        // For each map entry get layer model
        featuresMap.forEach((featureArray, layerName) => {
            // Get layer model
            let layerModel = rasterLayer ? rasterLayer : this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':' + layerName);

            let newFeatureTableDataModel: FeatureTableDataModel = {
                timestamp: Date.now(),
                features: featureArray,
                selectedLayer: layerModel,
                totalFeatures: featureArray.length,
                eventFrom: 1
            };

            if (newFeatureTableDataModel.totalFeatures === 1) {
                newFeatureTableDataModel.eventFrom = selectEventSource.map;
            }

            // Push to feature table array
            featureInfoArray.push(newFeatureTableDataModel);
        });

        return featureInfoArray;
    }

    parseXML(responce: any) {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(responce, "application/xml");
        let coverageField = xmlDoc.getElementsByTagName("wcs:Key")[0].childNodes[0].nodeValue;

        return coverageField;
    }

    /**
     * GET FEATURE 
     * @param layer 1.
     * @param bbox 2.
    */
    getFeaturesBbox(layers: Array<string>, bbox: any) {

        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.set('service', 'wfs');
        httpParams = httpParams.set('version', '2.0.0');
        httpParams = httpParams.set('request', 'GetFeature');
        httpParams = httpParams.set('outputFormat', 'application/json');
        httpParams = httpParams.set('typename', layers.toString());


        // Set the filter
        if (bbox) {
            httpParams = httpParams.set('bbox', bbox);
        }

        // Pagination options
        // urlSearchParams.append('count', '' + count);

        let getFeatureObservable = this.httpClient.get(this.gisServerUrl + 'wfs', {
            withCredentials: true,
            responseType: 'json',
            params: httpParams
        });

        return getFeatureObservable;
    }

    //(http://docs.geoserver.org/stable/en/user/filter/ecql_reference.html#filter-ecql-reference)
    ecqlQuery(leftExpression, rightExpression, predicate, temporalPredicate, spatialPredicate) {

    }

    /**
     * Fetch layer style
     * @param layer The Workspace layer
     */
    // getStyle(layerName: string) {

    // 	let urlSearchParams: URLSearchParams = new URLSearchParams();
    // 	urlSearchParams.append('service', 'wms');
    // 	urlSearchParams.append('version', '1.1.1');
    // 	urlSearchParams.append('request', 'GetStyles');

    // 	if (layerName) {
    // 		urlSearchParams.append('layers', layerName);
    // 	}

    // 	let requestOptions = new RequestOptions({
    // 		withCredentials: true
    // 	});

    // 	return this.http.post(this.gisServerUrl + 'wms', urlSearchParams, requestOptions).toPromise();
    // }

    /*
     * Returns the value if a keyword exists and is of the form "key:value"
     * else returns true if the keyword exists but it is of the form "key"
     * else returns false if the keyword does not exist in the keyword list 
    */
    getKeyValue(layer, keyword): any {

        for (const currentKeyword of layer.KeywordList) {

            if (currentKeyword.includes(keyword)) {

                let isSplitted = currentKeyword.split(keyword + ':');

                if (isSplitted.length === 2) { // This is a key:value keyword
                    let keywordValue = isSplitted[1];

                    //Try to parse Json
                    try {
                        let parsedKeyword = JSON.parse(keywordValue);
                        return parsedKeyword;
                    } catch {
                        return keywordValue;
                    }
                } else { // This is a plain keyword
                    return true;
                }

            }

        }
        return false;
    }

    getAllLayersWithKeyword(layerCollection: Array<WorkspaceLayer>, keyword: string) {
        let layerNames = [];
        layerCollection.forEach(layer => {
            if (layer.Model && layer.Model.snap_always) {
                layerNames.push(layer.Name.split(':')[1]);
            }
        });

        return layerNames;
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    /**
     * Generate style
     * @param featureType point,line,polygon
     * @param shape if point rectangle,triangle,hexagon,circle
     * @param fillColor fill color
     * @param strokeColor stroke color
     * @param strokeWidth stroke width
     * @param radius radius
     */
    generateVectorStyle(featureType, shape: string, fillColor: string, strokeColor: string, strokeWidth: number = 2, radius: number = 5, nullStyle: boolean = false) {

        let style;
        let fill = new Fill({ color: fillColor });
        let stroke = new Stroke({ color: strokeColor, width: strokeWidth });

        if (featureType === "point") {

            if (shape === "rectangle") {

                style = new Style({
                    image: new RegularShape({
                        fill: fill,
                        stroke: stroke,
                        points: 4,
                        radius: radius,
                        angle: Math.PI / 2
                    })
                });

            } else if (shape === "triangle") {

                style = new Style({
                    image: new RegularShape({
                        fill: fill,
                        stroke: stroke,
                        points: 3,
                        radius: radius,
                        rotation: Math.PI / 4,
                        angle: 0
                    })
                });

            } else if (shape === "hexagon") {

                style = new Style({
                    image: new RegularShape({
                        fill: fill,
                        stroke: stroke,
                        points: 6,
                        radius: radius,
                        // rotation: Math.PI / 4,
                        // angle: 0
                    })
                });

            } else if (shape === "circle") {

                style = new Style({
                    image: new Circle({
                        radius: radius,
                        stroke: stroke,
                        fill: fill
                    })
                });

            } else if (shape === "star") {

                style = new Style({
                    image: new RegularShape({
                        fill: fill,
                        stroke: stroke,
                        points: 5,
                        radius: radius,
                        radius2: 4,
                        angle: 0
                    })
                });
            }

        } else if (featureType === "line") {

            style = new Style({
                image: new Circle({
                    radius: radius,
                    stroke: stroke,
                    fill: fill
                }),
                fill: fill,
                stroke: stroke
            });

        } else if (featureType === "polygon") {
            if (nullStyle) {
                style = new Style({})
            } else {
                style = new Style({
                    fill: fill,
                    stroke: stroke
                });
            }

        }

        return style;
    }

}
