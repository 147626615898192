import { Component, OnInit, Input, OnChanges, HostListener, SimpleChanges, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LayerModel, LayerField, WorkspaceLayer, TableActionObject, NavButtons } from '../../../models';
import { SortEvent } from '../../../models';
import { LayoutService } from 'app/core/layout.service';
import { FormDataService } from 'app/shared/services/form-data.service';
import { SelectionModel } from '@angular/cdk/collections';
import { StateService } from 'app/core/state.service';
import { SnackBarService } from 'app/core/snack-bar.service';
import { LOCALE_ID, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { Feature } from 'ol';

declare type HorizontalRestorePoint = {
  columnName: string;
  columnLeftPos: number;
}

@Component({
  selector: 'mr-table',
  templateUrl: './mr-table.component.html',
  styleUrls: ['./mr-table.component.scss']
})
export class MrTableComponent implements OnInit, OnChanges {
  @Input() features: Feature[]; // features array
  @Input() dataModel: LayerModel;
  @Input() selectedLayer: WorkspaceLayer;
  @Input() tableTitle: any;
  @Input() dataLength: number;
  @Input() dataPageSize: number = 100; // Size of paginator page data
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100]; // Size of paginator page data
  @Input() uniqueKey: string = 'id'; // This is the object key that identifies uniquely each feature row //TODO: delete (maybe)
  @Input() multiTable = false;
  @Input() showRowSelectionCheckbox: boolean = false;
  @Input() lastSelection: Array<Feature> = [];
  @Input() dashboardMode: boolean = false;

  @Output() tableControlAction: EventEmitter<TableActionObject> = new EventEmitter();
  @Output() pageChange: EventEmitter<PageEvent | undefined> = new EventEmitter();
  @Output() sortChange: EventEmitter<SortEvent> = new EventEmitter();
  @Output() rowSelect: EventEmitter<Array<Feature>> = new EventEmitter(true);
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('matTableContainer', { static: true }) matTableContainer: ElementRef;

  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public tableHeaders: Array<string>;
  public displayedColumns: Array<string>;
  public ctrlDown: boolean = false;
  public shiftDown: boolean = false;
  public isExpanded: boolean = false;
  public availableLayers: WorkspaceLayer[] = []; // Being used for move operation
  public listSelection = new SelectionModel<any>(true, []); // Used when we have the row selection checkbox
  public showFilterButton = false;

  protected DUMMY_ROW: any = {}
  protected previouslySelectedRow: any = {};
  protected selectedRows: Array<number | string> = [];
  protected rawdata = [];

  private horizontalRestorePoint: HorizontalRestorePoint;
  private abortController: AbortController = new AbortController();
  private destroy$: Subject<void> = new Subject();

  constructor(
    private snackBarService: SnackBarService,
    private layoutService: LayoutService,
    private formDataService: FormDataService,
    private stateService: StateService,
    @Inject(LOCALE_ID) protected localeId: string,
  ) {
    this.layoutService.layoutChange$.pipe(
      takeUntil(this.destroy$),
      delay(100)
    ).subscribe(() => this.calculateHorizontalScrollRestorePoint());
  }

  @HostListener('document:keydown', ['$event'])
  keyEventDown(event: KeyboardEvent) {
    if (!this.ctrlDown && event.key === "Control") {
      this.ctrlDown = true;
    } else if (!this.shiftDown && event.key === "Shift") {
      this.shiftDown = true;
    }
  }

  @HostListener('document:keyup', ['$event'])
  keyEventUp(event: KeyboardEvent) {
    if (event.key === "Control") {
      this.ctrlDown = false;
    } else if (event.key === "Shift") {
      this.shiftDown = false;
    }
  }

  ngOnInit() {
    this.registerTableScrollListener();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.selectedLayer) {
      const stateLayer = this.stateService.getWorkspaceLayerByName(this.selectedLayer.Name);
      this.showFilterButton = !!stateLayer?.Model.ecql_filter && stateLayer?.Model.ecql_applied;
    }
    if (!changes['features'] || !this.features?.length) {
      return;
    }

    // Get table-readable data object list from features list
    if (!this.features[0]['properties']) {
      this.rawdata = this.features.map(f => {
        const props = this.formDataService.removeGeometryFromFeature(f);
        return props;
      }
      );
    }
    else {
      this.rawdata = this.features.map(f => ({
        ...f['properties'],
        _mrTableID_: f[this.uniqueKey]
      })
      );
    }

    // Always clear selection on features change
    this.clearSelection();

    // If layer/model hasn't changed then it means that we have a table data update due to e.g. page change, sorting page size change etc..
    if (!this.hasDataModelChanged(changes)) {
      this.restoreHorizontalScrollPos();
      this.dataSource.data = this.rawdata;

      if (this.rawdata.length != this.dataPageSize && this.paginator.hasNextPage() && !this.multiTable) {
        this.paginator.pageIndex = 0;
        this.dataPageSize = this.rawdata.length;
        this.paginator.firstPage();
      } else {
        this.dataPageSize = this.paginator.pageSize;
      }

      // Try to restore last selected features
      this.restoreLastSelection();
    } else {
      this.applyFieldAliases(this.selectedLayer, this.rawdata);
      // ===============================================================================================================
      // WARNING: Pagination setup must come always BEFORE data source assignment
      // Otherwise the table is rendering ALL the rows first and THEN applies pages which has a huge performance hit
      this.setPagination();
      this.dataSource.data = this.rawdata;
      // ===============================================================================================================
      this.tableHeaders = this.dataModel.fields.map((f: LayerField) => f.alias || f.name);
      this.displayedColumns = this.dataModel.fields.map((f: LayerField) => f.name);
      if (this.showRowSelectionCheckbox && this.selectedLayer?.Model.checkboxesVisible) {
        this.displayedColumns.unshift('select');
      }
      this.paginator.firstPage();
      this.matTableContainer.nativeElement.scrollTo({ top: 0, left: 0 });

      if (this.rawdata.length > this.dataPageSize) {
        this.dataPageSize = this.rawdata.length;
      } else {
        this.dataPageSize = 100;
      }
    }
  }

  ngOnDestroy() {
    this.abortController.abort();
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  hasElevationField(): boolean {
    if (this.dataModel && this.dataModel.fields) {
      return this.dataModel.fields.some(field => field.name === 'elevation' && field.editable);
    }
    return false;
  }

  public setPagination() {
    // Override this in child classes
  }

  // Returns true if the table data source has changed (i.e. different layer/model)
  private hasDataModelChanged(changes: SimpleChanges) {
    // Extract the layer name from the features list. For example the format is: 'hydrometers.214'
    // Previous value might be undefined so we map this to an empty string
    const previousLayerName = changes['features']?.previousValue?.length ? changes['features'].previousValue[0]['id_'].split('.')[0] : '';
    const currentLayerName = changes['features'].currentValue[0]['id_'].split('.')[0];
    return (
      changes['features'].firstChange ||
      changes['features'].previousValue?.length === 0 ||
      previousLayerName !== currentLayerName
    );
  }

  public applyFieldAliases(selectedLayer: WorkspaceLayer, data: Array<any>) {
    let layerFields = selectedLayer.Model.fields;

    //fields with domain
    let domainLayerFields = layerFields.filter(field => !!field.domain);

    for (let i = 0, n = data.length; i < n; i++) {
      for (let j = 0, k = domainLayerFields.length; j < k; j++) {
        let domain = domainLayerFields[j]
          .domain
          .find(domainIn => domainIn.name == data[i][domainLayerFields[j].name]);
        if (!!domain) {
          data[i][domainLayerFields[j].name] = domain.alias;
        }
      }
    }

  }


  /**
   * ========= Move action ============
   * Fill available (destination) layers from this.selectedLayer->canMoveTo array
   * This function is called in mr-table.component.html on user move button click
   */
  public availableDestinationLayers() {
    for (let index in this.selectedLayer.Model.canMoveTo) {
      this.availableLayers[index] = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':' + this.selectedLayer.Model.canMoveTo[index]);
    }
  }

  private toggleExpansion() {
    this.isExpanded = !this.isExpanded;
  }

  public handleTableAction(action: string, destinationWorkspaceLayer: WorkspaceLayer = null) {
    let actionControl: TableActionObject = {};

    switch (action) {
      case 'show': {
        actionControl.type = 'show';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'clear': {
        actionControl.type = 'clear';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'zoom': {
        actionControl.type = 'zoom';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'resize': {
        actionControl.type = 'resize';
        this.tableControlAction.emit(actionControl);
        this.toggleExpansion();
        break;
      }
      case 'close': {
        actionControl.type = 'close';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'delete': {
        if (this.selectedRows.length == 0) {
          this.snackBarService.setMessage($localize`Παρακαλώ επιλέξτε τουλάχιστον ένα στοιχείο προς διαγραφή`, 5000);
          return;
        }
        actionControl.type = 'delete';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'calculate': {
        actionControl.type = 'calculate';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'export': {
        actionControl.type = 'export';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'import': {
        actionControl.type = 'import';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'lineLayerLength': {
        actionControl.type = 'lineLayerLength';
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'move': {
        if (this.selectedRows.length == 0) {
          this.snackBarService.setMessage($localize`Παρακαλώ επιλέξτε τουλάχιστον ένα στοιχείο προς μεταφορά`, 5000);
          return;
        }
        actionControl.type = 'move';
        actionControl.features = this.getSelectedFeaturesFromSelectedRows();
        actionControl.destinationLayer = destinationWorkspaceLayer;
        let sameLayers = this.layersHaveSameFields(this.selectedLayer, actionControl.destinationLayer);
        if (!sameLayers) {
          this.snackBarService.setMessage(this.localeId === 'en' ? "Transfer is not allowed between layers where they have a different type of field." : "Η μεταφορά δεν επιτρέπεται μεταξύ επιπέδων όπου έχουν διαφορετικό τύπο πεδίων.", 4000);
          return;
        }
        this.tableControlAction.emit(actionControl);
        break;
      }
      case 'filter': {
        StateService.stateStore.dispatch(this.stateService.setLayerFilterNameOperation(this.selectedLayer.Name));
        this.layoutService.setSideNavContent(NavButtons.filters);
      }
      default: {
        break;
      }
    }
  }

  /**
   * Checks if fields(type,name,length) are same between src and dest layer
   * @param srcLayer
   * @param destLayer
   * @returns true if fields are same
   */
  private layersHaveSameFields(srcLayer: WorkspaceLayer, destLayer: WorkspaceLayer) {
    if (srcLayer.Model.fields.length != destLayer.Model.fields.length) {
      return false;
    }
    for (let index in srcLayer.Model.fields) {
      let SameName = destLayer.Model.fields.find(({ name }) => name === srcLayer.Model.fields[index].name);
      let SameType = destLayer.Model.fields.find(({ type }) => type === srcLayer.Model.fields[index].type);
      if (!SameName || !SameType) {
        return false;
      }
    }
    return true;
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pageChange.emit(pageEvent);
  }

  private isAllSelected() {
    const numSelected = this.listSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  private clearSelection() {
    this.listSelection.clear();
    this.selectedRows = [];
    this.previouslySelectedRow = this.DUMMY_ROW;
  }

  private selectAll() {
    this.dataSource.data.forEach(row => {
      if (!this.selectedRows.includes(row.id)) {
        this.listSelection.select(row);
        this.selectedRows.push(row.id);
      }
    });
  }

  protected getSelectedFeaturesFromSelectedRows(): Feature[] {
    return this.features.filter(feature => this.selectedRows.includes(feature['values_'][this.uniqueKey]))
  }

  public onSelectAllRowsChange() {
    this.isAllSelected() ? this.clearSelection() : this.selectAll();
    this.rowSelect.emit(this.getSelectedFeaturesFromSelectedRows());
  }

  private handleSingleSelectWhileInSelectionMode(row) {
    if (this.selectedRows.includes(row.id)) {
      this.selectedRows.splice(this.selectedRows.indexOf(row.id), 1);
      // this.selectedFeatures = this.selectedFeatures.filter(feature => feature.values_.id !== row.id);
    } else {
      this.selectedRows.push(row.id);
      // this.selectedFeatures.push(this.featuresMap.get(row.id));
      this.previouslySelectedRow = row;
    }
    this.listSelection.toggle(row);
  }

  public onRowSelect(row: any) {
    if (this.ctrlDown && !this.showRowSelectionCheckbox) {
      // deselect row if already selected
      if (this.selectedRows.includes(row.id)) {
        this.selectedRows = this.selectedRows.filter(rowId => rowId !== row.id);
      }
      else {
        // Always create new array to force change detection on the `rowSelected` pipe
        this.selectedRows = [...this.selectedRows, row.id];
      }
    }	else if (this.shiftDown) {
      const previousIndex = this.rawdata.findIndex(r => r.id === this.previouslySelectedRow.id);
      const currentIndex = this.rawdata.findIndex(r => r.id === row.id);
      const selectStartIndex = previousIndex > currentIndex ? currentIndex : previousIndex;
      const selectEndIndex = selectStartIndex === previousIndex ? currentIndex : previousIndex;
      for (let i = selectStartIndex; i <= selectEndIndex; i++) {
        if (this.selectedRows.indexOf(this.rawdata[i].id) === -1) {
          this.selectedRows = [...this.selectedRows, this.rawdata[i].id];
          if (this.showRowSelectionCheckbox) {
            this.listSelection.toggle(this.rawdata[i]);
          }
        }
      }
    } else if (this.showRowSelectionCheckbox) {
      this.handleSingleSelectWhileInSelectionMode(row);
    } else {
      this.selectedRows = [row.id];
      this.previouslySelectedRow = row;
    }
    // Save selected features in state despite checkboxVisibility
    this.rowSelect.emit(this.getSelectedFeaturesFromSelectedRows());
  }

  protected restoreLastSelection() {
    let lastSelectedRows = this.rawdata.filter(
      row => this.lastSelection.some(
        selection => row.id === selection['values_'][this.uniqueKey]
      )
    );
    // If all previously selected rows exist on the current table page
    // then re-select them and scroll into the 1st visible one
    if (this.lastSelection.length > 0 && lastSelectedRows.length === this.lastSelection.length) {
      lastSelectedRows.forEach(row => {
        this.listSelection.toggle(row);
        this.selectedRows = [...this.selectedRows, row.id];
      });
      this.rowSelect.emit(this.getSelectedFeaturesFromSelectedRows());
      setTimeout(() => (this.matTableContainer.nativeElement as HTMLDivElement).querySelector(`.selected-row`)?.scrollIntoView());
      return;
    }
    setTimeout(() =>(this.matTableContainer.nativeElement as HTMLDivElement).scrollTo({top: 0}));
  }

  onSort(sort: Sort) {
    let direction = undefined;
    if (sort.direction === 'asc') {
      direction = true;
    } else if (sort.direction === 'desc') {
      direction = false;
    }

    this.calculateHorizontalScrollRestorePoint(sort.active);

    this.sortChange.emit({
      sortBy: sort.active,
      sortOrder: direction
    });
  }

  registerTableScrollListener() {
    const scrollEventSupported = 'onscrollend' in window ? 'scrollend' : 'scroll';
    (this.matTableContainer.nativeElement as HTMLDivElement).addEventListener(
      scrollEventSupported,
      () => this.calculateHorizontalScrollRestorePoint(),
      {
        signal: this.abortController.signal
      }
    );
  }

  calculateHorizontalScrollRestorePoint(sortColumnName?: string) {
    if (!this.matTableContainer?.nativeElement) {
      return;
    }
    const tableLeftPos = (this.matTableContainer.nativeElement as HTMLDivElement).getBoundingClientRect().left;
    // Find the first column that's visible on the left edge of the table container 
    // or the column that user selected to sort the table with and keep its name and its left position
    for (const columnName of this.displayedColumns) {
      const columnLeftPos = (this.matTableContainer.nativeElement.querySelector(`td.cdk-column-${sortColumnName ?? columnName}`) as HTMLTableCellElement)?.getBoundingClientRect().left ?? 0;
      if (!!sortColumnName || (columnLeftPos > tableLeftPos && columnLeftPos - tableLeftPos > 0)) {
        this.horizontalRestorePoint = {
          columnLeftPos: columnLeftPos,
          columnName: sortColumnName ?? columnName
        };
        break;
      }
    }
  }

  restoreHorizontalScrollPos() {
    setTimeout(() => {
      if (this.horizontalRestorePoint?.columnName) {
        // Find the the referenced table column element
        const referenceColumn = this.matTableContainer.nativeElement.querySelector(`td.cdk-column-${this.horizontalRestorePoint.columnName}`) as HTMLTableCellElement;
        if (referenceColumn) {
            // Scroll the container by the remainder of the previous to the current left position of the reference column
            (this.matTableContainer.nativeElement as HTMLDivElement).scrollBy({left: referenceColumn.getBoundingClientRect().left - this.horizontalRestorePoint.columnLeftPos});
        }
      }
    }, 1);
  }

  myTrackById(index: number, featureRow: any) {
    return featureRow?.id ?? index;
  }
}
