// Angular imports
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// App imports
import { StateService } from '../../core/state.service';
import { MapService } from '../map.service';
import { GeoserverService } from '../../core/geoserver.service';
import { ActiveScreenManagementService } from '../../core/active-screen-management.service';
import { OPERATIONS, EDITOR_MODES, ZONE_EDIT_ACTIONS } from '../../models';
import { WorkspaceLayer, FormOperation, FormTransaction, EDIT_ACTIONS, coordinate } from '../../models';
import { ModelOperationsService } from '../../core/model-operations.service';
import { EditorControlsComponent } from 'app/shared/editor-controls/editor-controls.component';
import { ZoneEditorComponent } from 'app/shared/zone-editor/zone-editor.component';

// Openlayers imports
import Map from 'ol/Map';
// import Sphere from 'ol/Sphere.js';
import { Select, Modify, Snap, Draw } from 'ol/interaction.js';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { click, shiftKeyOnly } from 'ol/events/condition';
import Collection from 'ol/Collection';
import { LineString, Point, MultiLineString, Polygon, MultiPolygon, MultiPoint } from 'ol/geom';
import * as PolygonModule from 'ol/geom/Polygon.js';
import LinearRing from 'ol/geom/LinearRing';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/Geometry';

// Vendor imports
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';
import { EditingStateService } from 'app/shared/services/editing-state.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
	selector: 'app-editor',
	templateUrl: './editor.component.html',
	styleUrls: ['./editor.component.scss'],
	host: {
		'(document:keydown)': 'handleKeyboardEvent($event)'
	}
})
export class EditorComponent implements OnInit, OnDestroy {

	private initialized: boolean;
	private stateUnsubscription: any;
	public selectedLayer: WorkspaceLayer;
	public workspaceLayers: Array<any>;
	public currentEditedlayerModel: Object;
	public currentEditedlayerType: string;
	private latestTimestamp: number;
	private map: Map;
	private selectInteraction: Select;
	private modifyInteraction: Modify;
	private drawInteraction: Draw;
	private snapInteraction: Snap;
	private bufferSnapToEditInteraction: Snap;
	public currentEditedVectorLayer: VectorLayer<any>;
	public currentEditorMode: EDITOR_MODES;
	public selectedFeature: Feature<Geometry>;
	private selectedFeatureProperties: Array<any>;
	public isEditing: boolean;
	public isDrawing: boolean;
	public canSplit: boolean;
	private editingSnapInteractions: Array<Snap>;
	private snapAlwaysWfsLayers: Array<VectorLayer<any>>;
	private destroy$: Subject<void> = new Subject();
	public activeOperation: OPERATIONS;

	// Buffers
	private drawBufferInteraction: Draw;
	private snapBufferInteraction: Snap;
	private drawBufferLayerSource: VectorSource<Geometry>;
	private drawBufferLayer: VectorLayer<any>;
	private drawIntersectionsInteraction: Snap;
	@ViewChild('bufferRadius') bufferRadius: ElementRef;
	@ViewChild('editorControls') editorControls: EditorControlsComponent;
	@ViewChild('zoneEditor') zoneEditorControls: ZoneEditorComponent;

	constructor(private geoserverService: GeoserverService, private mapService: MapService,
		private stateService: StateService,
		private asmService: ActiveScreenManagementService, private modelOperationsService: ModelOperationsService,
		private editingStateService: EditingStateService) {

		this.workspaceLayers = [];
		this.selectedLayer = undefined;
		this.selectedFeature = null;
		this.selectedFeatureProperties = [];
		this.initialized = false;
		this.currentEditedVectorLayer = undefined;
		this.currentEditorMode = EDITOR_MODES.stop;
		this.isEditing = false;
		this.editingSnapInteractions = [];
		this.snapAlwaysWfsLayers = [];
	}

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.updateFromState();

		this.editingStateService.layerToDraw$.pipe(takeUntil(this.destroy$)).subscribe(layer => {
			if (!!layer) {
				this.onLayerSelect(layer);
				this.onUserAction(EDIT_ACTIONS.create);
			} else {
				if (this.currentEditorMode === EDITOR_MODES.draw) {
					this.currentEditedVectorLayer.getSource().getFeatures().forEach( (feature)=>{
						if(!feature.id_){
							this.currentEditedVectorLayer.getSource().removeFeature(feature);
						}						
					});
				}
			}
		});

		this.editingStateService.layerToEdit$.pipe(takeUntil(this.destroy$)).subscribe(layer => {
			if (!!layer) {
				this.onLayerSelect(layer);
				this.onUserAction(EDIT_ACTIONS.update);
			} else {
				if (this.currentEditorMode === EDITOR_MODES.edit) {
					// this.stopEditing();
				}
			}
		});
	}

	ngOnDestroy() {
		this.stateUnsubscription();
		this.stopDrawing();
		this.stopEditing();
		this.destroy$.next();
		this.destroy$.complete();
		this.removeBufferLayer();
	}

	stop() {
		this.isEditing = false;
		switch (this.currentEditorMode) {
			case EDITOR_MODES.draw:
				this.stopDrawing();
				break;
			case EDITOR_MODES.edit:
				this.stopEditing();
				break;
			case EDITOR_MODES.delete:
				this.stopDeleting();
				break;
			default:
				break;
		}

	}

	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key == "Escape") {
			this.stop();
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
		}
	}

	updateFromState() {

		if (!this.initialized && this.mapService.getMap() && this.stateService.getState().workspaceModelsLoaded) {
			this.map = this.mapService.getMap();
			let workspaceLayers = this.stateService.getWorkspaceLayers();
			// let editState = this.stateService.getEdit();

			workspaceLayers = this.flattenWorkspaceLayers(this.asmService.filterLayersByScreen(workspaceLayers));//, editState.groupName


			if (workspaceLayers && workspaceLayers.length > 0 && this.workspaceLayers.length === 0) {

				for (let index = 0; index < workspaceLayers.length; index++) {
					const layer = workspaceLayers[index];
					if (this.layerContainsKeyword(layer, 'is_view')) {
						continue;
					} else {//if (this.layerContainsKeyword(layer, 'is_edit'))
						this.workspaceLayers.push(layer);
					}
				}

				this.initialized = true;
				// this.stateUnsubscription();
				this.initializeBufferVectorLayer();
			}
		}

		//Operations actions
		this.activeOperation = this.stateService.getActiveOperation().operation;
		if (this.activeOperation != OPERATIONS.editor_draw && this.currentEditorMode === EDITOR_MODES.draw) {
			this.stop();
		}

		if (this.activeOperation != OPERATIONS.editor_edit && this.currentEditorMode === EDITOR_MODES.edit) {
			this.stop();
		}

		if (this.activeOperation != OPERATIONS.editor_delete && this.currentEditorMode === EDITOR_MODES.delete) {
			this.stop();
		}

	}

	flattenWorkspaceLayers(workspaceLayers: Array<WorkspaceLayer>) {
		let flattenWorkspaceLayers = [];
		workspaceLayers.map((layer) => {
			if (layer.Layer) {
				flattenWorkspaceLayers = [...flattenWorkspaceLayers, ...layer.Layer];
			} else {
				flattenWorkspaceLayers.push(layer);
			}
		});

		return flattenWorkspaceLayers;
	}

	layerContainsKeyword(layer: WorkspaceLayer, keyword: string) {

		for (let i = 0; i < layer.KeywordList.length; i++) {
			if (layer.KeywordList[i].includes(keyword)) {
				return true;
			}
		}

		return false;
	}

	/**
	*  SELECT LAYER
	*/
	onLayerSelect(layer) {
		let me = this;
		this.selectedLayer = layer;
		this.selectedFeature = null;
		// if (!!layer) {
		// 	this.canSplit = this.selectedLayer.Model.on_edit_split !== undefined;
		// }
	}

	arrayToObject(array) {
		return array.reduce(function (accumulator, currentProperty, currentIndex) {
			accumulator[currentProperty.name] = '';
			return accumulator;
		}, {});
	}

	// ====================================
	// 1. DRAWING
	// ====================================
	startDrawing(updateState: boolean = true) {
		this.isEditing = true;
		this.currentEditorMode = EDITOR_MODES.draw;
		// 1. Add the Vector Layer
		this.currentEditedVectorLayer = this.mapService.toggleWFSService(this.selectedLayer, this.mapService.getMap().getLayers().length, true);
		this.stopDrawBuffer(false);

		this.map = this.mapService.getMap();
		this.addDrawInteraction();
		this.addSnapInteractions();

		if (updateState) {
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.editor_draw));
		}
	}

	stopDrawing(updateState: boolean = true) {

		this.currentEditorMode = EDITOR_MODES.stop;
		this.selectedFeature = null;
		this.removeDrawInteraction();
		this.removeSnapInteractions();
		this.drawBufferLayerSource.clear();

		if (this.selectedLayer) {
			this.mapService.toggleWFSService(this.selectedLayer, undefined, false);
		}

		// if (updateState) {
		// 	StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
		// }

	}

	addDrawInteraction() {
		let me = this;
		let currentlyEditedLayerSource = this.currentEditedVectorLayer.getSource();

		this.drawInteraction = new Draw({
			source: currentlyEditedLayerSource,

			type: me.selectedLayer.Model.geometryType,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)'
				}),
				stroke: new Stroke({
					color: 'rgba(0, 0, 0, 0.5)',
					lineDash: [10, 10],
					width: 2
				}),
				image: new Circle({
					radius: 5,
					stroke: new Stroke({
						color: 'rgba(0, 0, 0, 0.7)'
					}),
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.2)'
					})
				})
			})
		});

		this.drawInteraction.on('drawend', (event:any) => {
			me.selectedFeature = event.feature;
			// 1. Create a form transaction
			let editFormOperation: FormOperation = {
				layer: this.selectedLayer,
				layerModel: this.selectedLayer.Model,
				feature: me.selectedFeature,
				formHeader: 'Εισαγωγή στοιχείου',
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.create
			}

			// 2. Create transaction
			let editTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [editFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(editTransaction));
		});

		this.map.addInteraction(this.drawInteraction);
	}

	removeDrawInteraction() {
		this.map.removeInteraction(this.drawInteraction);
		this.selectedFeature = null;
		// this.mapService.selectInteraction = null
	}

	onAddCoordinate(coordinate: coordinate) {
		if (this.drawInteraction) {
			if( (this.drawInteraction as any).type_==="MultiPoint"){				
				(this.drawInteraction as any).startDrawing_([coordinate.lat, coordinate.lon]);
				this.drawInteraction.finishDrawing();
			}else{
				this.drawInteraction.appendCoordinates([[coordinate.lat, coordinate.lon]]);
			}
			
		}
	}

	// ====================================
	// 2. EDITING
	// ====================================
	startEditing(updateState: boolean = true) {
		this.isEditing = true;
		this.currentEditorMode = EDITOR_MODES.edit;

		// 1. Add the Vector Layer
		this.currentEditedVectorLayer = this.mapService.toggleWFSService(this.selectedLayer, this.mapService.getMap().getLayers().length, true);
		this.stopDrawBuffer(false);

		this.map = this.mapService.getMap();
		this.addSelectInteraction();
		this.addModifyInteraction();
		this.addSnapInteractions();

		if (updateState) {
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.editor_edit));
		}
	}

	stopEditing(updateState: boolean = true) {
		this.currentEditorMode = EDITOR_MODES.stop;
		this.removeSelectInteraction();
		this.removeModifyInteraction();
		this.removeSnapInteractions();
		this.selectedFeature = null;
		this.drawBufferLayerSource.clear();

		if (this.selectedLayer) {
			this.mapService.toggleWFSService(this.selectedLayer, undefined, false);
		}
		// if (updateState) {
		// 	StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
		// }
	}

	addSelectInteraction() {
		let me = this;

		this.selectInteraction = new Select({
			condition: click,
			// wrapX           : false,
			toggleCondition: shiftKeyOnly,

			layers: layer => {
				return layer.get('name') == me.selectedLayer.Name + '_edit';
			},
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.8)'
				}),
				stroke: new Stroke({
					color: '#e040fb',
					width: 3
				}),
				image: new Circle({
					radius: 7,
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.8)'
					}),
					stroke: new Stroke({
						color: '#e040fb',
						width: 3
					})
				})
			})
		});

		this.mapService.selectInteraction = this.selectInteraction;

		this.selectInteraction.on('select', (e) => {
			me.selectedFeature = e.target.getFeatures().getArray()[0];

			// 1. Create a form transaction
			let editFormOperation: FormOperation = {
				layer: this.selectedLayer,
				layerModel: this.selectedLayer.Model,
				feature: me.selectedFeature,
				formHeader: 'Επεξεργασία στοιχείου',
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.update
			}

			// 2. Create transaction
			let editTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [editFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(editTransaction));

		});

		this.map.addInteraction(this.selectInteraction);
	}

	removeSelectInteraction() {
		this.map.removeInteraction(this.selectInteraction);
		this.mapService.selectInteraction = null;
	}

	addModifyInteraction(featureCollection: Collection<Feature<Geometry>> = undefined) {
		let me = this;

		this.modifyInteraction = new Modify({
			features: this.selectInteraction.getFeatures()
			// source : this.currentEditedVectorLayer.getSource()//I can add this but all the features will be modified
		});

		this.modifyInteraction.on('modifyend', function (e) {
			me.modelOperationsService.persist(me.selectedLayer, [e.features.getArray()[0]]).toPromise().then(() => {
				console.log('update completed');
			});
		});

		this.map.addInteraction(this.modifyInteraction);
	}

	removeModifyInteraction() {
		this.map.removeInteraction(this.modifyInteraction);
	}

	addSnapInteractions() {

		this.snapInteraction = new Snap({
			source: this.currentEditedVectorLayer.getSource()
		});
		this.snapBufferInteraction = new Snap({
			source: this.mapService.getVectorLayer().getSource()
		});
		this.drawIntersectionsInteraction = new Snap({
			source: this.drawBufferLayerSource
		});

		// 1. Get snapping layers with meta = "snap_always"
		let workspaceLayers = this.stateService.getWorkspaceLayers();
		// // Filter by screen
		workspaceLayers = this.flattenWorkspaceLayers(this.asmService.filterLayersByScreen(workspaceLayers));
		// Get snap always layers
		let snapAlwaysLayers = this.geoserverService.getAllLayersWithKeyword(workspaceLayers, 'snap_always');
		this.addSnapAlwaysInteractions([...snapAlwaysLayers]);

		this.map.addInteraction(this.snapInteraction);
		this.map.addInteraction(this.snapBufferInteraction);
		this.map.addInteraction(this.drawIntersectionsInteraction);
	}

	removeSnapInteractions() {
		this.map.removeInteraction(this.snapInteraction);
		this.map.removeInteraction(this.drawBufferInteraction);
		this.map.removeInteraction(this.snapBufferInteraction);

		this.removeSnapInteractionsOfMap();
		this.removeSnapAlwaysWfsLayersOfMap();
	}

	onEditCoordinate(coordinate: coordinate){		
		if(this.selectedFeature){
			(this.selectInteraction.getFeatures().getArray()[0].getGeometry() as MultiPoint).setCoordinates([[coordinate.lat,coordinate.lon]]);
			this.modelOperationsService.persist(this.selectedLayer, this.selectInteraction.getFeatures().getArray());
		}
	}

	// ##########################################
	// ################## SNAP ##################
	// ##########################################
	addSnapAlwaysInteractions(layerstoSnapTo: Array<string>) {
		this.editingSnapInteractions = [];
		this.snapAlwaysWfsLayers = [];

		for (let index = 0; index < layerstoSnapTo.length; index++) {
			const snapLayerName = layerstoSnapTo[index];
			const snapToLayer = this.getWorkspaceLayerByName(snapLayerName);
			let snapToLayerWfs;

			if (snapToLayer) {
				snapToLayerWfs = this.mapService.toggleWFSService(snapToLayer, this.mapService.getMap().getLayers().getLength(), true, undefined, undefined, '_snap');
				snapToLayerWfs.setStyle(this.generateStyle(snapToLayer));

				// Distinguish snap layers that will need topology check(line 277) from always_snap layer that will not 
				if (snapToLayer.Model.snap_always) {
					this.snapAlwaysWfsLayers.push(snapToLayerWfs);
				}

			} else {
				continue;
			}

			let layerSnapInteraction = new Snap({
				source: snapToLayerWfs.getSource()
				// ,pixelTolerance:20
			});
			this.editingSnapInteractions.push(layerSnapInteraction);
			this.map.addInteraction(layerSnapInteraction);
		}

	}

	removeSnapAlwaysWfsLayersOfMap() {

		for (let index = 0; index < this.snapAlwaysWfsLayers.length; index++) {
			const snapLayer = this.snapAlwaysWfsLayers[index];
			this.removeLayerFromMap(snapLayer.get('name'));
		}
	}

	removeSnapInteractionsOfMap() {
		for (let index = 0; index < this.editingSnapInteractions.length; index++) {
			const snapInteraction = this.editingSnapInteractions[index];
			this.map.removeInteraction(snapInteraction);
		}
	}

	removeLayerFromMap(layerName: string) {
		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer && currentLayer.get('name') === layerName) {
				this.map.removeLayer(currentLayer);
			}
		});
	}

	// ====================================
	// 3. DELETING
	// ====================================
	startDeleting(updateState: boolean = true) {
		this.isEditing = true;
		this.currentEditorMode = EDITOR_MODES.delete;
		// 1. Add the Vector Layer
		this.currentEditedVectorLayer = this.mapService.toggleWFSService(this.selectedLayer, this.mapService.getMap().getLayers().length, true);
		this.stopDrawBuffer(false);

		this.map = this.mapService.getMap();
		this.addSelectForDeleteInteraction();

		if (updateState) {
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.editor_delete));
		}
	}

	stopDeleting() {
		this.currentEditorMode = EDITOR_MODES.stop;
		this.removeSelectForDeleteInteraction();
		this.selectedFeature = null;
		this.drawBufferLayerSource.clear();

		if (this.selectedLayer) {
			this.mapService.toggleWFSService(this.selectedLayer, undefined, false);
		}
	}

	addSelectForDeleteInteraction() {
		let me = this;

		this.selectInteraction = new Select({
			condition: click,
			toggleCondition: shiftKeyOnly,

			layers: layer => {
				return layer.get('name') == me.selectedLayer.Name + '_edit';
			},
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.8)'
				}),
				stroke: new Stroke({
					color: '#e040fb',
					width: 3
				}),
				image: new Circle({
					radius: 7,
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.8)'
					}),
					stroke: new Stroke({
						color: '#e040fb',
						width: 3
					})
				})
			})
		});

		this.mapService.selectInteraction = this.selectInteraction;

		this.selectInteraction.on('select', (e) => {
			me.selectedFeature = e.target.getFeatures().getArray()[0];

			// 1. Create a form transaction
			let deleteFormOperation: FormOperation = {
				layer: this.selectedLayer,
				layerModel: this.selectedLayer.Model,
				feature: me.selectedFeature,
				formHeader: 'Διαγραφή στοιχείου',
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.delete
			}

			// 2. Create transaction
			let deleteTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [deleteFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(deleteTransaction));

		});

		this.map.addInteraction(this.selectInteraction);
	}

	removeSelectForDeleteInteraction() {
		this.map.removeInteraction(this.selectInteraction);
		this.mapService.selectInteraction = null;
	}

	// ====================================
	// 3. BUFFER VECTOR LAYER
	// ====================================
	initializeBufferVectorLayer() {

		this.map = this.mapService.getMap();
		if (this.drawBufferLayer) { return; }

		this.drawBufferLayerSource = new VectorSource();

		this.drawBufferLayer = new VectorLayer({
			source: this.drawBufferLayerSource,
			style: this.getBufferStyle()
		});

		this.drawBufferLayer.set('name', 'drawLayer_buffers');
		this.drawBufferLayer.setZIndex(1000);
		this.map.addLayer(this.drawBufferLayer);
	}

	onDrawBuffer() {
		this.addDrawBufferInteraction();

		if (this.currentEditedVectorLayer) {
			this.bufferSnapToEditInteraction = new Snap({
				source: this.currentEditedVectorLayer.getSource()
			});
			this.map.addInteraction(this.bufferSnapToEditInteraction);
		}

	}

	/**
	 *
	 * @param center The center in 2100 projection
	 * @param radius
	 */
	drawBuffer(center: Point, radius: number = 25) {
		// Before creating circular i must reproject to 4326
		let transformedCenter = center.clone().transform('EPSG:2100', 'EPSG:4326');

		let precisionCircle = (PolygonModule as any).circular( (transformedCenter as Point).getCoordinates(), radius, 64);
		let precisionCircle2100 = precisionCircle.transform('EPSG:4326', 'EPSG:2100');
		let precisionCircleFeature = new Feature(precisionCircle2100);

		this.calculateAndDrawIntersectionsAtBuffers(precisionCircleFeature);
		this.mapService.addFeaturesToVectorLayer([precisionCircleFeature]);
	}

	removeBufferLayer() {
		if (this.drawBufferLayer) {
			this.map.removeLayer(this.drawBufferLayer);
		}
		this.drawBufferLayerSource = null;
		this.drawBufferLayer = null;
	}

	onClearBuffers() {
		this.mapService.clearVectorLayer();
	}

	onClearIntersectedLayer() {
		this.drawBufferLayerSource.clear();
	}

	// ====================================
	// 4. INTERACTION
	// ====================================
	addDrawBufferInteraction() {//  'Point'

		this.drawBufferInteraction = new Draw({
			source: this.drawBufferLayerSource,
			type: 'Point',
			style: this.getIntersectionStyle()
		});

		this.map.addInteraction(this.drawBufferInteraction);
		this.mapService.unregisterMapEvents();

		this.drawBufferInteraction.on('drawend', (event:any) => {
			let eventFeature = event.feature;

			let userRadius = this.zoneEditorControls.radius || '';
			let radius = userRadius == '' ? 10 : parseInt(userRadius);
			this.drawBuffer(eventFeature.getGeometry(), radius);

			this.stopDrawBuffer(false);
			this.zoneEditorControls.onFinishDraw();
		});

	}

	calculateAndDrawIntersectionsAtBuffers(newFeature: Feature<Geometry>) {
		let drawLayer = this.mapService.getVectorLayer();
		let allOldFeatures = drawLayer.getSource().getFeatures();
		let parser = new OL3Parser();
		parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);

		let newFeatureJstsGeom = parser.read((newFeature.getGeometry() as Polygon).getLinearRing(0));
		for (let i = 0; i < allOldFeatures.length; i++) {
			let currentJstsGeom = parser.read((allOldFeatures[i].getGeometry() as Polygon).getLinearRing(0));

			var intersection = newFeatureJstsGeom.intersection(currentJstsGeom);
			// console.log( parser.write(intersection) );

			let intersectionFeature = new Feature(parser.write(intersection))
			// this.mapService.addFeaturesToVectorLayer( [ intersectionFeature ], this.getIntersectionStyle() );
			this.drawBufferLayerSource.addFeatures([intersectionFeature])
		}

	}

	stopDrawBuffer(enableMapEvents) {
		this.removeDrawBufferInteraction();
		if (this.bufferSnapToEditInteraction) {
			this.map.removeInteraction(this.bufferSnapToEditInteraction);
			this.bufferSnapToEditInteraction = null;
		}

		if (enableMapEvents) {
			setTimeout(() => { this.mapService.registerMapEvents(); }, 1000);
		}

	}

	removeDrawBufferInteraction() {
		if (this.drawBufferInteraction) {
			this.map.removeInteraction(this.drawBufferInteraction);
		}
	}

	getIntersectionStyle() {

		let style = new Style({
			fill: new Fill({ color: '#000' }),
			stroke: new Stroke({ color: '#000' }),
			image: new Circle({
				radius: 5,
				stroke: new Stroke({
					color: 'rgba(24, 255, 255, 1)'
				}),
				fill: new Fill({
					color: 'rgba(211, 47, 47, 0.8)'
				})
			})
		});

		return style;
	}

	getBufferStyle() {

		let style = new Style({
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.2)'
			}),
			stroke: new Stroke({
				color: 'rgba(0, 0, 0, 0.5)',
				lineDash: [10, 10],
				width: 2
			}),
			image: new Circle({
				radius: 5,
				stroke: new Stroke({
					color: 'rgba(0, 0, 0, 0.7)'
				}),
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)'
				})
			})
		})

		return style;
	}

	onUserAction(action?: EDIT_ACTIONS) {
		switch (action) {
			case EDIT_ACTIONS.create: {
				this.startDrawing();
				this.currentEditorMode = EDITOR_MODES.draw;
				break;
			}
			case EDIT_ACTIONS.update: {
				this.startEditing();
				this.currentEditorMode = EDITOR_MODES.edit;
				break;
			}
			case EDIT_ACTIONS.delete: {
				this.startDeleting();
				this.currentEditorMode = EDITOR_MODES.delete;
				break;
			}
			default: {
				this.stop();
				this.currentEditorMode = EDITOR_MODES.stop;
				break;
			}
		}
	}

	onZoneAction(action?: ZONE_EDIT_ACTIONS) {
		switch (action) {
			case ZONE_EDIT_ACTIONS.zone_draw: {
				this.stop();
				this.onDrawBuffer();
				break;
			}
			case ZONE_EDIT_ACTIONS.zone_clear_all: {
				this.onClearBuffers();
				break;
			}
			case ZONE_EDIT_ACTIONS.zone_clear_intersections: {
				this.onClearIntersectedLayer();
				break;
			}
			default: break;
		}
	}

	onSplitChecked(checked: boolean) {
		console.log("Stavros add action here");
	}

	// ##########################################
	// ############# LAYER FUNCTIONS ############
	// ##########################################

	generateStyle(layer: WorkspaceLayer) {

		switch (layer.Name.split(':')[1]) {
			case "pipes":
				return this.geoserverService.generateVectorStyle('line', '', '#3b5da7', '#06c5fb', 4);
			case "supplies":
				return this.geoserverService.generateVectorStyle('line', '', '#3b5da7', '#06c5fb', 2);
			case "hydrometers":
				return this.geoserverService.generateVectorStyle('point', 'hexagon', '#03c3ff', '#666666', 1, 6);
			case "network_parts":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#cecb79', '#232323', 1, 3);
			case "facilities":
				return this.geoserverService.generateVectorStyle('polygon', '', '#ffa000', '#7b1fa2', 2);
			case "switches":
				return this.geoserverService.generateVectorStyle('point', 'rectangle', '#f5f23c', '#325780');
			case "control_valves":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#e65100', '#616161');
			case "hydrants":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#b71c1c', '#616161');
			case "network_facilities":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#004d40', '#616161');
			default:
				return this.geoserverService.generateVectorStyle('point', 'circle', '#004d40', '#616161');
		}

	}

	getWorkspaceLayerByName(layerName: string) {
		let workspace = this.stateService.getWorkspace();
		for (let index = 0; index < this.workspaceLayers.length; index++) {
			const layer = this.workspaceLayers[index];
			if (layer.Name === workspace + ':' + layerName) {
				return layer;
			}
		}

		return null;
	}

}
