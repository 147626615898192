import { Component, OnInit, OnDestroy } from '@angular/core';

// App imports 
import { StateService } from '../../core/state.service';
import { OPERATIONS } from '../../models';

@Component({
	selector: 'app-map-status',
	templateUrl: './map-status.component.html',
	styleUrls: ['./map-status.component.css']
})
export class MapStatusComponent implements OnInit, OnDestroy {

	private stateUnsubscription;
	public activeOperations;
	public activeOperation;
	public statusMessage;
	public operations = OPERATIONS;

	constructor(private stateService: StateService) { }

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
		this.activeOperation = this.stateService.getActiveOperation();
	}

	ngOnDestroy() {
		this.stateUnsubscription();
	}

	updateFromState() {
		//Operations actions
		this.activeOperation = this.stateService.getActiveOperation();
	}

}
