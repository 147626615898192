import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, combineLatest } from 'rxjs';
import { StateService } from 'app/core/state.service';
import { skip } from 'rxjs/operators';
import { NavButtons } from 'app/models';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	private tableVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public tableVisible$: Observable<boolean> = from(this.tableVisible);
	
	private dataTableLayerTitle: BehaviorSubject<string> = new BehaviorSubject(undefined);
	public dataTableLayerTitle$: Observable<string> = from(this.dataTableLayerTitle);

	private multiTableVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public multiTableVisible$: Observable<boolean> = from(this.multiTableVisible);

	private topologyResultsVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public topologyResultsVisible$: Observable<boolean> = from(this.topologyResultsVisible);

	private tableHeight: BehaviorSubject<number> = new BehaviorSubject(400);
	public tableHeight$: Observable<number> = from(this.tableHeight);

	private formVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public formVisible$: Observable<boolean> = from(this.formVisible);

	private headerLoaderVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public headerLoaderVisible$: Observable<boolean> = from(this.headerLoaderVisible);

  private sidebarVisibilityChange: BehaviorSubject<void> = new BehaviorSubject(null);
  // This stream notifies subscribers when any layout change occurs
  public layoutChange$ = combineLatest([
    this.tableVisible,
    this.multiTableVisible,
    this.topologyResultsVisible,
    this.tableHeight,
    this.formVisible,
    this.sidebarVisibilityChange
  ]).pipe(
    skip(1)
  );

  private sideContent: BehaviorSubject<NavButtons> = new BehaviorSubject(null);
  public sideContent$: Observable<NavButtons> = from(this.sideContent);

	public sidebarOpen = false;
	public headerHeight: number;
	public side1Width = 55;
	public side2Width = 270;
	public formWidth = 320;
	public mrTableWidth = 500;
	public tableScrollbarWidth = 10;

	private featureTableTimeStamp: number = 0;
	private featureInfoTimeStamp: number = 0;
	private formTimeStamp: number = 0;

	constructor(private stateService: StateService) { }

	public toggleTable(show?: boolean) {
		if (show === this.tableVisible.getValue()) {
			return;
		}
		let visible = show || !this.tableVisible.getValue();
		if (!visible) {
			this.tableResize(400);
		}
		this.tableVisible.next(visible);
	}

	public toggleMultiTable(show?: boolean) {
		if (show === this.multiTableVisible.getValue()) {
			return;
		}
		let visible = show || !this.multiTableVisible.getValue();
		if (!visible) {
			this.tableResize(400);
		}
		this.multiTableVisible.next(visible);
	}

	public toggleTopologyResults(show?: boolean) {
		if (show === this.topologyResultsVisible.getValue()) {
			return;
		}
		let visible = show || !this.topologyResultsVisible.getValue();
		if (!visible) {
			this.tableResize(400);
		}
		this.topologyResultsVisible.next(visible);
	}

	public tableResize(height?: number) {
		let newHeight: number;
		if (!height) {
			newHeight = this.tableHeight.getValue() === 400 ? 600 : 400;
		} else {
			newHeight = height;
		}
		this.tableHeight.next(newHeight);
	}

	public formToggle(show?: boolean) {
		if (show === this.formVisible.getValue()) {
			return;
		}
		this.formVisible.next(show);
	}

	public handleTableAndFormToggle(multiTableVisible: boolean = undefined, tableVisible: boolean = undefined) {
		let featureTableData = this.stateService.getFeatureTableData();
		let featureInfo = this.stateService.getFeatureInfoNew();
		let formData = this.stateService.getFormData();

		//if featureTableData changed, toggle featureTable according to the change.
		if (this.featureTableTimeStamp !== featureTableData.timestamp) {
			this.featureTableTimeStamp = featureTableData.timestamp;
			//if feature table data have been cleared close feature table
			if (!!featureTableData.features && featureTableData.features.length === 0) {
				this.toggleTable(false);
				//if feature table data have been filled open feature table
			} else if (featureTableData.features.length > 0) {
				this.toggleMultiTable(false);
				this.toggleTable(true);
			}
		}

		//if featureInfoData, changed toggle multiTable according to the change.
		if (this.featureInfoTimeStamp !== featureInfo.timestamp) {
			this.featureInfoTimeStamp = featureInfo.timestamp;
			//if feature info data have been cleared close feature table
			if (!featureInfo.featureInfoVisible) {
				this.toggleMultiTable(false);
				//if feature info data have been filled open feature table
			} else {
				this.toggleTable(false);
				this.toggleMultiTable(true);
			}
		}

		//if form data are have been cleared close form
		if (this.formTimeStamp !== formData.timestamp) {
			this.formTimeStamp = formData.timestamp;
			if (formData?.features?.length === 0) {
				this.formToggle(false);
			}
		}

        this.dataTableLayerTitle.next(featureTableData?.selectedLayer?.Name);


	}

  public setSidebarStatus(open: boolean) {
    this.sidebarOpen = open;
    this.sidebarVisibilityChange.next();
  }

	public showHeaderLoader(show: boolean) {
		this.headerLoaderVisible.next(show);
	}

  public setSideNavContent(activeSideNav: NavButtons) {
    this.sideContent.next(activeSideNav);
  }
}
