<div class="sidebar-widget">
  <h2 class="mb-2 font-light" i18n="Print | Print">Εκτύπωση</h2>

  <div class="flex flex-col">
    <mat-form-field>
      <mat-label>Layout (A4 Landscape)</mat-label>
      <mat-select>
        <mat-option (click)="selectLayout(layout.name)" *ngFor="let layout of layouts" value="layout.name">
          {{ layout.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n="Resolution(dpi) (96) | Printing resolution">Ανάλυση(dpi) (96)</mat-label>
      <mat-select>
        <mat-option (click)="selectDpi(dpi.value)" *ngFor="let dpi of dpis" value="dpi.value">
          {{ dpi.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox class="mb-3" [checked]="true" (change)="selectAutoScale($event.checked)">
      <ng-container i18n="Automatic scale selection | Automatic scale selection">
        Αυτόματη επιλογή κλίμακας
      </ng-container>
    </mat-checkbox>

    <mat-form-field *ngIf="!selectedScale.auto">
      <mat-label i18n="Scale | Scale">Κλίμακα (1:2400)</mat-label>
      <mat-select>
        <mat-option (click)="selectScale(scale)" *ngFor="let scale of scales" value="scale.value">
          {{ scale.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button (click)="printMap()" mat-raised-button color="primary" [disabled]="loading">
      <ng-container i18n="Generate Map | Generate Map">
        Παραγωγή χάρτη
      </ng-container>
    </button>

    <mat-progress-bar *ngIf="loading" mode="indeterminate" class="mt-3"></mat-progress-bar>
  </div>
</div>
