import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormOperation, FormOperationsGroup } from '../../models';

@Component({
  selector: 'app-topology-ui-dialog',
  templateUrl: './topology-ui-dialog.component.html',
  styleUrls: ['./topology-ui-dialog.component.css']
})
export class TopologyUiDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TopologyUiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public operationsGroup: FormOperationsGroup
  ) { }

    select(opearation: FormOperation){
      this.dialogRef.close(opearation);
    }

}
