import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { WorkspaceLayer, ZONE_EDIT_ACTIONS } from 'app/models';

@Component({
  selector: 'app-zone-editor',
  templateUrl: './zone-editor.component.html',
  styleUrls: ['./zone-editor.component.scss']
})
export class ZoneEditorComponent implements OnInit {
  // actionDisabled: Map<string, boolean> = new Map([["create", false], ["update", false], ["delete", false]]);
  currentAction: ZONE_EDIT_ACTIONS = undefined;
  zoneActions = ZONE_EDIT_ACTIONS;
  @Input() isDrawing = false;
  @Input() disable = false;
  @Input() layerName: string; // This is only in order to fore change detection on selectedLayer object
  @Input() selectedLayer: WorkspaceLayer;
  @Output() userAction: EventEmitter<ZONE_EDIT_ACTIONS> = new EventEmitter();
  @ViewChild('group', { static: true }) group: MatButtonToggleGroup;
  radius: string;
  
  constructor() { }

  ngOnInit() {
  }

  onDraw(calledByParent: boolean = false) {
    this.userAction.emit(ZONE_EDIT_ACTIONS.zone_draw);
    this.currentAction = ZONE_EDIT_ACTIONS.zone_draw;
    this.isDrawing = true;
  }

  onFinishDraw(calledByParent: boolean = false) {
    this.currentAction = undefined;
    this.isDrawing = false;
  }

  onClearZones(calledByParent: boolean = false) {
    this.userAction.emit(ZONE_EDIT_ACTIONS.zone_clear_all);
    this.currentAction = undefined;
    this.group.value = "";
    this.isDrawing = false;
  }

  onClearIntersections(calledByParent: boolean = false) {
    this.userAction.emit(ZONE_EDIT_ACTIONS.zone_clear_intersections);
    this.currentAction = undefined;
    this.group.value = "";
    this.isDrawing = false;
  }
}
