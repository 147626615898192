import { Component, EventEmitter, Input, OnChanges, OnInit, Output, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldConfigDeya } from '../../models/field-config.interface';

@Component({
	exportAs: 'dynamicForm',
	selector: 'dynamic-form',
	styleUrls: ['dynamic-form.component.scss'],
	templateUrl: 'dynamic-form.component.html'
})
export class DynamicFormComponent implements OnChanges, OnInit, AfterViewInit {
	@Input() config: FieldConfigDeya[] = [];
	@Input() formData: any;
	@Input() freeze: boolean;
	@Input() isInModalTableEdit: boolean;
	@Input() multiRowSelectionSet: Set<string>;

	@Output() submit: EventEmitter<any> = new EventEmitter<any>();
	@Output() fileUpload: EventEmitter<any> = new EventEmitter<any>();
	@Output() delete: EventEmitter<any> = new EventEmitter<any>();
	@Output() calculateElevation: EventEmitter<any> = new EventEmitter<any>();


	form: FormGroup;
	lastChildWidth: number = 0;

	get controls() { return (this.config) ? this.config.filter(({ type, visible }) => type !== 'button' && !!visible) : []; }
	get changes() { return this.form.valueChanges; }
	get valid() { return this.form.valid; }
	get value() { return this.form.value; }

	constructor(private fb: FormBuilder) { }

	ngOnInit() {
		// this.form = this.createGroup();
	}

	ngAfterViewInit() {
		// this.form = this.createGroup();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.form && this.controls) {
			this.form = this.createGroup();
		}

		if (changes['config'] && this.form) {
			const controls = Object.keys(this.form.controls);
			const configControls = this.controls.map((item) => item.name);

			controls
				.filter((control) => !configControls.includes(control))
				.forEach((control) => this.form.removeControl(control));

			configControls
				.filter((control) => !controls.includes(control))
				.forEach((name) => {
					const config = this.config.find((control) => control.name === name);
					this.form.addControl(name, this.createControl(config));
				});
		}

		if (changes['formData'] && !!this.formData) {
			this.patchValues(this.formData);
		}

		if (changes['freeze']) {
			if (this.freeze && this.formData) {
				this.config.forEach(c => c.editable = false);
				this.form.disable();
			}
			else {
				for (const control in this.form.controls) {
					this.config.forEach(field => {
						if (field.name === control && field.editable) {
							this.form.get(control).enable();
						}
					});
				}
			}
		}
	}

	createGroup() {
		const group = this.fb.group({});
		this.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
		return group;
	}

	createControl(config: FieldConfigDeya) {
		const { editable, validation, value } = config;
		let validationFn;
		if( config.validationString ){
			validationFn = Validators.pattern(config.validationString);
		}
		return this.fb.control({disabled: !editable, value}, {validators: validation?validation:validationFn});
	}

	handleSubmit(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		this.submit.emit(this.value);
	}

	handleFileUpload(file) {
		this.fileUpload.emit(file);
	}

	handleCalculateElevation(){
		this.calculateElevation.emit()
	}

	handleDelete() {
		this.delete.emit();
	}

	public patchValues(values: any) {
    	this.form.reset();
		this.form.patchValue(values);
		/* check if there is the feature's id in formdata 
		to determinate if a new feature is being created, 
		or an already existing feature, is being edited */
		if (Object.keys(values).length > 0 && !!!values.id) {
			this.form.markAsDirty();
		}
	}


}
