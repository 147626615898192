<div class="relative" [ngClass]="{'pb-8': showMoreFilterControls}" (clickOutside)="showMoreFilterControls = false;">
  
  <ng-container [ngTemplateOutlet]="!layerFilter || (layerFilter && enabled) ? filterEnable : filterDisable"></ng-container>

  <div class="absolute flex extra-controls" *ngIf="showMoreFilterControls">
    <button
      mat-icon-button
      matRippleDisabled
      aria-label="Διαγραφή φίλτρου"
      i18n-aria-label="Delete filter"
      (click)="onDelete()"
      matTooltip="Διαγραφή φίλτρου"
      i18n-matTooltip="Delete filter"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>

    <ng-container [ngTemplateOutlet]="enabled ? filterDisable : filterEnable" [ngTemplateOutletContext]="{popoverMenu: true}"></ng-container>
    
    <button
      mat-icon-button
      matRippleDisabled
      aria-label="Επεξεργασία φίλτρου"
      i18n-aria-label="Edit filter"
      (click)="onEdit()"
      matTooltip="Επεξεργασία φίλτρου"
      i18n-matTooltip="Edit filter"
    >
      <mat-icon>settings</mat-icon>
    </button>
  </div>
</div>

<ng-template #filterEnable let-popoverMenu="popoverMenu">
  <button
    [ngClass]="{ 'text-accent': enabled }"
    mat-icon-button
    matRippleDisabled
    aria-label="{{!layerFilter ? createFilterLabel : (popoverMenu ? enableFilterLabel : filterEnabledLabel)}}"
    matTooltip="{{!layerFilter ? createFilterLabel : (popoverMenu ? enableFilterLabel : filterEnabledLabel)}}"
    [matTooltipPosition]="showMoreFilterControls && !popoverMenu ? 'above' : 'below'"
    (click)="onFilter(!!popoverMenu)"
  >
    <mat-icon>filter_list</mat-icon>
  </button>
</ng-template>

<ng-template #filterDisable let-popoverMenu="popoverMenu">
  <button
    mat-icon-button
    matRippleDisabled
    aria-label="{{popoverMenu ? disableFilterLabel : filterDisabledLabel}}"
    matTooltip="{{popoverMenu ? disableFilterLabel : filterDisabledLabel}}"
    [matTooltipPosition]="showMoreFilterControls && !popoverMenu ? 'above' : 'below'"
    (click)="onFilter(!!popoverMenu)"
  >
    <mat-icon>filter_list_off</mat-icon>
  </button>
</ng-template>
