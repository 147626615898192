import { Injectable } from '@angular/core';
import { FormTransaction, FormOperation, FormOperationsGroup } from '../models';
import { StateService } from '../core/state.service';
import { MatDialog } from '@angular/material/dialog';
import { ModelOperationsService } from './model-operations.service';
import { EDIT_ACTIONS } from '../models';
import { LayoutService } from './layout.service';
import { DecideDialogComponent } from 'app/shared/decide-dialog/decide-dialog.component';
import { TopologyUiDialogComponent } from '../shared/topology-ui-dialog/topology-ui-dialog.component';

enum TopologyUiAction {
	'DESIGN_EDGE',
	'DESIGN_NODE',
	'DELETE_EDGE',
	'DELETE_NODE',
	'EDIT_DATA',
	'TOPOLOGY_CHECKS',
	'NONE'
}

interface TopologyUiState {
	currentAction: TopologyUiAction;
}

@Injectable({
	providedIn:'root'
})
export class TopologyUiService {
	private currentTransaction: string = "";
	private currentOperation: string = "";
	private formOperations: (FormOperation | FormOperationsGroup)[] = [];
	private selectedFormOperations: FormOperation[] = [];
	topologyState: TopologyUiState = { currentAction: TopologyUiAction.NONE };

	constructor(
		private stateService: StateService,
		public formDialog: MatDialog,
		private crudService: ModelOperationsService,
		private layoutService: LayoutService,
		public operationDialog: MatDialog) {
		StateService.stateStore.subscribe(() => this.updateFromState());
	}

	updateFromState() {
		const formTrans = this.stateService.getState().formTransactions;
		if (formTrans.length > 0) {
			if (this.currentTransaction === "") {
				this.startFormTransaction(formTrans[0]);
			} else if (formTrans.length > 1) {
				// If user does not complete edit/ create we move to next transaction
				this.stopFormOperations(false);
			}
		} else {
			this.clearFormTransactions();
		}
	}

	startFormTransaction(transaction: FormTransaction) {
		this.currentTransaction = transaction.timestamp;
		this.formOperations = transaction.formOperations;
		this.getFormOperation(0);
	}

	stopTransaction() {
		const indx = this.stateService.getState().formTransactions.map(tr => tr.timestamp).indexOf(this.currentTransaction);
		this.clearFormTransactions();
		StateService.stateStore.dispatch(this.stateService.removeFormTransactionOperation(this.stateService.getState().formTransactions[indx]));
	}

	private getFormOperation(index: number): void {
		if (!!(this.formOperations[index] as FormOperationsGroup).description) {
			const dialogRef = this.operationDialog.open(TopologyUiDialogComponent, {
				disableClose: true,
				autoFocus: false,
				maxWidth: '80vw',
				data: this.formOperations[index]
			});

			dialogRef.afterClosed().subscribe(result => {
				this.selectedFormOperations.push(result);
				this.startFormOperation(result, index);
				//this._operation.next(result);
			})
		}
		else {
			//this._operation.next(this.formOperations[index] as FormOperation);
			this.selectedFormOperations.push(this.formOperations[index] as FormOperation);
			this.startFormOperation(this.formOperations[index] as FormOperation, index);
		}
	}

	private handleSilentOperation(operation: FormOperation) {
		if (operation.action === EDIT_ACTIONS.delete) {
			this.endFormOperation(true);
		} else {
			this.endFormOperation(operation.feature);
		}
	}

	startFormOperation(operation: FormOperation, currentIndex: number) {
		if (this.currentOperation !== this.formOperations[currentIndex].timestamp) {
			this.currentOperation = this.formOperations[currentIndex].timestamp;

			if (operation.silent) {
				this.handleSilentOperation(operation);
				return;
			}

			if (operation.action === EDIT_ACTIONS.delete) {
				this.formDialog.open(DecideDialogComponent, {
                }).afterClosed().subscribe(
					confirmDelete => {
						this.endFormOperation(confirmDelete);
						if (confirmDelete) {
							this.layoutService.toggleMultiTable(false);
						}
					}
				);
				return;
			}

			StateService.stateStore.dispatch(this.stateService.setFormDataOperation({
				title: operation.formHeader,
				features: [operation.feature],
				selectedLayer: operation.layer,
				action: operation.action,
				currentIndex: currentIndex + 1,
				totalOperations: this.formOperations.length
			}));

		}
	}

	endFormOperation(feature: any) {
		if (!!feature) {
			const currentOperationIndex = this.formOperations.map(op => op.timestamp).indexOf(this.currentOperation);
			if ((this.formOperations[currentOperationIndex] as FormOperation).action !== EDIT_ACTIONS.delete) {
				this.selectedFormOperations[currentOperationIndex].feature = feature;
			}
			this.getNextOperation();
		} else {
			this.stopFormOperations(false);
		}
	}

	getNextOperation() {
		const currentOperationIndex = this.formOperations.map(op => op.timestamp).indexOf(this.currentOperation);
		if (currentOperationIndex !== -1 && !!this.formOperations[currentOperationIndex + 1]) {
			this.getFormOperation(currentOperationIndex + 1);
		} else {
			this.stopFormOperations(true);
			this.layoutService.formToggle(false);
		}
	}

	stopFormOperations(persist: boolean) {
		if (persist) {
			// this.selectedFormOperations.forEach(op => {
			// 	if (op.action === EDIT_ACTIONS.create) {
			// 		this.crudService.persist(op.layer, null, [op.feature]);
			// 	} else if (op.action === EDIT_ACTIONS.update) {
			// 		this.crudService.persist(op.layer, [op.feature]);
			// 	} else if (op.action === EDIT_ACTIONS.delete) {
			// 		if (Array.isArray(op.feature)) {
			// 			this.crudService.persist(op.layer, null, null, op.feature);
			// 		} else {
			// 			this.crudService.persist(op.layer, null, null, [op.feature]);
			// 		}
			// 	}
			// })

			this.crudService.batchPersist(this.selectedFormOperations);
		}
		this.formOperations = [];
		this.selectedFormOperations = [];
		this.currentOperation = "";

		this.stopTransaction();
	}

	public clearFormTransactions() {
		this.currentOperation = "";
		this.currentTransaction = "";
	}
}
