<div class="card-style">
  <div class="flex flex-col">
    <h2 class="mb-2" i18n="Draw Zone | Draw Zone">Σχεδίαση ζώνης</h2>

    <mat-form-field>
      <mat-label>Ακτίνα σε μέτρα</mat-label>
      <input [(ngModel)]="radius" type="text" matInput>
    </mat-form-field>

    <div>
      <mat-button-toggle-group name="editorAction" aria-label="Editor Action" #group>
        <button class="rounded-r-none" mat-raised-button [ngClass]="{'toggled flashing': isDrawing}" i18n-matTooltip="Draw Zone | Draw Zone"
          matTooltip="Σχεδίαση ζώνης" [matTooltipHideDelay]="200" (click)="onDraw()" [disabled]="disable">
          <mat-icon class="editor-icon">filter_tilt_shift</mat-icon>
        </button>
        <button class="rounded-l-none rounded-r-none" mat-raised-button i18n-matTooltip="Clear Zone | Clear Zone" matTooltip="Καθαρισμός ζωνών"
          [matTooltipHideDelay]="200" (click)="onClearZones()" [disabled]="disable">
          <mat-icon class="editor-icon">blur_off</mat-icon>
        </button>
        <button class="rounded-l-none" mat-raised-button i18n-matTooltip="Clear intersection points | Clear intersection points"
          matTooltip="Καθαρισμός σημείων τομής" [matTooltipHideDelay]="200" (click)="onClearIntersections()"
          [disabled]="disable">
          <mat-icon class="editor-icon">link_off</mat-icon>
        </button>
      </mat-button-toggle-group>
    </div>

  </div>
</div>
