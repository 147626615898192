<div class="card-style">
  <h3 class="mb-2 font-light" i18n="Enter coordinates | Enter coordinates">
    Εισαγωγή συντεταγμένων (ΕΓΣΑ)
  </h3>

  <div class="grid grid-cols-2 gap-2.5">
    <mat-form-field>
      <mat-label>Lat</mat-label>
      <input [(ngModel)]="lat" type="number" matInput />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Lon</mat-label>
      <input [(ngModel)]="lon" type="number" matInput>
    </mat-form-field>
  </div>

  <button *ngIf="(operation === 3 || operation === 7); else add" color="primary" mat-raised-button
    matTooltip="Επεξεργασία" [matTooltipHideDelay]="200" (click)="insertCoord()">
    <mat-icon class="editor-icon">edit</mat-icon>
    Ενημέρωση
  </button>

  <ng-template #add>
    <button mat-raised-button color="primary" (click)="insertCoord()">
      <mat-icon class="editor-icon">add</mat-icon>
      Εισαγωγή
    </button>
  </ng-template>
</div>
