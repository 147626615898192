// Angular imports
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router }    from '@angular/router';

// App imports 
import { StateService } from '../../core/state.service';


@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {
	private stateUnsubscription;

	constructor( private router: Router, private stateService: StateService ) { }

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe( ()=>{ this.updateFromState(); } );
	}

	updateFromState(){
		let isAdmin = this.stateService.getIsAdmin();
		if( !isAdmin ){
			this.router.navigate(['/home']);
		}
	}

	ngOnDestroy(){
		this.stateUnsubscription();
	}

}
