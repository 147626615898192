import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-db-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnInit {

  @Input() title: string;
  @Input() expandable: boolean = true;
  @Output() expand = new EventEmitter<string>();
  @Output() shrink = new EventEmitter<string>();
  private _isExpanded: boolean = false;
  @Input() canExpand: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  private toggleExpand(): void {
    this._isExpanded = !this._isExpanded;
  }

  onExpand(): void {
    this.toggleExpand();
    this.expand.emit('expand');
  }

  onShrink(): void {
    this.toggleExpand();
    this.shrink.emit('shrink');
  }

}
