<div class="card-style form-wrapper" >
  <div class="form-header">
    <h3 class="font-light">
      {{formData?.title || formData?.selectedLayer.Title || 'Data Table Name'}}
       <mat-icon class="icon-badge" *ngIf="lastSelectedFeatures?.length > 1">{{formHeaderBadgeIcon}}</mat-icon>
    </h3>

    <button mat-icon-button aria-label="Close form drawer" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="form-content">
    <app-line-chart *ngIf="formData?.features.isEpanetFeature"></app-line-chart>
    <br>
    <dynamic-form *ngIf="formData && !(formData.multiCell && formData.action === actions.delete)" #dynForm
      [config]="fields" [formData]="dynamicFormRowData" [multiRowSelectionSet]="multiRowSelectionSet"
      [freeze]="!formData?.selectedLayer?.Model?.is_edit && !formData?.selectedLayer?.Model?.is_topology_edit"
      [isInModalTableEdit]="true" (fileUpload)="onFormFileUpload($event)" (calculateElevation)="onFormCalculateElevation()">
    </dynamic-form>
  </div>

  <div class="form-controls">
    <button mat-flat-button (click)="onDeleteFromInfo()" i18n-title="Delete item | Delete item"
      title="Διαγραφή εγγραφής" *ngIf="formData?.action !== actions.create"
      [disabled]="!!formData?.selectedLayer?.Model?.is_edit && !!formData?.selectedLayer?.Model?.is_topology_edit || lastSelectedFeatures?.length > 1">
      <ng-container i18n="Delete | Delete">Διαγραφή</ng-container>
    </button>

    <span *ngIf="formData?.action === actions.create && formData?.totalOperations > 1">
      <ng-container i18n="Step | Step">Βήμα</ng-container>
      {{formData?.currentIndex}} <ng-container i18n="From | From">από</ng-container> {{formData?.totalOperations}}
    </span>

    <button
      *ngIf="!(formData?.action === actions.create && formData?.totalOperations > 1 && formData?.currentIndex !== formData?.totalOperations)"
      mat-flat-button color="primary" (click)="onSaveFromInfo()" title="Αποθήκευση αλλαγών στη βάση δεδομένων"
      class="save-button"
      matBadge="{{lastSelectedFeatures?.length}}"
      [disabled]="(!dynForm || dynForm.form.invalid || !formData || (!formData?.selectedLayer?.Model?.is_edit && !formData?.selectedLayer?.Model?.is_topology_edit) || dynForm?.form?.pristine) && !!fields?.length">
      <ng-container i18n="Save | Save">Αποθήκευση</ng-container>
    </button>

    <button
      *ngIf="formData?.action === actions.create && formData?.totalOperations > 1 && formData?.currentIndex !== formData?.totalOperations"
      mat-flat-button color="primary" (click)="onSaveFromInfo()"
      [disabled]="(!dynForm || dynForm.form.invalid || !formData || (!formData?.selectedLayer?.Model?.is_edit && !formData?.selectedLayer?.Model?.is_topology_edit) || dynForm?.form?.pristine) && !!fields?.length">
      <ng-container i18n="Next | Next">Επόμενο</ng-container>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
