<div class="card-container">
	<mat-card id="lineCard">
  
	  <mat-card-header class="flex flex-wrap justify-between">
  
		<mat-form-field *ngIf="foundLink" class="basis-100">
		  <mat-label i18n>Link Attributes</mat-label>
		  <mat-select [(ngModel)]="selectedAttribute" name="linkAttribute" (ngModelChange)="renderChart(foundLink)">
			<mat-option *ngFor="let option of linkChartAttributesOptions" [value]="option">
			  {{ option }}
			</mat-option>
		  </mat-select>
		</mat-form-field>
  
		<mat-form-field *ngIf="foundNode" class="basis-100">
		  <mat-label i18n>Node Attributes</mat-label>
		  <mat-select [(ngModel)]="selectedAttribute" name="nodeAttribute" (ngModelChange)="renderChart(foundNode)">
			<mat-option *ngFor="let option of nodeChartAttributesOptions" [value]="option">
			  {{ option }}
			</mat-option>
		  </mat-select>
		</mat-form-field>
  
	  </mat-card-header>
  
	  <app-db-card id="line-chart" [canExpand]="false" #lineChart >
		  <!-- <div id="line-chart"></div> -->
	  </app-db-card>	  
  
	</mat-card>
  </div>
  
