import { Component } from '@angular/core';
import { AuthenticationService } from 'app/core/authentication.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.scss']
})
export class BootstrapComponent {
  userInitialized = false;

  constructor(private authService: AuthenticationService) {
    this.authService.userInitialized$.pipe(
      take(1)
    ).subscribe(() => this.userInitialized = true);
  }
}
