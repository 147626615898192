import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { Event, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, RouterEvent } from '@angular/router';
import { StateService } from './state.service';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import { mergeMap, filter, tap, startWith } from 'rxjs/operators';
import { ActiveScreenIDs, ActiveScreenRoute, NavButtons, WorkspaceLayer } from '../models';
@Injectable()
export class ActiveScreenManagementService {

	private static screens: ActiveScreenRoute[] = [
		{
			name: $localize`Αρχική`,
			route: '/home',
			id: ActiveScreenIDs.home,
			navButtons: [NavButtons.layers, NavButtons.filters, NavButtons.measurement, NavButtons.print]
		},
		{
			name: $localize`Epanet`,
			route: '/epanet',
			id: ActiveScreenIDs.epanet,
			navButtons: [NavButtons.layers, NavButtons.epanet]
		},
		{
			name: $localize`Τοπολογία`,
			route: '/topology',
			id: ActiveScreenIDs.topology,
			navButtons: [NavButtons.layers, NavButtons.tp_error_detection, NavButtons.tp_audit, NavButtons.create_supplies]
		},
		{
			name: $localize`Δίκτυο Αποχέτευσης`,
			route: '/drainage',
			id: ActiveScreenIDs.drainage,
			navButtons: [NavButtons.layers, NavButtons.drainage_detection, NavButtons.drainage_updownstream, NavButtons.shortest_path]
		},
		{
			name: $localize`Ποιοτικά`,
			route: '/capta',
			id: ActiveScreenIDs.capta,
			navButtons: [NavButtons.layers, NavButtons.filters, NavButtons.scada_filters, NavButtons.measurement]
		},
		{
			name: $localize`Καταγραφικά`,
			route: '/xilog',
			id: ActiveScreenIDs.xilog,
			navButtons: [NavButtons.layers, NavButtons.filters, NavButtons.scada_filters, NavButtons.measurement]
		},
		{
			name: 'SCADA',
			route: '/scada',
			id: ActiveScreenIDs.scada,
			navButtons: [NavButtons.layers, NavButtons.filters, NavButtons.scada_filters, NavButtons.measurement]
		},
		{
			name: $localize`Έγγραφα`,
			route: '/documents',
			id: ActiveScreenIDs.docs,
			navButtons: [NavButtons.document_tree]
		},
		{
			name: 'Android',
			route: '/android',
			id: ActiveScreenIDs.android,
			navButtons: []
		},
		{
			name: 'Disabled',
			route: '/disabled',
			id: ActiveScreenIDs.disabled,
			navButtons: []
		}
	];

	public static getAvailableNavButtons(screenId: ActiveScreenIDs): NavButtons[] {
		return ActiveScreenManagementService.screens.find(screen => screen.id === screenId)?.navButtons ?? [];
	}

	private activeScreenIDs: BehaviorSubject<number[]> = new BehaviorSubject([]);
	public activeScreenIDs$: Observable<Array<number>> = from(this.activeScreenIDs);

	private activeScreenRoutes: BehaviorSubject<ActiveScreenRoute[]> = new BehaviorSubject([]);
	public activeScreenRoutes$: Observable<Array<ActiveScreenRoute>> = from(this.activeScreenRoutes);

	public currentRouteName$: BehaviorSubject<string> = new BehaviorSubject('');

	private currentScreenId: BehaviorSubject<ActiveScreenIDs> = new BehaviorSubject(ActiveScreenIDs.disabled);
	public currentScreenId$: Observable<ActiveScreenIDs> = from(this.currentScreenId);

	constructor(@Inject(LOCALE_ID) protected localeId: string, private router: Router, private stateService: StateService) {
		this.currentScreenId.next(this.getScreenIdFromRoute(this.router.url));
		StateService.stateStore.subscribe(() => this.updateFromState());
		const routeName$ = this.router.events.pipe(
			filter((e: Event | RouterEvent) => e instanceof NavigationEnd),
			filter((routerEvent: RouterEvent) => routerEvent.url.indexOf('login') === -1),
			tap((routerEvent: RouterEvent) => this.currentScreenId.next(this.getScreenIdFromRoute(routerEvent.url))),
			mergeMap((routerEvent: RouterEvent) => of(this.getScreenNameFromRoute(routerEvent.url))),
      startWith(this.getScreenNameFromRoute(this.router.url)) // Ensure we have an initial value since service might start after initial app routing
		);
		// Converts router.events observable to a BehaviorSubject. That way every subscriber will immediately get the last value
		routeName$.subscribe(this.currentRouteName$)
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.activeScreenIDs$.pipe(
			filter(screenIDs => screenIDs.length !== 0),
			mergeMap((screenIDs: Array<number>) => {
				const allowed = this.isRouteAllowed(state, screenIDs);
				return of(allowed);
			}),
			tap(allowed => {
				if (!allowed) {
					this.router.navigate(['/404']);
				}
			})
		);
	}

	isRouteAllowed(state: RouterStateSnapshot, screenIDs: Array<number>): boolean {
		const currentRoute: ActiveScreenRoute = ActiveScreenManagementService.screens.find(screen => screen.route === state.url);
		return screenIDs.indexOf(currentRoute.id) !== -1;
	}

	updateFromState() {
		const temp = this.stateService.getUserData().modules;
		if (!!temp && temp.length !== 0) {
			this.activeScreenIDs.next(temp);
			this.activeScreenRoutes.next(this.getActiveRoutes(temp));
		}
	}

	getActiveRoutes(screenIDs: number[]): ActiveScreenRoute[] {
		let aid = ActiveScreenManagementService.screens.filter(screen => screenIDs.indexOf(screen.id) !== -1);
		return aid;
	}

	getScreenIdFromRoute(route: string): ActiveScreenIDs {
		if (route === "/" || !route) {
			route = "/home";
		}
		const activeScreen = ActiveScreenManagementService.screens.find(screen => screen.route === route);
		let id = (!!activeScreen) ? activeScreen.id : -1;
		return id;
	}

	getScreenNameFromRoute(route: string): string {
		if (route === "/") {
			route = "/home";
		}
		const activeScreen = ActiveScreenManagementService.screens.find(screen => screen.route === route);
		let name = activeScreen.name
		return name;
	}

	filterLayersByScreen(workspaceLayers: WorkspaceLayer[]): WorkspaceLayer[] {
		let activeScreenStr = `module_${this.stateService.getActiveModule()}`;

		return workspaceLayers
			.filter(workspace => {
				const temp = workspace.KeywordList.find(keyword => keyword === activeScreenStr);
				return !!temp;
			}).sort((a, b) => {
				const orderA = a.KeywordList.find(keyword => keyword.startsWith('order:'));
				const orderAindex = !!orderA ? parseInt(orderA.split(':')[1]) : 999; // If order does not exist set it last (999 as last order number...)

				const orderB = b.KeywordList.find(keyword => keyword.startsWith('order:'));
				const orderBindex = !!orderB ? parseInt(orderB.split(':')[1]) : 999; // If order does not exist set it last (999 as last order number...)

				return orderAindex - orderBindex;
			});
	}
}
