import { Component, OnInit, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { StateService } from '../../core/state.service';
import { WorkspaceLayer, EDIT_ACTIONS, FormOperation, FormTransaction, TableActionObject, FeatureInfo, ExportFileExtension } from 'app/models';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MapService } from '../map.service';
import { ModelOperationsService } from '../../core/model-operations.service';
import { ApiService } from '../../core/api.service';
import { SnackBarService } from '../../core/snack-bar.service';

import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { LayoutService } from 'app/core/layout.service';
import { GeoserverService } from 'app/core/geoserver.service';
import { MatDialog } from "@angular/material/dialog";
import { ExportToFileComponent } from "../export-to-file/export-to-file.component";

import { LOCALE_ID, Inject } from '@angular/core';
import { DecideDialogComponent } from 'app/shared/decide-dialog/decide-dialog.component';
@Component({
  selector: 'app-feature-table-multi',
  templateUrl: './feature-table-multi.component.html',
  styleUrls: ['./feature-table-multi.component.scss']
})
export class FeatureTableMultiComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isInDashboard: boolean = false;

  private stateUnsubscription: Function;
  public features: Array<Feature<Geometry>>;
  private selectionTimeStamp: number;
  public lastSelectedFeatures: Array<any> = [];
  public selectedLayer: WorkspaceLayer;
  public selectedTab: string;
  public featureInfo: FeatureInfo;
  private infoTimestamp = null;
  public tabIndex = 0;
  public selectedTabIndex;

  constructor(@Inject(LOCALE_ID) private localeId: string, private stateService: StateService,
    private mapService: MapService,
    private layoutService: LayoutService,
    private geoserverService: GeoserverService,
    private formDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    private modelOperationsService: ModelOperationsService) {

    this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => { this.updateFromState(); }, 0);
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.stateUnsubscription();
  }

  updateFromState() {
    let tempFeatureInfo = this.stateService.getFeatureInfoNew();
    this.selectedTabIndex = this.selectedLayer ? tempFeatureInfo.tables.findIndex(({ selectedLayer }) => selectedLayer?.Title === this.selectedLayer.Title) : 0;
    if (this.selectedTabIndex === -1) {
      this.selectedTabIndex = 0;
    }
    if (tempFeatureInfo && tempFeatureInfo.featureInfoVisible && tempFeatureInfo.tables[this.selectedTabIndex]?.timestamp !== this.infoTimestamp) {
      this.featureInfo = tempFeatureInfo;
      this.infoTimestamp = tempFeatureInfo.tables[this.selectedTabIndex].timestamp;
      this.features = tempFeatureInfo.tables[this.selectedTabIndex].features;
      this.selectedLayer = tempFeatureInfo.tables[this.selectedTabIndex].selectedLayer;
      this.selectedTab = this.selectedLayer.Title;
    }

    // Get cell from featureInfo array that contains the selectedFeatures
    let featureInfoSelected = tempFeatureInfo.tables.find(({ selectedLayer }) => selectedLayer?.Title === this.selectedLayer?.Title)?.selectedFeatures;

    if (!!featureInfoSelected?.features && featureInfoSelected?.selectedLayer?.Name === this.selectedLayer?.Name && this.selectionTimeStamp !== featureInfoSelected.timestamp) {
      this.selectionTimeStamp = featureInfoSelected.timestamp;
      this.lastSelectedFeatures = featureInfoSelected.features;
    }

  }

  onTabChange(tabChange: MatTabChangeEvent) {
    this.selectedTab = tabChange.tab.textLabel;
    const tableData = this.featureInfo.tables.find(rs => rs.selectedLayer.Title === this.selectedTab);
    this.features = tableData.features;
    this.selectedLayer = tableData.selectedLayer;
    let featureInfoSelected = this.stateService.getFeatureInfoNew().tables.find(({ selectedLayer }) => selectedLayer?.Title === this.selectedLayer?.Title)?.selectedFeatures;
    this.selectionTimeStamp = featureInfoSelected?.timestamp;
    // Get lastSelectedFeatures from current selectedTabIndex
    if (featureInfoSelected) {
      this.lastSelectedFeatures = featureInfoSelected.features;
    }
  }

  handleTableControlAction(action: TableActionObject) {
    switch (action.type) {
      case 'show': {
        this.onHighlight(null, null);
        break;
      }
      case 'clear': {
        this.onClear();
        break;
      }
      case 'zoom': {
        this.onZoom(null, null);
        break;
      }
      case 'close': {
        this.clearTable();
        break;
      }
      case 'delete': {
        // 1. Create a form transaction
        let deleteFormOperation: FormOperation = {
          layer: this.selectedLayer,
          layerModel: this.selectedLayer.Model,
          feature: this.lastSelectedFeatures,
          formHeader: 'Διαγραφή στοιχείων',
          timestamp: this.geoserverService.guid(),
          dirty: true,
          action: EDIT_ACTIONS.delete
        };

        // 2. Create transaction
        let deleteTransaction: FormTransaction = {
          timestamp: this.geoserverService.guid(),
          formOperations: [deleteFormOperation]
        };
        StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(deleteTransaction));
        break;
      }
      case 'export': {
        const dialogRef = this.formDialog.open(ExportToFileComponent, { width: '30rem' });

        dialogRef.afterClosed().subscribe((fileFormat: ExportFileExtension) => {
          if (!fileFormat) {
            return;
          }
          this.layoutService.showHeaderLoader(true);
          if (fileFormat === '.kmz') {
            this.exportKMZ(this.selectedLayer);
          } else {
            this.exportShapeAndCSV(this.selectedLayer, fileFormat);
          }
        });
        break;
      }
      case 'calculate': {
        // Open confirmation dialog with custom title and message
        this.formDialog.open(DecideDialogComponent, {
          data: {
            title: $localize`Υπολογισμός υψομέτρου`,
            message: $localize`Θέλετε σίγουρα να υπολογίσετε το υψόμετρο για όλα τα στοιχεία του συγκεκριμένου επιπέδου;`,
            cancel: $localize`Ακύρωση`,
            confirm: $localize`Υπολογισμός`
          }
        }).afterClosed().subscribe(confirmed => {
          if (confirmed) {
            // If the user confirms, proceed with the calculation
            StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));
            this.apiService.calculateElevationForLayer(this.selectedLayer.Name.split(':')[1]).subscribe((responce: any) => {
              this.snackBarService.setMessage(responce.message, 10000);
              this.modelOperationsService.updateMultiTableData(this.selectedLayer, this.lastSelectedFeatures);
              StateService.stateStore.dispatch(this.stateService.setLoadingOperation(false, '', -1, false));
            });
          }
        });
        break;
      }
      case 'move':
        // Add feature(s) to destination layer
        this.modelOperationsService.persist(action.destinationLayer, null, action.features, null, "");

        // Remove feature(s) from source layer
        this.modelOperationsService.persist(this.selectedLayer, null, null, action.features, this.localeId === 'en' ? "The transfer of the selected items was completed successfully." : "Η μεταφορά των επιλεγμένων στοιχείων ολοκληρώθηκε επιτυχώς.",);
        break;
      default: {
        break;
      }
    }
  }

  exportKMZ(layer: WorkspaceLayer, exportViewport = false) {
    this.mapService.getExportURL('kmz', [layer], exportViewport);
  }

  exportShapeAndCSV(layer: WorkspaceLayer, fileExt: ExportFileExtension) {
    this.geoserverService.exportWfs(fileExt === '.csv' ? 'csv' : 'shape-zip', layer);
  }

  handleRowSelect(selectedRows: Feature[]) {
    this.onFeatureClick(selectedRows);
    StateService.stateStore.dispatch(this.stateService.setFeatureInfoSelectedFeatures(this.selectedLayer, selectedRows));
  }

  onFeatureClick(features: Feature[]) {
    this.mapService.clearVectorLayer();
    if (features.length > 0) {
      this.mapService.onSelectFeaturesFromTable(features);
    }
    StateService.stateStore.dispatch(this.stateService.setFormDataOperation({
      features: features,
      selectedLayer: this.selectedLayer,
      totalFeatures: 1,
      eventFrom: 2
    }));
  }

  onHighlight(features, index) {
    this.mapService.clearVectorLayer();
    this.mapService.addFeaturesToVectorLayer(this.features);
  }

  onZoom(features, index) {
    this.mapService.zoomToFeatures(this.features);
  }

  onClear() {
    this.mapService.clearVectorLayer();
  }

  clearTable() {
    StateService.stateStore.dispatch(this.stateService.setFeatureInfoOperation([]));
    this.layoutService.toggleMultiTable(false);
  }

  onPageChange() {
    this.onFeatureClick([]);
  }
}
