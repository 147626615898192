<div class="flex flex-col gap-1">

  <!-- <mat-select [disabled]="currentEditorMode!=3" placeholder="Επιλογή επιπέδου" placeholder="Επιλογή επιπέδου">
    <mat-option *ngFor="let layer of workspaceLayers" value="layer" (click)="onLayerSelect(layer)">
      {{layer.Title}}
    </mat-option>
  </mat-select> -->

  <app-editor-controls *ngIf="selectedLayer" [layerName]="selectedLayer.Name" [selectedLayer]="selectedLayer"
    [canSplit]="canSplit" [hideControls]="true" #editorControls (userAction)="onUserAction($event)"
    (splitChecked)="onSplitChecked($event)">
  </app-editor-controls>

  <app-coord-editor *ngIf="(activeOperation === 7 && geometryType === 'MultiPoint') || activeOperation === 6"
    [operation]="activeOperation" (editCoordinate)="onEditCoordinate($event)" (addCoordinate)="onAddCoordinate($event)">
  </app-coord-editor>

</div>
