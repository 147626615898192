import { Component, Output, EventEmitter } from '@angular/core';
import { FormField } from "app/mr-form/models/form-field";

@Component({
	selector: 'form-input',
	styleUrls: ['form-input.component.scss'],
	templateUrl: 'form-input.component.html'
})
export class FormInputComponent extends FormField {
	@Output() calculateElevation: EventEmitter<any> = new EventEmitter<any>();

	onCalculateElevation() {
		this.calculateElevation.emit();
	}

}