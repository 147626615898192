<div class="flex flex-row gap-1.5 items-center">
  <div *ngIf="isInputVisible" class="autocomplete-wrapper card-style">
    <input (input)="search($event.target.value)" id="autocomplete" type="text" class="autocomplete-input"
      i18n-placeholder="Search Address | Search Address" placeholder="Αναζήτηση διεύθυνσης"
      [formControl]="streetControl" [matAutocomplete]="auto" />

    <mat-autocomplete [displayWith]="displayFn" (optionSelected)="onSelectAddress($event)" #auto="matAutocomplete">
      <mat-option *ngFor="let street of filteredStreets$ | async" [value]="street">
        {{ street.address }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <button class="button" (click)="toggleInputVisibility()">
    <mat-icon aria-label="Search Address">search</mat-icon>
  </button>
</div>

<button class="button" (click)="onActivateGeolocation()">
  <mat-icon aria-label="Enable geolocation">location_searching</mat-icon>
</button>

<button class="button" (click)="toggleBasemapListVisibility()">
  <img *ngIf="selectedBasemapImage" [src]="selectedBasemapImage" class="basemap-toggle-image" />
  <ng-template #defaultIcon>
    <mat-icon class="flex" aria-label="Select Map">map</mat-icon>
  </ng-template>
</button>

<app-basemap-list *ngIf="basemapListVisible"
  (basemapImageSelected)="handleBasemapImageSelected($event)"></app-basemap-list>
