import { Component, Output, EventEmitter, ViewChild, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { EDIT_ACTIONS, WorkspaceLayer } from '../../models';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-editor-controls',
    templateUrl: './editor-controls.component.html',
    styleUrls: ['./editor-controls.component.scss']
})
export class EditorControlsComponent implements OnInit, OnChanges{
    public splitActive=false;
    actionDisabled: Map<string, boolean> = new Map([["create", false], ["update", false], ["delete", false]]);
    currentAction: EDIT_ACTIONS = undefined;
    editActions = EDIT_ACTIONS;
    @Input() layerName: string; // This is only in order to force change detection on selectedLayer object
    @Input() selectedLayer: WorkspaceLayer;
    @Input() canSplit: boolean;
    @Input() hideControls: boolean;
    @Output() userAction: EventEmitter<EDIT_ACTIONS> = new EventEmitter();
    @ViewChild('group') group: MatButtonToggleGroup;

    ngOnInit() {
        this.resetAllowedActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['layerName'] && !!changes['layerName'].currentValue) {
            this.resetAllowedActions();
        }
    }

    resetAllowedActions() {
        this.actionDisabled.set('create', !this.isAllowedAction(EDIT_ACTIONS.create));
        this.actionDisabled.set('update', !this.isAllowedAction(EDIT_ACTIONS.update));
        this.actionDisabled.set('delete', !this.isAllowedAction(EDIT_ACTIONS.delete));
    }

    onCreate(calledByParent: boolean = false) {
        this.userAction.emit(EDIT_ACTIONS.create);
        this.currentAction = EDIT_ACTIONS.create;
        this.actionDisabled.set('update', true);
        this.actionDisabled.set('delete', true);
    }

    onUpdate(calledByParent: boolean = false) {
        this.userAction.emit(EDIT_ACTIONS.update);
        this.currentAction = EDIT_ACTIONS.update;
        this.actionDisabled.set('create', true);
        this.actionDisabled.set('delete', true);
    }

    onDelete(calledByParent: boolean = false) {
        this.userAction.emit(EDIT_ACTIONS.delete);
        this.currentAction = EDIT_ACTIONS.delete;
        this.actionDisabled.set('update', true);
        this.actionDisabled.set('create', true);
    }

    onCancel(calledByParent: boolean = false) {
        if (!calledByParent) {
            this.userAction.emit(null);
        }
        this.currentAction = undefined;
        this.resetAllowedActions();
        if (!this.hideControls && !!this.group) {
            this.group.value = "";
        }
    }

    // Checks from selected Layer model if the action provided is allowed from server config
    isAllowedAction(action: EDIT_ACTIONS) {
        if (!this.selectedLayer.Model.edit_actions) {
            if (action !== EDIT_ACTIONS.delete) {
                return true;
            } else return false;
        } else {
            return !!this.selectedLayer.Model.edit_actions.find(a => a === action);
        }
    }

    public parentAction(action: EDIT_ACTIONS | boolean) {
        switch (action) {
            case EDIT_ACTIONS.create: {
                this.onCreate(true);
            }
            case EDIT_ACTIONS.update: {
                this.onUpdate(true);
            }
            case EDIT_ACTIONS.delete: {
                this.onDelete(true);
            }
            default: {
                this.onCancel(true);
            }
        }
    }

    public onSplitToggle(change: MatCheckboxChange) {
        if (change.checked) {
            this.splitActive=true;
        } else {
            this.splitActive=false;
        }
    }
}
