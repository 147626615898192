// Angular imports
import { Injectable } from '@angular/core';

// App imports 
import { StateService } from '../../core/state.service';
import { GeoserverService } from '../../core/geoserver.service';
import { ModelOperationsService } from '../../core/model-operations.service';
import { MapService } from '../map.service';
import { WorkspaceLayer, FormOperation, FormTransaction, FormOperationsGroup, EDIT_ACTIONS } from '../../models';

// Openlayers imports
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import { Snap } from 'ol/interaction.js';
import { LineString, Point, MultiLineString, Polygon, MultiPolygon, MultiPoint } from 'ol/geom';
import LinearRing from 'ol/geom/LinearRing';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import Geometry from 'ol/geom/Geometry';
import { Draw } from 'ol/interaction.js';

// Vendor imports
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser.js';
import { Geometry as jstsGeometry } from 'jsts/org/locationtech/jts/geom/Geometry';
import DistanceOP from 'jsts/org/locationtech/jts/operation/distance/DistanceOp';
import RelateOp from 'jsts/org/locationtech/jts/operation/relate/RelateOp';
import { SnackBarService } from 'app/core/snack-bar.service';
import { LOCALE_ID, Inject } from '@angular/core';

@Injectable()
export class TopologyService {

	private editingSnapInteractions: Array<Snap>;
	private editingWorkspaceLayers: Array<WorkspaceLayer>;
	private snapWfsLayers: Array<VectorLayer<any>>;
	private snapAlwaysWfsLayers: Array<VectorLayer<any>>;
	private relatedWfsLayers: Array<VectorLayer<any>>;
	private workspace: string;
	private map: Map;
	private jstsParser: any;
	private freehandInteraction: Draw;

	constructor(@Inject(LOCALE_ID) private localeId: string, private mapService: MapService, private stateService: StateService,
		private snackBarService: SnackBarService, private geoserverService: GeoserverService,
		private modelOperationsService: ModelOperationsService) {
		this.editingSnapInteractions = [];
		this.editingWorkspaceLayers = [];
		this.snapWfsLayers = [];
		this.snapAlwaysWfsLayers = [];
		this.relatedWfsLayers = [];
		this.workspace = this.stateService.getWorkspace();
		this.map = this.mapService.getMap();
		this.jstsParser = new OL3Parser();
		this.jstsParser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);
	}

	// ##########################################
	// ######## FLATTEN TOPOLOGY LAYERS #########
	// ##########################################
	flattenWorkspaceLayers(workspaceLayers: Array<WorkspaceLayer>) {
		let flattenWorkspaceLayers = [];
		workspaceLayers.map((layer: any) => {
			if (layer.Layer) {
				flattenWorkspaceLayers = [...flattenWorkspaceLayers, ...layer.Layer];
			}
		});

		this.editingWorkspaceLayers = flattenWorkspaceLayers;
		return flattenWorkspaceLayers;
	}

	layerContainsKeyword(layer: WorkspaceLayer, keyword: string) {

		for (let i = 0; i < layer.KeywordList.length; i++) {
			if (layer.KeywordList[i].includes(keyword)) {
				return true;
			}
		}

		return false;
	}

	addDrawInteraction() {
		if (!this.map) {
			this.map = this.mapService.getMap();
		}
		this.freehandInteraction = new Draw({
			source: this.mapService.getVectorLayer().getSource(),
			type: 'Polygon'
		});

		this.map.addInteraction(this.freehandInteraction);

		return this.freehandInteraction;
	}

	removeDrawInteraction() {
		if (this.freehandInteraction) {
			this.map.removeInteraction(this.freehandInteraction);
		}
	}

	// ##########################################
	// ################## SNAP ##################
	// ##########################################
	addSnapInteractions(layerstoSnapTo: Array<string>) {
		this.snapWfsLayers = [];
		this.snapAlwaysWfsLayers = [];
		this.editingSnapInteractions = [];

		for (let index = 0; index < layerstoSnapTo.length; index++) {
			const snapLayerName = layerstoSnapTo[index];
			const snapToLayer = this.getWorkspaceLayerByName(snapLayerName);
			let snapToLayerWfs;

			if (snapToLayer) {
				snapToLayerWfs = this.mapService.toggleWFSService(snapToLayer, this.mapService.getMap().getLayers().getLength(), true, undefined, undefined, '_snap');
				snapToLayerWfs.setStyle(this.generateStyle(snapToLayer));

				// Distinguish snap layers that will need topology check(line 277) from always_snap layer that will not 
				if (snapToLayer.Model.snap_always) {
					this.snapAlwaysWfsLayers.push(snapToLayerWfs);
				} else {
					this.snapWfsLayers.push(snapToLayerWfs);
				}

			} else {
				continue;
			}

			let layerSnapInteraction = new Snap({
				source: snapToLayerWfs.getSource()
				// ,pixelTolerance:20
			});
			this.editingSnapInteractions.push(layerSnapInteraction);
			this.map.addInteraction(layerSnapInteraction);
		}

	}

	removeSnapWfsLayersOfMap() {

		for (let index = 0; index < this.snapWfsLayers.length; index++) {
			const snapLayer = this.snapWfsLayers[index];
			this.removeLayerFromMap(snapLayer.get('name'));
		}

		for (let index = 0; index < this.snapAlwaysWfsLayers.length; index++) {
			const snapLayer = this.snapAlwaysWfsLayers[index];
			this.removeLayerFromMap(snapLayer.get('name'));
		}
	}

	// ##########################################
	// ################ RELATED #################
	// ##########################################
	addRelatedLayers(workspaceLayers: Array<WorkspaceLayer>, relatedLayers: Array<string>) {

		for (let index = 0; index < relatedLayers.length; index++) {
			const relatedLayerName = relatedLayers[index];
			const relatedLayer = this.getWorkspaceLayerByName(relatedLayerName);
			let relatedLayerWfs;

			if (relatedLayer) {
				relatedLayerWfs = this.mapService.toggleWFSService(relatedLayer, this.mapService.getMap().getLayers().getLength(), true, undefined, undefined, '_related');
				relatedLayerWfs.setStyle(this.generateStyle(relatedLayer));
				this.relatedWfsLayers.push(relatedLayerWfs);
			} else {
				continue;
			}

		}

	}

	removeRelatedWfsLayersOfMap() {
		for (let index = 0; index < this.relatedWfsLayers.length; index++) {
			const snapLayer = this.relatedWfsLayers[index];
			this.removeLayerFromMap(snapLayer.get('name'));
		}
	}

	removeLayerFromMap(layerName: string) {
		this.map.getLayers().forEach((currentLayer) => {
			if (currentLayer && currentLayer.get('name') === layerName) {
				this.map.removeLayer(currentLayer);
			}
		});
	}

	removeSnapInteractionsOfMap() {
		for (let index = 0; index < this.editingSnapInteractions.length; index++) {
			const snapInteraction = this.editingSnapInteractions[index];
			this.map.removeInteraction(snapInteraction);
		}
	}

	// ##########################################
	// ############ TOPOLOGY CHECKS #############
	// ##########################################	
	topologyChecks(currentEditedWorkspaceLayer: WorkspaceLayer, currentEditedLayer: VectorLayer<any>, newFeature: Feature<Geometry>, currentWorkspaceLayer: WorkspaceLayer, splitActive: boolean = false) {

		let splitTransaction: FormTransaction;
		// Layer name
		let layerName = currentEditedLayer.get('name').split(':')[1];

		// Get start point of the new feature		
		let startingPoint = (newFeature.getGeometry() as SimpleGeometry).getFirstCoordinate();

		// 1. Snap checks
		let snappedElement = this.snapChecks(currentEditedWorkspaceLayer, currentEditedLayer, startingPoint);
		if (!snappedElement) {
			this.snackBarService.setMessage(this.localeId === 'en' ? "Geometry does not start from a permissible point. Try again" : "Η γεωμετρία δεν ξεκινάει από επιτρεπόμενο σημείο. Προσπαθήστε ξανά", 4000);
			return false;
		}

		// 2. Split actions if any(if is a switch ignore splitActive as a switch always splits a pipe)
		if (layerName != 'switches_edit' && currentEditedWorkspaceLayer.Model.on_edit_split.length > 0 && splitActive) {
			splitTransaction = this.splitActions(currentEditedWorkspaceLayer, currentEditedLayer, newFeature, snappedElement);
		}

		// 3. Actions
		switch (layerName) {
			case 'pipes_edit':
				// if (this.isEdgeTouchesAndNotIntersects(currentEditedLayer, newFeature.getGeometry())) {
				// 	this.snackBarService.setMessage("Ο αγωγός τέμνει κάποιον άλλον αγωγό του δικτύου.", 4000);
				// 	return false;
				// }
				this.pipesTopologyActions(newFeature, currentWorkspaceLayer);
				break;
			case 'supplies_edit':
				if (this.isEdgeTouchesAndNotIntersects(currentEditedLayer, newFeature.getGeometry())) {
					this.snackBarService.setMessage("Η παροχή τέμνει κάποια άλλη παροχή του δικτύου. Παρακαλώ προσπαθήστε ξανά.", 4000);
					return false;
				}
				this.suppliesTopologyActions(newFeature, currentWorkspaceLayer);
				break;
			case 'switches_edit':
				// The switch is intersecting the pipe so the pipe splits
				if (!this.pointIsOnLineEdgeAndNotIntersects(snappedElement.getGeometry(), newFeature.getGeometry())) {
					splitTransaction = this.splitActions(currentEditedWorkspaceLayer, currentEditedLayer, newFeature, snappedElement);
					this.switchesTopologyActions(newFeature, currentWorkspaceLayer, snappedElement, splitTransaction);
				} else {
					this.switchesTopologyActions(newFeature, currentWorkspaceLayer, null, splitTransaction);
				}

				break;
			case 'network_parts_edit':
				this.networkPartsTopologyActions(newFeature, currentWorkspaceLayer, splitTransaction);
				break;
			case 'hydrometers_edit':
				this.hydrometersTopologyActions(newFeature, currentWorkspaceLayer);
				break;
			case 'hydrants_edit':
				this.hydrantsTopologyActions(newFeature, currentWorkspaceLayer);
				break;
			case 'network_facilities_edit':
				this.networkFacilitiesTopologyActions(newFeature, currentWorkspaceLayer, splitTransaction);
				break;
			case 'facilities_edit':
				this.facilitiesTopologyActions(newFeature, currentWorkspaceLayer);
				break;
			case 'control_valves_edit':
				this.controlValvesTopologyActions(newFeature, currentWorkspaceLayer, splitTransaction);
				break;
			case 'drainage_pipes_edit':
				this.drainagePipesTopologyActions(newFeature, currentWorkspaceLayer);
				break;
			case 'drainage_parts_edit':
				this.drainagePartsTopologyActions(newFeature, currentWorkspaceLayer, splitTransaction);
				break;
			case 'facilities_drainage_edit':
				this.drainageFacilitiesTopologyActions(newFeature, currentWorkspaceLayer);
				break;
			case 'wells_edit':
				this.drainageWellsTopologyActions(newFeature, currentWorkspaceLayer, splitTransaction);
				break;
			default:
				break;
		}

		return true;
	}

	/**
	 * If the drawing starting point snaps to any of the snap layers
	 * return true else return false 
	 * @param startingPoint The user drawing starting point
	 */
	snapChecks(currentEditedWorkspaceLayer: WorkspaceLayer, currentEditedLayer: VectorLayer<any>, startingPoint: any): any {

		// If there are no snap checks return true
		if (this.snapWfsLayers.length === 0) {
			return true;
		}

		let startingPointOlGeom = new Point(startingPoint);

		// For each snap layer if at least one is in snap distance
		for (let index = 0; index < this.snapWfsLayers.length; index++) {
			//Get snap layer
			const snapLayer = this.snapWfsLayers[index];
			let closestFeatureOfSnapLayerToCoordinate = snapLayer.getSource().getClosestFeatureToCoordinate(startingPoint);
			if (!closestFeatureOfSnapLayerToCoordinate) continue;

			// If we are editing supplies layer check that the supply has been drawned the right way(first snapped in a pipe)
			// Ignore snap check to hydrometer 
			if (currentEditedLayer.get('name').split(':')[1] === "supplies_edit" && snapLayer.get('name').split(':')[1] === "hydrometers_snap") {
				continue;
			}

			let closestSnapFeatureJstsGeom: jstsGeometry = this.jstsParser.read(closestFeatureOfSnapLayerToCoordinate.getGeometry());
			let startingPointJstsGeom: jstsGeometry = this.jstsParser.read(startingPointOlGeom);

			// Calculate the distance between starting point of user drawing and snap layer closest feature
			let startingPointDistanceToNearest = DistanceOP.distance(startingPointJstsGeom, closestSnapFeatureJstsGeom);

			if (Math.round(startingPointDistanceToNearest) === 0) {
				// Try to add vertex if the snapped layer is a line or polygon
				let snappedFeatureType = closestFeatureOfSnapLayerToCoordinate.getGeometry().getType();
				if (snappedFeatureType === 'LineString' || snappedFeatureType === 'MultiLineString' || snappedFeatureType === 'Polygon' || snappedFeatureType === 'MultiPolygon') {
					// Create an extra vertex only if we don't split.
					if (currentEditedWorkspaceLayer.Model.on_edit_split.length === 0) {
						this.createVertexIfNotExists(snapLayer, closestFeatureOfSnapLayerToCoordinate, startingPointOlGeom);
					}
				}
				return closestFeatureOfSnapLayerToCoordinate;
			}
		}

		return false;
	}

	createVertexIfNotExists(snapLayer, featureToAddVertex: Feature<Geometry>, vertexToAdd: Point) {
		const snapLayerName = snapLayer.get('name').split('_snap')[0];
		const snapToLayer = this.getWorkspaceLayerByName(snapLayerName.split(':')[1]);
		const featureGeometry = featureToAddVertex.getGeometry();
		let vertexToAddJstsGeom = this.jstsParser.read(vertexToAdd);

		if (featureGeometry.getType() === "MultiLineString") {

			const lineString = (featureGeometry as MultiLineString).getLineString(0);
			lineString.forEachSegment((segmentStart, segmentEnd) => {
				const segmentLineString = new LineString([segmentStart, segmentEnd]);
				const segmentLineJstsGeom = this.jstsParser.read(segmentLineString);

				if (DistanceOP.distance(segmentLineJstsGeom, vertexToAddJstsGeom) < 0.0000001) {
					// Try to find the index of the segment start coord so we can intercept the vertex 
					const lineStringCoordinates = lineString.getCoordinates();

					lineStringCoordinates.every((coordinate, index) => {

						// Try not to create a vertex if already exists
						if (coordinate[0] === segmentStart[0] && coordinate[1] === segmentStart[1] &&
							lineStringCoordinates[index + 1][0] != vertexToAdd.getFirstCoordinate()[0] &&
							lineStringCoordinates[index + 1][1] != vertexToAdd.getFirstCoordinate()[1] &&
							coordinate[0] != vertexToAdd.getFirstCoordinate()[0] &&
							coordinate[1] != vertexToAdd.getFirstCoordinate()[1]) {

							lineStringCoordinates.splice(index + 1, 0, vertexToAdd.getFirstCoordinate());
							const multiLineString = new MultiLineString([lineStringCoordinates]);
							featureToAddVertex.setGeometry(multiLineString);
							this.modelOperationsService.persist(snapToLayer, [featureToAddVertex], null, null, "Δημιουργήθηκε κορυφή στον αγωγό", false);

							return false;// Break every
						}

						return true;
					});

				}
			});

		}

		// Not implemented yet
		// else if(featureGeometry.getType()==="MultiPolygon"){

		// 	const polygon = featureGeometry.getPolygon(0);
		// 	polygon.forEachSegment((start, end) => {
		// 		const segmentLineString = new openlayers.geom.LineString([start, end]);
		// 		const segmentLineJstsGeom = this.jstsParser.read(segmentLineString);

		// 		if( segmentLineJstsGeom.distance(vertexToAddJstsGeom)<0.0000001 ){
		// 			// Try to find the index of the segment start coord so we can intercept the vertex 
		// 			const lineStringCoordinates = polygon.getCoordinates();
		// 			lineStringCoordinates.forEach((coordinate,index)=>{
		// 				if( coordinate[0] === start[0] && coordinate[1] === start[1] ){
		// 					lineStringCoordinates.splice(index+1, 0, vertexToAdd.getFirstCoordinate());
		// 					// lineString.setCoordinates(lineStringCoordinates);

		// 					const multiLineString = new openlayers.geom.MultiLineString([lineStringCoordinates]);
		// 					featureToAddVertex.setGeometry(multiLineString);
		// 					this.modelOperationsService.persist(snapToLayer,[featureToAddVertex],null,null);
		// 				}
		// 			})
		// 		}
		// 	});

		// }		
	}

	splitActions(currentEditedWorkspaceLayer: WorkspaceLayer, currentEditedLayer: VectorLayer<any>,
		newFeature: Feature<Geometry>, snappedFeature: Feature<Geometry>, returnTransaction: boolean = true) {

		let featureSplitted = false;
		let splitTransaction: FormTransaction;
		let splitLayerName = currentEditedWorkspaceLayer.Model.on_edit_split[0];
		let layerTosplit = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':' + splitLayerName);
		let snappedFeatureGeometry = snappedFeature.getGeometry();
		let newFeatureGeometry = newFeature.getGeometry();

		// 1. Double check that the type of the layer to split is MultiLineString
		if (layerTosplit.Model.geometryType != "MultiLineString") {
			return;
		}

		// 2. First check that the new feature in not the first or last coordinate of the feature to split
		let snappedFeatureFirstCoordinate = (snappedFeatureGeometry as SimpleGeometry).getFirstCoordinate();
		let snappedFeatureFirstCoordinateOlGeom = new Point(snappedFeatureFirstCoordinate);
		let snappedFeatureLastCoordinate = (snappedFeatureGeometry as SimpleGeometry).getLastCoordinate();
		let snappedFeatureLastCoordinateOlGeom = new Point(snappedFeatureLastCoordinate);

		let snappedFeatureFirstCoordinateJstsGeom = this.jstsParser.read(snappedFeatureFirstCoordinateOlGeom);
		let snappedFeatureLastCoordinateJstsGeom = this.jstsParser.read(snappedFeatureLastCoordinateOlGeom);
		let newFeatureJstsGeom = this.jstsParser.read(newFeatureGeometry);


		// 3. Calculate the distance between starting point of user drawing and snap layer closest feature
		let startingPointDistanceToSnapped = DistanceOP.distance(newFeatureJstsGeom, snappedFeatureFirstCoordinateJstsGeom);
		let endingPointDistanceToSnapped = DistanceOP.distance(newFeatureJstsGeom, snappedFeatureLastCoordinateJstsGeom);

		if (Math.round(startingPointDistanceToSnapped * 100) / 100 < 0.01 || Math.round(endingPointDistanceToSnapped * 100) / 100 < 0.01) {
			// No split actions
			return splitTransaction;
		}

		// 4. Then locate the closest coordinate of the snapped feature to the new feature
		// Some caution here because if the new feature is exactly drawned on a vertex the distance check will 
		// return two segments so its safe to break if we find just one.
		const lineString = (snappedFeatureGeometry as MultiLineString).getLineString(0);
		lineString.forEachSegment((start, end) => {
			const segmentLineString = new LineString([start, end]);
			const segmentLineJstsGeom = this.jstsParser.read(segmentLineString);

			if (DistanceOP.distance(segmentLineJstsGeom, newFeatureJstsGeom) < 0.0000001) {

				// Continue only if we did not split yet
				if (featureSplitted) return;

				// Try to find the index of the segment start coord so we can split the line
				const lineStringCoordinates = lineString.getCoordinates();
				lineStringCoordinates.forEach((coordinate, index) => {
					if (coordinate[0] === start[0] && coordinate[1] === start[1]) {
						let leftLineCoordinateArray = lineStringCoordinates.slice(0, index + 1);
						leftLineCoordinateArray = [...leftLineCoordinateArray, (newFeatureGeometry as SimpleGeometry).getFirstCoordinate()];
						let rightLineCoordinateArray = lineStringCoordinates.slice(index + 1, lineStringCoordinates.length);
						rightLineCoordinateArray = [(newFeatureGeometry as SimpleGeometry).getFirstCoordinate(), ...rightLineCoordinateArray];

						// Create the new lines
						const leftLineGeom = new MultiLineString([leftLineCoordinateArray]);
						const rightLineGeom = new MultiLineString([rightLineCoordinateArray]);
						const leftLinefeature = new Feature({ geometry: leftLineGeom });
						const rightLinefeature = new Feature({ geometry: rightLineGeom });

						let snappedFeatureProperties = { ...snappedFeature.getProperties() };
						delete snappedFeatureProperties.id;
						delete snappedFeatureProperties.geometry;
						leftLinefeature.setProperties(snappedFeatureProperties);
						rightLinefeature.setProperties(snappedFeatureProperties);


						if (returnTransaction) {
							let formOperations: Array<FormOperation | FormOperationsGroup> = [];
							let deleteOriginalPipeFormOperation: FormOperation = {
								layer: layerTosplit,
								layerModel: layerTosplit.Model,
								feature: snappedFeature,
								formHeader: $localize`Διαγραφή αρχικού αγωγού`,
								timestamp: this.geoserverService.guid(),
								dirty: true,
								action: EDIT_ACTIONS.delete,
								silent: true
							}
							formOperations.push(deleteOriginalPipeFormOperation);

							let createLeftPipeFormOperation: FormOperation = {
								layer: layerTosplit,
								layerModel: layerTosplit.Model,
								feature: leftLinefeature,
								formHeader: $localize`Δημιουργία αριστερού αγωγού`,
								timestamp: this.geoserverService.guid(),
								dirty: true,
								action: EDIT_ACTIONS.create,
								silent: true
							}
							formOperations.push(createLeftPipeFormOperation);

							let createRightPipeFormOperation: FormOperation = {
								layer: layerTosplit,
								layerModel: layerTosplit.Model,
								feature: rightLinefeature,
								formHeader: $localize`Δημιουργία δεξιού αγωγού`,
								timestamp: this.geoserverService.guid(),
								dirty: true,
								action: EDIT_ACTIONS.create,
								silent: true
							}
							formOperations.push(createRightPipeFormOperation);

							splitTransaction = {
								timestamp: this.geoserverService.guid(),
								formOperations: formOperations
							}

						} else {
							// Old method : To be deleted
							// Delete the original line and add the 2 new lines
							this.modelOperationsService.persist(layerTosplit, null, null, [snappedFeature], null, false);
							this.modelOperationsService.persist(layerTosplit, null, [leftLinefeature], null, null, false);
							setTimeout(() => {// Timeout because of geoserver denial
								this.modelOperationsService.persist(layerTosplit, null, [rightLinefeature], null, null, false);
							}, 500);
						}

						// Return immediately
						featureSplitted = true;
					}
				})
			}
		});

		return splitTransaction;
	}

	// ##########################################
	// ######## AFTER CHECKS OPERATIONS #########
	// ##########################################
	pipesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer) {
		let formOperations: Array<FormOperation | FormOperationsGroup> = [];

		// ______________
		// 1. Create pipe

		let pipeCoordinates = (newFeature.getGeometry() as MultiLineString).getCoordinates();
		let pipesLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':pipes');
		let pipeFeature = new Feature({
			material: 'PVC',
			pressurized: 'no',
			init_status:'open'
		});
		// pipeFeature.setGeometry(new geom[pipesLayer.Model.geometryType](pipeCoordinates));
		pipeFeature.setGeometry(new MultiLineString(pipeCoordinates));

		let pipeFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: pipeFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου αγωγού`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}
		formOperations.push(pipeFormOperation);

		// ______________________________________________________________________________________
		// 2. Create network part on last pipe coordinate if a point of the snap layers not found
		let lastPoint = (newFeature.getGeometry() as Point).getLastCoordinate();
		let snapToLayersNames = this.geoserverService.getKeyValue(pipesLayer, 'on_edit_snap_to');
		let snapFeatureToLastPointFound = false;
		for (let index = 0; index < snapToLayersNames.length; index++) {
			const snapLayerName = snapToLayersNames[index];
			let snapLayer;
			this.map.getLayers().forEach((layer, index) => {
				if (layer.get('name') === this.stateService.getWorkspace() + ":" + snapLayerName + "_snap") {
					snapLayer = layer;
				}
			});
			if (snapLayer) {
				let closestNetworkPartInLastPipeCoordinate = snapLayer.getSource().getClosestFeatureToCoordinate(lastPoint);

				let lastPointDistanceToClosest;
				if (closestNetworkPartInLastPipeCoordinate) {

					// Check if distance is zero
					let closestInLastJstsGeom = this.jstsParser.read(closestNetworkPartInLastPipeCoordinate.getGeometry());
					let lastPipePointOlGeom = new Point(lastPoint);

					// Distances
					lastPointDistanceToClosest = DistanceOP.distance(closestInLastJstsGeom, this.jstsParser.read(lastPipePointOlGeom));
				}

				// If network part is not found on last point create one
				if (Math.round(lastPointDistanceToClosest) === 0) {
					snapFeatureToLastPointFound = true;
					break;
				}
			}
		}


		if (!snapFeatureToLastPointFound) {

			// If network part is not found on last point create one
			const networkPartsLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':network_parts');

			let networkPartFeature = new Feature({
				fittingtyp: 'tpa'
			});
			// networkPartFeature.setGeometry(new geom[networkPartsLayer.Model.geometryType]([lastPoint]));
			networkPartFeature.setGeometry(new MultiPoint([lastPoint]));

			let networkPartFormOperation: FormOperation = {
				layer: networkPartsLayer,
				layerModel: networkPartsLayer.Model,
				feature: networkPartFeature,
				formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου εξαρτήματος`,
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.create
			}

			// If network part is not found on last point create one
			const switchesLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':switches');

			let switchFeature = new Feature({
				fittingtyp: 'tpa'
			});
			// switchFeature.setGeometry(new geom[switchesLayer.Model.geometryType]([lastPoint]));
			switchFeature.setGeometry(new MultiPoint([lastPoint]));

			let switchFormOperation: FormOperation = {
				layer: switchesLayer,
				layerModel: switchesLayer.Model,
				feature: switchFeature,
				formHeader: $localize`Συμπληρώστε τα στοιχεία της νέας δικλείδας`,
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.create
			}

			formOperations.push({
				timestamp: this.geoserverService.guid(),
				description: $localize`Επιλέξτε τον τύπο της οντότητας στο τέλος του αγωγού.`,
				operations: [networkPartFormOperation, switchFormOperation]
			});

		}

		let pipeTransaction: FormTransaction = {
			timestamp: this.geoserverService.guid(),
			formOperations: formOperations
		}

		StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(pipeTransaction));
	}

	suppliesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer) {
		let suppliesFormOperations = [];

		// 1. Create supply
		let supplyCoordinates = (newFeature.getGeometry() as MultiLineString).getCoordinates();
		let suppliesLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':supplies');
		let supplyFeature = new Feature({
			material: 'polyethelene',
			diameter: '32'
		});
		// supplyFeature.setGeometry(new geom[suppliesLayer.Model.geometryType](supplyCoordinates));
		supplyFeature.setGeometry(new MultiLineString(supplyCoordinates));

		let supplyFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: supplyFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία της νέας παροχής`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}
		suppliesFormOperations.push(supplyFormOperation);

		// 2. Create network part 

		// First check if we have a network part at the end of the suuply and if not create it 
		if (!this.supplyHasNetworkPartOnStartPoint(newFeature)) {
			let startingPoint = (newFeature.getGeometry() as Point).getFirstCoordinate();
			let networkPartsLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':network_parts');
			let networkPartFeature = new Feature({
				fittingtyp: 'tpa'
			});
			// networkPartFeature.setGeometry(new geom[networkPartsLayer.Model.geometryType]([startingPoint]));
			networkPartFeature.setGeometry(new MultiPoint([startingPoint]));

			let networkPartFormOperation: FormOperation = {
				layer: networkPartsLayer,
				layerModel: networkPartsLayer.Model,
				feature: networkPartFeature,
				formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου εξαρτήματος`,
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.create
			}
			suppliesFormOperations.push(networkPartFormOperation);
		}
		// 3. Create hydrometer

		// First check if we have a hydrometer at the end of the suuply and if not create it 
		if (!this.supplyHasHydrometerOnEndPoint(newFeature)) {
			let endingPoint = (newFeature.getGeometry() as Point).getLastCoordinate();
			let hydrometersLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':hydrometers');
			let hydrometerFeature = new Feature({});
			// hydrometerFeature.setGeometry(new geom[hydrometersLayer.Model.geometryType]([endingPoint]));
			hydrometerFeature.setGeometry(new MultiPoint([endingPoint]));

			let hydrometerFormOperation: FormOperation = {
				layer: hydrometersLayer,
				layerModel: hydrometersLayer.Model,
				feature: hydrometerFeature,
				formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου υδρομέτρου`,
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.create
			}

			suppliesFormOperations.push(hydrometerFormOperation);
		}

		// 4. Create transaction 
		let supplyTransaction: FormTransaction = {
			timestamp: this.geoserverService.guid(),
			formOperations: suppliesFormOperations
		}

		StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(supplyTransaction));
	}

	supplyHasHydrometerOnEndPoint(supplyFeature: Feature<Geometry>): boolean {
		//const suppliesLayer = this.getWorkspaceLayerByName("supplies");
		const hydrometersLayer = this.getWorkspaceLayerByName("hydrometers");

		// Get start point of the new supply		
		let endPoint = (supplyFeature.getGeometry() as MultiLineString).getLastCoordinate();

		// Get hydrometers wfs layer from snapwfslayers
		let hydrometersWfsLayer;
		for (let index = 0; index < this.snapWfsLayers.length; index++) {
			if (this.snapWfsLayers[index].get('name').split(':')[1] === 'hydrometers_snap') {
				hydrometersWfsLayer = this.snapWfsLayers[index];
			}
		}

		if (hydrometersWfsLayer) {

			// Get closest hydrometer
			let hydrometerAtPoint = hydrometersWfsLayer.getSource().getClosestFeatureToCoordinate(endPoint);
			if (hydrometerAtPoint) {// If any found
				let hydrometerJstsGeom = this.jstsParser.read(hydrometerAtPoint.getGeometry());
				let supplyJstsGeom = this.jstsParser.read(supplyFeature.getGeometry());

				// And is at supply end point
				if (DistanceOP.distance(supplyJstsGeom, hydrometerJstsGeom) < 0.0000001) {
					return true;
				}
			}

		} else {
			return false;
		}

		return false;
	}

	supplyHasNetworkPartOnStartPoint(supplyFeature: Feature<Geometry>): boolean {

		// Get start point of the new supply		
		let startPoint = (supplyFeature.getGeometry() as MultiLineString).getFirstCoordinate();

		// Get network parts wfs layer from snapwfslayers
		let networkPartsWfsLayer;
		for (let index = 0; index < this.relatedWfsLayers.length; index++) {
			if (this.relatedWfsLayers[index].get('name').split(':')[1] === 'network_parts_related') {
				networkPartsWfsLayer = this.relatedWfsLayers[index];
			}
		}

		if (networkPartsWfsLayer) {

			// Get closest network part
			let networkPartAtPoint = networkPartsWfsLayer.getSource().getClosestFeatureToCoordinate(startPoint);
			if (networkPartAtPoint) {// If any found
				let networkPartJstsGeom = this.jstsParser.read(networkPartAtPoint.getGeometry());
				let supplyJstsGeom = this.jstsParser.read(supplyFeature.getGeometry());

				// And is at supply start point
				if (DistanceOP.distance(supplyJstsGeom,networkPartJstsGeom) < 0.0000001) {
					return true;
				}
			}

		} else {
			return false;
		}

		return false;
	}

	switchesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer, snappedPipe: Feature<Geometry> = null,
		splitTransaction: FormTransaction) {

		if (snappedPipe && snappedPipe.get('diatomi')) {
			newFeature.set('diatomi', snappedPipe.get('diatomi'));
		}

		newFeature.set('valvetype', 'ΕΛΑΣΤΙΚΗΣ ΕΜΦΡΑΞΗΣ');
		newFeature.set('operable', '1');
		newFeature.set('curropen', '1');

		let switchFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία της νέας δικλείδας`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		if (splitTransaction) {
			splitTransaction.formOperations.push(switchFormOperation);
			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(splitTransaction));
		} else {
			let switchTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [switchFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(switchTransaction));
		}

	}

	networkPartsTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer, splitTransaction: FormTransaction) {

		let networkPartFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου εξαρτήματος`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		if (splitTransaction) {
			splitTransaction.formOperations.push(networkPartFormOperation);
			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(splitTransaction));
		} else {
			let networkPartTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [networkPartFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(networkPartTransaction));
		}

	}

	controlValvesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer, splitTransaction: FormTransaction) {

		let controlValveFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία της νέας βάνας ελέγχου`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		if (splitTransaction) {
			splitTransaction.formOperations.push(controlValveFormOperation);
			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(splitTransaction));
		} else {
			let networkPartTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [controlValveFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(networkPartTransaction));
		}

	}

	hydrometersTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer) {

		let hydrometerFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου υδρομέτρου`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		let hydrometerTransaction: FormTransaction = {
			timestamp: this.geoserverService.guid(),
			formOperations: [hydrometerFormOperation]
		}

		StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(hydrometerTransaction));
	}

	hydrantsTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer) {

		let hydrantFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου πυροσβεστικού κρουνού`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		let hydrantTransaction: FormTransaction = {
			timestamp: this.geoserverService.guid(),
			formOperations: [hydrantFormOperation]
		}

		StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(hydrantTransaction));
	}

	networkFacilitiesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer, splitTransaction: FormTransaction) {

		let networkFacilityFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία της νέας εγκατάστασης ύδρευσης`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		if (splitTransaction) {
			splitTransaction.formOperations.push(networkFacilityFormOperation);
			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(splitTransaction));
		} else {
			let networkFacilityTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [networkFacilityFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(networkFacilityTransaction));
		}

	}

	facilitiesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer) {

		let facilityFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία της νέας εγκατάστασης`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		let facilityTransaction: FormTransaction = {
			timestamp: this.geoserverService.guid(),
			formOperations: [facilityFormOperation]
		}

		StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(facilityTransaction));
	}

	drainagePipesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer) {
		let formOperations = [];

		// 1. Create pipe
		let pipeCoordinates = (newFeature.getGeometry() as MultiLineString).getCoordinates();
		let pipesLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':drainage_pipes');
		let pipeFeature = new Feature({
			material: 'PVC',
			diatomi: '200',
			pressurized: 'no',
			pressure: '6',
			research: 'no'
		});
		// pipeFeature.setGeometry(new geom[pipesLayer.Model.geometryType](pipeCoordinates));
		pipeFeature.setGeometry(new MultiLineString(pipeCoordinates));

		let pipeFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: pipeFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου αγωγού αποχέτευσης`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}
		formOperations.push(pipeFormOperation);


		// ______________________________________________________________________________________
		// 2. Create part or well  on last pipe coordinate if a point of the snap layers not found
		let lastPoint = (newFeature.getGeometry() as Point).getLastCoordinate();
		let snapToLayersNames = this.geoserverService.getKeyValue(pipesLayer, 'on_edit_snap_to');
		let snapFeatureToLastPointFound = false;
		for (let index = 0; index < snapToLayersNames.length; index++) {
			const snapLayerName = snapToLayersNames[index];
			let snapLayer;
			this.map.getLayers().forEach((layer, index) => {
				if (layer.get('name') === this.stateService.getWorkspace() + ":" + snapLayerName + "_snap") {
					snapLayer = layer;
				}
			});
			if (snapLayer) {
				let closestNetworkPartInLastPipeCoordinate = snapLayer.getSource().getClosestFeatureToCoordinate(lastPoint);

				let lastPointDistanceToClosest;
				if (closestNetworkPartInLastPipeCoordinate) {

					// Check if distance is zero
					let closestInLastJstsGeom = this.jstsParser.read(closestNetworkPartInLastPipeCoordinate.getGeometry());
					let lastPipePointOlGeom = new Point(lastPoint);

					// Distances
					lastPointDistanceToClosest = DistanceOP.distance(closestInLastJstsGeom,this.jstsParser.read(lastPipePointOlGeom));
				}

				// If network part is not found on last point create one
				if (Math.round(lastPointDistanceToClosest) === 0) {
					snapFeatureToLastPointFound = true;
					break;
				}
			}
		}


		if (!snapFeatureToLastPointFound) {

			// If network part is not found on last point create one
			const drainagePartsLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':drainage_parts');

			let drainagePartFeature = new Feature({
				fittingtyp: 'tpa'
			});
			// drainagePartFeature.setGeometry(new geom[drainagePartsLayer.Model.geometryType]([lastPoint]));
			drainagePartFeature.setGeometry(new MultiPoint([lastPoint]));

			let drainagePartFormOperation: FormOperation = {
				layer: drainagePartsLayer,
				layerModel: drainagePartsLayer.Model,
				feature: drainagePartFeature,
				formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου εξαρτήματος`,
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.create
			}

			// If network part is not found on last point create one
			const wellsLayer = this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':wells');

			let wellFeature = new Feature({
				material: 'concrete',
				isFinal: '0'
			});
			// wellFeature.setGeometry(new geom[wellsLayer.Model.geometryType]([lastPoint]));
			wellFeature.setGeometry(new MultiPoint([lastPoint]));

			let wellFormOperation: FormOperation = {
				layer: wellsLayer,
				layerModel: wellsLayer.Model,
				feature: wellFeature,
				formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου φρεατίου`,
				timestamp: this.geoserverService.guid(),
				dirty: true,
				action: EDIT_ACTIONS.create
			}

			formOperations.push({
				timestamp: this.geoserverService.guid(),
				description: $localize`Επιλέξτε τον τύπο της οντότητας στο τέλος του αγωγού.`,
				operations: [drainagePartFormOperation, wellFormOperation]
			});

		}

		let pipeTransaction: FormTransaction = {
			timestamp: this.geoserverService.guid(),
			formOperations: formOperations
		}

		StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(pipeTransaction));
	}

	drainagePartsTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer, splitTransaction: FormTransaction) {

		let drainagePartFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου εξαρτήματος αποχέτευσης`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		if (splitTransaction) {
			splitTransaction.formOperations.push(drainagePartFormOperation);
			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(splitTransaction));
		} else {
			let drainagePartTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [drainagePartFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(drainagePartTransaction));
		}

	}

	drainageFacilitiesTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer) {

		let drainageFacilityFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία της νέας εγκατάστασης αποχέτευσης`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		let drainageFacilityTransaction: FormTransaction = {
			timestamp: this.geoserverService.guid(),
			formOperations: [drainageFacilityFormOperation]
		}

		StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(drainageFacilityTransaction));
	}

	drainageWellsTopologyActions(newFeature: Feature<Geometry>, currentEditedLayer: WorkspaceLayer, splitTransaction: FormTransaction) {

		// let drainageWellFormOperation: FormOperation = {
		// 	layer: currentEditedLayer,
		// 	layerModel: currentEditedLayer.Model,
		// 	feature: newFeature,
		// 	formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου φρεατίου`,
		// 	timestamp: this.geoserverService.guid(),
		// 	dirty: true,
		// 	action: EDIT_ACTIONS.create
		// }

		// let drainageWellTransaction: FormTransaction = {
		// 	timestamp: this.geoserverService.guid(),
		// 	formOperations: [drainageWellFormOperation]
		// }

		// StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(drainageWellTransaction));

		let drainageWellFormOperation: FormOperation = {
			layer: currentEditedLayer,
			layerModel: currentEditedLayer.Model,
			feature: newFeature,
			formHeader: $localize`Συμπληρώστε τα στοιχεία του νέου φρεατίου`,
			timestamp: this.geoserverService.guid(),
			dirty: true,
			action: EDIT_ACTIONS.create
		}

		if (splitTransaction) {
			splitTransaction.formOperations.push(drainageWellFormOperation);
			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(splitTransaction));
		} else {
			let drainageWellTransaction: FormTransaction = {
				timestamp: this.geoserverService.guid(),
				formOperations: [drainageWellFormOperation]
			}

			StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(drainageWellTransaction));
		}
	}

	// ##########################################
	// ############# GEOMETRY FUNCTIONS #########
	// ##########################################
	isEdgeTouchesAndNotIntersects(edgeVectorLayer: VectorLayer<any>, edgeGeometry: Geometry) {

		let edgeToCheckJstsGeom = this.jstsParser.read(edgeGeometry);
		let edgeLayerSource = edgeVectorLayer.getSource();
		let allEdges = edgeLayerSource.getFeatures();

		for (let index = 0; index < allEdges.length; index++) {
			let topologyEdgeJstsGeom = this.jstsParser.read(allEdges[index].getGeometry());
			let intersects = RelateOp.intersects(topologyEdgeJstsGeom,edgeToCheckJstsGeom);
			let touches = RelateOp.touches(topologyEdgeJstsGeom,edgeToCheckJstsGeom);

			if (!touches && intersects) {
				return allEdges[index];
			}
		}

		return false;
	}

	/**
	 * Check if point feature touches but not intersects line feature
	 * @param lineGeometry 
	 * @param pointGeometry 
	 */
	pointIsOnLineEdgeAndNotIntersects(lineGeometry: Geometry, pointGeometry: Geometry) {

		let pointToCheckJstsGeom = this.jstsParser.read(pointGeometry);

		// 1. Convert first and last line point to jsts geometries
		let lineFirstCoordinate = (lineGeometry as SimpleGeometry).getFirstCoordinate();
		let lineFirstCoordinateOlGeom = new Point(lineFirstCoordinate);
		let lineLastCoordinate = (lineGeometry as SimpleGeometry).getLastCoordinate();
		let lineLastCoordinateOlGeom = new Point(lineLastCoordinate);

		let lineFirstCoordinateJstsGeom = this.jstsParser.read(lineFirstCoordinateOlGeom);
		let lineLastCoordinateJstsGeom = this.jstsParser.read(lineLastCoordinateOlGeom);


		// 2. Calculate the distance between the new point and the starting and ending point of the line
		let startingPointDistanceToSnapped = DistanceOP.distance(pointToCheckJstsGeom,lineFirstCoordinateJstsGeom);
		let endingPointDistanceToSnapped = DistanceOP.distance(pointToCheckJstsGeom,lineLastCoordinateJstsGeom);
		if (Math.round(startingPointDistanceToSnapped) === 0 || Math.round(endingPointDistanceToSnapped) === 0) {
			// No split actions
			return true;
		}

		return false;
	}

	// ##########################################
	// ############# LAYER FUNCTIONS ############
	// ##########################################

	generateStyle(layer: WorkspaceLayer) {

		switch (layer.Name.split(':')[1]) {
			case "pipes":
				return this.geoserverService.generateVectorStyle('line', '', '#3b5da7', '#06c5fb', 4);
			case "supplies":
				return this.geoserverService.generateVectorStyle('line', '', '#3b5da7', '#06c5fb', 2);
			case "hydrometers":
				return this.geoserverService.generateVectorStyle('point', 'hexagon', '#03c3ff', '#666666', 1, 6);
			case "network_parts":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#cecb79', '#232323', 1, 3);
			case "facilities":
				return this.geoserverService.generateVectorStyle('polygon', '', '#ffa000', '#7b1fa2', 2);
			case "switches":
				return this.geoserverService.generateVectorStyle('point', 'rectangle', '#f5f23c', '#325780');
			case "control_valves":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#e65100', '#616161');
			case "hydrants":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#b71c1c', '#616161');
			case "network_facilities":
				return this.geoserverService.generateVectorStyle('point', 'circle', '#004d40', '#616161');
			default:
				return this.geoserverService.generateVectorStyle('point', 'circle', '#004d40', '#616161');
		}

	}

	/**
	 * Returns the value if a keyword exists and is of the form "key:value"
	 * else returns true if the keyword exists but it is of the form "key"
	 * else returns false if the keyword does not exist in the keyword list 
	 * @param layer The layer to retrieve the keyword from
	 * @param keyword The keyword
	 */
	getKeyValue(layer, keyword): any {

		for (const currentKeyword of layer.KeywordList) {

			if (currentKeyword.includes(keyword)) {

				let isSplitted = currentKeyword.split(':');

				if (isSplitted.length === 2) { // This is a key:value keyword
					let keywordValue = isSplitted[1];
					if (keywordValue.split(',').length > 0) { // This is a array keyword 
						return keywordValue.split(',');
					} else {
						return keywordValue;
					}
				} else { // This is a plain keyword
					return true;
				}

			}

		}
		return false;
	}

	getWorkspaceLayerByName(layerName: string) {
		for (let index = 0; index < this.editingWorkspaceLayers.length; index++) {
			const layer = this.editingWorkspaceLayers[index];
			if (layer.Name === this.workspace + ':' + layerName) {
				return layer;
			}
		}

		return null;
	}	

}
