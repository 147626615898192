import { Injectable } from '@angular/core';
import { Subject, Observable, from, BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EditingStateService {

	private layerToDraw: BehaviorSubject<any> = new BehaviorSubject(null);
	public layerToDraw$: Observable<any> = from(this.layerToDraw);

	private layerToEdit: BehaviorSubject<any> = new BehaviorSubject(null);
	public layerToEdit$: Observable<any> = from(this.layerToEdit);

	constructor() { }

	setLayerToDraw(layer: any) {
		this.layerToEdit.next(null);
		this.layerToDraw.next(layer);
	}

	setLayerToEdit(layer: any) {
		this.layerToDraw.next(null);
		this.layerToEdit.next(layer);
	}
}
