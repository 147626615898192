<div class="flex items-center">
  <button [matMenuTriggerFor]="screenMenu" class="button">
    <div class="flex items-center gap-1">
      <span class="text-base">{{ currentRouteName }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </button>

  <mat-menu class="routes-menu" #screenMenu="matMenu" [overlapTrigger]="false">
    <div class="flex flex-col gap-3 px-4 pb-4 -ml-4">
      <div class="routes-dropdown mat-elevation-z5">
        <mat-icon>map</mat-icon>
        <div class="routes-options">
          <button *ngFor="let appModule of activeScreenRoutes" [routerLink]="[appModule.route]" class="routes-item">
            <span [routerLinkActive]="'text-accent'">{{ appModule.name }}</span>
          </button>
        </div>
      </div>

      <div *ngIf="sensorsRouteActive.length > 0" class="routes-dropdown mat-elevation-z5">
        <mat-icon>insert_chart</mat-icon>
        <div class="routes-options">
          <button *ngFor="let dashboardRoute of sensorsRouteActive" class="routes-item"
            [routerLink]="[dashboardRoute.route]">
            <span [routerLinkActive]="'text-accent'">{{ dashboardRoute.name }}</span>
          </button>
        </div>
      </div>

      <div class="routes-dropdown mat-elevation-z5" *ngIf="docsRouteActive">
        <mat-icon>attach_file</mat-icon>
        <div class="routes-options">
          <button class="routes-item" [routerLink]="'documents'">
            <span i18n="Documents | Documents" [routerLinkActive]="'text-accent'">Έγγραφα</span>
          </button>
        </div>
      </div>
    </div>
  </mat-menu>
</div>

<app-map-status></app-map-status>

<mat-spinner *ngIf="layoutService.headerLoaderVisible$ | async" color="accent" mode="indeterminate" diameter="20" strokeWidth="3"></mat-spinner>

<div class="flex gap-1.5 items-center justify-end flex-auto">
  <button [matMenuTriggerFor]="languageMenu" class="button">
    <div class="flex items-center">
      <mat-icon>language</mat-icon>
      <span class="text-base ml-1">{{ langMap[localeId] }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </button>

  <mat-menu #languageMenu="matMenu" [overlapTrigger]="false" class="px-3 py-2 mt-2 bg-light-50/[.90] dark:bg-dark-500/[.90] backdrop-blur">
    <div class="flex flex-col gap-0.5">
      <button *ngFor="let lang of langMap | keyvalue" (click)="changeLang(lang.key)" class="language-option">
        <img class="h-6" src="{{ baseFolder }}\assets\images\lang_flags\{{ lang.key }}.svg" alt="{{ lang.key }}" />
        <span [ngClass]="{ 'text-accent': lang.key == localeId }">
          {{ lang.value }}
        </span>
      </button>
    </div>
  </mat-menu>

  <button class="icon-button" (click)="setTheme.next()">
    <mat-icon>color_lens</mat-icon>
  </button>

  <button class="icon-button" (click)="onHelpButton()">
    <mat-icon>help_outline</mat-icon>
  </button>

  <button class="icon-button" [matMenuTriggerFor]="pagesMenu" *ngIf="apps.length">
    <mat-icon>apps</mat-icon>
  </button>

  <mat-menu #pagesMenu="matMenu" [overlapTrigger]="false" class="px-3.5 py-2 mt-2 bg-light-50/[.90] dark:bg-dark-500/[.90] backdrop-blur">
    <div class="grid grid-cols-3 gap-2">
      <button (click)="newTab(app.url)" *ngFor="let app of apps" class="app-option">
        <div class="h-12 w-12">
          <img class="page-icon" src="{{ app.image }}" alt="{{ app.name }}" />
        </div>

        <div>{{ app.name }}</div>
      </button>
    </div>
  </mat-menu>

  <button [matMenuTriggerFor]="accountMenu" class="avatar-button">
    <ngx-avatars name="{{ userDisplayName }}" size="24" bgColor="#ff8c00" textSizeRatio="2.5" />
  </button>

  <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" class="px-6 py-4 mt-2 bg-light-50/[.90] dark:bg-dark-500/[.90] backdrop-blur">
    <div class="flex flex-col gap-3">
      <div class="text-center py-2">
        {{ userDisplayName }}
      </div>

      <button mat-flat-button color="primary" *ngIf="username" (click)="logout()" class="w-full">
        <ng-container i18n="Log out | Log out"> Αποσύνδεση </ng-container>
      </button>
    </div>
  </mat-menu>
</div>
