import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfigDeya } from "./field-config.interface";

export class FormField {
    config: FieldConfigDeya;
    formGroup: FormGroup;
    indicateMultiValueSelection?: boolean;
    fileUpload?: EventEmitter<any>;
    calculateElevation?: EventEmitter<any>;
    private cachedValue: any;

    onFieldFocus() {
        if (!this.indicateMultiValueSelection) {
            return;
        }
        this.cachedValue = this.formGroup.controls[this.config.name].value;
        this.indicateMultiValueSelection = false;
        this.formGroup.controls[this.config.name].patchValue(null);
    }

    onFieldBlur() {
        if (this.cachedValue != null && this.formGroup.controls[this.config.name].value === null) {
            this.formGroup.controls[this.config.name].patchValue(this.cachedValue);
            this.cachedValue = null;
            this.formGroup.controls[this.config.name].markAsUntouched();
            this.formGroup.controls[this.config.name].markAsPristine();
            this.indicateMultiValueSelection = true;
        }
    }
}
