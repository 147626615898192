<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()" color="warn">
    <ng-container>{{ data.cancel }}</ng-container>
  </button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>
    <ng-container>{{ data.confirm }}</ng-container>
  </button>
</mat-dialog-actions>
