import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { userInfo } from '../models';

// App imports 
import { StateService } from '../core/state.service';
import { environment } from '../../environments/environment';

// Openlayers imports
import { WMSCapabilities } from 'ol/format';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
	private gisServerUrl: string;
	private apiUrl: string;
	public userInitialized$ = new Subject();

	constructor(private router: Router,
		private keycloakService: KeycloakService,
		private stateService: StateService,
		private httpClient: HttpClient, private zone: NgZone) {
		this.gisServerUrl = StateService.getScheme() + '//' + environment.geoserverDomain + '/' + 'geoserver/';
		this.apiUrl = StateService.getScheme() + '//' + environment.apiDomain + environment.apiPath;
	}

	afterLoginActions() {
		// this.apiGetUserDetails();
		if (this.stateService.getUserData().modules.length === 0) {
			this.getKeycloakUserInfo();
		}
	}

	/**
	 * Request user info from account endpoint /auth/realms/smartville/account
	 */
	getKeycloakUserInfo() {

		this.keycloakService.loadUserProfile().then((userInfo: any) => {

			this.keycloakService.getToken().then((token: any) => {

				userInfo.attributes.waterpillar_info = JSON.parse(userInfo.attributes.waterpillar_info);
				let userInfoParsed: userInfo = { ...userInfo };

				if (StateService.getLocalStorageItem('username') !== userInfoParsed.username) {
					localStorage.clear();
				}
				StateService.stateStore.dispatch(this.stateService.setUserOperation(userInfoParsed.username));
				StateService.setLocalStorageItem('username', userInfoParsed.username);
				StateService.setLocalStorageItemJSON('userData', userInfoParsed);

				StateService.stateStore.dispatch(this.stateService.setUserDataOperation({
					username: userInfoParsed.username,
					logoUrl: userInfoParsed.attributes.waterpillar_info.logo_url,
					name: userInfoParsed.firstName,
					surname: userInfoParsed.lastName,
					modules: userInfoParsed.attributes.waterpillar_info.available_modules,
					workspace_name: userInfoParsed.attributes.waterpillar_info.workspace_name,
					maxZoomLevel: userInfoParsed.attributes.waterpillar_info.max_zoom_level,
					apiToken: token
				}));
				StateService.stateStore.dispatch(this.stateService.setWorkspaceOperation(userInfoParsed.attributes.waterpillar_info.workspace_name));
			})


		}).catch((error) => {
			console.error(error);
		}).finally(() => this.userInitialized$.next());
	}

	extractWorkspace(capabilities) {

		let workspaceName;
		if (capabilities.Layer.Layer[0].Layer) { // If the layer is a group Layer
			workspaceName = capabilities.Layer.Layer[0].Layer[0].Name.split(":")[0];
		} else { // The layer is a single layer
			workspaceName = capabilities.Layer.Layer[0].Name.split(":")[0];
		}

		return workspaceName;
	}

	geoserverLogout() {
		let redirectUri: string;

		if (environment.production) {
			redirectUri = environment.keycloackEndpoint + environment.keycloakLogout + "https://" + environment.domain;
		} else {
			redirectUri = environment.keycloackEndpoint + environment.keycloakLogout + "http://" + environment.domain + ":4200";
		}

		window.location.href = redirectUri;

	}

	getCapabilities() {

		return this.httpClient.get(this.gisServerUrl + 'wms', {
			withCredentials: true,
			responseType: 'text',
			params: {
				service: 'wms',
				version: '1.3.0',
				request: 'GetCapabilities'
			}
		});
	}

	keepAlive() {

		this.zone.runOutsideAngular(() => {
			setInterval(() => {
				this.getCapabilities().subscribe((responce: any) => { });
			}, 60000);
		});
	}

	extractData(data: string) {
		// use the tool to parse the data
		let formatter = new WMSCapabilities();
		let capabilities = (formatter.read(data));

		return capabilities;
	}

}
