import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from "@angular/core";
import { LOCALE_ID, Inject } from '@angular/core';

@Injectable()
export class MatPaginatorIntlGr extends MatPaginatorIntl {
    public itemsPerPageLabel;
    public nextPageLabel;
    public previousPageLabel;
    public firstPageLabel;
    public lastPageLabel;
    private from;

    constructor(@Inject(LOCALE_ID) protected localeId: string) {
        super();
        this.labelsInit();
    }

    private labelsInit() {
        switch (this.localeId) {
            case 'en':
                this.itemsPerPageLabel = 'Items per page';
                this.nextPageLabel = 'Next page';
                this.previousPageLabel = 'Previous pages';
                this.firstPageLabel = 'First page';
                this.lastPageLabel = 'Last page';
                this.from = 'from';
                break;

            default:
                this.itemsPerPageLabel = 'Στοιχεία ανά σελίδα';
                this.nextPageLabel = 'Επόμενη σελίδα';
                this.previousPageLabel = 'Προηγούμενη σελίδα';
                this.firstPageLabel = 'Πρώτη σελίδα';
                this.lastPageLabel = 'Τελευταία σελίδα';
                this.from = 'από';
                break;
        }
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length == 0 || pageSize == 0) {
            return `0 από ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.from} ${length}`;
    }
}
