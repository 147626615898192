import { Component, OnInit } from '@angular/core';
import { MrTableComponent } from '../mr-table/mr-table.component';
import { StateService } from 'app/core/state.service';
import { LayoutService } from 'app/core/layout.service';
import { FormDataService } from 'app/shared/services/form-data.service';
import { SnackBarService } from 'app/core/snack-bar.service';
import { Sort } from '@angular/material/sort';
import { LOCALE_ID, Inject } from '@angular/core';

@Component({
  selector: 'mr-table-sp',
  templateUrl: './mr-table-sp.component.html',
  styleUrls: ['./mr-table-sp.component.scss']
})
export class MrTableSpComponent extends MrTableComponent implements OnInit {

  // mr-table-sp (S)elf (P)aginated variance of mr-table component
  constructor(snackBarService: SnackBarService, stateService: StateService, layoutService: LayoutService, formDataService: FormDataService,
    @Inject(LOCALE_ID) protected localeid: string) {
    super(snackBarService, layoutService, formDataService, stateService, localeid);
  }

  ngAfterViewInit() {
    // After table view init try to scroll into the selected row
    setTimeout(() => 
        (this.matTableContainer.nativeElement as HTMLDivElement)?.querySelector(`.selected-row`)?.scrollIntoView()
    );
  }

  setPagination() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  onSort(sort: Sort) {
    if (sort.direction === 'asc') {
      this.rawdata.sort((a, b) => (a[sort.active] > b[sort.active]) && 1 || -1);
      this.dataSource.data = this.rawdata;
    } else if (sort.direction === 'desc') {
      this.rawdata.sort((a, b) => (a[sort.active] < b[sort.active]) && 1 || -1);
      this.dataSource.data = this.rawdata;
    }
    this.calculateHorizontalScrollRestorePoint(sort.active);
    setTimeout(() => this.restoreHorizontalScrollPos(), 1);

  }

  public onPageEvent() {
    this.pageChange.emit();
    this.restoreHorizontalScrollPos();
    setTimeout(() => this.restoreLastSelection());
  }

  protected restoreLastSelection() {
    // First get the current page's data list
    const skip = this.paginator.pageSize * this.paginator.pageIndex;
    const currentPageData = this.dataSource.data.filter((_row, i) => i >= skip).filter((_row, i) => i < this.paginator.pageSize);

    // Find which of the page's data are on the selected rows list
    let lastSelectedRows = currentPageData.filter(
      row => this.selectedRows.some(
        selectedRowId => row.id === selectedRowId
      )
    );
    // If all previously selected rows exist on the current table page
    // then re-select them and scroll into the 1st visible one
    if (lastSelectedRows.length > 0 && this.selectedRows.length === lastSelectedRows.length) {
      this.selectedRows = [...this.selectedRows];
    }
    if (this.lastSelection.length > 0 && lastSelectedRows.length === this.lastSelection.length) {
      lastSelectedRows.forEach(row => {
        this.listSelection.toggle(row);
        this.selectedRows = [...this.selectedRows, row.id];
      });
      this.rowSelect.emit(this.getSelectedFeaturesFromSelectedRows());
      setTimeout(() => (this.matTableContainer.nativeElement as HTMLDivElement).querySelector(`.selected-row`)?.scrollIntoView(), 100);
      return;
    }
    setTimeout(() =>(this.matTableContainer.nativeElement as HTMLDivElement).scrollTo({top: 0}));
  }
}
