<div class="multi-table-wrapper">
  <app-loader
    *ngIf="featureInfo && !featureInfo.featureInfoVisible"
  ></app-loader>

  <mat-tab-group
    [(selectedIndex)]="tabIndex"
    (selectedTabChange)="onTabChange($event)"
    [style.height.%]="100"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
  >
    <ng-container *ngFor="let tabLayer of featureInfo?.tables; let i = index">
      <ng-container *ngIf="tabLayer.selectedLayer && tabLayer.features?.length">
        <mat-tab
          *ngIf="!!tabLayer.selectedLayer && tabLayer.selectedLayer.Name"
          [label]="tabLayer.selectedLayer?.Title"
        >
          <div
            class="table-container"
            *ngIf="selectedTab === tabLayer.selectedLayer.Title"
          >
            <mr-table-sp
              [features]="tabLayer?.features"
              [dataModel]="tabLayer?.selectedLayer?.Model"
              [selectedLayer]="tabLayer?.selectedLayer"
              [tableTitle]="tabLayer?.selectedLayer?.Title"
              [dataLength]="tabLayer?.totalFeatures"
              [multiTable]="true"
              [lastSelection]="lastSelectedFeatures"
              [dashboardMode]="isInDashboard"
              (pageChange)="onPageChange($event)"
              (tableControlAction)="handleTableControlAction($event)"
              (rowSelect)="handleRowSelect($event)"
            >
            </mr-table-sp>
          </div>
        </mat-tab>
      </ng-container>
    </ng-container>
  </mat-tab-group>
</div>
