<mat-form-field class="inline">
  <mat-label>{{placeHolder}}</mat-label>
  <input type="text" #inputRef matInput [matAutocomplete]="auto" [disabled]="!editable" (input)="onType()"
         (focus)="focus.emit()">

  <button [disabled]="!editable" class="more-icon" matSuffix mat-icon-button aria-label="Select Autocomplete"
    (click)="onArrowClick()">
    <mat-icon>expand_more</mat-icon>
  </button>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" (closed)="blur.emit()">
    <mat-option *ngFor="let option of domain" [value]="option.alias || option.name">
      {{option.alias || option.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
