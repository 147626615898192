import { ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';
import { FormCheckboxComponent } from '../form-checkbox/form-checkbox.component';
import { FormDateComponent } from '../form-date/form-date.component';
import { FormFileComponent } from '../form-file/form-file.component';

import { FormField } from "app/mr-form/models/form-field";
import { FieldConfigDeya } from '../../models/field-config.interface';

const components: { [type: string]: Type<FormField> } = {
	'string': FormInputComponent,
	'date': FormDateComponent,
	'number': FormInputComponent,
	'int': FormInputComponent,
	'boolean': FormCheckboxComponent,
	'date-time': FormDateComponent,
	'file': FormFileComponent,
	'domain': FormSelectComponent
};

@Directive({
	selector: '[dynamicField]'
})
export class DynamicFieldDirective implements OnChanges, OnInit, AfterContentInit {
	@Input()
	config: FieldConfigDeya;

	@Input()
	formGroup: FormGroup;

	@Input()
	indicateMultiValueSelection = false;

	@Output() fileUpload: EventEmitter<any> = new EventEmitter();

	@Output() calculateElevation: EventEmitter<any> = new EventEmitter();

	component: ComponentRef<FormField>;

	constructor(
		private container: ViewContainerRef
	) { }

	ngOnChanges() {
		if (this.component) {
			this.component.instance.config = this.config;
			this.component.instance.formGroup = this.formGroup;
			this.component.instance.indicateMultiValueSelection = this.indicateMultiValueSelection;
		}
	}

	ngOnInit() {
		if (!components[this.config.localType]) {
			const supportedTypes = Object.keys(components).join(', ');
			throw new Error(
				`Trying to use an unsupported type (${this.config.localType}).
        Supported types: ${supportedTypes}`
			);
		}

		// TODO: Stavros this could be avoided if LayerField->localType is set to 'domain'
		// if (!!this.config.domain && this.config.domain.length > 0) {
		// 	component = this.resolver.resolveComponentFactory<FormField>(components['domain']);
		// } else {
		// 	component = this.resolver.resolveComponentFactory<FormField>(components[this.config.localType]);
		// }

		if (!!this.config.domain && this.config.domain.length > 0) {
			this.component = this.container.createComponent<FormField>(components['domain']);
		} else {
			this.component = this.container.createComponent<FormField>(components[this.config.localType]);
		}
		this.component.instance.config = this.config;
		this.component.instance.formGroup = this.formGroup;
		if (this.component.instance.fileUpload) {
			this.component.instance.fileUpload.subscribe(f => {
				this.fileUpload.emit(f);
			})
		}
		if (this.component.instance.calculateElevation) {
			this.component.instance.calculateElevation.subscribe(f => {
				this.calculateElevation.emit(f);
			})
		}
	}

	ngAfterContentInit() {

	}
}``