import { Injectable } from '@angular/core';
import { Observable ,  from ,  BehaviorSubject } from 'rxjs';
import { StateService } from '../../core/state.service';
import { WorkspaceLayer } from 'app/models';

@Injectable()
export class TimeseriesToggleService {
  public readonly TIMESERIES_HEIGHT = 100;
  private toggleTimeseriesWidget: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public toggleTimeseriesWidget$: Observable<boolean> = from(this.toggleTimeseriesWidget);

  constructor(private stateService: StateService) { }

  showTimeseriesWidget(layer: WorkspaceLayer) {
    if (!this.toggleTimeseriesWidget.value) {
      setTimeout(() => StateService.stateStore.dispatch(this.stateService.setTimeseriesGraphOperation(true, layer.Name)), 1);
      this.toggleTimeseriesWidget.next(true);
    }
  }

  hideTimeseriesWidget() {
    this.toggleTimeseriesWidget.next(false);
    StateService.stateStore.dispatch(this.stateService.setTimeseriesGraphOperation(false));
  }

}
