<div class="app-container">
  <mat-toolbar id="app-header" *ngIf="username">
    <div class="brand-logo mr-2" *ngIf="!!initialized">
      <a href="/home" class="flex">
        <img class="logo-img" src="{{assetsPrefix+'assets/images/' + userData?.logoUrl}}">
      </a>
    </div>

    <app-header-menu class="header-menu" [isAdmin]="isAdmin" [username]="username"
      [activeScreenRoutes]="activeScreenRoutes" [userDisplayName]="userDisplayName"
      [currentRouteName]="currentRouteName" (setTheme)="onSetTheme()">
    </app-header-menu>
  </mat-toolbar>

  <div class="app-body">
    <app-loader *ngIf="blocking"></app-loader>
    <router-outlet></router-outlet>
  </div>
</div>
