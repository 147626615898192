<mat-card class="dashboard-card card-style">
  <div class="dashboard-card-header">
    <div class="dashboard-card-title">{{title}}</div>

    <div class="control-container" *ngIf="expandable">
      <button *ngIf="!isExpanded && canExpand" mat-icon-button class="control-button" title="Μεγέθυνση"
        (click)='onExpand()'>
        <mat-icon>launch</mat-icon>
      </button>

      <button *ngIf="isExpanded" mat-icon-button class="control-button" title="Επιστροφή" (click)='onShrink()'>
        <mat-icon>dashboard</mat-icon>
      </button>
    </div>
  </div>

  <div class="dashboard-card-content">
    <ng-content #cardContent></ng-content>
  </div>
</mat-card>
