// Angular imports
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';

// App imports
import { MapService } from "../map.service";
import { transform } from 'ol/proj.js';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { Style, Fill, Stroke } from 'ol/style';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, map, tap, debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare const google: any;

@Component({
    selector: "app-geocoding",
    templateUrl: "./geocoding.component.html",
    styleUrls: ["./geocoding.component.scss"],
    host: {
        "(document:keydown)": "handleKeyboardEvent($event)",
    },
})
export class GeocodingComponent implements OnInit, AfterViewInit {
    isInputVisible: boolean = false;
    basemapListVisible: boolean = false;
    selectedBasemapImage: any;

    // @ViewChild('autocomplete') autocomplete: ElementRef;
    private googleAutocomplete: any;
    public placeFullAddress: string;
    public geolocationOn: boolean;
    public options: Array<string>;
    public streetControl = new FormControl<string>(null);
    filteredStreets$: Observable<string[]>;
    private apiAutocompleteEndpoint = "https://autocomplete.search.hereapi.com/v1/autocomplete?apiKey=WP6RqGVSazUNInNbAaXoxaoY83_5vn7bFhKFf3lGgRE&q=";
    private apiGeocodeEndpoint = "https://lookup.search.hereapi.com/v1/lookup?apiKey=WP6RqGVSazUNInNbAaXoxaoY83_5vn7bFhKFf3lGgRE&jsonattributes=1&gen=9&id=";
    private searchTerms = new Subject<string>();

    constructor(
        protected mapService: MapService,
        private httpClient: HttpClient
    ) { }

    ngOnInit() {
        const savedImageSrc = localStorage.getItem("selectedBasemapImage");
        if (savedImageSrc) {
            this.handleBasemapImageSelected(savedImageSrc);
        } else {
            const esritopoBasemap: any = this.mapService.getBaseMapList().find((bm: any) => bm.name === 'esritopo');
            this.handleBasemapImageSelected(esritopoBasemap?.imageSrc ?? "assets/images/blank.jpg");
        }

        this.filteredStreets$ = this.searchTerms.pipe(
            // tap(_ => this.loading = true),
            debounceTime(300),
            filter((term) => term.length > 3),
            distinctUntilChanged(),
            switchMap((term: string) => this.geocode(term)),
            map((res: any) => {
                return res.items;
            }),
            map((suggestions: any) => {
                return suggestions.map((suggestion) => {
                    let address;
                    let placeObject = {
                        locationId: suggestion.id,
                        address: "",
                    };

                    address = suggestion.address.label;

                    placeObject.address = address;
                    return placeObject;
                });
            })
        );
    }

    search(term: string) {
        this.searchTerms.next(term);
    }

    geocode(searchTerm: string) {
        let apiURL = `${this.apiAutocompleteEndpoint}${searchTerm}`;
        return this.httpClient.get(apiURL);
    }

    onSelectAddress(selection) {
        let locationId = selection.option.value.locationId;

        let apiURL = `${this.apiGeocodeEndpoint}${locationId}`;
        this.httpClient.get(apiURL).subscribe((res: any) => {
            this.createAndZoomToAddress(
                res.position
            );
        });
    }

    displayFn(street) {
        if (!street) return "";
        return street.address;
    }

    createAndZoomToAddress(displayPosition) {
        // Create and zoom to point
        let transformedCoord = transform(
            [displayPosition.lng, displayPosition.lat],
            "EPSG:4326",
            "EPSG:2100"
        );
        let addressGeometry = new Point(transformedCoord);
        let addressFeature = new Feature({
            geometry: addressGeometry,
            name: "Address",
        });
        addressFeature.setStyle(this.getGeocodingStyle());

        this.mapService.addFeaturesToVectorLayer([addressFeature]);
        this.mapService.zoomToGeometry(addressGeometry);
    }

    ngAfterViewInit() {
        // this.googleAutocomplete = new google.maps.places.Autocomplete(this.autocomplete.nativeElement, {
        // 	types: ['geocode'],
        // 	componentRestrictions: {
        // 		country: 'GR'
        // 	}
        // });
        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        // this.googleAutocomplete.addListener('place_changed', () => { this.placeSelected() });
    }

    onActivateGeolocation() {
        this.geolocationOn = !this.geolocationOn;
        if (this.geolocationOn) {
            this.mapService.enableGeolocation();
        } else {
            this.mapService.disableGeolocation();
        }
    }

    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key == "Escape") {
            this.mapService.clearVectorLayer();
        }
    }

    placeSelected() {
        // Get the place details from the autocomplete object.
        let place = this.googleAutocomplete.getPlace();
        if (!place.geometry) {
            return;
        }
        let placeLocation = place.geometry.location;

        // Calculate address string
        this.placeFullAddress = place.formatted_address;

        // Create and zoom to point
        let transformedCoord = transform(
            [placeLocation.lng(), placeLocation.lat()],
            "EPSG:4326",
            "EPSG:2100"
        );
        let addressGeometry = new Point(transformedCoord);
        let addressFeature = new Feature({
            geometry: addressGeometry,
            name: "Address",
        });
        addressFeature.setStyle(this.getGeocodingStyle());

        this.mapService.addFeaturesToVectorLayer([addressFeature]);
        this.mapService.zoomToGeometry(addressGeometry);
    }

    geolocate() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                let geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                let circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy,
                });
                this.googleAutocomplete.setBounds(circle.getBounds());
            });
        }
    }

    getGeocodingStyle() {
        return new Style({
            fill: new Fill({
                color: "rgba(255, 255, 255, 0.8)",
            }),
            stroke: new Stroke({
                color: "#e040fb",
                width: 3,
            }),
            image: this.mapService.getIconStyle("place24.svg"),
        });
    }

    toggleInputVisibility() {
        this.isInputVisible = !this.isInputVisible;
        if (this.isInputVisible) {
            this.basemapListVisible = false;
        }
    }

    toggleBasemapListVisibility() {
        this.basemapListVisible = !this.basemapListVisible;
        if (this.basemapListVisible) {
            this.isInputVisible = false;
        }
    }

    handleBasemapImageSelected(basemap: any) {
        this.selectedBasemapImage = basemap;
        localStorage.setItem("selectedBasemapImage", basemap);
    }
}
