import { Component, Output, EventEmitter } from '@angular/core';
import { FormField } from "app/mr-form/models/form-field";

@Component({
  selector: 'form-file',
  templateUrl: './form-file.component.html',
  styleUrls: ['./form-file.component.scss']
})
export class FormFileComponent extends FormField {
  @Output() fileUpload: EventEmitter<any> = new EventEmitter();

  onUploadFile(file: File) {
    this.fileUpload.emit(file);
  }

}
