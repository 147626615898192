<div class="layer-name-tab">
  <div>{{ selectedLayer?.Title }}</div>
  <hr />
</div>
<mr-table
  [features]="features"
  [dataModel]="layerModel"
  [selectedLayer]="selectedLayer"
  [tableTitle]="selectedLayer?.Title"
  [dataLength]="totalFeatures"
  [showRowSelectionCheckbox]="enableSelection"
  [lastSelection]="lastSelectedFeatures"
  [dashboardMode]="isInDashboard"
  (pageChange)="onPageChange($event)"
  (sortChange)="onSortChange($event)"
  (tableControlAction)="handleTableControlAction($event)"
  (rowSelect)="handleRowSelect($event)"
>
</mr-table>
