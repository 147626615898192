// Angular imports
import { Injectable } from '@angular/core';

//Redux imports
import { Action, ActionCreator, createStore, Store, StoreEnhancer, compose, Reducer, combineReducers } from 'redux';
import { AppState } from './redux/app-store';
import {
	WorkspaceLayer, LayerModel, FormTransaction, OPERATIONS, FeatureTableDataModel,
	UserDocModel, UserState, ScreenState, CRUD_OPERATION, FeatureInfo, FormDataModel,
	ScadaDashboardType, epanetResultsAndOptions
} from '../models';

import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { EpanetResults } from 'epanet-js';

//=====================================================
// 0. INTERFACES      =================================
//===================================================== 
export interface GeneralState {
	workspace: string,
	workspaceLayers: Array<WorkspaceLayer>,
	formData: FormDataModel,
	featureInfo: FeatureInfo,
	featureTableData: FeatureTableDataModel,
	multiTableData: {
		data: Array<FeatureTableDataModel>,
		multiTableVisible: boolean
	},
	mapOperation: string,
	activeModule: number,
	layout: number,
	loading: {
		isLoading: boolean
		, message: string
		, persentage: number
		, blocking: boolean
	},
	workspaceModelsLoaded: boolean,
	capabilitiesLoaded: boolean,
	user: string,
	userData: {
		username: string
		, logoUrl: string
		, name: string
		, surname: string
		, modules: Array<number>
		, apiToken: string
		, maxZoomLevel: number
	},
	userDocs: {
		docs: Array<UserDocModel>,
		timestamp: number
	},
	isAdmin: boolean,
	activeOperation: { timestamp: number, operation: OPERATIONS },
	messages: Array<Object>,
	edit: {
		active: boolean,
		groupName: string
	},
	topologyEdit: {
		active: boolean,
		groupName: string
	},
	scadaDashboard: {
		type: ScadaDashboardType,
		id: string
	},
	formTransactions: Array<FormTransaction>,
	timeseriesGraph: {
		layerName: string,
		visible: boolean,
		timestamp: number
	},
	userState: UserState,
	epanetResultsAndOptions: epanetResultsAndOptions,
	isAndroid: boolean,
	lastFilteredLayerName: string | null,
};

const initialState: GeneralState = {
	workspace: '',
	workspaceLayers: [],
	formData: { timestamp: null, features: [], selectedLayer: null, title: null, rowData: [] },
	featureInfo: {
		tables: [],
		featureInfoVisible: false,
		timestamp: null
	},
	featureTableData: {
		timestamp: null,
		features: [],
		featureTableVisible: false,
		selectedLayer: null,
		totalFeatures: -1,
		startIndex: -1,
		filter: ''
	},
	multiTableData: {
		data: [],
		multiTableVisible: false
	},
	mapOperation: 'none',
	activeModule: -1,
	layout: 1,
	loading: {
		isLoading: false,
		message: '',
		persentage: -1,
		blocking: false
	},
	workspaceModelsLoaded: false,
	capabilitiesLoaded: false,
	user: null,
	userData: {
		username: null
		, logoUrl: null
		, name: null
		, surname: null
		, modules: []
		, apiToken: ''
		, maxZoomLevel: 27
	},
	userDocs: {
		docs: [],
		timestamp: null
	},
	isAdmin: false,
	activeOperation: { timestamp: null, operation: OPERATIONS.info },
	messages: [],
	edit: {
		active: false,
		groupName: ''
	},
	topologyEdit: {
		active: false,
		groupName: ''
	},
	scadaDashboard: {
		type: 0,
		id: undefined
	},
	formTransactions: [],
	timeseriesGraph: null,
	userState: {
		screenStates: [],
		firstRun: true
	},
	epanetResultsAndOptions: {
		epanetResults: null,
		epanetOptions: null
	},
	isAndroid: false,
	lastFilteredLayerName: null
};

export interface SetLoadingAction extends Action {
	loading: boolean;
	message: string;
	persentage: number;
	blocking: boolean;
}

export interface SetWorkspaceModelsLoadedAction extends Action {
	loaded: boolean;
}

export interface SetWorkspaceAction extends Action {
	workspace: string;
}

export interface SetWorkspaceLayerAction extends Action {
	layer: WorkspaceLayer;
}

export interface SetUserAction extends Action {
	user: string;
}

export interface SetUserDataAction extends Action {
	userData: any;
}

export interface SetIsAdminAction extends Action {
	isAdmin: boolean;
}

export interface SetAllLayersAction extends Action {
	allLayers: Array<Object>;
}

export interface SetFormDataAction extends Action {
	feature: FormDataModel;
}

export interface SetFeatureInfoAction extends Action {
	features: Array<Object>;
}

export interface AddMessageAction extends Action {
	message: Object;
}

export interface RemoveMessageAction extends Action {
	uuid: number;
}

export interface SetFeatureTableDataAction extends Action {
	features: Array<Object>;
	featureTableVisible: boolean;
	selectedLayer: any;
	totalFeatures: number;
	startIndex: number;
	filter: string;
}

export interface UpdateDataAction extends Action {
	features: Array<Object>;
	selectedLayer: any;
	updateAction: string;
}

export interface SetFeatureTableSelectedFeaturesAction extends Action {
	selectedLayer: any;
	selectedFeatures: Array<Object>;
}

export interface SetFeatureInfoSelectedFeaturesAction extends Action {
	selectedLayer: any;
	selectedFeatures: Array<Object>;
}

export interface SetMultipleTableDataAction extends Action {
	tableData: Array<FeatureTableDataModel>;
	multiTableVisible: boolean;
}

export interface SetOperationAction extends Action {
	operation: OPERATIONS;
}

export interface SetActiveModuleAction extends Action {
	module: number;
}

export interface SetEditAction extends Action {
	edit: any;
}

export interface SetTopologyAction extends Action {
	topology_edit: any;
}

export interface AddFormTransactionAction extends Action {
	formTransaction: FormTransaction;
}

export interface RemoveFormTransactionAction extends Action {
	formTransaction: FormTransaction;
}

export interface SetTimeseriesGraphAction extends Action {
	visible: boolean;
	layerName: string;
}

export interface SetUserDocsAction extends Action {
	docs: Array<UserDocModel>;
}

export interface LoadUserStateAction extends Action {

}

export interface SetUserStateFirstRunAction extends Action {
	firstRun: boolean;
}

export interface AddOpenLayerStorageAction extends Action {
	layer: WorkspaceLayer;
}

export interface RemoveOpenLayerStorageAction extends Action {
	layer: WorkspaceLayer;
}

export interface SetScreenExtentStorageAction extends Action {
	extent: [number, number, number, number];
}

export interface SetScreenBasemapStorageAction extends Action {
	basemap: string;
}

export interface SetLayerOrderStorageAction extends Action {
	layerOrder: Array<WorkspaceLayer>;
}

export interface SetIsAndroidAction extends Action {
	isAndroid: boolean;
}

export interface SetEpanetResultsAction extends Action {
	epanetResults: EpanetResults;
}

export interface SetEpanetOptionsAction extends Action {
	epanetOptions: Map<string, any>;
}

export interface SetActiveDashboardAction extends Action {
	dashboardType: ScadaDashboardType;
	id: number;
}

export interface SetLayerFilterNameAction extends Action {
	lastFilteredLayerName: string;
}

export interface SetLayerFilterAction extends Action {
	layer: WorkspaceLayer;
	filter: string;
  applied: boolean;
}

const devtools: StoreEnhancer<AppState> = window['__REDUX_DEVTOOLS_EXTENSION__'] ? window['__REDUX_DEVTOOLS_EXTENSION__']() : f => f;
let messageCounter = 0;

@Injectable()
export class StateService {


	//=====================================================
	// 1. ACTION TYPES      ===============================
	//===================================================== 
	static SET_LOADING: string = '[State] Set loading';
	static SET_MODELS_LOADED: string = '[State] Set workspace layers models loaded';
	static SET_WORKSPACE: string = '[State] Set Workspace';
	static SET_ACTIVE_MODULE: string = '[State] Set Active Module';
	static SET_USER: string = '[State] Set User';
	static SET_USER_DATA: string = '[State] Set User Data';
	static SET_IS_ADMIN: string = '[State] Set Is Admin';
	static SET_ALL_LAYERS: string = '[State] Set All Layers';
	static SET_WORKSPACE_LAYER: string = '[State] Set Workspace Layer';
	static SET_FORM_DATA: string = '[State] Set form data';
	static SET_FEATURE_INFO: string = '[State] Set feature info';
	static SET_FEATURE_TABLE_DATA: string = '[State] Set feature table data';
	static SET_FEATURE_TABLE_SELECTED_FEATURES: string = '[State] Set table selected features';
	static SET_FEATURE_INFO_SELECTED_FEATURES: string = '[State] Set feature info selected features';
	static SET_MULTI_TABLE_DATA: string = '[State] Set multi table data';
	static SET_OPERATION: string = '[State] Set current operation';
	static ADD_MESSAGE: string = '[State] Add message operation';
	static REMOVE_MESSAGE: string = '[State] Remove message operation';
	static SET_EDIT: string = '[State] Set edit object';
	static SET_TOPOLOGY_EDIT: string = '[State] Set topology edit object';
	static ADD_FORM_TRANSACTION: string = '[State] Add form transaction';
	static REMOVE_FORM_TRANSACTION: string = '[State] Remove form transaction';
	static SET_TIMESERIES_GRAPH: string = '[State] Set timeseries graph';
	static SET_USER_DOCS: string = '[State] Set user docs';
	static LOAD_USER_STATE: string = '[State] Get user state (localstorage)';
	static ADD_OPEN_LAYER: string = '[State] Add open layer(localstorage)';
	static REMOVE_OPEN_LAYER: string = '[State] Remove open layer(localstorage)';
	static SET_SCREEN_EXTENT: string = '[State] Set screen map extent(localstorage)';
	static SET_SCREEN_BASEMAP: string = '[State] Set screen basemap(localstorage)';
	static SET_LAYER_ORDER: string = '[State] Set layer order(localstorage)';
	static SET_IS_ANDROID: string = '[State] Set is android';
	static SET_DASHBOARD_TYPE: string = '[State] Set dashboard type';
	static SET_USER_STATE_FIRST_RUN: string = '[State] Set user state first run';
	static SET_EPANET_RESULTS: string = '[State] Set epanet results';
	static SET_EPANET_OPTIONS: string = '[State] Set epanet options';
	static UPDATE_DATA: string = '[State] Update data on state on update and delete';
	static SET_LAYER_FILTER: string = '[State] Set layer filter on state';
	static SET_FILTER_LAYER_NAME: string = '[State] Set last filtered layer name on state';

	//=====================================================
	// 2. ACTION CREATORS   ===============================
	//===================================================== 
	setLoadingOperation: ActionCreator<SetLoadingAction> =
		(loading, message, persentage, blocking) => ({
			type: StateService.SET_LOADING,
			loading: loading,
			message: message,
			persentage: persentage,
			blocking: blocking
		});

	setWorkspaceLayerModelsLoadedOperation: ActionCreator<SetWorkspaceModelsLoadedAction> =
		(loaded) => ({
			type: StateService.SET_MODELS_LOADED,
			loaded: loaded
		});

	setWorkspaceOperation: ActionCreator<SetWorkspaceAction> =
		(workspace) => ({
			type: StateService.SET_WORKSPACE,
			workspace: workspace
		});

	setWorkspaceLayerOperation: ActionCreator<SetWorkspaceLayerAction> =
		(layer) => ({
			type: StateService.SET_WORKSPACE_LAYER,
			layer: layer
		});

	setUserOperation: ActionCreator<SetUserAction> =
		(user) => ({
			type: StateService.SET_USER,
			user: user
		});

	setUserDataOperation: ActionCreator<SetUserDataAction> =
		(userData) => ({
			type: StateService.SET_USER_DATA,
			userData: userData
		});

	setIsAdminOperation: ActionCreator<SetIsAdminAction> =
		(isAdmin) => ({
			type: StateService.SET_IS_ADMIN,
			isAdmin: isAdmin
		});

	setAllLayersOperation: ActionCreator<SetAllLayersAction> =
		(allLayers) => ({
			type: StateService.SET_ALL_LAYERS,
			allLayers: allLayers
		});

	setFormDataOperation: ActionCreator<SetFormDataAction> =
		(feature: FormDataModel) => ({
			type: StateService.SET_FORM_DATA,
			feature: feature
		});

	setFeatureInfoOperation: ActionCreator<SetFeatureInfoAction> =
		(features) => ({
			type: StateService.SET_FEATURE_INFO,
			features: features
		});

	setFeatureTableData: ActionCreator<SetFeatureTableDataAction> =
		(features, isVisible, selectedLayer, totalFeatures, startIndex, filter) => ({
			type: StateService.SET_FEATURE_TABLE_DATA,
			features: features,
			featureTableVisible: isVisible,
			selectedLayer: selectedLayer,
			totalFeatures: totalFeatures,
			startIndex: startIndex,
			filter: filter
		});

	updateData: ActionCreator<UpdateDataAction> =
		(features, selectedLayer, updateAction) => ({
			type: StateService.UPDATE_DATA,
			features: features,
			selectedLayer: selectedLayer,
			updateAction: updateAction
		});

	setFeatureTableSelectedFeatures: ActionCreator<SetFeatureTableSelectedFeaturesAction> =
		(selectedLayer, features) => ({
			type: StateService.SET_FEATURE_TABLE_SELECTED_FEATURES,
			selectedLayer,
			selectedFeatures: features,
		});

	setFeatureInfoSelectedFeatures: ActionCreator<SetFeatureInfoSelectedFeaturesAction> =
		(selectedLayer, features) => ({
			type: StateService.SET_FEATURE_INFO_SELECTED_FEATURES,
			selectedLayer,
			selectedFeatures: features,
		});

	setMultiTableData: ActionCreator<SetMultipleTableDataAction> =
		(tableData, multiTableVisible) => ({
			type: StateService.SET_MULTI_TABLE_DATA,
			tableData: tableData,
			multiTableVisible: multiTableVisible
		});

	setOperation: ActionCreator<SetOperationAction> =
		(operation) => ({
			type: StateService.SET_OPERATION,
			operation: operation
		});

	addMessage: ActionCreator<AddMessageAction> =
		(message) => ({
			type: StateService.ADD_MESSAGE,
			message: message
		});

	removeMessage: ActionCreator<RemoveMessageAction> =
		(uuid) => ({
			type: StateService.REMOVE_MESSAGE,
			uuid: uuid
		});

	setActiveModuleOperation: ActionCreator<SetActiveModuleAction> =
		(module) => ({
			type: StateService.SET_ACTIVE_MODULE,
			module: module
		});

	setEditOperation: ActionCreator<SetEditAction> =
		(edit) => ({
			type: StateService.SET_EDIT,
			edit: edit
		});

	setTopologyEditOperation: ActionCreator<SetTopologyAction> =
		(topology_edit) => ({
			type: StateService.SET_TOPOLOGY_EDIT,
			topology_edit: topology_edit
		});

	addFormTransactionOperation: ActionCreator<AddFormTransactionAction> =
		(formTransaction) => ({
			type: StateService.ADD_FORM_TRANSACTION,
			formTransaction: formTransaction
		});

	removeFormTransactionOperation: ActionCreator<RemoveFormTransactionAction> =
		(formTransaction) => ({
			type: StateService.REMOVE_FORM_TRANSACTION,
			formTransaction: formTransaction
		});

	setTimeseriesGraphOperation: ActionCreator<SetTimeseriesGraphAction> =
		(visible, layerName) => ({
			type: StateService.SET_TIMESERIES_GRAPH,
			visible: visible,
			layerName: layerName
		});

	setUserDocsOperation: ActionCreator<SetUserDocsAction> =
		(docs) => ({
			type: StateService.SET_USER_DOCS,
			docs: docs
		});

	addOpenLayerOperation: ActionCreator<AddOpenLayerStorageAction> =
		(layer) => ({
			type: StateService.ADD_OPEN_LAYER,
			layer: layer
		});

	removeOpenLayerOperation: ActionCreator<RemoveOpenLayerStorageAction> =
		(layer) => ({
			type: StateService.REMOVE_OPEN_LAYER,
			layer: layer
		});

	setScreenMapExtentOperation: ActionCreator<SetScreenExtentStorageAction> =
		(extent) => ({
			type: StateService.SET_SCREEN_EXTENT,
			extent: extent
		});

	setScreenBasemapOperation: ActionCreator<SetScreenBasemapStorageAction> =
		(basemap) => ({
			type: StateService.SET_SCREEN_BASEMAP,
			basemap: basemap
		});

	setLayerOrderOperation: ActionCreator<SetLayerOrderStorageAction> =
		(layerOrder) => ({
			type: StateService.SET_LAYER_ORDER,
			layerOrder: layerOrder
		});

	loadUserStateOperation: ActionCreator<LoadUserStateAction> =
		() => ({
			type: StateService.LOAD_USER_STATE
		});

	setUserStateFirstRunOperation: ActionCreator<SetUserStateFirstRunAction> =
		(firstRun) => ({
			type: StateService.SET_USER_STATE_FIRST_RUN,
			firstRun: firstRun
		});

	setIsAndroidOperation: ActionCreator<SetIsAndroidAction> =
		(isAndroid) => ({
			type: StateService.SET_IS_ANDROID,
			isAndroid: isAndroid
		});

	setEpanetResultsOperation: ActionCreator<SetEpanetResultsAction> =
		(epanetResults) => ({
			type: StateService.SET_EPANET_RESULTS,
			epanetResults: epanetResults
		});

	setEpanetOptionsOperation: ActionCreator<SetEpanetOptionsAction> =
		(epanetOptions) => ({
			type: StateService.SET_EPANET_OPTIONS,
			epanetOptions: epanetOptions
		});

	setActiveDashboardOperation: ActionCreator<SetActiveDashboardAction> =
		(dashboardType, id) => ({
			type: StateService.SET_DASHBOARD_TYPE,
			dashboardType: dashboardType,
			id: id
		});

	setLayerFilterNameOperation: ActionCreator<SetLayerFilterNameAction> =
		(filterLayerName) => ({
			type: StateService.SET_FILTER_LAYER_NAME,
			lastFilteredLayerName: filterLayerName
		});

	setLayerFilterOperation: ActionCreator<SetLayerFilterAction> =
		(layer: WorkspaceLayer, filter: string, applied: boolean) => ({
			type: StateService.SET_LAYER_FILTER,
			layer: layer,
			applied: applied,
      filter: filter
		});

	//=====================================================
	// 3. REDUCER      ====================================
	//===================================================== 

	static GeneralReducer(generalState: GeneralState = initialState, action: Action): GeneralState {

		switch (action.type) {

			// Action Types
			case StateService.SET_LOADING:
				const loading: boolean = (<SetLoadingAction>action).loading;
				const loadingMessage: string = (<SetLoadingAction>action).message;
				const persentage: number = (<SetLoadingAction>action).persentage;
				const blocking: boolean = (<SetLoadingAction>action).blocking;

				let loadingObject = Object.assign({}, generalState.loading, {
					isLoading: loading,
					message: loadingMessage,
					persentage: persentage,
					blocking: blocking
				});

				return { ...generalState, ...{ loading: loadingObject } };

			case StateService.SET_MODELS_LOADED:
				const modelsLoaded: boolean = (<SetWorkspaceModelsLoadedAction>action).loaded;
				return { ...generalState, ...{ workspaceModelsLoaded: modelsLoaded } };

			case StateService.SET_WORKSPACE:
				const workspace: string = (<SetWorkspaceAction>action).workspace;
				let stateObject = { ...generalState, ...{ workspace: workspace } };
				return stateObject;

			case StateService.SET_WORKSPACE_LAYER:
				let enrichedWorkspaceLayer: WorkspaceLayer = (<SetWorkspaceLayerAction>action).layer;
				const workspaceLayers: Array<WorkspaceLayer> = generalState.workspaceLayers;
				let groupIndex = -1;
				let layerIndex = -1;

				for (let i = 0; i < workspaceLayers.length; i++) {
					const currentGroup = workspaceLayers[i];
					if (currentGroup.Layer) {// Is group
						for (let j = 0; j < currentGroup.Layer.length; j++) {
							const currentLayer = currentGroup.Layer[j];
							if (currentLayer.Name === enrichedWorkspaceLayer.Name) {
								groupIndex = i;
								layerIndex = j;
							}
						}
					}
				}

				let currentGroup: WorkspaceLayer = generalState.workspaceLayers[groupIndex];
				let newGroupLayers = [...currentGroup.Layer];
				newGroupLayers.splice(layerIndex, 1, enrichedWorkspaceLayer);
				let newGroup = { ...currentGroup, ...{ Layer: newGroupLayers } };
				let newWorkspaceLayers = [...generalState.workspaceLayers];
				newWorkspaceLayers.splice(groupIndex, 1, newGroup);

				return { ...generalState, ...{ workspaceLayers: newWorkspaceLayers } };

			case StateService.SET_ACTIVE_MODULE:
				const module: number = (<SetActiveModuleAction>action).module;
				let moduleObject = Object.assign({}, generalState, { activeModule: module });
				return moduleObject;

			case StateService.SET_USER:
				const user: string = (<SetUserAction>action).user;
				return Object.assign({}, generalState, { user: user });

			case StateService.SET_USER_DATA:
				const userData: string = (<SetUserDataAction>action).userData;
				return Object.assign({}, generalState, { userData: userData });

			case StateService.SET_IS_ADMIN:
				const isAdmin: boolean = (<SetIsAdminAction>action).isAdmin;
				return Object.assign({}, generalState, { isAdmin: isAdmin });

			case StateService.SET_ALL_LAYERS:
				const allLayers: Array<Object> = (<SetAllLayersAction>action).allLayers;
				return Object.assign({}, generalState, { workspaceLayers: allLayers, capabilitiesLoaded: true });

			case StateService.SET_FORM_DATA:
				const formData: Object = (<SetFormDataAction>action).feature;

				if (!formData) {
					return Object.assign({}, generalState, {
						formData: {
							timestamp: null,
							features: [],
							selectedLayer: null,
							totalFeatures: null
						}
					});

				} else {
					return Object.assign({}, generalState, { formData: { ...formData, timestamp: Date.now() } });
				}


			case StateService.SET_FEATURE_INFO:
				const incomingFeatureInfo: Array<Object> = (<SetFeatureInfoAction>action).features;
				const currentFeatureInfo = generalState.featureInfo;

				// The multitable is closing
				if (incomingFeatureInfo.length === 0) {
					let newFeatureInfo = [];
					for (let j = 0; j < currentFeatureInfo.tables.length; j++) {
						newFeatureInfo.push({
							...currentFeatureInfo.tables[j], ...{
								features: [],
								selectedLayer: null,
								totalFeatures: 0,
								timestamp: Date.now()
							}
						})
					}

					if (generalState.formData.features.length > 1) {
						return Object.assign({}, generalState, {
							featureInfo: {
								tables: newFeatureInfo,
								featureInfoVisible: false,
								timestamp: Date.now()
							},
							formData: {
								timestamp: Date.now(),
								features: [],
								selectedLayer: null,
								totalFeatures: null
							}
						});

					} else {
						return Object.assign({}, generalState, {
							featureInfo: {
								tables: newFeatureInfo,
								featureInfoVisible: false,
								timestamp: Date.now()
							}
						});
					}

				}

				// Iterate all incoming data and enrich it with previously selected features 
				// taken from currentFeatureInfo data if found
				for (let i = 0; i < incomingFeatureInfo.length; i++) {
					let incoming = incomingFeatureInfo[i] as any;

					for (let j = 0; j < currentFeatureInfo.tables.length; j++) {
						const current = currentFeatureInfo.tables[j];
						if (current.selectedFeatures && current.selectedFeatures.selectedLayer.Name === incoming.selectedLayer.Name) {
							incoming.selectedFeatures = current.selectedFeatures;
							break;
						}
					}

				}

				return Object.assign({}, generalState, {
					featureInfo: {
						tables: incomingFeatureInfo,
						featureInfoVisible: true,
						timestamp: Date.now()
					},
					featureTableData: {
						...generalState.featureTableData, ...{
							timestamp: null,
							features: [],
							featureTableVisible: false,
							selectedLayer: null,
							totalFeatures: -1,
							startIndex: -1
						}
					} // Close feature table
				});

			case StateService.SET_FEATURE_TABLE_DATA:
				const featureTableData: Array<Object> = (<SetFeatureTableDataAction>action).features;
				const featureTableVisible: boolean = (<SetFeatureTableDataAction>action).featureTableVisible;
				const selectedLayer: any = (<SetFeatureTableDataAction>action).selectedLayer;
				const totalFeatures: any = (<SetFeatureTableDataAction>action).totalFeatures;
				const startIndex: any = (<SetFeatureTableDataAction>action).startIndex;
				const filter: any = (<SetFeatureTableDataAction>action).filter;
				const currentFeatureInfo2 = generalState.featureInfo;

				const newFeatureTableData2 = Object.assign({}, generalState.featureTableData, {
					timestamp: Date.now(),
					features: featureTableData,
					featureTableVisible: featureTableVisible,
					selectedLayer: selectedLayer,
					totalFeatures: totalFeatures,
					startIndex: startIndex,
					filter: filter
				});

				// If the featureTableData are not [] close featureInfo
				if (featureTableData.length > 0) {
					let newFeatureInfo2 = [];
					for (let j = 0; j < currentFeatureInfo2.tables.length; j++) {
						newFeatureInfo2.push({
							...currentFeatureInfo2.tables[j], ...{
								features: [],
								selectedLayer: null,
								totalFeatures: 0,
								timestamp: Date.now()
							}
						})
					}

					return Object.assign({}, generalState, {
						featureTableData: newFeatureTableData2,
						featureInfo: {
							tables: newFeatureInfo2,
							featureInfoVisible: false,
							timestamp: Date.now()
						}
					});

				} else {// If we are closing the feature table
					// if the form has more than one selected close that too(To prevent user to make a multi update without seeing the table and the selected features)
					if (generalState.formData.features.length > 1) {
						return Object.assign({}, generalState, {
							featureTableData: newFeatureTableData2,
							formData: {
								timestamp: Date.now(),
								features: [],
								selectedLayer: null,
								totalFeatures: null
							}
						});
					}

				}

				return Object.assign({}, generalState, {
					featureTableData: newFeatureTableData2
				});

			case StateService.SET_FEATURE_TABLE_SELECTED_FEATURES:
				const selectedLayer2: any = (<SetFeatureTableSelectedFeaturesAction>action).selectedLayer;
				const selectedFeatures: Array<Object> = (<SetFeatureTableSelectedFeaturesAction>action).selectedFeatures;
				const newFeatureTableData = Object.assign({}, generalState.featureTableData, {
					selectedFeatures: {
						timestamp: Date.now(),
						selectedLayer: selectedLayer2,
						features: selectedFeatures
					}
				});

				return Object.assign({}, generalState, {
					featureTableData: newFeatureTableData
				});

			case StateService.SET_FEATURE_INFO_SELECTED_FEATURES:
				const selectedInfoLayer: any = (<SetFeatureInfoSelectedFeaturesAction>action).selectedLayer;
				const selectedInfoFeatures: Array<Object> = (<SetFeatureInfoSelectedFeaturesAction>action).selectedFeatures;

				let currentFeatureInfoLayerIndex = -1;
				let featureInfoLayers = generalState.featureInfo.tables;

				for (let i = 0; i < featureInfoLayers.length; i++) {

					// This executes when emptying selected features
					if (selectedInfoFeatures.length === 0 && featureInfoLayers[i].selectedFeatures?.selectedLayer.Name == selectedInfoLayer.Name) {
						currentFeatureInfoLayerIndex = i;
						break;
					}

					// This executes when populating selected features
					if (featureInfoLayers[i].selectedLayer?.Name == selectedInfoLayer.Name) {
						currentFeatureInfoLayerIndex = i;
						break;
					}
				}

				// Completely remove table if there are not data and also selected features
				if (generalState.featureInfo.tables[currentFeatureInfoLayerIndex]?.features.length === 0 && selectedInfoFeatures.length === 0) {

					let alteredFeatureInfo = [...featureInfoLayers.slice(0, currentFeatureInfoLayerIndex), ...featureInfoLayers.slice(currentFeatureInfoLayerIndex + 1, featureInfoLayers.length)];

					let newFeatureInfo = { ...generalState.featureInfo, ...{ tables: alteredFeatureInfo } };
					return Object.assign({}, generalState, {
						featureInfo: newFeatureInfo
					});
				} else {
					const newFeatureInfoData = Object.assign({}, generalState.featureInfo.tables[currentFeatureInfoLayerIndex], {
						selectedFeatures: {
							timestamp: Date.now(),
							selectedLayer: selectedInfoLayer,
							features: selectedInfoFeatures
						}
					});
					let alteredFeatureInfo = [...featureInfoLayers.slice(0, currentFeatureInfoLayerIndex), newFeatureInfoData, ...featureInfoLayers.slice(currentFeatureInfoLayerIndex + 1, featureInfoLayers.length)];

					let newFeatureInfo = { ...generalState.featureInfo, ...{ tables: alteredFeatureInfo } };
					return Object.assign({}, generalState, {
						featureInfo: newFeatureInfo
					});
				}


			case StateService.SET_MULTI_TABLE_DATA:
				const multiTableData: Array<FeatureTableDataModel> = (<SetMultipleTableDataAction>action).tableData;
				const multiTableVisible: boolean = (<SetMultipleTableDataAction>action).multiTableVisible;
				return Object.assign({}, generalState, {
					multiTableData: {
						data: multiTableData,
						multiTableVisible: multiTableVisible
					}
				});

			// draw,edit,info,measure	
			case StateService.SET_OPERATION:
				const operation: OPERATIONS = (<SetOperationAction>action).operation;
				return Object.assign({}, generalState, { activeOperation: { timestamp: Date.now(), operation: operation } });

			case StateService.ADD_MESSAGE:
				let message: any = (<AddMessageAction>action).message;
				message.uuid = messageCounter++;
				return Object.assign({}, generalState, { messages: [...generalState.messages, message] });

			case StateService.REMOVE_MESSAGE:
				const uuid: any = (<RemoveMessageAction>action).uuid;
				let messageIndex = -1;
				for (let i = 0; i < generalState.messages.length; i++) {
					let currentMessage: any = generalState.messages[i];
					if (currentMessage.uuid == uuid) {
						messageIndex = i;
					}
				};
				if (messageIndex >= 0) {
					return Object.assign({}, generalState, { messages: [...generalState.messages].splice(messageIndex + 1, 1) });
				} else {
					return generalState;
				}

			case StateService.SET_EDIT:
				const edit: string = (<SetEditAction>action).edit;
				return Object.assign({}, generalState, { edit: edit });

			case StateService.SET_TOPOLOGY_EDIT:
				const topologyEdit: string = (<SetTopologyAction>action).topology_edit;
				return Object.assign({}, generalState, { topologyEdit: topologyEdit });

			case StateService.ADD_FORM_TRANSACTION:
				let addedFormTransaction: FormTransaction = (<AddFormTransactionAction>action).formTransaction;
				return Object.assign({}, generalState, { formTransactions: [...generalState.formTransactions, addedFormTransaction] });

			case StateService.REMOVE_FORM_TRANSACTION:
				let removedFormTransaction: FormTransaction = (<RemoveFormTransactionAction>action).formTransaction;

				let formTransactionIndex = -1;
				for (let i = 0; i < generalState.formTransactions.length; i++) {
					let currentFormTransaction: any = generalState.formTransactions[i];
					if (currentFormTransaction.timestamp == removedFormTransaction.timestamp) {
						formTransactionIndex = i;
					}
				};

				if (formTransactionIndex >= 0) {
					return Object.assign({}, generalState, { formTransactions: [...generalState.formTransactions].splice(formTransactionIndex + 1, 1) });
				} else {
					return generalState;
				}

			case StateService.SET_TIMESERIES_GRAPH:
				const visible: boolean = (<SetTimeseriesGraphAction>action).visible;
				const layerName: string = (<SetTimeseriesGraphAction>action).layerName;

				let timeseriesGraphObject = Object.assign({}, generalState.timeseriesGraph, {
					visible: visible,
					layerName: layerName,
					timestamp: Date.now()
				});

				return { ...generalState, ...{ timeseriesGraph: timeseriesGraphObject } };

			case StateService.SET_USER_DOCS:
				const docs: Array<Object> = (<SetUserDocsAction>action).docs;
				return Object.assign({}, generalState, {
					userDocs: {
						docs: docs,
						timestamp: Date.now()
					}
				});

			case StateService.ADD_OPEN_LAYER:
				let layerToAdd: WorkspaceLayer = (<AddOpenLayerStorageAction>action).layer;
				let currentScreenStateIndex = -1;
				let screenStates = generalState.userState.screenStates;
				for (let i = 0; i < screenStates.length; i++) {
					if (screenStates[i].screenId == generalState.activeModule) {
						currentScreenStateIndex = i;
						break;
					}
				}
				if (currentScreenStateIndex < 0) {
					let newScreenState = {
						screenId: generalState.activeModule,
						openLayers: [layerToAdd],
						basemapName: 'bing'
					};

					let newUserState = { userState: { screenStates: [...generalState.userState.screenStates, newScreenState], firstRun: generalState.userState.firstRun } };
					StateService.setLocalStorageItemJSON('userState', generalState.userState);
					return Object.assign({}, generalState, newUserState);
				} else {
					let currentScreenState = { ...screenStates[currentScreenStateIndex] };
					// Add if not exists
					for (let index = 0; index < currentScreenState.openLayers.length; index++) {
						const openLayer = currentScreenState.openLayers[index];
						if (openLayer.Name === layerToAdd.Name) {
							return generalState;
						}
					}
					let alteredScreenState = { ...currentScreenState, ...{ openLayers: [...currentScreenState.openLayers, layerToAdd] } };

					let newUserState = { firstRun: generalState.userState.firstRun, screenStates: [...screenStates.slice(0, currentScreenStateIndex), alteredScreenState, ...screenStates.slice(currentScreenStateIndex + 1, screenStates.length)] };
					StateService.setLocalStorageItemJSON('userState', newUserState);
					return { ...generalState, ...{ userState: newUserState } };
				}


			case StateService.REMOVE_OPEN_LAYER:
				let layerToRemove: WorkspaceLayer = (<AddOpenLayerStorageAction>action).layer;
				let currentScreenStateIndex3 = -1;
				let currentLayerIndex = -1;
				let screenStates3 = generalState.userState.screenStates;

				// Find screen state index
				for (let i = 0; i < screenStates3.length; i++) {
					if (screenStates3[i].screenId == generalState.activeModule) {
						currentScreenStateIndex3 = i;
						break;
					}
				}
				let currentScreenState3 = { ...screenStates3[currentScreenStateIndex3] };

				// Find layer index
				for (let i = 0; i < currentScreenState3.openLayers.length; i++) {
					if (currentScreenState3.openLayers[i].Name === layerToRemove.Name) {
						currentLayerIndex = i;
						break;
					}
				}
				let alteredScreenState3 = { ...currentScreenState3, ...{ openLayers: [...currentScreenState3.openLayers.slice(0, currentLayerIndex), ...currentScreenState3.openLayers.slice(currentLayerIndex + 1, currentScreenState3.openLayers.length)] } };

				let newUserState = { firstRun: generalState.userState.firstRun, screenStates: [...screenStates3.slice(0, currentScreenStateIndex3), alteredScreenState3, ...screenStates3.slice(currentScreenStateIndex3 + 1, screenStates3.length)] };
				StateService.setLocalStorageItemJSON('userState', newUserState);
				return { ...generalState, ...{ userState: newUserState } };

			case StateService.SET_SCREEN_EXTENT:
				const extent: [number, number, number, number] = (<SetScreenExtentStorageAction>action).extent;
				let currentScreenStateIndex2 = -1;
				let screenStates2 = generalState.userState.screenStates;
				for (let i = 0; i < screenStates2.length; i++) {
					if (screenStates2[i].screenId == generalState.activeModule) {
						currentScreenStateIndex2 = i;
						break;
					}
				}
				if (currentScreenStateIndex2 < 0) {
					let newScreenState: ScreenState = {
						screenId: generalState.activeModule,
						openLayers: [],
						layerOrder: [],
						mapExtent: extent,
						basemapName: 'bing'
					};
					return Object.assign({}, generalState, { userState: { screenStates: [...generalState.userState.screenStates, newScreenState] } });
				} else {
					let currentScreenState2 = { ...screenStates2[currentScreenStateIndex2] };
					currentScreenState2.mapExtent = extent;

					let newUserState = { firstRun: generalState.userState.firstRun, screenStates: [...screenStates2.slice(0, currentScreenStateIndex2), currentScreenState2, ...screenStates2.slice(currentScreenStateIndex2 + 1, screenStates2.length)] };
					StateService.setLocalStorageItemJSON('userState', newUserState);
					return { ...generalState, ...{ userState: newUserState } };
				}

			case StateService.SET_SCREEN_BASEMAP:
				const basemapName: string = (<SetScreenBasemapStorageAction>action).basemap;
				let currentScreenStateIndex4 = -1;
				let screenStates4 = generalState.userState.screenStates;
				for (let i = 0; i < screenStates4.length; i++) {
					if (screenStates4[i].screenId == generalState.activeModule) {
						currentScreenStateIndex4 = i;
						break;
					}
				}
				if (currentScreenStateIndex4 < 0) {
					let newScreenState: ScreenState = {
						screenId: generalState.activeModule,
						openLayers: [],
						layerOrder: [],
						mapExtent: [0, 0, 0, 0],
						basemapName: basemapName
					};
					return Object.assign({}, generalState, { userState: { screenStates: [...generalState.userState.screenStates, newScreenState] } });
				} else {
					let currentScreenState4 = { ...screenStates4[currentScreenStateIndex4] };
					currentScreenState4.basemapName = basemapName;

					let newUserState = { firstRun: generalState.userState.firstRun, screenStates: [...screenStates4.slice(0, currentScreenStateIndex4), currentScreenState4, ...screenStates4.slice(currentScreenStateIndex4 + 1, screenStates4.length)] };
					StateService.setLocalStorageItemJSON('userState', newUserState);
					return { ...generalState, ...{ userState: newUserState } };
				}

			case StateService.SET_LAYER_ORDER:
				const layerOrder: Array<WorkspaceLayer> = (<SetLayerOrderStorageAction>action).layerOrder;
				let currentScreenStateIndex5 = -1;
				let screenStates5 = generalState.userState.screenStates;
				for (let i = 0; i < screenStates5.length; i++) {
					if (screenStates5[i].screenId == generalState.activeModule) {
						currentScreenStateIndex5 = i;
						break;
					}
				}
				if (currentScreenStateIndex5 < 0) {
					let newScreenState: ScreenState = {
						screenId: generalState.activeModule,
						openLayers: [],
						layerOrder: [],
						mapExtent: [0, 0, 0, 0],
						basemapName: 'bing'
					};
					return Object.assign({}, generalState, { userState: { screenStates: [...generalState.userState.screenStates, newScreenState] } });
				} else {
					let currentScreenState5 = { ...screenStates5[currentScreenStateIndex5] };
					currentScreenState5.layerOrder = layerOrder;

					let newUserState = {
						firstRun: generalState.userState.firstRun,
						screenStates: [...screenStates5.slice(0, currentScreenStateIndex5), currentScreenState5, ...screenStates5.slice(currentScreenStateIndex5 + 1, screenStates5.length)]
					};
					StateService.setLocalStorageItemJSON('userState', newUserState);
					return { ...generalState, ...{ userState: newUserState } };
				}

			case StateService.LOAD_USER_STATE:
				let loadedUserState = StateService.getLocalStorageItemJSON('userState');
				if (loadedUserState) {
					return { ...generalState, ...{ userState: loadedUserState } };
				}
				return generalState;

			case StateService.UPDATE_DATA:
				const updatedFeatures: Array<Object> = (<UpdateDataAction>action).features;
				const updatedLayer: WorkspaceLayer = (<UpdateDataAction>action).selectedLayer;
				const updateAction = (<UpdateDataAction>action).updateAction;
				let newState;

				// 1. Updated feature table data if needed
				if (generalState.featureTableData.selectedLayer && generalState.featureTableData.selectedLayer.Name === updatedLayer.Name) {

					let newFeatures = StateService.searchAndReplaceUpdated(generalState.featureTableData.features, updatedFeatures, updateAction);
					let newFeatureTableData = Object.assign({}, generalState.featureTableData, {
						timestamp: Date.now(),
						features: newFeatures
					});

					newState = { ...generalState, ...{ featureTableData: newFeatureTableData } };
				}

				// 1b. Update form data?
				if (generalState.formData.selectedLayer && generalState.formData.selectedLayer.Name === updatedLayer.Name && updatedFeatures.length === 1) {

					let newFormData = Object.assign({}, generalState.formData, {
						timestamp: Date.now(),
						features: [updatedFeatures[0]]
					});

					if (newState) {
						newState = {
							...newState, ...{
								formData: newFormData
							}
						};
					} else {
						newState = {
							...generalState, ...{
								formData: newFormData
							}
						};
					}

				}


				// 2. Update featureInfo data if needed
				let updatedLayerIndex = -1;
				let featureInfoLayers2 = generalState.featureInfo.tables;
				for (let i = 0; i < featureInfoLayers2.length; i++) {
					if (featureInfoLayers2[i].selectedLayer && featureInfoLayers2[i].selectedLayer.Name == updatedLayer.Name) {
						updatedLayerIndex = i;
						break;
					}
				}

				if (updatedLayerIndex > -1) {
					let newFeatures = StateService.searchAndReplaceUpdated(generalState.featureInfo.tables[updatedLayerIndex].features, updatedFeatures, updateAction);
					let newFeatureInfoData = Object.assign({}, generalState.featureInfo.tables[updatedLayerIndex], {
						timestamp: Date.now(),
						features: newFeatures
					});
					let alteredFeatureInfo = [...featureInfoLayers2.slice(0, updatedLayerIndex), newFeatureInfoData, ...featureInfoLayers2.slice(updatedLayerIndex + 1, featureInfoLayers2.length)];
					let newFeatureInfo = { ...generalState.featureInfo, ...{ tables: alteredFeatureInfo } };
					newState = {
						...generalState, ...{
							featureInfo: newFeatureInfo
						}
					};
				}

				return newState ? newState : generalState;

			case StateService.SET_USER_STATE_FIRST_RUN:
				let firstRun: boolean = (<SetUserStateFirstRunAction>action).firstRun;
				let newUserStateFirst = { firstRun: firstRun, screenStates: generalState.userState.screenStates };
				StateService.setLocalStorageItemJSON('userState', newUserStateFirst);
				return { ...generalState, ...{ userState: newUserStateFirst } };

			case StateService.SET_IS_ANDROID:
				const isAndroid: boolean = (<SetIsAndroidAction>action).isAndroid;
				return Object.assign({}, generalState, { isAndroid: isAndroid });

			case StateService.SET_EPANET_RESULTS:
				const epanetResults: EpanetResults = (<SetEpanetResultsAction>action).epanetResults;
				return Object.assign({}, generalState, { epanetResultsAndOptions: { ...generalState.epanetResultsAndOptions, ...{ epanetResults: epanetResults } } });

			case StateService.SET_EPANET_OPTIONS:
				const epanetOptions: Map<string,any> = (<SetEpanetOptionsAction>action).epanetOptions;
				return Object.assign({}, generalState, { epanetResultsAndOptions: { ...generalState.epanetResultsAndOptions, ...{ epanetOptions: epanetOptions } } });

			case StateService.SET_DASHBOARD_TYPE:
				const dashboardType: ScadaDashboardType = (<SetActiveDashboardAction>action).dashboardType;
				const dashboardItemId: ScadaDashboardType = (<SetActiveDashboardAction>action).id;
				return Object.assign({}, generalState, { scadaDashboard: { type: dashboardType, id: dashboardItemId } });

			case StateService.SET_FILTER_LAYER_NAME: 
				const lastFilteredLayerName = (<SetLayerFilterNameAction>action).lastFilteredLayerName;
				return {...generalState, lastFilteredLayerName};
				
			case StateService.SET_LAYER_FILTER: {
				// Mutate stateStore
				const filteredLayer = (<SetLayerFilterAction>action).layer;
				filteredLayer.Model.ecql_filter = (<SetLayerFilterAction>action).filter;
				filteredLayer.Model.ecql_applied = (<SetLayerFilterAction>action).applied;

				// Save layer filter state to LocalStorage
				const newLayerFilter = {
					layerName: filteredLayer.Name,
					filter: filteredLayer.Model.ecql_filter,
					applied: filteredLayer.Model.ecql_applied,
				};

				const currentScreenState = generalState.userState.screenStates.find(screenState => screenState.screenId === generalState.activeModule);
				if (currentScreenState.layerFilters?.length) {
					const layerFiltersIndex = currentScreenState.layerFilters.findIndex(layerFilter => layerFilter.layerName === filteredLayer.Name);
					if (layerFiltersIndex >= 0) {
						currentScreenState.layerFilters[layerFiltersIndex] = newLayerFilter;
					} else {
						currentScreenState.layerFilters.push(newLayerFilter);
					}
				} else {
					currentScreenState.layerFilters = [newLayerFilter];
				}
        // Remove deleted filters from LocalStorage
        currentScreenState.layerFilters = currentScreenState.layerFilters.filter(lf => !!lf.filter);
				StateService.setLocalStorageItemJSON('userState', generalState.userState);
				return {...generalState};
			}
				
			default:
				return generalState;
		}

	} // Reducer

	static rootReducer: Reducer<AppState> = combineReducers<AppState>({
		general: StateService.GeneralReducer
	});

	static stateStore: Store<AppState> = createStore<AppState>(
		StateService.rootReducer
		, compose(
			devtools//Store enhancer
		)
	);

	constructor() {
		// StateService.stateStore.subscribe(() => this.updateFromState());
	}

	static searchAndReplaceUpdated(originalFeatureSet: Array<Object>, updatedFeatureSet: Array<Object>, updateAction: string): Array<Feature<Geometry>> {
		let newFeatures: Array<Feature<Geometry>> = [];

		// a. Iterate through all features and replace any of them that are updated
		for (let i = 0; i < originalFeatureSet.length; i++) {
			const currentFeatureTableElement = originalFeatureSet[i] as Feature<Geometry>;

			// See if the current element has an updated feature and replaced it if true
			let updateActionOccured = false;
			for (let j = 0; j < updatedFeatureSet.length; j++) {
				const updatedElement = updatedFeatureSet[j] as Feature<Geometry>;
				if (updatedElement.get('id') === currentFeatureTableElement.get('id') && updateAction === CRUD_OPERATION.update) {
					newFeatures.push(updatedElement);
					updateActionOccured = true;
					break;
				} else if (updatedElement.get('id') === currentFeatureTableElement.get('id') && updateAction === CRUD_OPERATION.delete) {
					// Do not add deleted item
					updateActionOccured = true;
					break;
				}
			}

			if (!updateActionOccured) {
				newFeatures.push(currentFeatureTableElement);
			}

		}

		return newFeatures;
	}
	//=====================================================
	// 2. STATE UPDATE   ==================================
	//===================================================== 
	updateFromState() { }

	//=====================================================
	// 2. GETTERS   =======================================
	//=====================================================
	getLoading() {
		return this.getState().loading;
	}

	getWorkspaceLayers(): Array<WorkspaceLayer> {
		return this.getState().workspaceLayers;
	}

	getWorkspaceLayerByName(layerName: string): WorkspaceLayer {

		const workspaceLayers: WorkspaceLayer[] = this.getState().workspaceLayers;

		for (let layerGroup of workspaceLayers) {

			if (layerGroup.Layer) {

				const sublayers: WorkspaceLayer[] = layerGroup.Layer;
				for (let sublayer of sublayers) {
					if (sublayer.Name === layerName) {
						return sublayer;
					}
				}

			} else {

				if (layerGroup.Name === layerName) {
					return layerGroup;
				}

			}

		}

		return null;
	}

	/**
	 * Get the layer model
	 * @param name The layer name
	 */
	getLayerModel(name: string): LayerModel {
		if (!name) {
			return;
		}
		let model: LayerModel;
		this.getState().workspaceLayers.forEach((workspaceLayer: WorkspaceLayer) => {
			if (!!workspaceLayer.Layer) {
				workspaceLayer.Layer.forEach((layer: WorkspaceLayer) => {
					if (layer.Name === name) {
						model = layer.Model;
					}
				});
			} else {
				if (workspaceLayer.Name === name) {
					model = workspaceLayer.Model;
				}
			}
		});
		if (!model) {
			console.warn(`No Model found for the Workspace Layer : ${name}`);
			return;
		} else {
			return model;
		}
	}

	/**
	 * Returns group and layer index
	 * @param layerName The layer name
	 */
	getWorkspaceLayerAndGroupIndexesByLayerName(layerName: string): any {
		const workspaceLayers: WorkspaceLayer[] = this.getState().workspaceLayers;
		for (let i = 0; i < workspaceLayers.length; i++) {
			const currentGroup = workspaceLayers[i];
			if (currentGroup.Layer) {// Is group
				for (let j = 0; j < currentGroup.Layer.length; j++) {
					const currentLayer = currentGroup.Layer[j];
					if (currentLayer.Name === layerName) {
						return {
							groupIndex: i,
							layerIndex: j
						}
					}
				}
			} else {
				return -1;
			}
		}
	}

	getMessage(type: string) {

		let messages: Array<any> = this.getState().messages;
		for (let i = 0; i < messages.length; i++) {
			if (messages[i].type == type) {
				return messages[i];
			}
		}
		return null;
	}

	getFormData() {
		return this.getState().formData;
	}

	getFeatureInfoNew() {
		return this.getState().featureInfo;
	}

	getWorkspace() {
		return this.getState().workspace;
	}

	getActiveModule() {
		return this.getState().activeModule;
	}

	getUser() {
		return this.getState().user;
	}

	getUserData() {
		return this.getState().userData;
	}

	getIsAdmin() {
		return this.getState().isAdmin;
	}

	getState(state = null): GeneralState {
		if (!state) { state = StateService.stateStore.getState(); }
		return state.general;
	}

	getFeatureTableData() {
		return this.getState().featureTableData;
	}

	getMultiTableData() {
		return this.getState().multiTableData;
	}

	getActiveOperation() {
		return this.getState().activeOperation;
	}

	getEdit() {
		return this.getState().edit;
	}

	getTopologyEdit() {
		return this.getState().topologyEdit;
	}

	getTimeseriesGraph() {
		return this.getState().timeseriesGraph;
	}

	getIsAndroid() {
		return this.getState().isAndroid;
	}

	getEpanetResults(): EpanetResults {
		return this.getState().epanetResultsAndOptions.epanetResults;
	}

	getEpanetOptions(): Map<string,any> {
		return this.getState().epanetResultsAndOptions.epanetOptions;
	}

	getActiveDashboard() {
		return this.getState().scadaDashboard;
	}

	getScreenState(screenId: number = StateService.stateStore.getState().general.activeModule) {

		let allScreenStates = StateService.stateStore.getState().general.userState.screenStates;
		for (let index = 0; index < allScreenStates.length; index++) {
			const currentScreenState = allScreenStates[index];
			if (currentScreenState.screenId === screenId) {
				return currentScreenState;
			}
		}

		return false;
	}

	getUserStateFirstRun() {
		return StateService.stateStore.getState().general.userState.firstRun;
	}
	
	/**
	 * 
	 * @param layerFilterNames String array of the layer names you want the filter to be constructed for
	 * @returns string The combined filter for all layers with ; as a separator, in the order provided in layerFilterNames
	 */
	getCombinedLayerFilter(layerFilterNames: string[]): string {
		// Construct combined filter by appending each layer's filter
		// Layers that have no applied filter need the "INCLUDE" filter keyword which means no filter
		let filter = '';
		layerFilterNames.forEach(
			layerName => {
				if (this.getLayerModel(layerName).ecql_filter && this.getLayerModel(layerName).ecql_applied) {
					filter += `${this.getLayerModel(layerName).ecql_filter};`;
				} else {
					filter += 'INCLUDE;';
				}
			}
		);
		// Remove last ; character from filter string
		filter = filter.length ? filter.slice(0,-1) : filter;
		return filter;
	}

	static getScheme() {
		return 'https:';
		// let scheme = window.location.href.split("/")[0];

		// if (scheme === 'file:') {
		// 	return 'https:';
		// } else {
		// 	return window.location.href.split("/")[0];
		// }

	}

	//=====================================================
	// 3. LOCAL STORAGE   =================================
	//=====================================================
	static setLocalStorageItem(name: string, value: string) {
		window.localStorage.setItem(name, value);
	}

	static setLocalStorageItemJSON(name: string, value: object) {
		window.localStorage.setItem(name, JSON.stringify(value));
	}

	static getLocalStorageItem(name: string) {
		return window.localStorage.getItem(name);
	}

	static getLocalStorageItemJSON(name: string) {
		return JSON.parse(window.localStorage.getItem(name));
	}

	static removeLocalStorageItem(name: string) {
		return window.localStorage.removeItem(name);
	}

}
