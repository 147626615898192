import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-csv-form',
  templateUrl: './upload-csv-form.component.html',
  styleUrls: ['./upload-csv-form.component.css']
})
export class UploadCsvFormComponent implements OnInit {

  public file: File;
  
  constructor() { }

  ngOnInit() {
  }


  handleFileInput(files: FileList) {
    this.file = files.item(0);
  }

  onAccept() {
    return {file: this.file} ;
  }

}
