import { Injectable, QueryList } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Injectable()
export class WidgetFocusService {

  matAccordionElementRefList: Map<string, any> = new Map();
  matAccordionComponentList: Map<string, MatExpansionPanel> = new Map();

  constructor() { }

  private resetElementRefs() {
    this.matAccordionElementRefList.clear();
    const temp = document.querySelectorAll('mat-expansion-panel');
    Array.from(temp).forEach(w => {
      const name = w.firstChild.parentElement.querySelector('mat-panel-description').textContent;
      this.matAccordionElementRefList.set(name, w);
    });
  }

  /*
    Call this from the parent component of the accordions, onInit and any time that accordions are added or removed
  */
  public updateAccordionRefs(accordions: QueryList<MatExpansionPanel>) {
    this.resetElementRefs();
    this.matAccordionComponentList.clear();
    accordions.forEach(w => {
      this.matAccordionComponentList.set((<any>w)._viewContainerRef.element.nativeElement.children[0].children[0].children[1].innerText, w);
    })
  }

  // Call this with the title name of the accordion when you want to scroll an accordion to view
  public setFocusToAccordion(name: string) {
    if (!!this.matAccordionElementRefList && !!this.matAccordionElementRefList.get(name)) {
      this.matAccordionElementRefList.get(name).scrollIntoView();
    }
  }

  public forceReRenderExpansionPanels() {
    if (!!this.matAccordionComponentList) {
      this.matAccordionComponentList.forEach(ac => ac.toggle());
      setTimeout(() => this.matAccordionComponentList.forEach(ac => ac.toggle()), 10);
    }
  }

  // Call this with the title name of the accordion when you want to expand an accordion
  public expandAccordion(name: string) {
    if (!!this.matAccordionComponentList && !!this.matAccordionComponentList.get(name)) {
      this.matAccordionComponentList.get(name).open();
    }
  }

}
