<div class="form-field-wrapper relative" [formGroup]="formGroup"
    tabindex="0"
    (focus)="onFieldFocus()">
  <form-multi-value-indicator
    [show]="indicateMultiValueSelection"
    [config]="config">
  </form-multi-value-indicator>

  <div class="checkbox-container" [ngClass]="{'opacity-0': indicateMultiValueSelection}">
      <mat-checkbox (change)="onFieldBlur()"
                    [formControlName]="config.name"
      >{{config.alias || config.name}}</mat-checkbox>
  </div>
</div>
