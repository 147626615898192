import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { ImportBillsComponent } from './import-bills/import-bills.component';

//Material
import { MaterialModule } from '../material/material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    AdminComponent,
    ImportBillsComponent
  ],
  exports: [
    AdminComponent,
    ImportBillsComponent
  ]
})
export class AdminModule { }
