<layout-component [navBtnList]="navBtnList" (navChange)="onNavChanged($event)" #layout>
  <ng-container sidebar-content>
    <div [ngClass]="{ hidden: sideContent !== navButtons.layers }" class="sidebar-content">
      <app-layer-list #layerList class="sidebar-content-inner"></app-layer-list>
    </div>

    <div [ngClass]="{ hidden: sideContent !== navButtons.filters }" class="sidebar-content">
      <app-query class="sidebar-content-inner"></app-query>
    </div>

    <div [ngClass]="{ hidden: sideContent !== navButtons.measurement }" class="sidebar-content">
      <app-measurement class="sidebar-content-inner"></app-measurement>
    </div>

    <div [ngClass]="{ hidden: sideContent !== navButtons.print }" class="sidebar-content">
      <app-print class="sidebar-content-inner"></app-print>
    </div>

    <!-- <div [ngClass]="{ hidden: sideContent !== navButtons.transform_coordinates }" class="sidebar-content">
      <app-transform class="sidebar-content-inner"></app-transform>
    </div> -->
  </ng-container>

  <ng-container form-content>
    <app-form></app-form>
  </ng-container>

  <ng-container map-content>
    <app-map></app-map>
  </ng-container>

  <ng-container geocoding-content>
    <app-geocoding></app-geocoding>
  </ng-container>

  <ng-container timeseries-content>
    <app-timeseries-graph [style.max-height.px]="layout.graphHeight" *ngIf="layout.graphHeight > 0">
    </app-timeseries-graph>
  </ng-container>

  <ng-container table-content>
    <app-feature-table *ngIf="layout.featureTableVisible" [panelVheight]="layout.panelVheight"
      class="card-style"></app-feature-table>

    <app-feature-table-multi *ngIf="layout.multiTableVisible" [panelVheight]="layout.panelVheight" class="card-style">
    </app-feature-table-multi>
  </ng-container>

  <ng-container floating-content>
    <app-editor *ngIf="layerList.editorVisible"></app-editor>
    <app-topology-editor *ngIf="layerList.topologyEditorVisible"></app-topology-editor>
  </ng-container>
</layout-component>
