import { Directive, OnChanges, Input, ElementRef, Renderer2 } from '@angular/core';

import { MapService } from '../map/map.service';
import { noop } from 'rxjs';


@Directive({
  selector: '[appLayerIcon]'
})
export class LayerIconDirective implements OnChanges {
  @Input() bgColor: string;
  @Input() fontColor: string;
  @Input() layer: any;
  @Input() property: string;

  constructor(
    private mapService: MapService,
    private el: ElementRef,
    private render: Renderer2) { }
  ngOnChanges(): void {
    this.getIconByLayerType(this.layer);
  }

  private getIconByLayerType(layer) {

    // Previous logic
    // let legendIcon = layer.Style[0].LegendURL[0].OnlineResource;
    // legendIcon = legendIcon.replace(':80', '');
    // legendIcon += `&LEGEND_OPTIONS=bgColor:${this.bgColor};dpi:${dpi};fontColor:${this.fontColor};fontAntiAliasing:true`;
    // return legendIcon;

    this.mapService.getIcon(layer, this.bgColor, this.fontColor, 91).subscribe((res: Blob) => {
      if (this.property === "backgroundImage") {
        this.editBlobAndRender(res, true);
      } else {
        this.editBlobAndRender(res, false);
      }
    });
  }

  private editBlobAndRender(blob: Blob, setStyle: boolean) {
    createImageBitmap(blob).then(imageBitmap => {
      const canvas = document.createElement('canvas');
      canvas.width = imageBitmap.width;
      canvas.height = imageBitmap.height;
      
      const ctx = canvas.getContext('2d');
      ctx.drawImage(imageBitmap, 0, 0)
      const myImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = myImageData.data;
      
      const rgbBgColor = this.hexToRgb(this.bgColor);
      // data Array is 1-dimensional although it contains 2d data
      // Each 4 consequtive elements of the array represent the RGBA value for each pixel
      // See https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Pixel_manipulation_with_canvas
      for (let i = 0; i < data.length; i += 4) {
        // This transformation could be refined to allow more granular pixel color replacement
        // and mimic anti-aliasing functions
        if (data[i] === rgbBgColor[0] && data[i+1] === rgbBgColor[1] && data[i+2] === rgbBgColor[2] /* && data[i+3] === 255 */){
          data[i+3] = 0;
        }
      }
      
      ctx.putImageData(myImageData, 0, 0);
      canvas.toBlob(blob => {
        if (setStyle) {
          this.render.setStyle(this.el.nativeElement, this.property, "url(" + window.URL.createObjectURL(blob) + ")");
        } else {
          this.render.setProperty(this.el.nativeElement, this.property, window.URL.createObjectURL(blob));
        }
      });
    }).catch(noop);
  }

  /**
   * @param hex string formatted as: '0xFFAA00'
  */
  private hexToRgb(hex: string): Array<number> {
    var result = /^0x?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : [255, 255, 255];
  }

}
