// Angular imports
import { Component, OnInit } from '@angular/core';

// App imports
import { MapService } from '../map.service';
import { StateService } from '../../core/state.service';
import { OPERATIONS, GeometryType } from '../../models';

// Openlayers imports
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import { Draw, Snap } from 'ol/interaction.js';
import { EventsKey } from 'ol/events.js';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { Polygon, LineString } from 'ol/geom';

import * as olObservable from 'ol/Observable.js';
import { getLength, getArea } from 'ol/sphere.js';
import Projection from 'ol/proj/Projection';
import Geometry from 'ol/geom/Geometry';

@Component({
	selector: 'app-measurement',
	templateUrl: './measurement.component.html',
	styleUrls: ['./measurement.component.css'],
	host: {
		'(document:keydown)': 'handleKeyboardEvent($event)'
	}
})
export class MeasurementComponent implements OnInit {
	private stateUnsubscription;
	private operationsTimestamp;
	public distanceMeasurementActive: boolean;
	public areaMeasurementActive: boolean;
	private drawVectorLayerSource: VectorSource;
	private drawVectorLayer: VectorLayer<any>;
	private map: Map;
	private sketch: Feature<Geometry>;
	private helpTooltipElement: HTMLElement;
	private helpTooltip: Overlay;
	private measureTooltipElement: HTMLElement;
	private measureTooltip: Overlay;
	private drawInteraction: Draw;
	private snapInteraction: Snap;
	private drawStartEventKey: EventsKey;
	private changeGeometryEventKey: EventsKey;
	private continuePolygonMsg: string = 'Click to continue drawing the polygon';
	private continueLineMsg: string = 'Click to continue drawing the line';
	public selectedMeasurement: string;


	constructor(private mapService: MapService, private stateService: StateService) {
		this.distanceMeasurementActive = undefined;
		this.areaMeasurementActive = undefined;
		this.drawVectorLayerSource = null;
		this.drawVectorLayer = null;
	}

	ngOnInit() {
		this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
	}

	updateFromState() {

		//Operations actions
		let activeOperation = this.stateService.getActiveOperation();
		if (activeOperation.operation != OPERATIONS.measure) {
			if (this.distanceMeasurementActive) {
				this.activateDistanceMeasurement(false);
			} else if (this.areaMeasurementActive) {
				this.activateAreaMeasurement(false);
			}
		}

	}

    destroyWidget() {
		this.removeVectorLayer();
		this.removeDrawInteraction();
		this.clearMeasurements();
		this.distanceMeasurementActive = undefined;
		this.areaMeasurementActive = undefined;
	}

	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key == "Escape") {
			this.clearMeasurements();
			this.destroyWidget();
			StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
		}
	}	

	activateDistanceMeasurement(updateState: boolean = true) {
		this.selectedMeasurement = 'distance';
		this.distanceMeasurementActive = !this.distanceMeasurementActive;

		if (this.distanceMeasurementActive) {
			this.initMeasurement('LineString');
			if (this.areaMeasurementActive) this.areaMeasurementActive = false;

			if (updateState) {
				StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.measure));
			}

		} else {
			this.removeDrawInteraction();

			if (updateState) {
				StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
			}

		}
	}

	activateAreaMeasurement(updateState: boolean = true) {
		this.selectedMeasurement = 'area';
		this.areaMeasurementActive = !this.areaMeasurementActive;

		if (this.areaMeasurementActive) {
			this.initMeasurement('Polygon');
			if (this.distanceMeasurementActive) this.distanceMeasurementActive = false;

			if (updateState) {
				StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.measure));
			}
		} else {
			this.removeDrawInteraction();
			if (updateState) {
				StateService.stateStore.dispatch(this.stateService.setOperation(OPERATIONS.info));
			}
		}
	}

	initMeasurement(type: string) {
		this.initializeAndAddMeasureVectorLayer();
		this.addDrawInteraction(type);
		this.createMeasureTooltip();
	}

	clearMeasurements() {
		if (!this.drawVectorLayer) { return; }

		this.drawVectorLayerSource.clear();
		let measureTooltips = document.getElementsByClassName('tooltip-static');
		for (let i = measureTooltips.length - 1; i > -1; i--) {
			measureTooltips[i].remove();
		}

	}

	// ====================================
	// 1. VECTOR LAYER
	// ====================================
	initializeAndAddMeasureVectorLayer() {

		this.map = this.mapService.getMap();
		if (this.drawVectorLayer) { return; }

		this.drawVectorLayerSource = new VectorSource();

		this.drawVectorLayer = new VectorLayer({
			source: this.drawVectorLayerSource,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)'
				}),
				stroke: new Stroke({
					color: '#ffcc33',
					width: 2
				}),
				image: new Circle({
					radius: 7,
					fill: new Fill({
						color: '#ffcc33'
					})
				})
			})
		});

		this.drawVectorLayer.set('name', 'drawLayer_measure');
		this.map.addLayer(this.drawVectorLayer)
	}

	removeVectorLayer() {
		if (this.drawVectorLayer) {
			this.map.removeLayer(this.drawVectorLayer);
		}
		this.drawVectorLayerSource = null;
		this.drawVectorLayer = null;
	}

	// ====================================
	// 2. INTERACTION
	// ====================================
	addDrawInteraction(type) {// 'Polygon' || 'LineString'

		this.drawInteraction = new Draw({
			source: this.drawVectorLayerSource,
			type: type,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)'
				}),
				stroke: new Stroke({
					color: 'rgba(0, 0, 0, 0.5)',
					lineDash: [10, 10],
					width: 2
				}),
				image: new Circle({
					radius: 5,
					stroke: new Stroke({
						color: 'rgba(0, 0, 0, 0.7)'
					}),
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.2)'
					})
				})
			})
		});

		this.map.addInteraction(this.drawInteraction);

		this.drawInteraction.on('drawstart', (event) => {
			// set sketch
			this.sketch = event.feature;

			// /** @type {openlayers.Coordinate|undefined} */
			let tooltipCoord; //= event.coordinate;

			this.changeGeometryEventKey = this.sketch.getGeometry().on('change', (event) => {
				let geom = event.target;
				let output;
				if (geom instanceof Polygon) {
					output = this.formatArea(geom);
					tooltipCoord = geom.getInteriorPoint().getCoordinates();
				} else if (geom instanceof LineString) {
					output = this.formatLength(geom);
					tooltipCoord = geom.getLastCoordinate();
				}
				this.measureTooltipElement.innerHTML = output;
				this.measureTooltip.setPosition(tooltipCoord);
			});
		});

		this.drawInteraction.on('drawend', () => {
			this.measureTooltipElement.className = 'tooltip tooltip-static';
			this.measureTooltip.setOffset([0, -7]);
			// unset sketch
			this.sketch = null;
			// unset tooltip so that a new one can be created
			this.measureTooltipElement = null;
			this.createMeasureTooltip();
			olObservable.unByKey(this.changeGeometryEventKey);
			// this.sketch.getGeometry().un('change');
		});


	}

	removeDrawInteraction() {
		if (this.drawInteraction) {
			this.map.removeInteraction(this.drawInteraction);
		}
	}


	addSnapInteraction() {
		// i left this because its not easy to snap to wms
		// this.snapInteraction = new openlayers.interaction.Snap({
		//        source: this.geoserverService.allLayers[0].getSource()
		//      });
		//     	this.map.addInteraction( this.snapInteraction );

	}

	// ====================================
	// 3. MEASURE ELEMENT
	// ====================================

	/**
	* Creates a new measure tooltip
	*/
	createMeasureTooltip() {

		if (this.measureTooltipElement) {
			this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
		}
		this.measureTooltipElement = document.createElement('div');
		this.measureTooltipElement.className = 'tooltip tooltip-measure';
		this.measureTooltip = new Overlay({
			element: this.measureTooltipElement,
			offset: [0, -15],
			positioning: 'bottom-center'
		});
		this.map.addOverlay(this.measureTooltip);

	}

	/**
	* Format length output.
	* @param {openlayers.geom.LineString} line The line.
	* @return {string} The formatted length.
	*/
	formatLength(line: LineString) {
		// let clonedLine = line.clone();
		// let transformedLine = clonedLine.transform('EPSG:2100', 'EPSG:4326');
		let length = getLength(line, {
			projection: new Projection({ code: 'EPSG:2100' })
		});
		let output;
		// if (length > 100) {
		// 	output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km';
		// } else {
		// 	output = (Math.round(length * 100) / 100) + ' ' + 'm';
		// }
		output = (Math.round(length * 100) / 100) + ' ' + 'm';
		return output;
	};

	/**
	* Format area output.
	* @param {openlayers.geom.Polygon} polygon The polygon.
	* @return {string} Formatted area.
	*/
	formatArea(polygon: Polygon) {

		let area = getArea(polygon, {
			projection: new Projection({ code: 'EPSG:2100' })
		});
		let output;
		if (area > 10000) {
			output = (Math.round(area / 1000000 * 100) / 100) +
				' ' + 'km<sup>2</sup>';
		} else {
			output = (Math.round(area * 100) / 100) +
				' ' + 'm<sup>2</sup>';
		}
		return output;

	};

}
