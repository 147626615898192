import { Component, ElementRef, ViewChild } from '@angular/core';
import { ExportFileFormat } from 'app/models';

@Component({
  selector: 'app-export-to-file',
  templateUrl: './export-to-file.component.html',
  styleUrls: ['./export-to-file.component.scss']
})
export class ExportToFileComponent {
  @ViewChild('fileNameInputRef', { static: true }) fileNameInputRef: ElementRef<HTMLInputElement>;

  fileName = `{layer name}_export_{timestamp}`;
  fileInputWidth = '38px';
  availableFormats: Array<ExportFileFormat> = [{
      label: 'KMZ [Keyhole Markup Language]',
      value: '.kmz'
    },
    {
      label: 'CSV [Comma-separated Values]',
      value: '.csv'
    },
    {
      label: 'SHP [ESRI Shapefile archive]',
      value: '.zip'
    }
  ];
  selectedFileFormat: ExportFileFormat = this.availableFormats[0];

  private canvas = document.createElement("canvas");
  private fileInputStyle: CSSStyleDeclaration;

  ngAfterViewInit() {
    if (!!this.fileNameInputRef) {
      this.fileInputStyle = getComputedStyle(this.fileNameInputRef.nativeElement);
    }
  }

  onAccept(): string {
    return this.selectedFileFormat.value;
  }

  onFileNameChange(fileName: string) {
    this.fileName = fileName;
    this.fileInputWidth = this.getTextWidth(
      this.fileNameInputRef.nativeElement.value,
      this.fileInputStyle
    ) + 'px';
  }

  getTextWidth(text: string, elementStyle: CSSStyleDeclaration) {
    var context = this.canvas.getContext("2d");
    context.font = `${elementStyle.fontWeight} ${elementStyle.fontSize} ${elementStyle.fontFamily}`;
    var metrics = context.measureText(text);
    return metrics.width + 2; // add small space (2px) before file type
  }
}
