<div class="flex justify-between items-center gap-2 py-5 pl-4 pr-2">
  <h2 class="font-light" i18n="Manual | Manual">Εγχειρίδιο χρήσης</h2>
  <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
</div>

<div class="flex pdf-container">
  <object class="flex-auto basis-full" [data]="file" type="application/pdf" width="100%">
    <p>Your browser does not support PDFs.
      <a [href]="file">Download the PDF</a>
    </p>
  </object>
</div>
