<div id="unauthorized">
    <main>
        <div class="login-container">
            <div class="logo-container vertical-center">
                <img src="\assets\images\WaterPillar-02.png" class="img-fluid" alt="smartville-logo">
            </div>
            <div class="vertical-center text-center">
                <div class="container">
                    <div class="custom-row">
                        <div class="auth-content col-lg-12 mx-auto">
                            <img class="d-xl-none" src="\assets\images\WaterPillar-01.png" alt="smartville-logo"
                                width="200">
                            <h1 class="h3 mb-3 fw-normal" i18n="You do not have access to this service
                            | You do not have access to this service
                            ">Δεν έχετε πρόσβαση στη συγκεκριμένη υπηρεσία</h1>
                            <div (click)="logout()" class="w-100 btn btn-lg btn-primary" type="submit"
                                i18n="Sign in">Σύνδεση</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>