<div class="dynamic-field relative" [formGroup]="formGroup">
  <form-multi-value-indicator
    [show]="indicateMultiValueSelection"
    [config]="config">
  </form-multi-value-indicator>
  <file-input [ngClass]="{'opacity-0': indicateMultiValueSelection}"
              [formControlName]="config.name"
              [name]="config.name"
              [alias]="config.alias"
              (fileOutput)="onUploadFile($event)"
              (blur)="onFieldBlur()"
              (focus)="onFieldFocus()"
   ></file-input>
</div>