import { Component, OnInit, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { MapService } from '../map.service'
import { StateService } from '../../core/state.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
import { ScreenState } from 'app/models';

@Component({
	selector: 'app-basemap-list',
	templateUrl: './basemap-list.component.html',
	styleUrls: ['./basemap-list.component.scss'],
	animations: [
		trigger('slide', [
			state('hide', style({ display: 'none', width: '0px' })),
			state('show', style({ display: 'block', width: '100%' })),
			transition('* => *', animate('250ms ease-in-out'))
		]
		)]
})
export class BasemapListComponent implements OnInit {

	@Output() basemapImageSelected = new EventEmitter<string>();
	
	public basemaps: Array<any>;
	public selectedBasemap: string;
	public showMenu: boolean = false;
	public stateSlide: string = 'hide';
	public assetsPrefix: string;
	private unsubscribe: Function;

	constructor(private stateService: StateService, private mapService: MapService, private elRef: ElementRef) {
		// this.basemaps = this.mapService.getBaseMapList();	
		// this.selectedBasemap = 	this.basemaps[0].label;
		this.assetsPrefix = environment.assetsPrefix;
		this.basemaps = this.mapService.getBaseMapList();
		this.unsubscribe = StateService.stateStore.subscribe(() => { this.updateFromState(); });
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (!this.elRef.nativeElement.contains(event.target) && this.showMenu) {
			this.toggleMenu();
		}
	}

	ngOnInit() {
	}

	updateFromState() {
		const state = this.stateService.getScreenState() as ScreenState;
		if (!!this.basemaps && !!state && state.basemapName) {
			this.selectedBasemap = this.basemaps.find(b => b.name === state.basemapName).label;
			this.unsubscribe();
		}
	}

	onBasemapSelect(basemap: any) {
		this.mapService.setBasemap(basemap.name);
		this.selectedBasemap = basemap.label;
		StateService.stateStore.dispatch(this.stateService.setScreenBasemapOperation(basemap.name));

		this.basemapImageSelected.emit(basemap.imageSrc);
	}

	toggleMenu() {
		this.showMenu = !this.showMenu;
		if (this.stateSlide === 'hide') {
			this.stateSlide = 'show';
		}
		else {
			this.stateSlide = 'hide';
		}
	}


}