// Angular imports
import { Component, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

// App imports
import { StateService } from '../../core/state.service';
import { MapService } from '../../map/map.service';
import { GeoserverService } from '../../core/geoserver.service';
import { LayerModel, WorkspaceLayer, FormOperation, FormTransaction, TableActionObject, ExportFileExtension } from '../../models';
import { SortEvent, EDIT_ACTIONS } from '../../models';
import { ModelOperationsService } from '../../core/model-operations.service';
import { LayoutService } from 'app/core/layout.service';
import { SnackBarService } from '../../core/snack-bar.service';
import { MatDialog } from "@angular/material/dialog";
import { UploadCsvFormComponent } from "../../shared/upload-csv-form/upload-csv-form.component";
import { ApiService } from '../../core/api.service';
import { ExportToFileComponent } from "../export-to-file/export-to-file.component";
import { DecideDialogComponent } from 'app/shared/decide-dialog/decide-dialog.component';
import { Feature } from 'ol';

@Component({
  selector: 'app-feature-table',
  templateUrl: './feature-table.component.html',
  styleUrls: ['./feature-table.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event)'
  }
})
export class FeatureTableComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() enableSelection: boolean = false;
  @Input() isInDashboard: boolean = false;

  private stateUnsubscription;
  public features: Array<any>;
  private featuresTimestamp: number;
  public selectedLayer: WorkspaceLayer;
  public startIndex: number;
  public totalFeatures: number;
  private pageIndex: number;
  public layerModel: LayerModel;
  public editable: boolean;
  public lastSelectedFeatures: Array<any> = [];
  private selectionTimeStamp: number;
  private latestFilter: string;

  constructor(private stateService: StateService,
    private formDialog: MatDialog,
    private mapService: MapService,
    private geoserverService: GeoserverService,
    private apiService: ApiService,
    private modelOperationsService: ModelOperationsService,
    private layoutService: LayoutService,
    private snackBarService: SnackBarService) {
  }

  ngOnInit() {
    this.features = [];
    this.featuresTimestamp = 0;
    this.stateUnsubscription = StateService.stateStore.subscribe(() => { this.updateFromState(); });
    this.pageIndex = 0; // Init to 0 always (?)
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateFromState(); }, 0);
  }

  updateFromState() {
    let featureTableData = this.stateService.getFeatureTableData();

    if (this.featuresTimestamp != featureTableData.timestamp) {
      this.features = featureTableData.features;
      this.selectedLayer = featureTableData.selectedLayer;
      this.layerModel = this.selectedLayer ? this.selectedLayer.Model : null;
      this.editable = this.layerModel ? !!this.layerModel.is_edit : false;
      this.latestFilter = featureTableData.filter ? featureTableData.filter : '';
      if (this.editable && this.layerModel && this.layerModel.edit_actions) {
        this.editable = (this.layerModel.edit_actions && !!this.layerModel.edit_actions.find(a => a === EDIT_ACTIONS.update));
      }
      this.featuresTimestamp = featureTableData.timestamp;
      this.totalFeatures = featureTableData.totalFeatures;
      this.startIndex = featureTableData.startIndex;
    }
    if (!!featureTableData.selectedFeatures?.features && featureTableData.selectedFeatures?.selectedLayer?.Name === this.selectedLayer?.Name && this.selectionTimeStamp !== featureTableData.selectedFeatures.timestamp) {
      this.selectionTimeStamp = featureTableData.selectedFeatures.timestamp;
      this.lastSelectedFeatures = featureTableData.selectedFeatures.features;
    }
  }

  ngOnDestroy() {
    this.stateUnsubscription();
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape" && !this.isInDashboard) {
      this.clearTable();
    }
  }

  onFeatureClick(features: Feature[]) {
    this.mapService?.clearVectorLayer();
    if (features.length > 0) {
      this.mapService.onSelectFeaturesFromTable(features);
    }
    StateService.stateStore.dispatch(this.stateService.setFormDataOperation({
      title: this.selectedLayer.Title,
      features: features,
      selectedLayer: this.selectedLayer,
      eventFrom: 2
    }));
  }

  onHighlight() {
    this.mapService.clearVectorLayer();
    this.mapService.addFeaturesToVectorLayer(this.features);
  }

  onZoom() {
    this.mapService.zoomToFeatures(this.features);
  }

  resizeTablePanel() {
    this.layoutService.tableResize();
  }

  onClear() {
    this.mapService.clearVectorLayer();
  }

  clearTable() {
    StateService.stateStore.dispatch(this.stateService.setFeatureTableData([], false));
    this.layoutService.toggleTable(false);
  }

  handleTableControlAction(action: TableActionObject) {
    switch (action.type) {
      case 'show': {
        this.onHighlight();
        break;
      }
      case 'clear': {
        this.onClear();
        break;
      }
      case 'zoom': {
        this.onZoom();
        break;
      }
      case 'resize': {
        this.resizeTablePanel();
        break;
      }
      case 'close': {
        this.clearTable();
        break;
      }
      case 'export': {
        const dialogRef = this.formDialog.open(ExportToFileComponent, { width: '30rem' });
        dialogRef.afterClosed().subscribe((fileFormat: ExportFileExtension) => {
          if (!fileFormat) {
            return;
          }
          this.layoutService.showHeaderLoader(true);
          if (fileFormat === '.kmz') {
            this.exportKMZ(this.selectedLayer);
          } else {
            this.exportShapeAndCSV(this.selectedLayer, fileFormat);
          }
        });
        break;
      }
      case 'calculate': {
        // Open confirmation dialog with custom title and message
        this.formDialog.open(DecideDialogComponent, {
          data: {
            title: $localize`Υπολογισμός υψομέτρου`,
            message: $localize`Θέλετε σίγουρα να υπολογίσετε το υψόμετρο για όλα τα στοιχεία του συγκεκριμένου επιπέδου;`,
            cancel: $localize`Ακύρωση`,
            confirm: $localize`Υπολογισμός`
          }
        }).afterClosed().subscribe(confirmed => {
          if (confirmed) {
            // If the user confirms, proceed with the calculation
            StateService.stateStore.dispatch(this.stateService.setLoadingOperation(true, '', -1, false));
            this.apiService.calculateElevationForLayer(this.selectedLayer.Name.split(':')[1]).subscribe((responce: any) => {
              this.snackBarService.setMessage(responce.message, 10000);
              this.geoserverService.getFeature(this.selectedLayer, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);

            });
          }
        });
        break;
      }
      case 'import': {
        const dialogRef = this.formDialog.open(UploadCsvFormComponent, { width: '25rem' });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (!!result) {
            this.apiService.uploadCsv(this.selectedLayer.Name.split(':')[1], result.file).subscribe((responce: any) => {
              let bboxRaw = responce.bbox.substring(4, responce.bbox.length - 1);
              let bboxCoords = bboxRaw.split(',');
              let bboxlat = bboxCoords[0].split(' ');
              let bboxlon = bboxCoords[1].split(' ');
              let bboxExtent = [bboxlat[0], bboxlat[1], bboxlon[0], bboxlon[1]];

              this.mapService.zoomToExtent(bboxExtent);
              this.mapService.reloadVisibleLayers();

              this.snackBarService.setMessage('Έγινε εισαγωγή ' + responce.total_imports + ' στοιχείων από συνολικά ' + responce.total_points + ' στοιχεία', 4000);
              this.geoserverService.getFeature(this.stateService.getWorkspaceLayerByName(this.stateService.getWorkspace() + ':' + this.selectedLayer.Name.split(':')[1]), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
            });
          }
        });

        break;
      }
      case 'delete': {
        // 1. Create a form transaction
        let deleteFormOperation: FormOperation = {
          layer: this.selectedLayer,
          layerModel: this.selectedLayer.Model,
          feature: this.lastSelectedFeatures,
          formHeader: 'Διαγραφή στοιχείων',
          timestamp: this.geoserverService.guid(),
          dirty: true,
          action: EDIT_ACTIONS.delete
        };

        // 2. Create transaction
        let deleteTransaction: FormTransaction = {
          timestamp: this.geoserverService.guid(),
          formOperations: [deleteFormOperation]
        };
        StateService.stateStore.dispatch(this.stateService.addFormTransactionOperation(deleteTransaction));
        break;
      }
      case 'move':
        // Add feature(s) to destination layer
        this.modelOperationsService.persist(action.destinationLayer, null, action.features, null, "");
        // Remove feature(s) from source layer
        this.modelOperationsService.persist(this.selectedLayer, null, null, action.features, "Η μεταφορά των επιλεγμένων στοιχείων ολοκληρώθηκε επιτυχώς.");
        break;
      case 'lineLayerLength': {
        this.apiService.getLineLayerLength(this.selectedLayer, this.latestFilter).subscribe((responce: any) => {
          this.snackBarService.setMessage('Το επίπεδο έχει συνολικό μήκος ' + parseInt(responce.length).toLocaleString('el') + ' μέτρα.', 10000);
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  exportKMZ(layer: WorkspaceLayer, exportViewport = false) {
    this.mapService.getExportURL('kmz', [layer], exportViewport);
  }

  exportShapeAndCSV(layer: WorkspaceLayer, fileExt: ExportFileExtension) {
    this.geoserverService.exportWfs(fileExt === '.csv' ? 'csv' : 'shape-zip', layer);
  }

  onPageChange(pageChange: PageEvent) {
    // Clear selected rows, and form data on pagination event
    this.onFeatureClick([]);

    // Initialize pageIndex when the attribute table is being called
    if (this.pageIndex !== pageChange.previousPageIndex) {
      this.pageIndex = 0;
    }
    if (this.features.length !== pageChange.pageSize) {
      this.geoserverService.getFeature(this.selectedLayer, undefined, this.latestFilter, pageChange.pageSize, undefined, undefined, undefined, undefined, undefined, true);
      this.pageIndex = 0;
    } else if (pageChange.pageIndex > this.pageIndex) {
      this.geoserverService.getFeature(undefined, undefined, this.latestFilter, pageChange.pageSize, undefined, true, false, undefined, undefined, undefined);
      this.pageIndex = pageChange.pageIndex;
    } else {
      this.geoserverService.getFeature(undefined, undefined, this.latestFilter, pageChange.pageSize, undefined, false, true, undefined, undefined, undefined);
      this.pageIndex = pageChange.pageIndex;
    }
  }

  /**
   * Sort by property name
   * @param property The property name
   * @param order false = ASC | true = DESC
   */
  onSortChange(sort: SortEvent) {
    if (sort.sortOrder === undefined) {
      // TODO:  Stavros : Maybe handle undefined to reset to initial query (no sorting) ??
      return;
    } else {
      this.geoserverService.getFeature(undefined, undefined, undefined, undefined, undefined, undefined, undefined, sort.sortBy, sort.sortOrder);
    }

  }

  handleRowSelect(selectedRows: Feature[]) {
    this.onFeatureClick(selectedRows);
    StateService.stateStore.dispatch(this.stateService.setFeatureTableSelectedFeatures(this.selectedLayer, selectedRows));
  }
}
