export const environment = {
  production             : true,
  keycloackEndpoint      : 'https://sso.smartville.gr',
  keycloakLogout         : '/realms/smartville/protocol/openid-connect/logout?client_id=waterpillar&post_logout_redirect_uri=',
  domain                 : 'water-pillar.com',
  geoserverDomain        : 'water-pillar.com',
  apiDomain              : 'api2.water-pillar.com',
  bigDataApiDomain       : 'v1.data.smartville.gr/data',
  scadaLatestEndpoint    : '/scada/latest?client=drama',
  scada                  : '/scada',
  apiPath                : '/api',
  uploadEndpoint         : '/files/uploads',
  uploadCSVEndpoint      : '/files/uploadcsv',
  epanetPrepareEndpoint  : '/epanet/prepareEpanetInp/',
  epanetFindClusters     : '/epanet/findClusters/',
  epanetRunEndpoint      : '/epanetapi/',
  epanetGetElevation     : '/epanet/getElevationByPoint',
  epanetOrphanFacilities : '/epanet/identifyOrphanFacilities/',
  epanetCalculateElevationForLayer: '/epanet/calculateElevationForLayer/',
  loginEndpoint          : '/user/login',
  storageEndpoint        : '/storage',
  docUploadEndpoint      : '/docs/upload',
  docDeleteEndpoint      : '/docs/delete',
  docsEndpoint           : '/docs/get',
  docsGetFileFromRoot    : '/docs/getFileFromUserRoot',
  sensorsEndpoint        : '/scada/measurements/',
  xilogEndpoint          : '/xilog/measurements/',
  xilogInfraEndpoint     : '/xilog/',
  kaptaEndpoint          : '/kapta/',
  metadata               : '/metadata',
  variables              : '/kafka/variablesInfo/',
  createSuppliesEndpoint : '/geometry/createSupplies/',
  lineLayerLengthEndpoint: '/geometry/getLineLayerLength/',
  lineLayerLengthWithFilterEndpoint: '/geometry/getLineLayerLengthWithFilter/',
  api2GetUser            : '/auth/getUser',
  baseName               : 'gis',
  assetsPrefix           : '../',
  pillarProxyEndpoint    : '/deyapp/smartvilleRequest',
  smartvilleApiEndpoint  : {
    kos:'https://deyakos.dev.smartville.gr/api/infrastructure/announcements/',
    makrohori:'https://deyakos.dev.smartville.gr/api/infrastructure/announcements/'
  },
  smartvilleUrl  : {
    kos:'https://deyakos.dev.smartville.gr',
    makrohori:'https://demo.dev.smartville.gr',
    megara: 'https://megara.smartville.gr',
    rigasfereos: 'https://rigasfereos.smartville.gr',
    mykonos:'https://deyamykonou.smartville.gr',
    deskati:'https://deskati.smartville.gr',
    lavrio:'https://deyalavreotikis.smartville.gr',
    drama:'https://deyadramas.smartville.gr',
    karditsa:'https://deyakarditsas.smartville.gr',
    paros:'https://deyaparou.smartville.gr',
    amyntaio:'https://amyntaio.smartville.gr',
    servia:'https://servia.smartville.gr',
    kerkira:'https://deyakerkiras.smartville.gr/'
  },
  theArray: {
    kos: 'https://deyakos.thearray.eu/admin/dashboard',
    kerkira: 'https://deyakerkiras.thearray.eu/admin/dashboard',
    makrohori: 'https://demo.thearray.eu/admin/dashboard'
  },
  symmetry: {
    kos: 'https://symmetry.ianic.gr/admin/dashboard',
    makrohori: 'https://symmetry.ianic.gr/admin/dashboard'
  },
  safeWaterPlan: {
    kos: 'https://safewaterplan.gr/',
    makrohori: 'https://safewaterplan.gr/'
  },
  balloonWorks: {
    kos: 'https://meters.ianic.gr/login',
    makrohori: 'https://meters.ianic.gr/login'
  }
};
