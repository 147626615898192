<div class="flex flex-col gap-2">
  <div class="sidebar-widget">
    <h2 class="mb-2 font-light" i18n="Filters | filters for querying">Φίλτρα</h2>

    <form class="flex flex-col">
      <mat-form-field>
        <mat-label i18n="Group selection | select group">Επιλογή ομάδας</mat-label>
        <mat-select [value]="selectedGroup">
          <mat-option (click)="onGroupSelect(group)" *ngFor="let group of workspaceGroups" [value]="group">
            {{ group.Title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="Layer selection | select layer">Επιλογή επιπέδου</mat-label>
        <mat-select [value]="selectedLayer">
          <mat-option (click)="onLayerSelect(layer)" *ngFor="let layer of workspaceLayers" [value]="layer">
            {{ layer.Title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n-placeholder="Property selection | select property">Επιλογή ιδιότητας</mat-label>
        <mat-select [value]="selectedProperty">
          <mat-option (click)="onPropertySelect(property)" *ngFor="let property of layerProperties" [value]="property">
            {{ property.alias ? property.alias : property.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="!selectedPropertyIsDate">
        <mat-form-field class="w-full">
          <mat-label i18n="Property selection | select property">Επιλογή τιμής</mat-label>
          <mat-select [value]="selectedPropertyValue">
            <mat-option (click)="onPropertyValueSelect(value)" *ngFor="let value of distinctPropertyValues" [value]="value">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="selectedPropertyIsDate">
        <mat-form-field>
          <mat-label i18n="Date selection | select date">Επιλογή ημερομηνίας</mat-label>
          <input matInput [matDatepicker]="myDatepicker" (dateChange)="onPropertyDateSelect('change', $event)" />
          <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
          <mat-datepicker type="hidden" #myDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </form>

    <div class="flex flex-col gap-2.5">
      <div class="flex justify-between gap-2">
        <button (click)="operatorClick(0)" mat-mini-fab color="basic">=</button>
        <button (click)="operatorClick(2)" mat-mini-fab color="basic">
          &#60;&#62;
        </button>
        <button (click)="operatorClick(16)" mat-raised-button class="rounded-full p-0">
          DURING
        </button>
        <button (click)="operatorClick(18)" mat-raised-button class="rounded-full p-0">
          LIKE
        </button>
      </div>

      <div class="flex justify-between gap-2">
        <button (click)="operatorClick(4)" mat-mini-fab color="basic">
          &#60;
        </button>
        <button (click)="operatorClick(6)" mat-mini-fab color="basic">
          &#60;=
        </button>
        <button (click)="operatorClick(14)" mat-raised-button class="rounded-full p-0">
          AFTER
        </button>
        <button (click)="operatorClick(3)" mat-raised-button class="rounded-full p-0">
          AND
        </button>
      </div>

      <div class="flex justify-between gap-2">
        <button (click)="operatorClick(8)" mat-mini-fab color="basic">
          &#62;
        </button>
        <button (click)="operatorClick(10)" mat-mini-fab color="basic">
          &#62;=
        </button>
        <button (click)="operatorClick(12)" mat-raised-button class="rounded-full p-0">
          BEFORE
        </button>
        <button (click)="operatorClick(5)" mat-raised-button class="rounded-full p-0">
          OR
        </button>
      </div>
    </div>
  </div>

  <div class="sidebar-widget">
    <form class="flex flex-col gap-2.5">
      <mat-form-field class="relative">
        <mat-label i18n="Query | Query to be sent">Ερώτημα</mat-label>
        <textarea [(ngModel)]="ecqlQuery" [ngModelOptions]="{ standalone: true }" type="text" rows="5"
          matInput></textarea>

        <button class="absolute top-2.5 right-0 flex items-center justify-center" *ngIf="!!ecqlQuery" matSuffix
          mat-icon-button aria-label="Clear" (click)="clearEcql()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div class="grid grid-cols-2 gap-1.5 mb-4">
        <mat-checkbox color="primary" [(ngModel)]="spatialFilterEnabled" [ngModelOptions]="{ standalone: true }">
          <ng-container i18n="Spatial filter | Spatial filter">
            Χωρικό φίλτρο
          </ng-container>
        </mat-checkbox>

        <!-- <mat-checkbox color="primary" class="basis-1/2" [(ngModel)]="fetchAll" [ngModelOptions]="{ standalone: true }">
          <ng-container i18n="Retrieve all | Retrieve all">Ανάκτηση όλων</ng-container>
        </mat-checkbox> -->
      </div>

      <!-- <mat-form-field>
        <mat-label i18n="Data format selection | select format">Επιλογή μορφής δεδομένων</mat-label>
        <mat-select [value]="dataFormats[0].value">
          <mat-option (click)="onDataFormatSelect(format.value)" *ngFor="let format of dataFormats"
            [value]="format.value">
            {{ format.alias }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <div class="flex items-center justify-between">
        <button class="flex-initial w-full" (click)="query()" color="primary" mat-raised-button>
          <ng-container i18n="Submit | Submit the form"> Υποβολή </ng-container>
        </button>
      </div>
    </form>
  </div>
</div>
