import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {
  file: any;
  constructor(public ds: DomSanitizer, private dialogService: MatDialog, @Inject(LOCALE_ID) public localeid: string) { }

  ngOnInit() {
    if (this.localeid === 'el') {
      this.file = this.ds.bypassSecurityTrustResourceUrl('../assets/manuals/Οδηγός Χρήσης v3.pdf');
    }
    else{
      this.file = this.ds.bypassSecurityTrustResourceUrl('../assets/manuals/User guide v3.pdf');
    }
  }

  close() {
    this.dialogService.closeAll();
  }

}
