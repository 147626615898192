import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthenticationService } from './authentication.service';
import { LOCALE_ID, Inject } from '@angular/core';

// App imports 
import { StateService } from '../core/state.service';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
	private gisServerUrl: string;

	constructor(
		protected readonly router: Router,
		protected readonly keycloak: KeycloakService,
		private authService: AuthenticationService,
		@Inject(LOCALE_ID) public localeId: string
	) {
		super(router, keycloak);
		this.gisServerUrl = StateService.getScheme() + '//' + environment.geoserverDomain + '/' + 'geoserver/';
	}

	public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// Force the user to log in if currently unauthenticated.
		if (!this.authenticated) {
			let localeUri = this.localeId === 'el' ? '' : ('/' + this.localeId + '/');
			await this.keycloak.login({
				redirectUri: window.location.origin + localeUri + state.url,
			});
		}

		// Get the roles required from the route.
		const requiredRoles = route.data.roles;

		// Allow the user to to proceed if no additional roles are required to access the route.
		if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
			return true;
		}

		let rolesRequired = requiredRoles.every((role) => this.roles.includes(role))

		if (rolesRequired) {
			// return true;
			this.authService.afterLoginActions();
			// this.getCapabilities().subscribe((responce: any) => {
			// 	console.log(responce);
			// });
			return true;
		} else {
			return this.router.parseUrl('/notauthorized');
		}
		// Allow the user to proceed if all the required roles are present.
		// return requiredRoles.every((role) => this.roles.includes(role));
	}
}
